import axios from 'axios'
import Cookies from 'js-cookie'

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    'vlr-authorization': 'eyJpZCI6IlZBTV9BUFAifQ==',
    'authorization': Cookies.get('_ssoToken')
  }
})

const manageReponse = (response) => {
  const isError = response instanceof Error

  if (isError) {
    return response.response.data
  }

  const data = response.data.result.body
  const parsedData = JSON.parse(data)

  return parsedData
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  listTransactions: async () => {
    try {
      const result = await instance.post('/listtransaction')
      const response = manageReponse(result)

      return response
    } catch (error) {
      throw manageReponse(error)
    }
  },

  insertTransaction: async (payload) => {
    try {
      const result = await instance.post('/inserttransaction', payload)
      console.log(result)
      console.log(result.data)
      
      const response = result.data.response

      return response
    } catch (error) {
      throw manageReponse(error)
    }
  },
}
