import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AiFillClockCircle, AiOutlineRight } from "react-icons/ai";
import { FaHardHat } from "react-icons/fa";
import { MdDateRange } from "react-icons/md";
import styled from "styled-components";
// import AssemblyMakeUp from "../../assets/images/LandingPage/AssemblyMakeUp.png";
import MakeUpGraph from "../../assets/images/LandingPage/MakeUpGraphBlack.svg";
import MakeUpGraphBlue from "../../assets/images/LandingPage/MakeUpGraphBlue.png";
// import RigSite from "../../assets/images/LandingPage/RigSite.png";
// import YardInspection from "../../assets/images/LandingPage/YardInspection.png";
import { Button } from "../../components/common";
import {
  Body,
  HelloText,
  HelloTextBold,
  MainCard,
} from "../../components/common/MainCard";
// import Caller from "../../components/layout/caller";
import Footer from "../../components/layout/footer";
import Header from "../../components/layout/header";
import NavBar from "../../components/layout/navBar";
import { UserContext } from "../../stores/UserStore";
import { LandingPageContext } from "../../stores/LandingPageStore";
import { GraphContext } from "../../stores/GraphStore";
import LoadingScreen from "../../components/layout/LoadingScreen";
import { useHistory } from "react-router-dom";

const LandingPage = () => {
  document.title = "VAM® Field Service";
  const { currentUser } = useContext(UserContext);
  const { setReviewType } = useContext(GraphContext)
  const {
    // showRigSite,
    // showAssembly,
    // showYard,
    showMakeUpGraph,
    gotCustomerInformation,
    amountGraphs,
    timeElapsed,
    nextAppointment,
    // listOfOnSiteSupports,
    completeListOfOnSiteSupports,
    showHiddenInfo,
    GetSettingsFromUser,
    GetCustomerData,
    handleShowOnSiteList,
    handleCloseOnSiteList,
    ConvertTimezone
  } = useContext(LandingPageContext);

  const history = useHistory();

  const { t } = useTranslation();

  const SideBySideDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-right: 25px;
    button {
      min-height: 50px;
      width: 300px;
      background-color: #009ee0;
      color: white;
      margin-top: 20px;
      box-shadow: 0px 15px 20px rgba(167, 164, 206, 0.4);
      transition: all 0.3s ease 0s;
      :hover {
        background-color: #fff;
        color: #009ee0;
        transform: translateX(7px);
      }
    }
  `;

  const SideBySideOption = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 25px;
    margin-left: 30px;
  `;

  const LongCard = styled.div`
    color: gray;
    background-color: white;
    width: 100%;
    height: 250px;
    justify-content: center;
    text-align: center;
    padding-top: 10px;
    margin-top: 20px;
    position: relative;

    .Title {
      font-size: calc(12px + 0.5vw);
      color: #0f516d;
    }

    .JobList {
      padding-left: 75px;
      padding-right: 75px;
      display: flex;
      justify-content: space-between;
    }

    .MiniCard {
      width: 23%;
      margin-left: 10px;
      margin-bottom: 10px;
      height: 215px;
      justify-content: center;
      text-align: center;
      .Title {
        margin-top: 20px;
        margin-bottom: 10px;
        color: black;
      }
    }
    img {
      padding-top: 20px;
      padding-bottom: 10px;
      width: 50px;
      height: 50px;
    }

    .ButtonListOnSiteSupport {
      background-color: Transparent;
      background-repeat: no-repeat;
      border: none;
      cursor: pointer;
      overflow: hidden;
      margin: 0px;
    }
  `;

  const GrayOverlay = styled.div`
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
  `;

  const PopUpBeforeProceding = styled.div`
    position: absolute;
    left: 35%;
    top: 20%;
    border-style: outset;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 50px;
    margin-left: 20px;
    color: black;
    background-color: white;
    width: 400px;

    text-align: left;
    z-index: 1;

    .PopUpTitle {
      font-size: calc(18px + 0.6vw);
      font-weight: normal;
      margin-bottom: 23px;
    }

    .JobList {
      display: flex;
      justify-content: space-around;
    }

    button {
      margin-left: auto;
      margin-right: auto;
      height: 50px;
      justify-content: space-between;
      width: 100px;
      background-color: #fff;
      color: #009ee0;
      margin-top: 20px;
      box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);
      transition: all 0.3s ease 0s;
      border: 0px;
      border-radius: 50px;
      :hover {
        background-color: #009ee0;
        color: #fff;
        transform: translateX(7px);
      }
    }
  `;

  const Card = styled.div`
    color: #009ee0;
    background-color: white;
    width: 23%;
    height: 305px;
    justify-content: center;
    text-align: center;
    padding-top: 10px;
    position: relative;
    & + div {
      margin-left: 4vw;
    }

    img {
      padding-top: 29px;
      padding-bottom: 24px;
    }

    button {
      margin-left: auto;
      margin-right: auto;
      min-height: 50px;
      justify-content: space-between;
      width: 90%;
      background-color: #fff;
      color: #009ee0;
      margin-bottom: 20px;
      box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);
      transition: all 0.3s ease 0s;
      :hover {
        background-color: #009ee0;
        color: #fff;
        transform: translateX(7px);
      }
    }

    .Title {
      font-size: calc(12px + 0.5vw);
      color: #0f516d;
    }

    .BottomOfCard {
      position: absolute;
      bottom: 10px;
      width: 100%;
      .Subtitle {
        font-size: 12px;
        text-align: left;
        color: gray;
        margin-left: 20px;
      }
    }
  `;

  const handleMakeUp = (type) => {
    history.push("/MakeUpGraphAssistance");
    setReviewType(type)
  };

  const handleBookChatAppointment = () => {
    history.push("/CreateChat");
  };

  const handleProvideChatSupport = () => {
    history.push("/Messages");
  };

  const handleProvideVideoSupport = () => {
    history.push("/VideoChatMessages");
  };

  // const handleRigSite = () => {
  //   history.push("/UnderConstruction");
  // };

  // const handleAssembly = () => {
  //   history.push("/UnderConstruction");
  // };

  // const handleYard = () => {
  //   history.push("/UnderConstruction");
  // };


  useEffect(() => {
    (async () => {
      await GetSettingsFromUser()
      await GetCustomerData()
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Body>
      {/* {GetSettingsFromUser()}
      {GetCustomerData()} */}
      <Header />
      {/* <Caller /> */}
      <NavBar />
      <span style={{ display: !gotCustomerInformation ? "block" : "none" }}>
        <LoadingScreen />
      </span>
      <span style={{ display: gotCustomerInformation ? "block" : "none" }}>
        {showHiddenInfo && (
          <GrayOverlay id="GrayOverlay">
            <PopUpBeforeProceding>
              <center>
                <div className="PopUpTitle">
                  {" "}
                  {t("LandingPage.ListofOnSiteSupport")}
                </div>
              </center>

              {completeListOfOnSiteSupports.map((OnSiteSupport) => (
                <div key={OnSiteSupport.OnSiteSupportID}>
                  <div className="JobList">
                    {t("LandingPage.Job")} {OnSiteSupport.OnSiteSupportID}
                    <div />
                    {ConvertTimezone(OnSiteSupport.ServiceDate)}{" "}
                  </div>
                </div>
              ))}
              <center>
                <button
                  className="ButtonListOnSiteSupport"
                  onClick={handleCloseOnSiteList}
                >
                  {t("LandingPage.Close")}
                </button>
              </center>
            </PopUpBeforeProceding>
          </GrayOverlay>
        )}
        <MainCard id="MainCard">
          <SideBySideDiv>
            <HelloTextBold>
              {t("LandingPage.Hello")}{" "}
              {currentUser != null && currentUser.firstName}!
            </HelloTextBold>
            {currentUser != null && currentUser.roles.ADMIN_ACCESS ? (
              <SideBySideDiv>
                <Button
                  onClick={handleProvideChatSupport}
                  style={{ marginRight: "20px" }}
                >
                  {" "}
                  {t("LandingPage.ChatRoom")}
                </Button>
                <Button onClick={handleProvideVideoSupport}>
                  {" "}
                  {t("LandingPage.VideoChatCall")}
                </Button>
              </SideBySideDiv>
            ) : (
              <SideBySideDiv>
                <Button
                  onClick={handleBookChatAppointment}
                  style={{ marginRight: "20px" }}
                >
                  {" "}
                  {t("LandingPage.BookanAppointment")}
                </Button>
              </SideBySideDiv>
            )}
          </SideBySideDiv>
          <HelloText> {t("LandingPage.Whatcanwedoforyou?")}</HelloText>

          <SideBySideOption>
            {showMakeUpGraph && (
              <Card>
                <img alt="" src={MakeUpGraphBlue} />
                <div className="Title">
                  {t("LandingPage.Make-upGraphReview")}
                </div>
                <div className="BottomOfCard">
                  <div className="Subtitle">
                    {t("LandingPage.YourRequestReviewConcerns")}
                  </div>
                  <Button onClick={() => handleMakeUp("Assembly")}>
                    {" "}
                    <div>
                      {t("LandingPage.Assembly")}{" "}
                      <font style={{ fontWeight: "normal" }}>
                        {" "}
                        {t("LandingPage.Make-UpGraphs")}
                      </font>
                    </div>
                    <AiOutlineRight size="1em" />
                  </Button>
                  <Button onClick={() => handleMakeUp("RigSite")}>
                    {" "}
                    <div>
                      {t("LandingPage.RigSite")}

                      <font style={{ fontWeight: "normal" }}>
                        {" "}
                        {t("LandingPage.Make-UpGraphs")}
                      </font>
                    </div>
                    <AiOutlineRight size="1em" />
                  </Button>
                </div>
              </Card>
            )}
            {/* {showRigSite && (
              <Card>
                {" "}
                <img alt="" src={RigSite} />
                <div className="Title">
                  {t("LandingPage.RigSite")}
                  <div /> {t("LandingPage.TroubleShooting")}
                </div>
                <div className="BottomOfCard">
                  <Button onClick={handleRigSite}>
                    {" "}
                    <div>{t("LandingPage.RequestSuport")}</div>
                    <AiOutlineRight size="1em" />
                  </Button>
                </div>
              </Card>
            )} */}
            {/* {showAssembly && (
              <Card>
                {" "}
                <img alt="" src={AssemblyMakeUp} />
                <div className="Title">
                  {t("LandingPage.AssemblyMake-up")}
                  <div /> {t("LandingPage.TroubleShooting")}
                </div>
                <div className="BottomOfCard">
                  <Button onClick={handleAssembly}>
                    {" "}
                    <div>{t("LandingPage.RequestSuport")}</div>
                    <AiOutlineRight size="1em" />
                  </Button>
                </div>
              </Card>
            )}
            {showYard && (
              <Card>
                {" "}
                <img alt="" src={YardInspection} />
                <div className="Title">
                  {t("LandingPage.YardInspection")}
                  <div /> {t("LandingPage.TroubleShooting")}
                </div>
                <div className="BottomOfCard">
                  <Button onClick={handleYard}>
                    {" "}
                    <div>{t("LandingPage.RequestSuport")}</div>
                    <AiOutlineRight size="1em" />
                  </Button>
                </div>
              </Card>
            )} */}
          </SideBySideOption>
          <SideBySideOption>
            <LongCard>
              <div className="Title">{t("LandingPage.YourInformations")}</div>
              <SideBySideOption>
                <div className="MiniCard">
                  <img alt="" src={MakeUpGraph} />
                  <div className="Title">
                    {t("LandingPage.SubmittedMakeupGraphs")}{" "}
                  </div>
                  {amountGraphs}
                </div>
                <div className="MiniCard">
                  <AiFillClockCircle
                    size="2.5em"
                    style={{
                      paddingTop: "29px",
                      paddingBottom: "10px",
                      color: "grey",
                    }}
                  />
                  <div className="Title">
                    {" "}
                    {currentUser != null && currentUser.roles.ADMIN_ACCESS
                      ? t("LandingPage.TimeElapsedAdmin")
                      : t("LandingPage.TimeElapsed")}
                  </div>{" "}
                  {timeElapsed}
                </div>
                <div className="MiniCard">
                  <MdDateRange
                    size="2.5em"
                    style={{
                      paddingTop: "29px",
                      paddingBottom: "10px",
                      color: "grey",
                    }}
                  />
                  <div className="Title">
                    {t("LandingPage.NextAppointment")}
                  </div>
                  {nextAppointment}
                </div>
                <div className="MiniCard">
                  <FaHardHat
                    size="2.5em"
                    style={{
                      paddingTop: "29px",
                      paddingBottom: "10px",
                      color: "grey",
                    }}
                  />
                  <div className="Title">
                    {t("LandingPage.OnSiteSuppportSummary")}
                  </div>
                  {completeListOfOnSiteSupports.length > 2 ? completeListOfOnSiteSupports.length + '+' : completeListOfOnSiteSupports.length}
                  {completeListOfOnSiteSupports.length > 2 && (
                    <button
                      className="ButtonListOnSiteSupport"
                      onClick={handleShowOnSiteList}
                    >
                      {t("LandingPage.ViewAll")}
                    </button>
                  )}
                </div>
              </SideBySideOption>
            </LongCard>
          </SideBySideOption>
        </MainCard>
      </span>
      <Footer />
    </Body>
  );
};

export default LandingPage;
