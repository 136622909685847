import axios from "axios";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

const EndpointInsertArticles = process.env.REACT_APP_API_URL + "/insertarticle"

const EndpointListArticles = process.env.REACT_APP_API_URL + "/listarticles"

//     const EndpointInsertArticles =
//   window.location.href.indexOf("http://localhost:3000") > -1
//     ? "https://cojo0f0ovi.execute-api.us-west-2.amazonaws.com/default/insertarticle"
//     : process.env.REACT_APP_AWS_ENDPOINT + "/PieceRequest/Insert";

// const EndpointListArticles =
//   window.location.href.indexOf("http://localhost:3000") > -1
//     ? "https://cojo0f0ovi.execute-api.us-west-2.amazonaws.com/default/listarticles"
//     : process.env.REACT_APP_AWS_ENDPOINT + "/PieceRequest/List";

var LocalListArticles = [];
var verbose = true;
const header = {
  'vlr-authorization': 'eyJpZCI6ICJWQU1fQVBQIn0=',
  'authorization': Cookies.get("_ssoToken")
}

export async function ListArticles() {
  axios
    .post(EndpointListArticles, { headers: header })
    .then((response) => {
      LocalListArticles = response.data;
      if (verbose) {
        console.log(response);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function CreateArticle(
  userID,
  userName,
  ArticleText,
  ArticleTopic
) {
  const data = {
    AgentID: "" + userID,
    AgentName: "" + userName,
    ArticleText: "" + ArticleText,
    ArticleTopic: "" + ArticleTopic,
  };
  axios
    .post(EndpointInsertArticles, data, { headers: header })
    .then((response) => {
      toast.success("Created Article Sucessfully", { autoClose: 3000 });
      if (verbose) {
        console.log(response);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export function ReturnArticles() {
  var SavedArticles = [];
  LocalListArticles.forEach((element) => {
    SavedArticles.push(element);
  });

  SavedArticles.sort((a, b) => (a.LastAltered > b.LastAltered ? -1 : 1));

  return SavedArticles;
}

export function ReturnArticlesFiltered(Filtered) {
  var SavedChats = [];
  Filtered = Filtered.toLowerCase();
  LocalListArticles.forEach((element) => {
    if (
      element.AgentName.toLowerCase().includes(Filtered) ||
      element.ArticleTopic.toLowerCase().includes(Filtered) ||
      element.ArticleText.toLowerCase().includes(Filtered)
    ) {
      SavedChats.push(element);
    }
  });

  SavedChats.sort((a, b) => (a.LastAltered > b.LastAltered ? -1 : 1));

  return SavedChats;
}

export function ReturnArticlesID(ID) {
  var SavedChats = [];
  LocalListArticles.forEach((element) => {
    if (element.ArticleID.includes(ID)) {
      SavedChats.push(element);
    }
  });

  return SavedChats;
}
