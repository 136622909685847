import axios from "axios";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

const EndpointList = process.env.REACT_APP_API_URL + "/listmakeupgraph";

const EndpointInsert = process.env.REACT_APP_API_URL + "/insertmakeupgraph";

const EndpointUpdate = process.env.REACT_APP_API_URL + "/updatemakeupgraph";

var verbose = false;

var LocalListMakeUpGraph = [];

const header = {
  'vlr-authorization': 'eyJpZCI6ICJWQU1fQVBQIn0=',
  'authorization': Cookies.get("_ssoToken")
}

export async function ListMakeUpGraph(userID) {
  const data = {
    BuyerID: "" + userID,
  };
  axios
    .post(EndpointList, data, { headers: header })
    .then((response) => {
      console.log(response)
      console.log(data)
      LocalListMakeUpGraph = response.data.Items;
      if (verbose) {
        console.log(response);
      }
    })
    .catch((error) => {
      console.log(data)
      console.log(error);
    });
}

export async function ListMakeUpGraphByRequestID(requestID) {
  var result = [];
  const data = {
    RequestID: "" + requestID,
  };
  await axios
    .post(EndpointList, data, { headers: header })
    .then((response) => {
      if (verbose) {
        console.log(response);
      }
      result = response;
    })
    .catch((error) => {
      console.log(error);
    });

  return result;
}

export function ReturnMakeUpGraph() {
  var SavedMakeUpGraph = [];
  LocalListMakeUpGraph.forEach((element) => {
    SavedMakeUpGraph.push(element);
  });

  return SavedMakeUpGraph;
}

export async function SaveTempMakeUpGraph(userID, RequestID, Graphs, type) {
  const data = [];
  Graphs.forEach((element) => {
    const dataEach = {
      BuyerID: "" + userID,
      RequestID: "" + RequestID,
      RequestType: "" + type,
      Equipment: {
        CertificateUploadedLink: element.Equipment.CertificateUploadedLink,
        MakeUpEquipmentUsed: element.Equipment.MakeUpEquipmentUsed,
        DateLastCalibration: element.Equipment.DateLastCalibration,
        BuckingInOperator: element.Equipment.BuckingInOperator,
      },
      ThreadCompound: {
        RunningCompoundUsed: element.ThreadCompound.RunningCompoundUsed,
        RunningCompoundExpiryDate:
          element.ThreadCompound.RunningCompoundExpiryDate,
      },
      ConnectionInfo: {
        Diameter: element.ConnectionInfo.Diameter,
        BoxThreadWeight: element.ConnectionInfo.BoxThreadWeight,
        BoxThreadGrade: element.ConnectionInfo.BoxThreadGrade,
        BoxThreadType: element.ConnectionInfo.BoxThreadType,
        BoxEndTally: element.ConnectionInfo.BoxEndTally,
        PinThreadWeight: element.ConnectionInfo.PinThreadWeight,
        PinThreadGrade: element.ConnectionInfo.PinThreadGrade,
        PinThreadType: element.ConnectionInfo.PinThreadType,
        PinUndersized: element.ConnectionInfo.PinUndersized,
      },
      MakeUpResults: {
        MakeUpDateTime: element.MakeUpResults.MakeUpDateTime,
        FinalTorque: element.MakeUpResults.FinalTorque,
        ShoulderTorque: element.MakeUpResults.ShoulderTorque,
        DeltaTurns: element.MakeUpResults.DeltaTurns,
        ShoulderSlopeFactor: element.MakeUpResults.ShoulderSlopeFactor,
        Comments: element.MakeUpResults.Comments,
      },
      GeneralInfo: {
        CustomerOrWorkshopName: element.GeneralInfo.CustomerOrWorkshopName,
        EndUserOrOilCompany: element.GeneralInfo.EndUserOrOilCompany,
      },
    };
    data.push(dataEach);
  });
  axios
    .post(EndpointInsert, data, { headers: header })
    .then((response) => {
      if (verbose) {
        console.log(response);
      }
      toast.success("Saved Sucessfully", { autoClose: 3000 });
    })
    .catch((error) => {
      console.log(error);
      toast.success("Saved Error", { autoClose: 3000 });
    });
}

export async function UpdateMakeUpGraph(Graphs, type) {
  const data = [];
  Graphs.forEach((element) => {
    const dataEach = {
      MakeUpGraphID: element.MakeUpGraphID,
      BuyerID: element.BuyerID,
      RequestID: element.RequestID,
      RequestType: "" + type,
      Equipment: {
        CertificateUploadedLink: element.Equipment.CertificateUploadedLink,
        MakeUpEquipmentUsed: element.Equipment.MakeUpEquipmentUsed,
        DateLastCalibration: element.Equipment.DateLastCalibration,
        BuckingInOperator: element.Equipment.BuckingInOperator,
      },
      ThreadCompound: {
        RunningCompoundUsed: element.ThreadCompound.RunningCompoundUsed,
        RunningCompoundExpiryDate:
          element.ThreadCompound.RunningCompoundExpiryDate,
      },
      ConnectionInfo: {
        Diameter: element.ConnectionInfo.Diameter,
        BoxThreadWeight: element.ConnectionInfo.BoxThreadWeight,
        BoxThreadGrade: element.ConnectionInfo.BoxThreadGrade,
        BoxThreadType: element.ConnectionInfo.BoxThreadType,
        BoxEndTally: element.ConnectionInfo.BoxEndTally,
        PinThreadWeight: element.ConnectionInfo.PinThreadWeight,
        PinThreadGrade: element.ConnectionInfo.PinThreadGrade,
        PinThreadType: element.ConnectionInfo.PinThreadType,
        PinUndersized: element.ConnectionInfo.PinUndersized,
      },
      MakeUpResults: {
        MakeUpDateTime: element.MakeUpResults.MakeUpDateTime,
        FinalTorque: element.MakeUpResults.FinalTorque,
        ShoulderTorque: element.MakeUpResults.ShoulderTorque,
        DeltaTurns: element.MakeUpResults.DeltaTurns,
        ShoulderSlopeFactor: element.MakeUpResults.ShoulderSlopeFactor,
        Comments: element.MakeUpResults.Comments,
      },
      GeneralInfo: {
        CustomerOrWorkshopName: element.GeneralInfo.CustomerOrWorkshopName,
        EndUserOrOilCompany: element.GeneralInfo.EndUserOrOilCompany,
      },
    };
    data.push(dataEach);
  });
  axios
    .post(EndpointUpdate, data, { headers: header })
    .then((response) => {
      if (verbose) {
        console.log(response);
      }
      toast.success("Make up graph updated successfully", {autoClose: 3000})
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function SubmitMakeUpGraph(userID, RequestID, Graphs, type) {
  const data = [];
  Graphs.forEach((element) => {
    const dataEach = {
      BuyerID: "" + userID,
      RequestID: "" + RequestID,
      RequestType: "" + type,
      Equipment: {
        CertificateUploadedLink: element.Equipment.CertificateUploadedLink,
        MakeUpEquipmentUsed: element.Equipment.MakeUpEquipmentUsed,
        DateLastCalibration: element.Equipment.DateLastCalibration,
        BuckingInOperator: element.Equipment.BuckingInOperator,
      },
      ThreadCompound: {
        RunningCompoundUsed: element.ThreadCompound.RunningCompoundUsed,
        RunningCompoundExpiryDate:
          element.ThreadCompound.RunningCompoundExpiryDate,
      },
      ConnectionInfo: {
        Diameter: element.ConnectionInfo.Diameter,
        BoxThreadWeight: element.ConnectionInfo.BoxThreadWeight,
        BoxThreadGrade: element.ConnectionInfo.BoxThreadGrade,
        BoxThreadType: element.ConnectionInfo.BoxThreadType,
        BoxEndTally: element.ConnectionInfo.BoxEndTally,
        PinThreadWeight: element.ConnectionInfo.PinThreadWeight,
        PinThreadGrade: element.ConnectionInfo.PinThreadGrade,
        PinThreadType: element.ConnectionInfo.PinThreadType,
        PinUndersized: element.ConnectionInfo.PinUndersized,
      },
      MakeUpResults: {
        MakeUpDateTime: element.MakeUpResults.MakeUpDateTime,
        FinalTorque: element.MakeUpResults.FinalTorque,
        ShoulderTorque: element.MakeUpResults.ShoulderTorque,
        DeltaTurns: element.MakeUpResults.DeltaTurns,
        ShoulderSlopeFactor: element.MakeUpResults.ShoulderSlopeFactor,
        Comments: element.MakeUpResults.Comments,
      },
      GeneralInfo: {
        CustomerOrWorkshopName: element.GeneralInfo.CustomerOrWorkshopName,
        EndUserOrOilCompany: element.GeneralInfo.EndUserOrOilCompany,
      },
    };
    data.push(dataEach);
  });
  return axios
    .post(EndpointInsert, data, { headers: header })
    .then((response) => {
      if (verbose) {
        console.log(response);
      }
      toast.success("Make up graph submited successfully", {autoClose: 3000})
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      toast.success("Submited Error", { autoClose: 3000 });
    });
}
