import React, { useState, useContext } from "react";
// import { useHistory } from "react-router-dom";
import {
  returnConnectionsCatalog,
  returnDiameterCatalog,
  returnDesignCatalog,
  returnWeightCatalog,
  returnFilterDesign,
  returnFilterDiameter,
  returnFilterWheigth
} from "../components/connect/makeUpGraphForm";

import {
  ListMakeUpGraphByRequestID,
  SubmitMakeUpGraph,
  UpdateMakeUpGraph,
} from "../components/connect/makeUpGraphConnect";
import _ from "lodash";
import { toast } from "react-toastify";
import {
  SubmitMakeUpGraphRequest,
  UploadGraphsMakeUpGraphRequests,
} from "../components/connect/makeUpGraphRequestConnect";
import * as ConnectMakeUpGraphReview from "../components/connect/makeUpGraphRequestConnect";
import { GetAllCatalog } from '../components/connect/makeUpGraphForm'
import { sendFile, sendFileS3 } from "../components/connect/sendFileToUpload";
import { UserContext } from "../stores/UserStore";
import { parseInt } from "lodash";
import { LoadingOverlay } from "../components/common";
import { useEffect } from "react";
import { UploadFilesMakeUpGraphRequests } from "../components/connect/makeUpGraphRequestConnect";
import styled from "styled-components";
import transactions from '../api/transactions';

const initialState = {
  item: 1,
};

export const GraphContext = React.createContext(initialState);

export const GraphProvider = ({ children }) => {

  // const history = useHistory();

  const ListOfFiles = styled.div`
  font-size: calc(8px + 0.3vw);
  text-align: center;
  justify-content: center;
`;

  const [graph, setGraph] = useState(1);
  const [graphUnitMesure, setGraphUnitMesure] = useState("");
  const [graphID, setGraphID] = useState("");
  const [graphContinue, setGraphContinue] = useState(false);
  const [iceCandidate, setIceCandidate] = useState([]);

  const [SelectedCheckBox] = useState([false, false, false, false]);
  const [savedMakeUpGraphs, setSavedMakeUpGraphs] = useState([]);
  const [reviewMakeUpGraphs, setReviewedMakeUpGraphs] = useState([]);
  const [showHiddenInfo, setShowHiddenInfo] = useState(false);
  const [gotSavedData, setGotSavedData] = useState(false);

  const [isThereAnRequestID] = useState(false);
  const [allowFinish, setAllowFinish] = useState(false);
  const [errorNumber, setErrorNumber] = useState(false);
  const [errorType, setErrorType] = useState(false);
  const [errorSize, setErrorSize] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(false);
  const [overlayUnit, setOverlayUnit] = useState(true);
  const [overlayDetail, setOverlayUpload] = useState(true);
  const [overlayCreate, setOverlayCreate] = useState(true);
  const [returnedFromForm, setReturnedFromForm] = useState(false);
  const [returnedFromFormCheck, setReturnedFromFormCheck] = useState(false);
  const [fileListLenght, setFileListLenght] = useState(0);
  const [fileListSize, setFileListSize] = useState(0);
  const [fileList, setFileList] = useState([]);
  const [reviewType, setReviewType] = useState([]);

  const [draftedMakeUpGraphs, setDraftedMakeUpGraphs] = useState([]);

  //BOX
  const [selectedConnections, setSelectedConnections] = useState([]);
  const [selectedDesign, setSelectedDesign] = useState([]);
  const [selectedDiameter, setSelectedDiameter] = useState([]);
  const [selectedWeight, setSelectedWeight] = useState([]);
  const [selectedSmys, setSelectedSmys] = useState([]);

  const [connections, setConnections] = useState([]);
  const [diameter, setDiameter] = useState([]);
  const [design, setDesign] = useState([]);
  const [weight, setWeight] = useState([]);
  const [SMYS, setSMYS] = useState([]);


  //PIN
  const [selectedConnectionsPin, setSelectedConnectionsPin] = useState([]);
  const [selectedDesignPin, setSelectedDesignPin] = useState([]);
  const [selectedDiameterPin, setSelectedDiameterPin] = useState([]);
  const [selectedWeightPin, setSelectedWeightPin] = useState([]);
  const [selectedSmysPin, setSelectedSmysPin] = useState([]);

  const [connectionsPin, setConnectionsPin] = useState([]);
  const [diameterPin, setDiameterPin] = useState([]);
  const [designPin, setDesignPin] = useState([]);
  const [weightPin, setWeightPin] = useState([]);
  const [SMYSPin, setSMYSPin] = useState([]);

  const [MakeUpGraphs, setMakeUpGraphs] = useState([]);
  const [ListFiles] = useState([]);
  const [FilesNames, setFilesNames] = useState([]);
  const [CreateForm, setCreateForm] = useState(true);
  const [ActiveGraph, setActiveGraph] = useState(0);
  const [tab, setTab] = useState(0)
  const [isLoading, setLoading] = useState(true);

  let Connection = '';
  let Design = '';
  let Diameter = '';
  let Weight = '';
  let smys = '';
  let filterDesign = []
  let filterDO = []
  let filterWeight = []


  let ConnectionPin = '';
  let DesignPin = '';
  let DiameterPin = '';
  let WeightPin = '';
  let smysPin = '';
  let filterDesignPin = []
  let filterDOPin = []
  let filterWeightPin = []


  const { currentUser } = useContext(UserContext);

  const handleGetList = (BuyerID) => {
    if (!gotSavedData) {
      setTimeout(async () => {
        // eslint-disable-line no-unused-vars
        await ConnectMakeUpGraphReview.ListMakeUpGraphRequests(BuyerID);
        setReviewedMakeUpGraphs(
          ConnectMakeUpGraphReview.ReturnReviewedMakeUpGraphRequests()
        );
        setSavedMakeUpGraphs(
          ConnectMakeUpGraphReview.ReturnSavedMakeUpGraphRequests()
        );
        setDraftedMakeUpGraphs(
          ConnectMakeUpGraphReview.ReturnDraftedMakeUpGraphRequests()
        )
        setGotSavedData(true);
      }, 50);
    }
  };
  const ConvertTimezone = (oldDate) => {
    var date = new Date(oldDate),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2),
      hour = ("0" + (date.getHours() + 1)).slice(-2),
      minute = ("0" + (date.getMinutes() + 1)).slice(-2);
    return (
      [day, mnth, date.getFullYear()].join("/") + " " + hour + ":" + minute
    );
  };

  const handleDeleteSaved = async (RequestID) => {
    await ConnectMakeUpGraphReview.DeleteMakeUpGraphRequests(RequestID);
    setGotSavedData(false);
    setReviewedMakeUpGraphs(
      ConnectMakeUpGraphReview.ReturnReviewedMakeUpGraphRequests()
    );
    setSavedMakeUpGraphs(
      ConnectMakeUpGraphReview.ReturnSavedMakeUpGraphRequests()
    );
  };

  const handleContinue = (ID, NumberGraph) => {
    setGraphID(ID);
    setGraph(NumberGraph);
    setGraphContinue(true);
    // history.push("/MakeUpGraphForm");
    window.location.href = "/MakeUpGraphForm";
  };

  const handleChangeCheckbox = (option) => {
    console.log(option)
    if (option === 1) {
      SelectedCheckBox[0] = !SelectedCheckBox[0];
    } else if (option === 2) {
      SelectedCheckBox[1] = !SelectedCheckBox[1];
    } else if (option === 3) {
      SelectedCheckBox[2] = !SelectedCheckBox[2];
    } else if (option === 4) {
      SelectedCheckBox[3] = !SelectedCheckBox[3];
    } else if (option === 5) {
      SelectedCheckBox[4] = !SelectedCheckBox[4];
    }
  };

  const HandleShowPopUp = () => {
    setShowHiddenInfo(true);
  };

  const handleCancel = () => {
    SelectedCheckBox[0] = false;
    SelectedCheckBox[1] = false;
    SelectedCheckBox[2] = false;
    SelectedCheckBox[3] = false;
    SelectedCheckBox[4] = false;
    setShowHiddenInfo(false);
  };

  const handleConfirmAssembly = () => {
    if (
      SelectedCheckBox[0] &&
      SelectedCheckBox[1] &&
      SelectedCheckBox[2] &&
      SelectedCheckBox[3] &&
      SelectedCheckBox[4]
    ) {
      setGraphID("");

      window.location.href = "./MakeUpGraphAssistance"
      // history.push("./MakeUpGraphAssistance");
    } else {
      document.getElementById("errorMessage").style.display = "block";
    }
  };

  const CheckForMakeUpGraphID = () => {
    // if (!returnedFromFormCheck) {
      if (graphID !== "") {
        setReturnedFromForm(true);
      }
      // setReturnedFromFormCheck(true);
    // }
  };

  const handleUploadToLambda = async () => {
    var link;
    if (fileList.size > 5990000 || fileList.fileSize > 5990000) {
      link = await sendFileS3(fileList, String(currentUser.id));
    } else {
      link = await sendFile(fileList, String(currentUser.id));
    }
    const results = [];

    for (let i = 0; i < link.length; i++) {
      results.push(link[i].link);
    }
    UploadFilesMakeUpGraphRequests(graphID, results);
    window.location.href = '/MakeUpGraphFinish'
    // history.push("/MakeUpGraphFinish");
    const payload = {
      operation: 'Graph Analysis',
      oldBudget:  '1',
      newBudget: "0",
      customer: `${Object.keys(currentUser.roles.COMPANY)[0]}`,
      user: `${currentUser.firstName} ${currentUser.lastName}`
    }

    await transactions.insertTransaction(payload)
  
  };

  const handleUpload = () => {
    document.getElementById("file").click();
  };

  const showUpload = () => {
    setOverlayUpload(false);
  };

  const showCreate = () => {
    setOverlayCreate(false);
  };

  const showUnit = () => {
    setOverlayUnit(false);
  };

  const handleListFiles = (event) => {
    const inputFiles = event.target.files;
    const filesList = [];
    var errorTypeFlag = false;
    var errorSizeFlag = false;
    var fileSize = 0;

    for (let i = 0; i < inputFiles.length; i++) {
      fileSize = fileSize + inputFiles[i].size / 1000000;
      if (fileSize < 20) {
        if (
          inputFiles[i].type === "application/pdf" ||
          inputFiles[i].type === "application/csv"
        ) {
          filesList.push(inputFiles[i]);
        } else {
          errorTypeFlag = true;
        }
      } else {
        errorSizeFlag = true;
      }
      setFileList(filesList);
    }
    setUploadedFile(true);
    setAllowFinish(true);
    setFileListSize(Math.round(fileSize * 100) / 100);
    setFileListLenght(inputFiles.length);
    setErrorType(errorTypeFlag);
    setErrorSize(errorSizeFlag);
    setErrorNumber(false);
  };

  const handleIncreaseGraphs = () => {
    var value = document.getElementById("GraphAmount").value;
    if (value !== "") {
      document.getElementById("GraphAmount").value =
        parseInt(document.getElementById("GraphAmount").value) + 1;
      SaveNumberGraphs();
    } else {
      document.getElementById("GraphAmount").value = 1;
    }
  };

  const SaveNumberGraphs = () => {
    var value = document.getElementById("GraphAmount").value;
    if (value !== "") {
      setGraph(value);
    }
  };

  const handleDecreaseGraphs = () => {
    var value = document.getElementById("GraphAmount").value;
    if (value !== "") {
      if (value > 0) {
        document.getElementById("GraphAmount").value =
          parseInt(document.getElementById("GraphAmount").value) - 1;
        SaveNumberGraphs();
      }
    } else {
      document.getElementById("GraphAmount").value = 0;
    }
  };

  function PopulateListFiles() {
    if (fileList.toString() !== "") {
      return (
        <ListOfFiles>
          {fileListLenght} file(s) selected <div />
          Total Size:{fileListSize} MB
        </ListOfFiles>
      );
    } else {
      return <> </>;
    }
  }

  const handleChangeMetricUnit = () => {
    if (graphUnitMesure === "" && currentUser !== null) {
      currentUser.measuringUnit !== null
        ? setGraphUnitMesure(currentUser.measuringUnit)
        : setGraphUnitMesure("Metric");
    }
    if (graphUnitMesure === "Metric") {
      document.getElementById("Metric").className = "";
      document.getElementById("Imperial").className = "active";
      setGraphUnitMesure("Imperial");
    } else {
      document.getElementById("Metric").className = "active";
      document.getElementById("Imperial").className = "";
      setGraphUnitMesure("Metric");
    }
  };

  const createConnectionsOption = () => {
    let Fraction = require('fractional').Fraction
    let connectionBox = [];
    let diameterBox = [];
    let designBox = [];
    let weightBox = [];

    let returnConnections = _.uniqBy(returnConnectionsCatalog());
    let returnDiameter = _.uniqBy(returnDiameterCatalog());
    let returnDesign = _.uniqBy(returnDesignCatalog());
    let returnWeight = _.uniqBy(returnWeightCatalog());

    _.map(returnConnections, item => {
      connectionBox.push({ value: item, label: item })
    })
    setConnections(connectionBox);
    setConnectionsPin(connectionBox);

    _.map(returnDiameter, item => {
      diameterBox.push({ value: item, label: (new Fraction(item)).toString() })
    })
    setDiameter(diameterBox);
    setDiameterPin(connectionBox);

    _.map(returnDesign, item => {
      designBox.push({ value: item, label: item })
    })
    setDesign(designBox)
    setDesignPin(designBox);

    _.map(returnWeight, item => {
      weightBox.push({ value: item, label: item })
    })
    setWeight(weightBox);
    setWeightPin(weightBox);

    let i = 50;
    let smys = []
    while (i !== 155) {
      smys.push({ value: i, label: i })
      i = i + 5;
    }
    setSMYS(smys)
    setSMYSPin(smys)
  }

  const handleCompleteFromMakeUpGraphs = () => {
    const idsEquipment = [
      "MakeUpEquipmentUsed",
      "DateLastCalibration",
      "BuckingInOperator"
    ];

    const idsThreadCompound = [
      "RunningCompoundUsed",
      "RunningCompoundExpiryDate"
    ];

    const idsConnectionInfo = [
      "BoxThreadType",
      "BoxDesign",
      "BoxDiameter",
      "BoxThreadWeight",
      "BoxSMYS",
      "BoxThreadGrade",
      "BoxEndTally",

      "PinThreadType",
      "PinThreadDesign",
      "PinDiameter",
      "PinWeight",
      "PinSMYS",
      "PinThreadGrade",
      "PinInformation"
    ];

    const idsMakeUpResults = [
      "MakeUpDateTime1",
      "MakeUpDateTime2",
      "FinalTorque",
      "ShoulderTorque",
      "DeltaTurns",
      "ShoulderSlopeFactor",
      "Comments",
    ]

    const idsGeneralInfo = [
      "CustomerOrWorkshopName",
      "EndUserOrOilCompany"
    ]

    for (let i = 0; i < graph; i++) {

      // document.getElementById("f1" + i).value = MakeUpGraphs[i].Equipment.MakeUpEquipmentUsed;
      // document.getElementById("f3" + i).value = MakeUpGraphs[i].Equipment.DateLastCalibration;
      // document.getElementById("f4" + i).value = MakeUpGraphs[i].Equipment.BuckingInOperator;
      // document.getElementById("f5" + i).value = MakeUpGraphs[i].ThreadCompound.RunningCompoundUsed;
      // document.getElementById("f6" + i).value = MakeUpGraphs[i].ThreadCompound.RunningCompoundExpiryDate;

      for (let item of idsEquipment) {
        const element = document.getElementById(item)
        if (element && element.value)
          element.value = MakeUpGraphs[i].Equipment[item];
      }

      for (let item of idsThreadCompound) {
        const element = document.getElementById(item)
        if (element && element.value)
          element.value = MakeUpGraphs[i].ThreadCompound[item];
      }

      for (let item of idsConnectionInfo) {
        const element = document.getElementById(item)
        if (element && element.value)
          element.value = MakeUpGraphs[i].ConnectionInfo[item];
      }

      for (let item of idsMakeUpResults) {
        const element = document.getElementById(item)
        if (element && element.value)
          element.value = MakeUpGraphs[i].MakeUpResults[item];
      }

      for (let item of idsGeneralInfo) {
        const element = document.getElementById(item)
        if (element && element.value)
          element.value = MakeUpGraphs[i].GeneralInfo[item];
      }
    }
    // for (let i = 0; i < graph; i++) {
    //   
    //   document.getElementById("Connections").value = MakeUpGraphs[i].ConnectionInfo.BoxThreadType;
    //   document.getElementById("Design").value = MakeUpGraphs[i].ConnectionInfo.BoxDesign;
    //   document.getElementById("Diameter").value = MakeUpGraphs[i].ConnectionInfo.BoxDiameter;
    //   document.getElementById("Weight").value = MakeUpGraphs[i].ConnectionInfo.BoxThreadWeight;
    //   document.getElementById("SMYS").value = MakeUpGraphs[i].ConnectionInfo.BoxSMYS;
    //   document.getElementById("BoxThreadGrade").value = MakeUpGraphs[i].ConnectionInfo.BoxThreadGrade;
    //   document.getElementById("BoxEndTally").value = MakeUpGraphs[i].ConnectionInfo.BoxEndTally;

    //   document.getElementById("PinThreadType").value = MakeUpGraphs[i].ConnectionInfo.PinThreadType;
    //   document.getElementById("PinThreadDesign").value = MakeUpGraphs[i].ConnectionInfo.PinDesign;
    //   document.getElementById("PinDiameter").value = MakeUpGraphs[i].ConnectionInfo.PinDiameter;
    //   document.getElementById("PinWeight").value = MakeUpGraphs[i].ConnectionInfo.PinThreadWeight;
    //   document.getElementById("PinSMYS").value = MakeUpGraphs[i].ConnectionInfo.PinSMYS;
    //   document.getElementById("PinThreadGrade").value = MakeUpGraphs[i].ConnectionInfo.PinThreadGrade;
    //   document.getElementById("PinInformation").value = MakeUpGraphs[i].ConnectionInfo.PinUndersized;

    //   document.getElementById("f16" + i).value = MakeUpGraphs[i].MakeUpResults.MakeUpDateTime;
    //   document.getElementById("f17" + i).value = MakeUpGraphs[i].MakeUpResults.MakeUpDateTime;
    //   document.getElementById("f18" + i).value = MakeUpGraphs[i].MakeUpResults.FinalTorque;
    //   document.getElementById("f19" + i).value = MakeUpGraphs[i].MakeUpResults.ShoulderTorque;
    //   document.getElementById("f20" + i).value = MakeUpGraphs[i].MakeUpResults.DeltaTurns;
    //   document.getElementById("f21" + i).value = MakeUpGraphs[i].MakeUpResults.ShoulderSlopeFactor;
    //   document.getElementById("f22" + i).value = MakeUpGraphs[i].MakeUpResults.Comments;
    //   document.getElementById("f23" + i).value = MakeUpGraphs[i].GeneralInfo.CustomerOrWorkshopName;
    //   document.getElementById("f24" + i).value = MakeUpGraphs[i].GeneralInfo.EndUserOrOilCompany;
    // }
  };

  const handleInputFileReplicator = (File, ID) => {
    handleRefreshMakeUpGraphs();
    ListFiles[ID] = File;
    var NamesList = [];
    setFilesNames();
    for (let i = 0; i < MakeUpGraphs.length; i++) {
      if (ListFiles[i] !== undefined) {
        NamesList.push(ListFiles[i].name);
      }
    }
    setFilesNames(NamesList);
    document.getElementById(ID).style.display = "block";
  };

  const handleSaveMakeUpGraph = async () => {
    handleRefreshMakeUpGraphs();
    if (graphID === "") {
      const responseFromMakeUpGraphRequest = await SubmitMakeUpGraphRequest(
        currentUser.id,
        graph,
        graphUnitMesure,
        reviewType
      );
      const responseFromMakeUpGraph = await SubmitMakeUpGraph(
        currentUser.id,
        responseFromMakeUpGraphRequest,
        MakeUpGraphs,
        reviewType,
      );

      var GraphsList = [];
      setGraphID(responseFromMakeUpGraphRequest);

      for (let i = 0; i < responseFromMakeUpGraph.length; i++) {
        MakeUpGraphs[i].MakeUpGraphID =
          responseFromMakeUpGraph[i].MakeUpGraphID;
        MakeUpGraphs[i].RequestID = responseFromMakeUpGraphRequest;
        GraphsList.push(responseFromMakeUpGraph[i].MakeUpGraphID);
      }

      UploadGraphsMakeUpGraphRequests(
        responseFromMakeUpGraphRequest,
        GraphsList
      );
      return true;
    } else {
      UpdateMakeUpGraph(MakeUpGraphs, reviewType);
      return true;
    }
  };

  const handleRefreshMakeUpGraphs = () => {
    const idsEquipment = [
      "MakeUpEquipmentUsed",
      "DateLastCalibration",
      "BuckingInOperator"
    ];

    const idsThreadCompound = [
      "RunningCompoundUsed",
      "RunningCompoundExpiryDate"
    ];

    const idsConnectionInfo = [
      "BoxThreadType",
      "BoxDesign",
      "BoxDiameter",
      "BoxThreadWeight",
      "BoxSMYS",
      "BoxThreadGrade",
      "BoxEndTally",

      "PinThreadType",
      "PinThreadDesign",
      "PinDiameter",
      "PinWeight",
      "PinSMYS",
      "PinThreadGrade",
      "PinInformation"
    ];

    const idsMakeUpResults = [
      "MakeUpDateTime1",
      "MakeUpDateTime2",
      "FinalTorque",
      "ShoulderTorque",
      "DeltaTurns",
      "ShoulderSlopeFactor",
      "Comments",
    ]

    const idsGeneralInfo = [
      "CustomerOrWorkshopName",
      "EndUserOrOilCompany"
    ]

    for (let i = 0; i < graph; i++) {

      for (let item of idsEquipment) {
        const element = document.getElementById(item)
        if (element && element.value)
          MakeUpGraphs[i].Equipment[item] = element.value;
      }

      for (let item of idsThreadCompound) {
        const element = document.getElementById(item)
        if (element && element.value)
          MakeUpGraphs[i].ThreadCompound[item] = element.value;
      }

      for (let item of idsConnectionInfo) {
        const element = document.getElementById(item)
        if (element && element.value)
          MakeUpGraphs[i].ConnectionInfo[item] = element.value;
      }

      for (let item of idsMakeUpResults) {
        const element = document.getElementById(item)
        if (element && element.value)
          MakeUpGraphs[i].MakeUpResults[item] = element.value;
      }

      for (let item of idsGeneralInfo) {
        const element = document.getElementById(item)
        if (element && element.value)
          MakeUpGraphs[i].GeneralInfo[item] = element.value;
      }
    }
    setMakeUpGraphs(MakeUpGraphs);
  };

  const handleInputFile = async (event, ID) => {
    ListFiles[ID] = event.target.files[0];
    var NamesList = [];
    setFilesNames([]);
    for (let i = 0; i < MakeUpGraphs.length; i++) {
      if (ListFiles[i] !== undefined) {
        NamesList.push(ListFiles[i].name);
      }
    }
    handleRefreshMakeUpGraphs();
    setFilesNames(NamesList);
    setActiveGraph(parseInt(ActiveGraph));
    setMakeUpGraphs(MakeUpGraphs);
    var link;
    if (ListFiles[ID].size > 5990000 || ListFiles[ID].fileSize > 5990000) {
      link = await sendFileS3(ListFiles[ID], String(currentUser.id));
    } else {
      link = await sendFile(ListFiles[ID], String(currentUser.id));
    }
    MakeUpGraphs[ID].Equipment.CertificateUploadedLink = link[0].link;
    console.log(MakeUpGraphs)
  }

  const handleContinueSaved = async () => {
    var SavedGraph = await ListMakeUpGraphByRequestID(graphID);
    SavedGraph = SavedGraph.data;
    SavedGraph = SavedGraph.reverse();
    for (let i = 0; i < SavedGraph.length; i++) {
      Object.assign(SavedGraph[i], { ID: i });
      MakeUpGraphs[i] = SavedGraph[i];
    }
    setGraphID(SavedGraph[0].RequestID);
    handleCompleteFromMakeUpGraphs();
    var NamesList = [];
    for (let i = 0; i < MakeUpGraphs.length; i++) {
      if (MakeUpGraphs[i].Equipment.CertificateUploadedLink !== "") {
        NamesList.push(
          MakeUpGraphs[i].Equipment.CertificateUploadedLink.split("/")[4]
            .substring(13)
            .replaceAll("%20", " ")
            .replaceAll("%28", "(")
            .replaceAll("%29", ")")
        );
      }
    }
    handleRefreshMakeUpGraphs();
    setFilesNames(NamesList);
    setGraphContinue(false);
  }

  useEffect(() => {
    GetAllCatalog().then(() => {
      createConnectionsOption()
    }).finally(() => {
      setLoading(false)
    })
  }, [])

  const handleCreateForm = () => {
    if (CreateForm) {
      for (let i = 0; i < graph; i++) {
        MakeUpGraphs.push({
          ID: i,
          MakeUpGraphID: "",
          BuyerID: currentUser.id,
          RequestID: "",
          Equipment: {
            CertificateUploadedLink: "",
            MakeUpEquipmentUsed: "",
            DateLastCalibration: "0001-01-01T00:00:00.0000000",
            BuckingInOperator: "",
          },
          ThreadCompound: {
            RunningCompoundUsed: "",
            RunningCompoundExpiryDate: "0001-01-01T00:00:00.0000000",
          },
          ConnectionInfo: {
            BoxThreadType: "",
            BoxDesign: "",
            BoxDiameter: 0,
            BoxThreadWeight: 0,
            BoxSMYS: 0,
            BoxThreadGrade: 0,
            BoxEndTally: "",

            PinThreadType: "",
            PinDesign: "",
            PinDiameter: 0,
            PinThreadWeight: 0,
            PinSMYS: 0,
            PinThreadGrade: 0,
            PinUndersized: "",
          },
          MakeUpResults: {
            MakeUpDateTime: "0001-01-01T00:00:00.0000000",
            FinalTorque: 0,
            ShoulderTorque: 0,
            DeltaTurns: 0,
            ShoulderSlopeFactor: 0,
            Comments: "",
          },
          GeneralInfo: {
            CustomerOrWorkshopName: "",
            EndUserOrOilCompany: "",
          },
        });
        if (graphContinue) {
          handleContinueSaved();
        }
      }
      setCreateForm(false);
    }
    setLoading(false);
  }

  const StartMaximumDate = () => {
    setTimeout(() => {
      for (var ID = 0; ID < MakeUpGraphs.length; ID++) {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();
        if (dd < 10) {
          dd = "0" + dd;
        }
        if (mm < 10) {
          mm = "0" + mm;
        }

        today = yyyy + "-" + mm + "-" + dd;
        if (document.getElementById("f3" + ID) != null) {
          document.getElementById("f3" + ID).setAttribute("max", today);
        }
      }
    }, 50)
  };

  const handleSubmit = async () => {
    await handleSaveMakeUpGraph();
    if (CheckIfValidValues()) {
      window.location.href = "./MakeUpGraphAssistance"
      // history.push("./MakeUpGraphAssistance");
    } else {
      toast.error("One or more fields are not filled");
    }
  };

  const handleSaveForm = async () => {
    await handleSaveMakeUpGraph();
  };


  const CheckIfValidValues = () => {
    var result = true;
    for (let i = 0; i < graph; i++) {
      result = result && CheckText(MakeUpGraphs[i].Equipment.CertificateUploadedLink, 'Upload Link');
      result = result && CheckText(MakeUpGraphs[i].Equipment.MakeUpEquipmentUsed, 'Equipment');
      result = result && CheckDate(MakeUpGraphs[i].Equipment.DateLastCalibration, 'Calibration');
      result = result && CheckText(MakeUpGraphs[i].Equipment.BuckingInOperator, 'Bucking');
      result = result && CheckText(MakeUpGraphs[i].ThreadCompound.RunningCompoundUsed, 'Compound');
      result = result && CheckDate(MakeUpGraphs[i].ThreadCompound.RunningCompoundExpiryDate, 'Comp Date');

      // result = result && CheckText(MakeUpGraphs[i].ConnectionInfo.BoxThreadType, 'Box Type');
      result = result && CheckText(MakeUpGraphs[i].ConnectionInfo.BoxDesign, 'Box Design');
      result = result && CheckText(MakeUpGraphs[i].ConnectionInfo.BoxDiameter, 'Box Diameter');
      result = result && CheckNumber(MakeUpGraphs[i].ConnectionInfo.BoxThreadWeight, 'Box');
      result = result && CheckNumber(MakeUpGraphs[i].ConnectionInfo.BoxSMYS, 'Box SMYS');
      result = result && CheckNumber(MakeUpGraphs[i].ConnectionInfo.BoxThreadGrade, 'Box Grade');
      // result = result && CheckText(MakeUpGraphs[i].ConnectionInfo.BoxEndTally, 'Box End');

      result = result && CheckText(MakeUpGraphs[i].ConnectionInfo.PinThreadType, 'Pin Thread');
      // result = result && CheckText(MakeUpGraphs[i].ConnectionInfo.PinDesign, 'Pin Design');
      result = result && CheckText(MakeUpGraphs[i].ConnectionInfo.PinDiameter, 'Pin Diameter');
      // result = result && CheckNumber(MakeUpGraphs[i].ConnectionInfo.PinThreadWeight, 'Pin');
      result = result && CheckNumber(MakeUpGraphs[i].ConnectionInfo.PinSMYS, 'Pin SMYS');
      // result = result && CheckNumber(MakeUpGraphs[i].ConnectionInfo.PinThreadGrade, 'Pin Grade');
      // result = result && CheckText(MakeUpGraphs[i].ConnectionInfo.PinUndersized, 'Pin Undersize');

      result = result && CheckDate(MakeUpGraphs[i].MakeUpResults.MakeUpDateTime, 'Makeup Date');
      result = result && CheckNumber(MakeUpGraphs[i].MakeUpResults.FinalTorque, 'Torque');
      result = result && CheckNumber(MakeUpGraphs[i].MakeUpResults.ShoulderTorque, 'Shoulder Torque');
      result = result && CheckNumber(MakeUpGraphs[i].MakeUpResults.DeltaTurns, 'Delta');
      result = result && CheckNumber(MakeUpGraphs[i].MakeUpResults.ShoulderSlopeFactor, 'Slope');
      result = result && CheckText(MakeUpGraphs[i].GeneralInfo.CustomerOrWorkshopName, 'Workshop');
      result = result && CheckText(MakeUpGraphs[i].GeneralInfo.EndUserOrOilCompany, 'Oil');
    }
    // console.log(result)
    return result;
  };


  const CheckText = (text, field = '') => {
    console.log(`${field}: ${text !== ''}`)
    if (text !== "") return true;
    return false;
  };

  const CheckNumber = (number, field) => {
    console.log(`${field}: ${number > -1}`)
    if (number > -1) return true;
    return false;
  };

  const CheckDate = (date, field) => {
    console.log(`${field}: ${date !== "0001-01-01T00:00:00.0000000"} - ${date}`)
    if (date !== "0001-01-01T00:00:00.0000000") return true;
    return false;
  };


  const UploadFile = (ID) => {
    document.getElementById("f2" + ID).click();
  };

  const DropAreaDragEnter = () => {
    document.getElementById("drop-area").style.backgroundColor = "#009ee0";
    document.getElementById("drop-area").style.color = "white";
  };

  const DropAreaDragLeave = () => {
    document.getElementById("drop-area").style.backgroundColor = "white";
    document.getElementById("drop-area").style.color = "#cccccc";
  };

  const DropAreaDragDrop = (event, ID) => {
    document.getElementById("drop-area").style.backgroundColor = "white";
    document.getElementById("drop-area").style.color = "#cccccc";
    handleInputFileReplicator(event.dataTransfer.files[0], ID);
  };

  const EraseFile = (ID) => {
    ListFiles[ID] = undefined;
    var NamesList = [];
    setFilesNames();
    for (let i = 0; i < MakeUpGraphs.length; i++) {
      if (ListFiles[i] !== undefined) {
        NamesList.push(ListFiles[i].name);
      }
    }
    setFilesNames(NamesList);
    MakeUpGraphs[ID].Equipment.CertificateUploadedLink = "";
    document.getElementById(ID).style.display = "block";
  };

  const handleShowOptions = (e) => {
    const ID = e.target.value;
    // for (let i = 0; i < graph; i++) {
    //    document.getElementById(i).style.display = "none";
    //   document.getElementById("b" + i).className = "";
    //   for (let x = 0; x < 5; x++) {
    //     document.getElementById(i + "-" + x).style.display = "none";
    //     document.getElementById(i + "-" + x).className = "";
    //     document.getElementById("s" + x + i).className = "";
    //   }
    // }
    // document.getElementById("b" + ID).className = "active";
    // document.getElementById(ID).style.display = "flex";
    // document.getElementById("s" + 0 + ID).className = "active";
    // document.getElementById(ID + "-0").style.display = "block";
    setActiveGraph(ID);
  };

  const handleReplicateInfoMakeUpEquip = (ID) => {
    setActiveGraph(ID);
    handleRefreshMakeUpGraphs();
    const MakeUpEquipmentUsed = document.getElementById("f1" + ID).value;
    const DateLastCalibration = document.getElementById("f3" + ID).value;
    const BuckingInOperator = document.getElementById("f4" + ID).value;
    for (let i = 0; i < graph; i++) {
      handleInputFileReplicator(ListFiles[ID], i);
      MakeUpGraphs[i].Equipment.MakeUpEquipmentUsed = MakeUpEquipmentUsed;
      MakeUpGraphs[i].Equipment.DateLastCalibration = DateLastCalibration;
      MakeUpGraphs[i].Equipment.BuckingInOperator = BuckingInOperator;
    }
    handleCompleteFromMakeUpGraphs();
    toast.success("Copied Sucessfully", { autoClose: 3000 });
  };

  const handleReplicateInfoThreadCompound = (ID) => {
    const RunningCompoundUsed = document.getElementById("f5" + ID).value;
    const RunningCompoundExpiryDate = document.getElementById("f6" + ID).value;
    for (let i = 0; i < graph; i++) {
      MakeUpGraphs[i].ThreadCompound.RunningCompoundUsed = RunningCompoundUsed;
      MakeUpGraphs[i].ThreadCompound.RunningCompoundExpiryDate =
        RunningCompoundExpiryDate;
      handleInputFileReplicator(ListFiles[ID], i);
    }
    toast.success("Copied Sucessfully", { autoClose: 3000 });
  };

  const GraphFormSelect = async (mode) => {
    if (mode === 1) {
      let Fraction = require('fractional').Fraction
      Connection = document.getElementById("BoxThreadType").value
      setSelectedConnections(Connection)
      Design = document.getElementById("BoxDesign").value
      setSelectedDesign(Design)
      Diameter = document.getElementById("BoxDiameter").value
      setSelectedDiameter(Diameter)
      setLoading(true)

      setTimeout(() => {
        if (selectedConnections !== '') {
          let filter = returnFilterDesign(Connection)
          _.map(filter, item => {
            filterDesign.push({ value: item, label: item })
          })
          setDesign(filterDesign)
          if (selectedDesign !== '') {
            let filter = returnFilterDiameter(Connection, Design)
            _.map(filter, item => {
              filterDO.push({ value: item, label: (new Fraction(item)).toString() })
            })
            setDiameter(filterDO)
          }
          if (selectedDiameter !== '') {
            let filter = returnFilterWheigth(Connection, Design, Diameter)
            _.map(filter, item => {
              filterWeight.push({ value: item, label: item })
            })
            setWeight(filterWeight)
          }
        }
        setLoading(false)
      }, 500);
      Weight = document.getElementById("BoxThreadWeight").value
      setSelectedWeight(Weight)
      smys = document.getElementById("BoxSMYS").value
      setSelectedSmys(smys)
    } else if (mode === 2) {
      let Fraction = require('fractional').Fraction
      ConnectionPin = document.getElementById("PinThreadType").value
      setSelectedConnectionsPin(ConnectionPin)
      DesignPin = document.getElementById("PinDesign").value
      setSelectedDesignPin(DesignPin)
      DiameterPin = document.getElementById("PinDiameter").value
      setSelectedDiameterPin(DiameterPin)
      setLoading(true)

      setTimeout(() => {
        if (selectedConnectionsPin !== '') {
          let filter = returnFilterDesign(ConnectionPin)
          _.map(filter, item => {
            filterDesignPin.push({ value: item, label: item })
          })
          setDesignPin(filterDesignPin)
          if (selectedDesignPin !== '') {
            let filter = returnFilterDiameter(ConnectionPin, DesignPin)
            _.map(filter, item => {
              filterDOPin.push({ value: item, label: (new Fraction(item)).toString() })
            })
            setDiameterPin(filterDOPin)
          }
          if (selectedDiameterPin !== '') {
            let filter = returnFilterWheigth(ConnectionPin, DesignPin, DiameterPin)
            _.map(filter, item => {
              filterWeightPin.push({ value: item, label: item })
            })
            setWeightPin(filterWeightPin)
          }
        }
        setLoading(false)
      }, 500);

      WeightPin = document.getElementById("PinThreadWeight").value
      setSelectedWeightPin(WeightPin)
      smysPin = document.getElementById("PinSMYS").value
      setSelectedSmysPin(smysPin)

    }
  }

  const renderGraph = isLoading ?
    <LoadingOverlay visible={true} />
    :
    <GraphContext.Provider
      value={{
        graph, setGraph,
        graphUnitMesure, setGraphUnitMesure,
        connections, setConnections,
        selectedConnections, setSelectedConnections,
        selectedDesign, setSelectedDesign,
        selectedWeight,
        diameter, setDiameter,
        design, setDesign,
        weight, setWeight,
        SMYS, setSMYS,
        MakeUpGraphs, setMakeUpGraphs,
        ListFiles,
        FilesNames, setFilesNames,
        CreateForm, setCreateForm,
        ActiveGraph, setActiveGraph,
        StartMaximumDate,
        handleCreateForm,
        handleInputFile,
        handleInputFileReplicator,
        handleSaveMakeUpGraph,
        handleRefreshMakeUpGraphs,
        handleCompleteFromMakeUpGraphs,
        handleSubmit,
        handleSaveForm,
        UploadFile,
        DropAreaDragEnter,
        DropAreaDragLeave,
        DropAreaDragDrop,
        EraseFile,
        handleShowOptions,
        handleReplicateInfoMakeUpEquip,
        handleReplicateInfoThreadCompound,
        GraphFormSelect,
        Connection, filterDO,
        tab, setTab,
        selectedDiameter,
        isLoading,
        selectedSmys,
        selectedConnectionsPin,
        selectedDesignPin,
        selectedDiameterPin,
        selectedWeightPin,
        selectedSmysPin,
        connectionsPin,
        diameterPin,
        designPin,
        weightPin,
        SMYSPin,
        graphID, setGraphID,
        iceCandidate, setIceCandidate,
        graphContinue, setGraphContinue,
        SelectedCheckBox,
        savedMakeUpGraphs, setSavedMakeUpGraphs,
        reviewMakeUpGraphs, setReviewedMakeUpGraphs,
        showHiddenInfo, setShowHiddenInfo,
        gotSavedData, setGotSavedData,
        handleGetList,
        ConvertTimezone,
        handleDeleteSaved,
        handleContinue,
        handleChangeCheckbox,
        HandleShowPopUp,
        handleCancel,
        handleConfirmAssembly,
        isThereAnRequestID,
        allowFinish, setAllowFinish,
        errorNumber, setErrorNumber,
        errorType, setErrorType,
        errorSize, setErrorSize,
        uploadedFile, setUploadedFile,
        overlayUnit, setOverlayUnit,
        overlayDetail, setOverlayUpload,
        overlayCreate, setOverlayCreate,
        returnedFromForm, setReturnedFromForm,
        returnedFromFormCheck, setReturnedFromFormCheck,
        fileListLenght, setFileListLenght,
        fileListSize, setFileListSize,
        fileList, setFileList,
        CheckForMakeUpGraphID,
        handleUploadToLambda,
        handleUpload,
        showUpload,
        showCreate,
        showUnit,
        handleListFiles,
        handleIncreaseGraphs,
        handleDecreaseGraphs,
        PopulateListFiles,
        handleChangeMetricUnit,
        SaveNumberGraphs,
        draftedMakeUpGraphs, setDraftedMakeUpGraphs,
        reviewType, setReviewType,
      }}
    >
      {children}
    </GraphContext.Provider>;

  return renderGraph;
};
