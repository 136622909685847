/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AiFillFile, AiOutlineSend } from "react-icons/ai";
import { BsPaperclip } from "react-icons/bs";
import styled from "styled-components";
import {
  Body,
  HelloTextBold,
  MainCard,
} from "../../components/common/MainCard";
import { getFile } from "../../components/connect/sendFileToUpload";
// import Caller from "../../components/layout/caller";
import Footer from "../../components/layout/footer";
import Header from "../../components/layout/header";
// import LoadingScreen from "../../components/layout/LoadingScreen";
import NavBar from "../../components/layout/navBar";
import { ChatIDContext } from "../../stores/ChatIDStore";
import { UserContext } from "../../stores/UserStore";
import { ChatSocketContext } from "../../stores/ChatSocketStore";
import { useState } from 'react'

const ChatRoom = () => {
  document.title = "VAM® Field Service";
  const {
    chatID,
    ChatInfo,
    messages,
    ChatStatus,
    gotMessages,
    // handleSendMessage,
    handleSendFile,
    ConvertTimezone,
    handleGetTranscript,
    updateScroll,
    handleFromMessage,
    handleInputFile,
    // BuyerID
  } = useContext(ChatIDContext);

  const { RegisterSocketChatEvents, /* chatMessages, */ sendMessage/* , setMessageToSend */ } = useContext(ChatSocketContext)
  const { currentUser } = useContext(UserContext);
  const { t } = useTranslation();
  const [filteredMessages, setFilteredMessages] = useState([])

  const TranslationDiv = styled.div`
    font-size: calc(8px + 0.5vw);
    min-height: 300px;
    height: 65vh;
    width: 50%;
    color: black;
    border-radius: 5px;
    background-color: white;
    position: relative;

    .transcript {
      overflow-y: scroll;
      width: 100%;
      max-height: 57vh;
      transition: all 0.3s ease 0s;
    }

    .message {
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
    }

    .SYSTEM {
      text-align: center;
      border-radius: 10px;
      background-color: #ccc;
      padding: 10px;
      margin-left: auto;
      margin-right: auto;
      max-width: 70%;
      margin-top: 10px;
      margin-bottom: 10px;
      word-wrap: break-word;
      display: inline-block;
      font-size: 16px;
    }

    .SELF {
      text-align: left;
      border-radius: 10px;
      background-color: #7ccff1;
      padding: 10px;
      max-width: 70%;
      min-width: 100px;
      margin-left: auto;
      margin-right: 10px;
      margin-top: 10px;
      margin-bottom: 10px;
      word-wrap: break-word;
      display: inline-block;
      font-size: 16px;
      :hover {
        cursor: pointer;
      }
      .time {
        text-align: right;
        font-size: 10px;
      }
    }

    .OTHER {
      text-align: right;
      border-radius: 10px;
      background-color: #ccc;
      padding: 10px;
      max-width: 70%;
      margin-left: 10px;
      margin-right: auto;
      margin-top: 10px;
      margin-bottom: 10px;
      min-width: 100px;
      word-wrap: break-word;
      display: inline-block;
      font-size: 16px;
      .time {
        text-align: righ;
        font-size: 10px;
      }
    }

    .Answer {
      border-radius: 100%;
      width: 50px;
      height: 50px;
      background-color: #4bfa4b;
      border: 0;
      margin-right: 10.5px;
      margin-top: 10px;
      margin-bottom: 10px;
      cursor: pointer;
    }

    .Reject {
      border-radius: 100%;
      background-color: #ff5050;
      border: 0;
      width: 50px;
      height: 50px;
      margin-right: 10.5px;
      margin-top: 10px;
      margin-bottom: 10px;
      cursor: pointer;
    }

    .messageBox {
      position: absolute;
      bottom: 0;
      display: flex;
      width: 100%;
      align-items: stretch;
      textarea[type="text"],
      select {
        flex: 1;
        padding: 12px 20px;
        margin: 5px 8px;
        display: inline-block;
        border: 0;
        border-radius: 4px;
        box-sizing: border-box;
        box-shadow: 2px 2px 2px 2px rgba(167, 164, 206, 0.4);
        resize: none;
        :focus {
          outline: none;
        }
      }
      button {
        width: 50px;
        height: 50px;
        border: 0;
        border-radius: 50%;
        background-color: #7ccff1;
        box-shadow: 2px 2px 2px 2px rgba(167, 164, 206, 0.4);
        margin: 5px 8px;
        cursor: pointer;
        :focus {
          outline: none;
        }
      }
    }
  `;

  const Status = styled.div`
    display: flex;
    justify-content: space-between;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 10px;
    padding: 5px;
    top: 0;
    font-size: 16px;
    color: black;
    display: inline-block;
    width: 50%;

    button {
      margin-left: 5px;
      border: 0;
      padding: 5px;
      border-radius: 10px;
      margin-right: 20px;
    }

    .Pending {
      background-color: #7ccff1;
    }
    .Completed {
      background-color: #ccc;
    }

    .right {
      align-items: flex-end;
      margin-right: 0px
    }
  `;

  useEffect(() => {
    (async () => {
      await RegisterSocketChatEvents()
      handleGetTranscript()
      console.log(messages)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {// Set chat messages on opening card
    setFilteredMessages(ChatInfo.filter((chat) => chat.RequestID === chatID))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatID])

  return (
    <Body>
      <Header />
      <NavBar />
      <MainCard
        id="MainCard"
        style={{ display: !gotMessages ? "hidden" : "block" }}
      >
        <HelloTextBold>{t("ChatRoom.LiveSupport")}</HelloTextBold>
        <input
          type="file"
          onChange={(event) => handleInputFile(event)}
          id={"file"}
          hidden
        ></input>
        <center>
          {filteredMessages.map(
            (Chat) =>
              Chat.RequestID === chatID && (
                <Status key={Chat.CreationDate}>
                  <div>
                    {`${t("ChatRoom.Status")} ${Chat.SupportStatus}`}
                  </div>
                  <div>
                    {t("ChatRoom.Created")} {ConvertTimezone(Chat.CreatedAt)}{" "}
                    {t("ChatRoom.By")}{" "}
                    {currentUser && !currentUser.roles.ADMIN_ACCESS
                      ? currentUser.firstName
                      : Chat.BuyerName
                    }{" "}
                    {Chat.SupportSubject}{" - "}{Chat.Title}
                  </div>
                </Status>
              )
          )}
          <TranslationDiv>
            <div id="transcript" className="transcript">
              {messages && Array.isArray(messages) &&
                messages.map((Message) => (
                  <div
                    key={Message.CreatedAt + Math.floor(Math.random() * 1000)}
                    className="message"
                  >
                    {Message.MessageAttachment !== "" ? (
                      <div className={handleFromMessage(Message.MessageSender)}>
                        <a href="javascript:void(0)" onClick={() => getFile(Message.MessageAttachment, Message.MessageSender)}>
                          <AiFillFile />
                          <div>{Message.MessageContent} </div>
                        </a>
                        <div className="time">
                          {ConvertTimezone(Message.CreatedAt)}{" "}
                        </div>
                      </div>
                    ) : (
                      <div className={handleFromMessage(Message.MessageSender)}>
                        <div>{Message.MessageContent} </div>
                        <div className="time">
                          {ConvertTimezone(Message.CreatedAt)}{" "}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
            </div>
            <div className="messageBox">
              <textarea
                type="text"
                id="message"
                placeholder={t("ChatRoom.TypeMessage")}
              // disabled={ChatStatus === "In Progress" || ChatStatus === "InProgress" || ChatStatus === "In progress" || ChatStatus === "Pending" ? false : true}
              ></textarea>{" "}
              {/* {currentUser != null && currentUser.roles.ADMIN_ACCESS && (
                <button
                  onClick={handleStartVideoChat}
                  disabled={ChatStatus === "Pending" ? false : true}
                >
                  <FaVideo size="25px" style={{ color: "#c0e6f1" }} />
                </button>
              )} */}
              <button onClick={() => handleSendFile()}>
                <BsPaperclip
                  size="25px"
                  style={{ color: "#c0e6f1" }}
                  disabled={ChatStatus === "In Progress" || ChatStatus === "InProgress" || ChatStatus === "In progress" || ChatStatus === "Pending" ? false : true}
                />
              </button>
              <button onClick={async() => await sendMessage()}>
                <AiOutlineSend
                  size="25px"
                  style={{ color: "#c0e6f1" }}
                  disabled={ChatStatus === "In Progress" || ChatStatus === "InProgress" || ChatStatus === "In progress" || ChatStatus === "Pending" ? false : true}
                />
              </button>
            </div>
          </TranslationDiv>
        </center>
        <div />
      </MainCard>
      {/* : <LoadingScreen />} */}
      {updateScroll()}
      <Footer />
    </Body>
  );
};

export default ChatRoom;
