import axios from "axios";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

const EndpointList = process.env.REACT_APP_API_URL + "/listmakeupgraphrequest";

const EndpointInsert = process.env.REACT_APP_API_URL + "/insertmakeupgraphrequest";

const EndpointUpdate = process.env.REACT_APP_API_URL + "/updatemakeupgraphrequest";

var RequestID = "";

var LocalListMakeUpGraphRequests = [];

var verbose = false;

const header = {
  'vlr-authorization': 'eyJpZCI6ICJWQU1fQVBQIn0=',
  'authorization': Cookies.get("_ssoToken")
}

export async function ListMakeUpGraphRequests(userID) {
  const data = {
    BuyerID: "" + userID,
  };
  await axios
    .post(EndpointList, data, { headers: header })
    .then((response) => {
      LocalListMakeUpGraphRequests = response.data;
      if (verbose) {
        console.log(response);
      }
    })
    .catch((error) => {
      console.log(error);
    });
  return true;
}

export async function ListMakeUpGraphRequestsAdmin(InitialDate, FinishDate) {
  const data = {
    AgentKey: "Tony Stark",
    InitialDate: "" + InitialDate,
    FinishDate: "" + FinishDate,
  };
  await axios
    .post(EndpointList, data, { headers: header })
    .then((response) => {
      LocalListMakeUpGraphRequests = response.data;
      if (verbose) {
        console.log(response);
      }
    })
    .catch((error) => {
      console.log(error);
    });
  return true;
}

export async function ListMakeUpGraphRequestsByRequestID(RequestID) {
  const data = {
    RequestID: "" + RequestID,
  };
  return axios
    .post(EndpointList, data, { headers: header })
    .then((response) => {
      if (verbose) {
        console.log(response);
      }
      return response.data.Items;
    })
    .catch((error) => {
      console.log(error);
    });
}

export function ReturnMakeUpGraphRequests() {
  return LocalListMakeUpGraphRequests;
}

export function ReturnSavedMakeUpGraphRequests() {
  var SavedMakeUpGraphRequests = [];
  if (LocalListMakeUpGraphRequests !== undefined) {
    LocalListMakeUpGraphRequests.forEach((element) => {
      if (element.RequestStatus === "Pending" ||  element.RequestStatus === "In Progress" ) {
        SavedMakeUpGraphRequests.push(element);
      }
    });
  }
  return SavedMakeUpGraphRequests;
}

export function ReturnDraftedMakeUpGraphRequests() {
  var SavedMakeUpGraphRequests = [];
  if (LocalListMakeUpGraphRequests !== undefined) {
    LocalListMakeUpGraphRequests.forEach((element) => {
      if (element.RequestStatus === "Drafted") {
        SavedMakeUpGraphRequests.push(element);
      }
    });
  }
  return SavedMakeUpGraphRequests;
}

export function ReturnReviewedMakeUpGraphRequests() {
  var EndedMakeUpGraphRequests = [];
  if (LocalListMakeUpGraphRequests !== undefined) {
    LocalListMakeUpGraphRequests.forEach((element) => {
      if (element.RequestStatus === "Reviewed") {
        EndedMakeUpGraphRequests.push(element);
      }
    });
  }
  return EndedMakeUpGraphRequests;
}

export async function DeleteMakeUpGraphRequests(RequestID) {
  const data = {
    RequestStatus: "Deleted",
    RequestID: RequestID,
    RequestLog: "Deleted",
  };
  await axios
    .post(EndpointUpdate, data, { headers: header })
    .then((response) => {
      toast.success("Deleted Sucessfully", {autoClose: 3000});
      if (verbose) {
        console.log(response);
      }
    })
    .catch((error) => {
      toast.error("Error Deleting",  {autoClose: 3000});
      console.log(error);
    });
  return true;
}

export function UploadFilesMakeUpGraphRequests(RequestID, Files) {
  const data = {
    RequestStatus: "Drafted",
    RequestID: RequestID,
    RequestLog: "UploadFiles",
    Graphs: [""],
    FileUploadedLink: Files,
  };
  axios
    .post(EndpointUpdate, data, { headers: header })
    .then((response) => {
      if (verbose) {
        console.log(response);
      }
    })
    .catch((error) => {
      toast.error("Error Submiting");
      console.log(error);
    });
}

export function UploadGraphsMakeUpGraphRequests(RequestID, Graphs) {
  const data = {
    RequestID: RequestID,
    RequestLog: "Update Graphs",
    Graphs: Graphs,
  };
  axios
    .post(EndpointUpdate, data, { headers: header })
    .then((response) => {
      if (verbose) {
        console.log(response);
      }
      toast.success("Make up graph uploaded successfully", {autoClose: 3000})
    })
    .catch((error) => {
      toast.error("Error Submiting");
      console.log(error);
    });
}

export async function SubmitMakeUpGraphRequest(
  userID,
  NumGraphs,
  MeasuringUnit,
  type
) {
  const data = {
    BuyerID: "" + userID,
    NumberOfGraphs: NumGraphs,
    RequestType: type,
    MeasuringUnit: MeasuringUnit,
  };

  return axios
    .post(EndpointInsert, data, { headers: header })
    .then((response) => {
      RequestID = response.data.RequestID;
      toast.success("Make up graph requested successfully", {autoClose: 3000})
      return RequestID;
    })
    .catch((error) => {
      console.log(error);
    });
}
