import React from "react";
import { UserProvider } from "./stores/UserStore";
import Router from "./Router";
import { toast, Zoom } from "react-toastify";
import "./i18n";
import theme from "./utils/theme";
import { ThemeProvider } from "styled-components";

import { AccountManagementProvider } from "./stores/AccountManagementStore";
import { ArticlesProvider } from "./stores/ArticlesStore";
import { AssignTechnictianProvider } from "./stores/AssignTechnictianStore";
import { ChatIDProvider } from "./stores/ChatIDStore";
import { ChatSocketProvider } from "./stores/ChatSocketStore";
import { GraphProvider } from "./stores/GraphStore";
import { LandingPageProvider } from "./stores/LandingPageStore";
import { OnSiteSupportProvider } from "./stores/OnSiteSupportStore";
import { ProfileProvider } from "./stores/ProfileStore";
import { TransactionProvider } from "./stores/TransactionStore"


toast.configure({
  autoClose: 4000,
  draggable: false,
  transition: Zoom,
  hideProgressBar: true,
  closeButton: false,
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <UserProvider>
        <LandingPageProvider>
          <ChatIDProvider>
            <ChatSocketProvider>
              <GraphProvider>
                <ProfileProvider>
                  <OnSiteSupportProvider>
                    <AccountManagementProvider>
                      <ArticlesProvider>
                        <AssignTechnictianProvider>
                          {/* <DashboardProvider> */}
                          <TransactionProvider>
                            {" "}
                            <Router />
                          </TransactionProvider>
                          {/* </DashboardProvider> */}
                        </AssignTechnictianProvider>
                      </ArticlesProvider>
                    </AccountManagementProvider>
                  </OnSiteSupportProvider>
                </ProfileProvider>
              </GraphProvider>
            </ChatSocketProvider>
          </ChatIDProvider>
        </LandingPageProvider>
      </UserProvider>
    </ThemeProvider >
  );
};

export default App;
