import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

const EndpointList = process.env.REACT_APP_API_URL + "/listonsitesupport"

const EndpointInsert = process.env.REACT_APP_API_URL + "/insertonsitesupport"

const EndpointUpdate = process.env.REACT_APP_API_URL + "/updateonsitesupport"

var verbose = false;
const header = {
  'vlr-authorization': 'eyJpZCI6ICJWQU1fQVBQIn0=',
  'authorization': Cookies.get("_ssoToken")
}

export async function CreateOnSiteSupport(
  BuyerID,
  FirstName,
  LastName,
  Company,
  Position,
  CountryCode,
  Phone,
  Email,
  ServiceCountry,
  ServiceType,
  ServiceDate
) {
  const data = {
    BuyerID: BuyerID,
    FirstName: FirstName,
    LastName: LastName,
    Company: Company,
    BuyerPosition: Position,
    CountryCode: CountryCode,
    Phone: Phone,
    Email: Email,
    ServiceCountry: ServiceCountry,
    ServiceType: ServiceType,
    ServiceDate: ServiceDate,
  };
  axios
    .post(EndpointInsert, data, { headers: header })
    .then((response) => {
      toast.success("Created Ticket", { autoClose: 3000 });
      if (verbose) {
        console.log(response);
      }
      return true;
    })
    .catch((error) => {
      toast.error("Error: " + error);
      console.log(error);
      return false;
    });
}

export async function UpdateOnSiteSupport(
  OnSiteSupportID,
  BuyerID,
  FirstName,
  LastName,
  Company,
  Position,
  CountryCode,
  Phone,
  Email,
  ServiceCountry,
  ServiceType,
  ServiceDate,
  AltererID
) {
  const data = {
    OnSiteSupportID: OnSiteSupportID,
    BuyerID: BuyerID,
    FirstName: FirstName,
    LastName: LastName,
    Company: Company,
    BuyerPosition: Position,
    CountryCode: CountryCode,
    Phone: Phone,
    Email: Email,
    ServiceCountry: ServiceCountry,
    ServiceType: ServiceType,
    ServiceDate: ServiceDate,
    AlteredBy: AltererID,
  };
  axios
    .post(EndpointUpdate, data, { headers: header })
    .then((response) => {
      toast.success("Updated Ticket", { autoClose: 3000 });
      if (verbose) {
        console.log(response);
      }
      return true;
    })
    .catch((error) => {
      toast.error("Error: " + error);
      console.log(error);
      return false;
    });
}

export async function ListOnSiteSuportByAgentID(AgentID) {
  var result = [];
  const data = {
    AgentID: "" + AgentID,
  };
  await axios
    .post(EndpointList, data, { headers: header })
    .then((response) => {
      if (verbose) {
        console.log(response);
      }
      result = response.data;
    })
    .catch((error) => {
      console.log(error);
    });

  return result;
}

export async function ListOnSiteSuportByBuyerID(buyerID) {
  var result = [];
  const data = {
    BuyerID: "" + buyerID,
  };
  await axios
    .post(EndpointList, data, { headers: header })
    .then((response) => {
      if (verbose) {
        console.log(response);
      }
      result = response.data;
    })
    .catch((error) => {
      console.log(error);
    });

  return result;
}
