import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Logo from '../../assets/images/logo.png'
import BackgroundImage from '../../assets/images/background.jpg'
import { Button, Card, FlexView } from '../../components/common'
import { FaHardHat } from "react-icons/fa";

const Background = styled.div`
  background: url(${BackgroundImage}) no-repeat center center fixed;
  background-size: cover;
  background-color: ${({ theme }) => theme.colors.background };
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

// const Copyrights = styled.div`
//   color: ${({ theme }) => theme.colors.gray};
// `

// const ContentText = styled.div`
//   font-size: ${({ theme }) => theme.fontSizes.small};
//   color: ${({ theme }) => theme.colors.gray};
//   font-family: Roboto;
//   text-align: justify;
//   width: 100%;
// `

const Anchor = styled.a`
  text-decoration: none;
`

const Terms = () => {
  const { t } = useTranslation()

  return (
    <Background>
      <Card width="90vw" height="80vh" elevation="veryHigh" padding="24px" justifyContent="space-between" alignItems="stretch" style={{overflow: 'auto'}}>
        <FlexView flexDirection="row" alignItems="flex-end" justifyContent="space-between" width="100%">
        <Anchor href="/">
            <Button width="140px" backgroundColor="primary" color="white">{t('Go Back')}</Button>
          </Anchor>
          <img src={Logo} alt="Application Logo" width="120px" />
        </FlexView>
        <FlexView flex="1 0" flexDirection="column" alignItems="center" justifyContent="center">
        <center>  <FaHardHat size="60px" style={{color:"#009ee0"}}/> <div />Under Construction</center>
         {/* <ContentText>
            

             <h1><b><u> { t('terms.title_page') } </u></b></h1>
            <p> { t('terms.subtitle_1') }  </p>
            <p> { t('terms.subtitle_2') } </p>
            <p> { t('terms.subtitle_3') } </p>
            <h2><b><u> { t('terms.title_uc') } </u></b></h2>
            <p><u><strong> { t('terms.contacts_title') } : </strong></u> { t('terms.contacts') }  </p>
            <p><u><strong> Política de Privacidade:</strong></u> Ver Políticas de Privacidade e Cookies no Website. </p>
            <p><u><strong> { t('terms.cookies_title') } :</strong></u> { t('terms.cookies') } </p>
            <p><u><strong> { t('terms.hyperlinks_title') } :</strong></u>  { t('terms.hyperlinks') } </p>
            <p><u><strong> { t('terms.features_title') } :</strong></u>  { t('terms.features') } </p>
            <p><u><strong> { t('terms.materials_submit_title') } :</strong></u> { t('terms.materials_submit_1') } </p>
            <p> { t('terms.materials_submit_2') } </p>
            <p><u><strong> { t('terms.copyrights_trademarks_permissions_title') } : </strong></u> { t('terms.copyrights_trademarks_permissions_1') }  </p>
            <ol>
              <li>
                { t('terms.copyrights_trademarks_permissions_2') }
              </li>
              <li>
                { t('terms.copyrights_trademarks_permissions_3') }
              </li>
              <li>
                { t('terms.copyrights_trademarks_permissions_4') }
              </li>
              <li>
                { t('terms.copyrights_trademarks_permissions_5') }
              </li>
              <li>
                { t('terms.copyrights_trademarks_permissions_6') }
              </li>
              <li>
                { t('terms.copyrights_trademarks_permissions_7') }
              </li>
              <li>
                { t('terms.copyrights_trademarks_permissions_8') } 
              </li> 
              <li>
                { t('terms.copyrights_trademarks_permissions_9') } 
              </li>
              <li>
                { t('terms.copyrights_trademarks_permissions_10') }
              </li>
            </ol>
            <p><u><strong> { t('terms.forums_interactive_areas_postings_feedback_title') } : </strong></u>{ t('terms.forums_interactive_areas_postings_feedback') } </p>
            <p><u><strong> { t('terms.vallourec_software_title') } :</strong></u> { t('terms.vallourec_software') } </p>
            <h2><b><u> { t('terms.disclaimers_title_uc') } </u></b></h2>
            <p> { t('terms.disclaimers') } </p>
            <h2><b><u> { t('terms.limitation_liability_title_uc') }  </u></b></h2>
            <p> { t('terms.limitation_liability') } </p>
            <p><u><strong> { t('terms.indemnification_title') } :</strong></u> { t('terms.indemnification') } </p>
            <p><u><strong> { t('terms.termination_title') } :</strong></u> { t('terms.termination') } </p>
            <p><u><strong> { t('terms.severability_title') } :</strong></u> { t('terms.severability') } </p>
            <p><u><strong> { t('terms.language_title')} :</strong></u> { t('terms.language')} </p>
            <p><u><strong> { t('terms.changes_these_terms_title')} :</strong></u> { t('terms.changes_these_terms')} </p>
            <p><u><strong> { t('terms.miscellaneous_title')} </strong></u> { t('terms.miscellaneous')} </p>
            <p><u><strong> { t('terms.governing_law_jurisdiction_title')} </strong></u> { t('terms.governing_law_jurisdiction')} </p>
          </ContentText>
          <Anchor href="/login">
            <Button width="140px" backgroundColor="primary" color="white" margin="10px 5px">{t('Go Back')}</Button>
          </Anchor> */}
        </FlexView>
        {/* <Copyrights></Copyrights> */}
      </Card>
    </Background>
  )
}

export default Terms