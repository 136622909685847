import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import styled from "styled-components";
import {
  Body,
  HelloTextBold,
  MainCard,
} from "../../components/common/MainCard";
import Caller from "../../components/layout/caller";
import Footer from "../../components/layout/footer";
import Header from "../../components/layout/header";
import LoadingScreen from "../../components/layout/LoadingScreen";
import NavBar from "../../components/layout/navBar";
import { AssignTechnictianContext } from "../../stores/AssignTechnictianStore"

const SideBySideOnTheLeft = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px;

  .firstDiv {
    width: 20%;
    .whiteBox {
      margin-left: 10px;
      padding: 20px;
      height: 64vh;
      background-color: white;
      border-radius: 10px;
      .splitRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 20px;

        button {
          background-color: Transparent;
          background-repeat: no-repeat;
          border-width: 0px;
          color: #172983;
          font-size: 20px;
          cursor: pointer;
        }
      }
      table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0px;
        td {
          padding: 3px;
          text-align: center;
        }
        .thisMonth {
          color: black;
        }
        .currentDay {
          background-color: #172983;
          border-radius: 10px;
          color: white;
        }
        .newSelected {
          background-color: #009ee0;
          border-radius: 10px;
          color: white;
        }
      }
      .WeekNo {
        background-color: gray;
        color: white;
      }
      .WeekDay {
        font-weight: bold;
      }
    }
  }

  .secondDiv {
    width: 100%;
    text-align: start;
    color: #666666;
    .secondSplitRow {
      display: flex;
      flex-direction: row;

      select {
        width: 100px;
        color: #009ee0;
        border-color: transparent;
        border-radius: 10px;
        padding: 5px;
        box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);
        :focus {
          box-shadow: none !important;
          border: 0px;
          outline: 0px;
        }
      }
      .AgentSelect {
        width: 200px;
        margin-right: 10px;
        color: black;
        :focus {
          box-shadow: none !important;
          border: 0px;
          outline: 0px;
        }
      }
    }

    .splitRow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-left: 10px;
      .darkBlueButton {
        border-radius: 5px;
        padding: 5px;
        border-width: 1px;
        border-color: #172983;
        color: #172983;
        font-weight: bold;
        background-color: Transparent;
        background-repeat: no-repeat;
        margin-left: 5px;
        margin-right: 5px;
        cursor: pointer;
      }
      .lightBlueButton {
        border-radius: 5px;
        padding: 5px;
        border-width: 1px;
        border-color: #009ee0;
        color: #009ee0;
        font-weight: bold;
        background-color: Transparent;
        background-repeat: no-repeat;
        margin-left: 5px;
        margin-right: 5px;
        cursor: pointer;
      }

      .filledlightBlueButton {
        border-radius: 5px;
        padding: 5px;
        border-width: 1px;
        border-color: #009ee0;
        color: white;
        font-weight: bold;
        background-color: #009ee0;
        background-repeat: no-repeat;
        margin-left: 5px;
        margin-right: 5px;
        cursor: pointer;
      }

      .borderlessLightBlueButton {
        cursor: pointer;
        vertical-align: middle;
        padding: 5px;
        border-width: 0px;
        color: #009ee0;
        font-weight: bold;
        background-color: Transparent;
        background-repeat: no-repeat;
        margin-left: 5px;
        margin-right: 5px;
        display: flex;
        align-items: center;
      }
      input {
        padding: 5px;
        border-width: 0px;
        border-radius: 10px;
        box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);
      }
    }
    .largeWhiteBox {
      margin: 10px;
      padding: 20px;
      height: 60vh;
      background-color: white;
      border-radius: 10px;
      table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0px;

        .thisMonth {
          color: black;
          height: 70px;
        }
        .currentDay {
          color: white;
          background-color: #172983;
          border-radius: 10px;
        }
        .newSelected {
          background-color: #009ee0;
          border-radius: 10px;
          color: white;
        }

        th {
          padding: 5px;
          text-align: center;
          color: lightgray;
          border-right: 1px solid lightgray;
        }

        td {
          padding: 8px;
          width: 14vw;
          text-align: center;
          border-right: 1px solid lightgray;
          color: lightgray;

          button {
            width: 100%;
            border: 0px;
            border-radius: 10px;
            display: inline-block;
            padding: 2px;
            padding-left: 7px;
            padding-right: 7px;
            display: flex;
            justify-content: space-between;
            cursor: pointer;
          }
          .scheduled {
            background-color: #172983;
            color: white;
          }
          .pending {
            background-color: #f8b334;
            color: white;
          }

          .scheduledHighlight {
            background-color: #172983;
            opacity: 0.5;
            color: white;
          }
          .pendingDimlight {
            background-color: #f8b334;
            opacity: 0.5;
            color: white;
          }
          .scheduledDimlight {
            background-color: #172983;
            opacity: 0.5;
            color: white;
          }
        }

        .scheduleWidthLast {
          border-right: 0px;
        }
        .Time {
          width: 50px;
        }
        .lastDay {
          border-right: 0px;
        }
        .lastDayFiltered {
          width: 100%;
          border-right: 0px;
        }
        .today {
          color: #172983;
        }
        .todayBall {
          color: white;
          width: 25px;
          background-color: #172983;
          border-radius: 50px;
        }
      }
    }
  }
`;



const SideBySideOptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;

  .CheckAvaliability {
    margin-top: 10px;
  }

  button {
    background-color: #009ee0;
    border: 5px;
    border-radius: 10px;
    color: #fff;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: bold;
    box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    :hover {
      transform: translateY(-7px);
    }
    :focus {
      outline: none;
    }
  }
`;



const AssignTechnictianToLiveSupport = ({ setLoading }) => {
  document.title = "VAM® Field Service";

  const {
    gotSavedData,
    selectedDate,
    // highlighted,
    // setFilter,
    showPopUp,
    monthNames,
    handleGetInitialData,
    calculateDateTable,
    calculateScheduleTable,
    // ChangeToToday,
    AddMonth,
    RemoveMonth,
    // GetAgents,
    // HighlightPending,
    // HighlightAssigned,
    PopUpTecnichian
  } = useContext(AssignTechnictianContext)

  //const { currentUser } = useContext(UserContext);
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      // await PopUpTecnichian()
      await handleGetInitialData()
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Body>
      {showPopUp && PopUpTecnichian()}
      {/* 
      {handleGetInitialData()} */}
      <Header />
      <Caller />
      <span style={{ display: !gotSavedData ? "block" : "none" }}>
        <LoadingScreen />
      </span>
      <NavBar />
      <MainCard
        id="MainCard"
        style={{ display: gotSavedData ? "block" : "none" }}
      >
        <SideBySideOptions>
          <HelloTextBold>
            {t("AssignTechnictianToLiveSupport.AssignTechnictianToLiveSupport")}{" "}
          </HelloTextBold>{" "}
        </SideBySideOptions>
        <SideBySideOnTheLeft>
          <div className="firstDiv">
            {" "}
            <div className="whiteBox">
              <div className="splitRow">
                <div>
                  {" "}
                  {monthNames[selectedDate.getMonth()]}{" "}
                  {selectedDate.getFullYear()}
                </div>
                <div>
                  {" "}
                  <button onClick={RemoveMonth}>
                    <FaAngleLeft />
                  </button>{" "}
                  <button onClick={AddMonth}>
                    <FaAngleRight />
                  </button>
                </div>
              </div>
              <table>
                <tbody>{calculateDateTable()}</tbody>
              </table>
            </div>
          </div>
          <div className="secondDiv">
            <div>
              <div className="largeWhiteBox">
                {" "}
                <table>
                  <tbody>{calculateScheduleTable()}</tbody>
                </table>
              </div>
            </div>
          </div>
        </SideBySideOnTheLeft>
      </MainCard>
      <Footer />
    </Body>
  );
};

export default AssignTechnictianToLiveSupport;
