import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import MessageIcon from "../../assets/icons/Chat/MessageIcon.png";
import {
  Body,
  HelloTextBold,
  MainCard,
} from "../../components/common/MainCard";
import Footer from "../../components/layout/footer";
import Header from "../../components/layout/header";
import LoadingScreen from "../../components/layout/LoadingScreen";
import NavBar from "../../components/layout/navBar";
import { ChatIDContext } from "../../stores/ChatIDStore";
import { UserContext } from "../../stores/UserStore";
import Caller from "../../components/layout/caller";
import { useTranslation } from "react-i18next";

const MessagesAdmin = () => {
  document.title = "VAM® Field Service";

  const { currentUser } = useContext(UserContext);
  const history = useHistory();
  const { 
    setChatID,
    savedAdminChats,
    gotSavedAdminData,
    schearchAdminText,
    statusAdminFilter,
    handleGetChatControl,
    handleGetAllChats,
    handleListChats,
    handleSchearchTerm,
    handleGetUnread,
    handleGetAllByStatus, 
    ConvertTimezone} = useContext(ChatIDContext);
  const { t } = useTranslation();

  const handleGetChat = (ID) => {
    setChatID(ID);
    history.push("/ChatRoom");
  };

  const SideBySideOptions = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    input {
      width: 95%;
      height: 20px;
      margin-left: 10px;
      padding: 8px;
      border: 0;
      border-radius: 10px;
      box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);
      :focus {
        outline: none;
      }
    }
    select {
      -webkit-appearance: none;
      padding: 7px 40px 7px 12px;
      width: 200px;
      margin-left: 10px;
      border: 1px solid #e8eaed;
      border-radius: 5px;
      background: white;
      box-shadow: 0 1px 3px -2px #9098a9;
      cursor: pointer;
      font-family: inherit;
      font-size: 16px;
      transition: all 150ms ease;
    }
    option {
      color: #223254;
    }
    :focus {
      outline: none;
      border-color: #0077ff;
      box-shadow: 0 0 0 2px rgba(#0077ff, 0.2);
    }
    button {
      padding-top: 5px;
      background-color: #009ee0;
      border: 0;
      color: #fff;
      margin-left: 10px;
      box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);
      transition: all 0.3s ease 0s;
      cursor: pointer;
      :hover {
        transform: translateY(-7px);
      }
      :focus {
        outline: none;
      }
    }
  `;

  const SavedDiv = styled.div`
    width: 98%;
    min-width: 450px;
    display: flex;
    flex-direction: column;
    color: #009ee0;
    background-color: white;
    border-radius: 0 10px 10px 0;
    font-size: calc(12px + 0.5vw);
    vertical-align: center;
    margin-bottom: 10px;
    transition: all 0.3s ease 0s;
    :hover {
      transform: translateX(3px);
    }
    .GrayText {
      color: #666666;
      font-size: calc(8px + 0.3vw);
    }
    .left {
      width: 90%;
      margin-left: 0;
      display: flex;
      align-items: center;
    }
    .information {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 10px;
      text-align: left;
    }
    .statusPending {
      width: 10%;
      font-size: 14px;
      color: white;
      background-color: #009ee0;
      border-radius: 0 10px 10px 0;
      margin-top: 10px;
      padding: 5px;
      padding-left: 2%;
    }
    .statusProcessed {
      width: 10%;
      font-size: 14px;
      color: white;
      background-color: #666666;
      border-radius: 0 10px 10px 0;
      margin-top: 10px;
      padding: 5px;
      padding-left: 2%;
    }
    .right {
      color: #666666;
      font-size: calc(8px + 0.3vw);
      display: flex;
      flex-direction: column;
      width: 6%;
      align-items: flex-end;
      justify-content: center;
    }
    .unread {
      color: white;
      display: flex;
      width: 4%;
      justify-content: center;
      align-items: center;
    }
    .takeControl {
      width: 100%;
      justify-content: flex-end;
      display: flex;

      button {
        background-color: #009ee0;
        margin-right: 20px;
        border-radius: 10px;
        color: white;
        :focus {
          outline: none;
        }
      }
    }
    button {
      background-color: Transparent;
      background-repeat: no-repeat;
      border: none;
      cursor: pointer;
      overflow: hidden;
    }
    font {
      font-size: 16px;
      color: black;
    }
    img {
      margin-right: 10px;
    }
    span {
      background-color: #8d1677;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      display: inline-block;
      text-align: center;
      font-size: 16px;
    }
  `;

  const WhiteUnitBoxDiv = styled.div`
  display: flex;
  background-color: white;
  justify-content: center;
  padding: 10px 12px 5px 12px;
  margin-left: 10px;
  cursor: pointer;
  
  .active {
    color: #009ee0;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 15px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #7abcf1;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 0;
    bottom: -2.5px;
    background-color: #2196f3;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #7abcf1;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #7abcf1;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;

  return (
    <Body>
      {handleGetAllChats(currentUser.id)}
      {handleListChats()}
      <Header />
      <Caller />
      <NavBar />
      {!gotSavedAdminData ? (
        <LoadingScreen />
      ) : (
        <MainCard id="MainCard">
          <HelloTextBold>Messages Admin</HelloTextBold>
          <SideBySideOptions>
            {" "}
            <input
              type="text"
              id="scearch"
              placeholder="Scearch ..."
              onChange={handleSchearchTerm}
              defaultValue={schearchAdminText}
              autoFocus
            ></input>
            <WhiteUnitBoxDiv>
              <div
                id="Pending"
                className={statusAdminFilter === "Pending" || statusAdminFilter === "In Progress" ||
                  statusAdminFilter === "InProgress" || statusAdminFilter === "In progress"
                  ? "active" : ""
                }
              >
                {t("Messages.Pending")}
              </div>
              <label className="switch">
                <input
                  type="checkbox"
                  onClick={() => handleGetAllByStatus(currentUser.id)}
                  id={statusAdminFilter}
                  defaultChecked={statusAdminFilter === "Pending" || statusAdminFilter === "In Progress" ||
                    statusAdminFilter === "InProgress" || statusAdminFilter === "In progress" ? true : false}
                />
                <span className="slider round"></span>
              </label>
              <div
                id="Achieved"
                className={statusAdminFilter === "Achieved" ||
                  statusAdminFilter === "Completed"
                  ? "active"
                  : ""
                }
              >
                {t("Messages.Achieved")}
              </div>
            </WhiteUnitBoxDiv>
            {/* <select
              onChange={() => handleGetAllByStatus(currentUser.id)}
              id="status"
              defaultValue={statusFilter}
            >
              <option value="Pending">Pending</option>
              <option value="Achieved">Achieved</option>
            </select> */}
          </SideBySideOptions>

          {savedAdminChats.map((SavedChats) => (
            <div key={SavedChats.RequestID}>
              <SavedDiv>
                <button onClick={() => handleGetChat(SavedChats.RequestID)}>
                  <div
                    className={
                      SavedChats.AgentName === ""
                        ? "statusPending"
                        : "statusProcessed"
                    }
                  >
                    Agent:
                    {SavedChats.AgentName !== ""
                      ? " " + SavedChats.AgentName
                      : " Pending"}
                  </div>
                  <div className="information">
                    <div className="left">
                      <img src={MessageIcon} alt="Message Icon" />
                      <div>
                        <font>User: {SavedChats.BuyerName}</font> <div />
                        <font>Status: {SavedChats.SupportStatus}</font> <div />
                        <font>{SavedChats.IssueName}</font>
                        <div className="GrayText">
                          {" "}
                          {SavedChats.SupportSubject}
                        </div>{" "}
                      </div>
                    </div>
                    <div className="right">
                      {" "}
                      {ConvertTimezone(SavedChats.LastAltered)}
                    </div>
                    <div className="unread">
                      {handleGetUnread(SavedChats.RequestID)}
                    </div>
                  </div>
                </button>
                <div className="takeControl">
                  <button
                    onClick={() => {
                      handleGetChatControl(
                        SavedChats.RequestID,
                        SavedChats.AgentID
                      );
                    }}
                  >
                    Take Control
                  </button>
                </div>
              </SavedDiv>
            </div>
          ))}
        </MainCard>
      )}
      <Footer />
    </Body>
  );
};

export default MessagesAdmin;
