import styled from "styled-components";

export const MainCard = styled.div`
  height: 80vh;
  width: 98vw;
  flex-direction: column;
  margin-top: 10px;
  margin-left: 40px;
  overflow-y: scroll;
  position: fixed;
  transition: 0.3s ease;
`;

export const HelloTextBold = styled.div`
  padding-top: 10px;
  font-size: calc(20px + 0.9vw);
  color: black;
  margin-left: 30px;
`;

export const HelloText = styled.div`
  padding-top: 10px;
  font-size: calc(12px + 0.8vw);
  margin-left: 30px;
`;

export const HelloTextHidden = styled.div`
  display: none;
  padding-top: 10px;
  padding-left: 30px;
  padding-bottom: 20px;
  font-weight: bold;
  font-size: calc(8px + 0.8vw);
`;

export const HelloTextSmall = styled.div`
  padding-left: 10px;
  font-size: calc(8px + 0.5vw);
`;

export const Body = styled.div`
  font-family: Roboto;
  display: block;
  height: 80vh;
  background-color: #f8f8f8;
`;
