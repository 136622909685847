import axios from "axios";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { concat } from "lodash";

const EndpontGetAppointment = process.env.REACT_APP_API_URL + "/getappointment";

const EndpointListCalendar = process.env.REACT_APP_API_URL + "/listappointment";

const EndpointUpdateSchedule = process.env.REACT_APP_API_URL + "/updateappointment";

var LocalListCalendar = [];
var NewVideoChats = [];

const header = {
  'vlr-authorization': 'eyJpZCI6ICJWQU1fQVBQIn0=',
  'authorization': Cookies.get("_ssoToken")
}

export async function ListCalendarByAgentID(agentID, startDate, finalDate) {
  const data = {
    AgentID: "" + agentID,
    InitialDate: startDate,
    FinalDate: finalDate,
  };
  return axios
    .post(EndpointListCalendar, data, { headers: header })
    .then((response) => {
      console.log(response)
      LocalListCalendar = response.data;
      if (agentID === "undef") {
        NewVideoChats = response.data
      }
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export function PutCalendar() {
  return concat(NewVideoChats, LocalListCalendar)
}

export function ReturnNewChats() {
  return NewVideoChats;
}

export async function GetCalendar(id) {
  const data = {
    RequestID: "" + id,
  };
  return axios
    .post(EndpontGetAppointment, data, { headers: header })
    .then((response) => {
      LocalListCalendar = response.data;
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}


export async function ListAllCalendar(startDate, finalDate) {
  const data = {
    BuyerID: "Pepper Stark",
    InitialDate: startDate,
    FinalDate: finalDate,
  };
  return axios
    .post(EndpointListCalendar, data, { headers: header })
    .then((response) => {
      LocalListCalendar = response.data;
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function ListCalendarByBuyerID(BuyerID, startDate, finalDate) {
  const data = {
    BuyerID: "" + BuyerID,
    InitialDate: startDate,
    FinalDate: finalDate,
  };
  await axios
    .post(EndpointListCalendar, data, { headers: header })
    .then((response) => {
      LocalListCalendar = response.data;
    })
    .catch((error) => {
      console.log(error);
    });
  return true;
}

export function UpdateSchedule(ScheduleID, AgentID, AgentName) {
  const data = {
    RequestID: ScheduleID,
    AgentID: AgentID,
    AgentName: AgentName,
  };
  axios.post(EndpointUpdateSchedule, data, { headers: header })
  .then((response) => {
    toast.success(response, { autoClose: 3000 });
  })
  .catch((error) => {
    toast.success(error, { autoClose: 3000 });
  });
}

export function ReturnCalendar() {
  return LocalListCalendar;
}

export function ReturnChats(supportType) {
  var SavedChats = [];
  LocalListCalendar.forEach((element) => {
    if (element.AppointmentType === supportType) {
      SavedChats.push(element);
    }
  });

  // SavedChats.sort((a, b) => (a.LastAltered > b.LastAltered ? -1 : 1));
  return SavedChats;
}