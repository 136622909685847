import React from "react";
import styled from "styled-components";
import BlueLogo from "../../assets/images/Oracle/BlueLogo.png";
import FacebookIcon from "../../assets/images/Oracle/Facebook.png";
import LinkedinIcon from "../../assets/images/Oracle/Linkedin.png";
import TwitterIcon from "../../assets/images/Oracle/Twitter.png";
import YoutubeIcon from "../../assets/images/Oracle/Youtube.png";
import { Anchor } from "../common";
import { useTranslation } from "react-i18next";

const Footer = (props) => {
  const Footer = styled.div`
    margin-top: 14vh;
    position: absolute;
    bottom: 0;
    justify-items: center;
    align-items: center;
    display: flex;
    background-color: white;
    padding: 0 31px;
    width: 100%;
    img {
      height: 31px;
    }
  `;

  const FooterLinks = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
    a {
      color: black;
      + a {
        margin-top: 0;
      }
    }
  `;

  const FooterLine = styled.div`
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 10px;
    border-left: 1px solid #cccccc;
    margin-left: 96px;
  `;

  const FooterIcon = styled.div`
    position: absolute;
    right: 100px;

    img {
      margin-left: 26px;
      height: 16px;
    }
  `;
  const { t } = useTranslation();

  return (
    <Footer>
      <div color="gray" style={{display:"flex", flexDirection:"column", textAlign:"center",fontSize: "x-small"}}>
        <img alt="" src={BlueLogo} />
        Desktop Version {process.env.REACT_APP_VERSION}
      </div>
      <FooterLine />
      <FooterLinks>
        <Anchor href="/legal-notice">{t("Oracle.LegalNotice")}</Anchor>
        <Anchor href="/privacy">{t("Oracle.Privacy")}</Anchor>
        <Anchor href="/terms">{t("Oracle.TermsOfUse")}</Anchor>
      </FooterLinks>
      <FooterIcon>
        <Anchor href="https://www.facebook.com/Vallourec/" target="_blank">
          {" "}
          <img alt="" src={FacebookIcon} />{" "}
        </Anchor>
        <Anchor href="https://twitter.com/vallourec/" target="_blank">
          {" "}
          <img alt="" src={TwitterIcon} />{" "}
        </Anchor>
        <Anchor
          href="https://www.linkedin.com/company/vallourec/"
          target="_blank"
        >
          <img alt="" src={YoutubeIcon} />{" "}
        </Anchor>
        <Anchor
          href="https://www.linkedin.com/company/vallourec/"
          target="_blank"
        >
          {" "}
          <img alt="" src={LinkedinIcon} />{" "}
        </Anchor>
      </FooterIcon>
    </Footer>
  );
};

export default Footer;
