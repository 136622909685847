import React, { useContext } from "react";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import { ProtectedRoute } from "./components/router/ProtectedRoute";
import AccountRechargeManagement from "./pages/AccountRechargeManagement/AccountRechargeManagement";
import Articles from "./pages/Articles/Articles";
import ArticleViewers from "./pages/Articles/ArticleViewer";
import CreateArticles from "./pages/Articles/CreateArticles";
import AssignTechnictianToLiveSupport from "./pages/AssignTechnictianToLiveSupport/AssignTechnictianToLiveSupport";
import Dashboard from "./pages/Dashboard/Dashboard";
import LandingPage from "./pages/LandingPage/LandingPage";
import LiveSupport from "./pages/LiveSupport/LiveSupport";
import AccessDenied from "./pages/Login/AccessDenied";
import Home from "./pages/Login/Home";
import LegalNotice from "./pages/Login/LegalNotice";
import NotFound from "./pages/Login/NotFound";
import Privacy from "./pages/Login/Privacy";
import Terms from "./pages/Login/Terms";
import MakeUpGraph from "./pages/MakeUpGraph/MakeUpGraph";
import MakeUpGraphAssistance from "./pages/MakeUpGraph/MakeUpGraphAssistance";
import MakeUpGraphDraftedGraph from "./pages/MakeUpGraph/MakeUpGraphDraftedGraph";
import MakeUpGraphFinish from "./pages/MakeUpGraph/MakeUpGraphFinish";
import MakeUpGraphForm from "./pages/MakeUpGraph/MakeUpGraphForm";
import ChatRoom from "./pages/Messages/ChatRoom";
import CreateChat from "./pages/Messages/CreateChat";
import Messages from "./pages/Messages/Messages";
import UpdateMessages from "./pages/Messages/UpdateMessages";
import MessagesAdmin from "./pages/Messages/MessagesAdmin";
import VideoChat from "./pages/Messages/VideoChat";
import VideoChatMessages from "./pages/Messages/VideoChatMessages";
import ListOnSiteSupport from "./pages/OnSiteSupport/ListOnSiteSupport";
import OnSiteSupport from "./pages/OnSiteSupport/OnSiteSupport";
import Oracle from "./pages/Oracle/Oracle";
import Profile from "./pages/Profile/Profile";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import Transaction from "./pages/Transaction/Transaction";
import UnderConstruction from "./pages/UnderConstruction/UnderConstruction";
import { UserContext } from "./stores/UserStore";
import RedirectTo from './pages/RedirectTo';

const Router = () => {
  const { authState } = useContext(UserContext);
  const history = useHistory()

  const protectedRouteProps = {
    isAuthenticated: authState === "SIGNED_IN",
    authenticationPath: "/",
  };

  return (
    <BrowserRouter>
      <Switch>
        <Route history={history} path="/redirect/:to" exact>
            <RedirectTo />
        </Route>
        <Route history={history} exact path="/" component={Oracle} />
        <ProtectedRoute
          path="/access"
          component={Home}
          {...protectedRouteProps}
        />
        <Route history={history} path="/legal-notice" component={LegalNotice} />
        <Route history={history} path="/privacy" component={Privacy} />
        <Route history={history} path="/terms" component={Terms} />
        <Route history={history} path="/landingPage" component={LandingPage} />
        <Route history={history} path="/Profile" component={Profile} />
        <Route history={history} path="/MakeUpGraph" component={MakeUpGraph} />
        <Route history={history} path="/MakeUpGraphRequest" component={MakeUpGraph} />
        <Route history={history}
          path="/MakeUpGraphAssistance"
          component={MakeUpGraphAssistance}
        />
        <Route history={history} path="/MakeUpGraphFinish" component={MakeUpGraphFinish} />
        <Route history={history} path="/MakeUpGraphForm" component={MakeUpGraphForm} />
        <Route history={history}
          path="/MakeUpGraphDraftedGraph"
          component={MakeUpGraphDraftedGraph}
        />
        <Route history={history} path="/LiveSupport" component={LiveSupport} />
        <Route history={history} path="/TermsAndConditions" component={TermsAndConditions} />
        <Route history={history} path="/Messages" component={Messages} />
        <Route history={history} path="/VideoChatMessages" component={VideoChatMessages} />
        <Route history={history} path="/MessagesAdmin" component={MessagesAdmin} />
        <Route history={history} path="/UpdateMessages" component={UpdateMessages} />
        <Route history={history} path="/Dashboard" component={Dashboard} />
        <Route history={history} path="/CreateChat" component={CreateChat} />
        <Route history={history} path="/ChatRoom" component={ChatRoom} />
        <Route history={history} path="/VideoChat" component={VideoChat} />
        <Route history={history} path="/Transaction" component={Transaction} />
        <Route history={history} path="/OnSiteSupport" component={OnSiteSupport} />
        <Route history={history} path="/ListOnSiteSupport" component={ListOnSiteSupport} />
        <Route history={history}
          path="/AccountRechargeManagement"
          component={AccountRechargeManagement}
        />
        <Route history={history}
          path="/AssignTechnictianToLiveSupport"
          component={AssignTechnictianToLiveSupport}
        />
        <Route history={history} path="/Articles" component={Articles} />
        <Route history={history} path="/ArticleViewer" component={ArticleViewers} />
        <Route history={history} path="/CreateArticles" component={CreateArticles} />
        <Route history={history} path="/UnderConstruction" component={UnderConstruction} />

        <ProtectedRoute
          path="/access-denied"
          component={AccessDenied}
          {...protectedRouteProps}
        />
        <Route history={history} component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
