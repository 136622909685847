import axios from "axios";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import _ from 'lodash'

const EndpointGetCatalog = process.env.REACT_APP_API_URL + "/getcatalog";

const EndpointGetGrade = process.env.REACT_APP_API_URL + "/getgrade";

var catalogAll = []
const header = {
    'vlr-authorization': 'eyJpZCI6ICJWQU1fQVBQIn0=',
    'authorization': Cookies.get("_ssoToken")
}

export async function GetAllCatalog() {
    const data = { "body": [] }
    return axios
        .post(EndpointGetCatalog, data, { headers: header })
        .then((response) => {
            catalogAll = response.data;
            return response.data;
        })
        .catch((error) => {
            toast.error(error);
        });
}

export async function GetGrade() {
    const data = { "body": [] }
    return axios
        .post(EndpointGetGrade, data, { headers: header })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            toast.error(error);
        });
}

export function returnConnectionsCatalog() {
    var connections = []
    _.forEach(catalogAll, item => { return connections.push(item.connection) })
    return connections;
}

export function returnDiameterCatalog() {
    var diameter = []
    _.forEach(catalogAll, item => { return diameter.push(item.od) })
    return diameter;
}

export function returnDesignCatalog() {
    var design = []
    _.forEach(catalogAll, item => { return design.push(item.isolated) })
    return design;
}

export function returnWeightCatalog() {
    var weight = []
    _.forEach(catalogAll, item => { return weight.push(item.weight) })
    return weight;
}

export function returnFilterDesign(selectedC) {
    var filter = []
    _.forEach(catalogAll, item => {
        if (item.connection === selectedC) {
            filter.push(item.isolated)
        }
    })
    return _.uniqBy(filter);
}

export function returnFilterDiameter(selectedC, selectedD) {
    var filter = []
    _.each(catalogAll, item => {
        if (item.connection === selectedC && item.isolated === selectedD) {
            filter.push(item.od)
        }
    })
    return _.uniqBy(filter);
}


export function returnFilterWheigth(selectedC, selectedD, selectedW) {
    var filter = []
    _.each(catalogAll, item => {
        if ((item.connection === selectedC) && (item.isolated === selectedD) && (item.od === selectedW)) {
            filter.push(item.weight)
        }
    })
    return (filter);
}