import React, { useContext, useEffect, useState } from 'react'
import { AiFillCloseCircle } from 'react-icons/ai'
import styled from 'styled-components'
import dropFileIcon from '../../assets/icons/dropFileIcon.svg'
import { Body, HelloTextBold, MainCard } from '../../components/common/MainCard'
import Caller from '../../components/layout/caller'
import Footer from '../../components/layout/footer'
import Header from '../../components/layout/header'
import NavBar from '../../components/layout/navBar'
import { GraphContext } from '../../stores/GraphStore'
import { useTranslation } from 'react-i18next'
import { parseInt } from 'lodash'

const MakeUpGraphForm = () => {
  document.title = 'VAM® Field Service'
  const {
    connections,
    selectedConnections,
    selectedDesign,
    diameter,
    design,
    weight,
    SMYS,
    MakeUpGraphs,
    FilesNames,
    ActiveGraph,
    StartMaximumDate,
    handleCreateForm,
    handleInputFile,
    handleSubmit,
    // handleSaveForm,
    UploadFile,
    DropAreaDragEnter,
    DropAreaDragLeave,
    DropAreaDragDrop,
    EraseFile,
    handleShowOptions,
    // handleReplicateInfoMakeUpEquip,
    handleReplicateInfoThreadCompound,
    GraphFormSelect,
    tab,
    setTab,
    selectedDiameter,
    selectedWeight,
    selectedSmys,
    selectedConnectionsPin,
    selectedDesignPin,
    selectedDiameterPin,
    selectedWeightPin,
    selectedSmysPin,
    connectionsPin,
    diameterPin,
    designPin,
    weightPin,
    SMYSPin,
  } = useContext(GraphContext)

  const isSmallScreen = window.screen.width <= 1366

  const { t } = useTranslation()
  const [formToShow, setFormToShow] = useState([])

  window.addEventListener(
    'dragover',
    function (e) {
      e.preventDefault()
    },
    false
  )

  window.addEventListener(
    'drop',
    function (e) {
      e.preventDefault()
    },
    false
  )

  const GraphsOption = styled.div`
    font-size: calc(8px + 0.4vw);
    position: relative;
    display: inline-block;
    margin-bottom: 15px;
    width: 60px;
    margin-left: 10px;
    margin-right: 50px;
    margin-top: 10px;

    select {
      font-family: 'Arial';
      display: inline-block;
      width: 100%;
      cursor: pointer;
      padding: 15px 15px;
      outline: 0;
      border: 0px solid #000000;
      border-radius: 0px;
      background: #009ee0;
      color: #ffffff;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
    }

    option {
      font-family: 'Arial';
      display: inline-block;
      width: 100%;
      cursor: pointer;
      padding: 15px 15px;
      outline: 0;
      border: 0px solid #000000;
      border-radius: 0px;
      background: #ffffff;
      color: #009ee0;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
    }

    .select_arrow {
      position: absolute;
      top: 16px;
      right: 15px;
      width: 0px;
      height: 0px;
      border: solid #4b4a4a;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  `

  const GraphsOptionHidden = styled.div`
    flex-direction: column;
    display: none;
    font-size: calc(8px + 0.4vw);
    width: 100%;
    min-width: 170px;
    background-color: #f8f9fa;
    justify-content: space-around;
    -webkit-box-shadow: 5px 5px 5px -5px #999, 5px 5px 5px -5px #999, -5px 5px 5px -5px #999;
    -moz-box-shadow: 5px 5px 5px -5px #999, 5px 5px 5px -5px #999, -5px 5px 5px -5px #999;
    box-shadow: 5px 5px 5px -5px #999, 5px 5px 5px -5px #999, -5px 5px 5px -5px #999;
    margin-left: 30px;
    height: 90%;
    border-radius: 20px;
    padding: 5px;

    button {
      min-height: 50px;
      min-width: 150px;
      border-radius: 20px;
      margin: 10px;
      background-color: #fff;
      color: #009ee0;
      border: 1px solid #e7e7e7;
      transition: all 0.3s ease 0s;
      outline: none;
      :hover {
        background-color: #009ee0;
        box-shadow: 0px 15px 20px rgba(167, 164, 206, 0.4);
        color: #fff;
      }
    }
    .active {
      min-height: 50px;
      min-width: 150px;
      background-color: #009ee0;
      color: #fff;
      border: 1px solid #e7e7e7;
      transition: all 0.3s ease 0s;
      outline: none;
    }
  `

  const GraphForm = styled.div`
    display: block;

    select {
      width: 42.5%;
      padding: 12px 25px;
      margin-right: 12px;
      display: inline-block;
      border: 0px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
      outline: none;
    }
  `

  const ApplyToAll = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-content: space-between;
    button {
      border-radius: 0 10px 0 10px;
    }
  `

  const SideBySideTextField = styled.div`
    display: flex;
    align-content: stretch;
    align-items: stretch;
    width: 100%;
    justify-content: center;
    div {
      width: 100%;
    }
  `

  const SideBySideDateTime = styled.div`
    display: flex;
    align-content: stretch;
    align-items: stretch;
    width: 50%;
  `

  const SideBySideNav = styled.div`
    display: flex;
    .Options {
      width: 10%;
      height: 30%;
    }
    .Forms {
      width: 85%;
      margin-left: 10px;
    }
  `

  const SideBySideGraphSelector = styled.div`
    display: flex;
    justify-content: space-between;
    align-content: space-between;
  `

  const StraightLine = styled.div`
    width: 100%;
    border-style: dotted none none none;
    border-width: thin;
    margin-top: 5px;
    margin-bottom: 5px;
  `

  const ButtonsDiv = styled.div`
    position: absolute;
    right: 3%;
    display: flex;
    text-align: right;

    button {
      min-height: 50px;
      min-width: 150px;
      margin-right: 20px;
      background-color: #009ee0;
      color: #fff;
      border: 1px solid #e7e7e7;
      transition: all 0.3s ease 0s;
      outline: none;
      :hover {
        background-color: #fff;
        box-shadow: 0px 15px 20px rgba(167, 164, 206, 0.4);
        color: #009ee0;
      }
    }
  `

  const FormsAlign = styled.div`
    max-width: 81.5vw;
    overflow: auto;
    overflow-y: scroll;
    border-radius: 10px;
    background-color: #f8f9fa;
    -webkit-box-shadow: 5px 5px 5px -5px #999, 5px 5px 5px -5px #999, -5px 5px 5px -5px #999;
    -moz-box-shadow: 5px 5px 5px -5px #999, 5px 5px 5px -5px #999, -5px 5px 5px -5px #999;
    box-shadow: 5px 5px 5px -5px #999, 5px 5px 5px -5px #999, -5px 5px 5px -5px #999;
    margin-left: calc(125px - 3vw);
    color: #666666;
    font-size: 12px;
    button {
      height: 50px;
      min-width: 150px;
      margin-left: 10px;
      background-color: #009ee0;
      color: #fff;
      border: 1px solid #e7e7e7;
      transition: all 0.3s ease 0s;
      outline: none;
      :hover {
        background-color: #fff;
        box-shadow: 0px 15px 20px rgba(167, 164, 206, 0.4);
        color: #009ee0;
      }
    }
    .SecondButton {
      margin-top: 5px;
      margin-left: 250px;
      margin-bottom: 0;
      background-color: #fff;
      color: #009ee0;
      outline: none;
      transition: all 0.3s ease 0s;
      :hover {
        background-color: #009ee0;
        box-shadow: 0px 15px 20px rgba(167, 164, 206, 0.4);
        color: #fff;
      }
    }

    .Title {
      padding-top: 10px;
      padding-left: 10px;
      color: black;
      font-size: calc(16px + 0.4vw);
    }
    .Subtitle {
      font-size: calc(8px + 0.4vw);
      padding-bottom: 10px;
      padding-left: 10px;
    }
    .SubtitleForConnection {
      font-size: calc(8px + 0.4vw);
      padding-bottom: 10px;
      padding-left: 10px;
      color: black;
    }
    textarea {
      width: 95%;
      resize: none;
      border: 0;
      :focus {
        outline: none;
      }
    }
    .FileList {
      margin-top: 10px;
      margin-left: 10px;
      color: #009ee0;
      display: flex;
      button {
        background-color: transparent;
        border: none;
        color: #009ee0;
        min-width: 10px;
        width: 10px;
        height: 10px;
        padding: 0;
        :hover {
          box-shadow: 0;
          cursor: pointer;
        }
      }
    }
    #drop-area {
      background-color: white;
      border-radius: 20px;
      width: 42%;
      padding-top: 20px;
      padding-bottom: 20px;
      margin-top: 10px;
      margin-bottom: 10px;
      text-align: center;
      color: #cccccc;
      transition: all 0.3s ease 0s;
      .highlight {
        border-color: purple;
      }
    }
    p {
      margin-top: 0;
    }
    .my-form {
      margin-bottom: 10px;
    }
    .Field {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 20px;
      color: #7e7e7e;
      select {
        width: 43%;
        padding: 12px 0px;
        display: inline-block;
        border: 0px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
        color: #7e7e7e;
        outline: none;
      }
      input {
        width: 40%;
        margin-top: 10px;
        padding: 10px;
        border: 0;
        :focus {
          outline: none;
        }
      }
      input[type='date'] {
        width: 150px;
        color: #7e7e7e;
      }
      input[type='time'] {
        width: 150px;
      }
      input[type='checkbox'] {
        width: 25px;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type='number'] {
        -moz-appearance: textfield;
        color: #7e7e7e;
        :focus {
          outline: none;
        }
      }
    }
  `

  useEffect(() => {
    setFormToShow([MakeUpGraphs[ActiveGraph]])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ActiveGraph])

  useEffect(() => {
    console.log(`Selected Design: ${selectedDesign}`)
  }, [selectedDesign])

  return (
    <Body>
      {handleCreateForm()}
      {StartMaximumDate()}
      <Header />
      <Caller />
      <NavBar />
      <MainCard id='MainCard' style={{ height: '100%', backgroundColor: 'white' }}>
        <SideBySideGraphSelector>
          <HelloTextBold>{t('MakeUpGraphForm.MakeupGraphReview')}</HelloTextBold>
          <div>
            {' '}
            {t('MakeUpGraphForm.SelectyourGraph')}
            <GraphsOption>
              <select onChange={handleShowOptions} defaultValue={ActiveGraph}>
                {MakeUpGraphs.map((Graphs) => (
                  <option
                    key={Graphs.ID}
                    id={'b' + Graphs.ID}
                    value={Graphs.ID}
                    className={Graphs.ID === parseInt(ActiveGraph) ? 'active' : ''}
                  >
                    {Graphs.ID + 1}
                  </option>
                ))}
              </select>
              <div className='select_arrow' />
            </GraphsOption>
          </div>
        </SideBySideGraphSelector>
        {formToShow.map((Graphs) => (
          <SideBySideNav key={Graphs.ID}>
            <div className='Options'>
              <GraphsOptionHidden
                id={Graphs.ID}
                style={{
                  display: Graphs.ID === parseInt(ActiveGraph) ? 'flex' : 'none',
                }}
              >
                <button id={'s0' + Graphs.ID} className={tab === 0 ? 'active' : ''} onClick={() => setTab(0)}>
                  {t('MakeUpGraphForm.Equipment')}
                </button>
                <button id={'s1' + Graphs.ID} className={tab === 1 ? 'active' : ''} onClick={() => setTab(1)}>
                  {t('MakeUpGraphForm.ThreadCompound')}
                </button>
                <button id={'s2' + Graphs.ID} className={tab === 2 ? 'active' : ''} onClick={() => setTab(2)}>
                  {t('MakeUpGraphForm.ConnectionInfo')}
                </button>
                <button id={'s3' + Graphs.ID} className={tab === 3 ? 'active' : ''} onClick={() => setTab(3)}>
                  {t('MakeUpGraphForm.MakeUpResults')}
                </button>
                <button id={'s4' + Graphs.ID} className={tab === 4 ? 'active' : ''} onClick={() => setTab(4)}>
                  {t('MakeUpGraphForm.GeneralInfo')}
                </button>
              </GraphsOptionHidden>
            </div>
            <div className='Forms'>
              <FormsAlign style={{ maxHeight: isSmallScreen ? '50vh' : '75vh' }}>
                {tab === 0 ? (
                  <GraphForm>
                    {/* <ApplyToAll>
                      <div>
                        <div className='Title'>{t('MakeUpGraphForm.MakeupEquipment')}</div>
                        <div className='Subtitle'> {t('MakeUpGraphForm.PleaseVerify')}</div>
                      </div>
                      <button onClick={() => handleReplicateInfoMakeUpEquip(Graphs.ID)}>
                        {t('MakeUpGraphForm.ApplytoallGraphs')}
                      </button>
                    </ApplyToAll> */}
                    <div className='Field'>
                      {t('MakeUpGraphForm.Makeupequimentused')} <div />
                      <input
                        type='text'
                        id={'MakeUpEquipmentUsed'}
                        defaultValue={Graphs.Equipment.MakeUpEquipmentUsed}
                        placeholder={t('MakeUpGraphForm.MakeupEquipmentusedPH')}
                        onChange={(e) => {
                          Graphs.Equipment.MakeUpEquipmentUsed = e.target.value
                        }}
                      ></input>
                    </div>
                    <div className='Field'>
                      {t('MakeUpGraphForm.Certificateoflastcalibration')} <div />
                      <input
                        type='file'
                        onChange={(event) => handleInputFile(event, Graphs.ID)}
                        id={'f2' + Graphs.ID}
                        hidden
                      ></input>
                      {FilesNames[Graphs.ID] !== undefined ? (
                        <div>
                          {t('MakeUpGraphForm.Addupto20MB')}
                          <div />
                          <button className='SecondButton' onClick={() => UploadFile(Graphs.ID)}>
                            {' '}
                            {t('MakeUpGraphForm.Modify')}{' '}
                          </button>
                        </div>
                      ) : (
                        <div>
                          <div
                            id='drop-area'
                            onDragEnter={() => DropAreaDragEnter(Graphs.ID)}
                            onDragOver={() => DropAreaDragEnter(Graphs.ID)}
                            onDragLeave={() => DropAreaDragLeave(Graphs.ID)}
                            onDrop={(event) => DropAreaDragDrop(event, Graphs.ID)}
                          >
                            <form className='my-form'>
                              <p>{t('MakeUpGraphForm.Dropfilesheretoinstalntlyuploadthem')}</p>
                              <img src={dropFileIcon} alt='Drop Files Here' />
                            </form>
                          </div>
                          {t('MakeUpGraphForm.Addupto20MB')}
                          <div>
                            <button className='SecondButton' onClick={() => UploadFile(Graphs.ID)}>
                              {' '}
                              {t('MakeUpGraphForm.UploadCertificate')}
                            </button>
                          </div>
                        </div>
                      )}
                      <div />
                      <div className='FileList'>
                        {FilesNames[Graphs.ID]}
                        {FilesNames[Graphs.ID] !== undefined && (
                          <button onClick={() => EraseFile(Graphs.ID)}>
                            <AiFillCloseCircle size='10px' />
                          </button>
                        )}
                      </div>
                    </div>
                    <div className='Field'>
                      {t('MakeUpGraphForm.Dateoflastcalibration')} <div />
                      <input
                        type='date'
                        id={'DateLastCalibration'}
                        defaultValue={Graphs.Equipment.DateLastCalibration}
                        placeholder={t('MakeUpGraphForm.DateoflastcalibrationPH')}
                        onChange={(e) => {
                          Graphs.Equipment.DateLastCalibration = e.target.value
                        }}
                      ></input>
                    </div>
                    <div className='Field'>
                      {t('MakeUpGraphForm.Buckingunitoperatorwhocarriedoutthemakeup')} <div />
                      <input
                        type='text'
                        id={'BuckingInOperator'}
                        defaultValue={Graphs.Equipment.BuckingInOperator}
                        placeholder={t('MakeUpGraphForm.NameoftheoperatorwhocarriedoutthemakeupPH')}
                        onChange={(e) => {
                          Graphs.Equipment.BuckingInOperator = e.target.value
                        }}
                      ></input>
                    </div>
                  </GraphForm>
                ) : tab === 1 ? (
                  <GraphForm>
                    <ApplyToAll>
                      <div className='Title'>{t('MakeUpGraphForm.ThreadCompound')}</div>
                      <button onClick={() => handleReplicateInfoThreadCompound(Graphs.ID)}>
                        {t('MakeUpGraphForm.ApplytoallGraphs')}
                      </button>
                    </ApplyToAll>
                    <div className='Field'>
                      {t('MakeUpGraphForm.Runningcompoundusedformakeup')} <div />
                      <input
                        type='text'
                        id={'RunningCompoundUsed'}
                        defaultValue={Graphs.ThreadCompound.RunningCompoundUsed}
                        placeholder={t('MakeUpGraphForm.RunningcompoundusedformakeupPH')}
                        onChange={(e) => {
                          Graphs.ThreadCompound.RunningCompoundUsed = e.target.value
                        }}
                      ></input>
                    </div>
                    <div className='Field'>
                      {t('MakeUpGraphForm.RunningCompoundExpiryDate')} <div />
                      <input
                        type='date'
                        id={'RunningCompoundExpiryDate'}
                        defaultValue={Graphs.ThreadCompound.RunningCompoundExpiryDate}
                        placeholder={t('MakeUpGraphForm.RunningCompoundExpiryDatePH')}
                        onChange={(e) => {
                          Graphs.ThreadCompound.RunningCompoundExpiryDate = e.target.value
                        }}
                      ></input>
                    </div>
                  </GraphForm>
                ) : tab === 2 ? (
                  <GraphForm>
                    <div className='Title'>
                      {t('MakeUpGraphForm.ConnectionInfo')} - {t('MakeUpGraphForm.BoxThread')}
                    </div>
                    <SideBySideTextField>
                      <div className='Field'>
                        {t('MakeUpGraphForm.BoxThreadType')} <div />
                        <select id={'BoxThreadType'} value={selectedConnections} onChange={() => GraphFormSelect(1)}>
                          {connections.map((option) => (
                            <option value={option.value}>{option.label}</option>
                          ))}
                        </select>
                      </div>
                      <div className='Field'>
                        {t('MakeUpGraphForm.Design')} <div />
                        <select id={'BoxDesign'} value={selectedDesign} onChange={() => GraphFormSelect(1)}>
                          {design.map((option) => (
                            <option value={option.value}>{option.label}</option>
                          ))}
                        </select>
                      </div>
                      <div className='Field'>
                        {t('MakeUpGraphForm.DiameterOD')} <div />
                        <select id={'BoxDiameter'} value={selectedDiameter} onChange={() => GraphFormSelect(1)}>
                          {diameter.map((option) => (
                            <option value={option.value}>{option.label}</option>
                          ))}
                        </select>
                      </div>
                    </SideBySideTextField>
                    <SideBySideTextField>
                      <div className='Field'>
                        {t('MakeUpGraphForm.Weight')} <div />
                        <select id={'BoxThreadWeight'} value={selectedWeight} onChange={() => GraphFormSelect(1)}>
                          {weight.map((option) => (
                            <option value={option.value}>{option.label}</option>
                          ))}
                        </select>
                      </div>
                      <div className='Field'>
                        {t('MakeUpGraphForm.BoxThreadWeightfoot')} <div />
                        <select id={'BoxSMYS'} value={selectedSmys} onChange={() => GraphFormSelect(1)}>
                          {SMYS.map((option) => (
                            <option value={option.value}>{option.label}</option>
                          ))}
                        </select>
                      </div>
                      <div className='Field'>
                        {t('MakeUpGraphForm.BoxThreadGrade')} <div />
                        <input
                          id={'BoxThreadGrade'}
                          type='number'
                          placeholder={t('MakeUpGraphForm.BoxThreadGradePH')}
                          // value={Graphs.ConnectionInfo.BoxThreadGrade}
                          defaultValue={Graphs.ConnectionInfo.BoxThreadGrade}
                          onChange={(e) => {
                            Graphs.ConnectionInfo.BoxThreadGrade = e.target.value
                          }}
                        ></input>
                      </div>
                    </SideBySideTextField>
                    <div className='Field'>
                      {t('MakeUpGraphForm.BoxendTallyorPartNumber')} <div />
                      <input
                        id={'BoxEndTally'}
                        placeholder={t('MakeUpGraphForm.BoxendTallyorPartNumberPH')}
                        // value={Graphs.ConnectionInfo.BoxEndTally}
                        defaultValue={Graphs.ConnectionInfo.BoxEndTally}
                        onChange={(e) => {
                          Graphs.ConnectionInfo.BoxEndTally = e.target.value
                        }}
                      ></input>
                    </div>
                    <StraightLine />
                    <div className='Title'>
                      {t('MakeUpGraphForm.ConnectionInfo')} - {t('MakeUpGraphForm.PinThread')}
                    </div>
                    <SideBySideTextField>
                      <div className='Field'>
                        {t('MakeUpGraphForm.PinThreadType')} <div />
                        <select id={'PinThreadType'} value={selectedConnectionsPin} onChange={() => GraphFormSelect(2)}>
                          {connectionsPin.map((option) => (
                            <option value={option.value}>{option.label}</option>
                          ))}
                        </select>
                      </div>
                      <div className='Field'>
                        {t('MakeUpGraphForm.PinThreadDesign')} <div />
                        <select id={'PinDesign'} value={selectedDesignPin} onChange={() => GraphFormSelect(2)}>
                          {designPin.map((option) => (
                            <option value={option.value}>{option.label}</option>
                          ))}
                        </select>
                      </div>
                      <div className='Field'>
                        {t('MakeUpGraphForm.DiameterOD')} <div />
                        <select id={'PinDiameter'} value={selectedDiameterPin} onChange={() => GraphFormSelect(2)}>
                          {diameterPin.map((option) => (
                            <option value={option.value}>{option.label}</option>
                          ))}
                        </select>
                      </div>
                    </SideBySideTextField>
                    <SideBySideTextField>
                      <div className='Field'>
                        {t('MakeUpGraphForm.PinThreadWeight')} <div />
                        <select id={'PinThreadWeight'} value={selectedWeightPin} onChange={() => GraphFormSelect(2)}>
                          {weightPin.map((option) => (
                            <option value={option.value}>{option.label}</option>
                          ))}
                        </select>
                      </div>
                      <div className='Field'>
                        {t('MakeUpGraphForm.BoxThreadWeightfoot')} <div />
                        <select id={'PinSMYS'} value={selectedSmysPin} onChange={() => GraphFormSelect(2)}>
                          {SMYSPin.map((option) => (
                            <option value={option.value}>{option.label}</option>
                          ))}
                        </select>
                      </div>
                      <div className='Field'>
                        {t('MakeUpGraphForm.PinThreadGrade')}
                        <div />
                        <input
                          type='number'
                          id={'PinThreadGrade'}
                          placeholder={t('MakeUpGraphForm.PinThreadGradePH')}
                          // value={Graphs.ConnectionInfo.PinThreadGrade}
                          defaultValue={Graphs.ConnectionInfo.PinThreadGrade}
                          onChange={(e) => {
                            Graphs.ConnectionInfo.PinThreadGrade = e.target.value
                          }}
                        ></input>
                      </div>
                    </SideBySideTextField>
                    <div className='Field'>
                      {t('MakeUpGraphForm.Pinisundersized')}
                      <div />
                      <textarea
                        rows='2'
                        id={'PinUndersized'}
                        placeholder={t('MakeUpGraphForm.Typehereadditionalinformation')}
                      ></textarea>
                    </div>
                  </GraphForm>
                ) : tab === 3 ? (
                  <GraphForm id={Graphs.ID + '-3'}>
                    <div className='Title'>{t('MakeUpGraphForm.MakeUpResults')}</div>
                    <SideBySideDateTime>
                      <div className='Field'>
                        {t('MakeUpGraphForm.Makeupdate')} <div />
                        <input
                          type='date'
                          // manual
                          id={'MakeUpDateTime1'}
                          defaultValue={Graphs.MakeUpResults.MakeUpDateTime}
                          value={Graphs.MakeUpResults.MakeUpDateTime1}
                          onChange={(e) => {
                            Graphs.MakeUpResults.MakeUpDateTime1 = e.target.value
                            Graphs.MakeUpResults.MakeUpDateTime = e.target.value
                          }}
                          placeholder={t('MakeUpGraphForm.MakeupdatePH')}
                        ></input>
                      </div>
                      <div className='Field'>
                        {t('MakeUpGraphForm.MakeupTime')} <div />
                        <input
                          type='time'
                          // manual
                          id={'MakeUpDateTime2'}
                          defaultValue={Graphs.MakeUpResults.MakeUpDateTime}
                          placeholder={t('MakeUpGraphForm.MakeupTimePH')}
                          value={Graphs.MakeUpResults.MakeUpDateTime2}
                          onChange={(e) => {
                            Graphs.MakeUpResults.MakeUpDateTime2 = e.target.value
                          }}
                        ></input>
                      </div>
                    </SideBySideDateTime>
                    <div className='Field'>
                      {t('MakeUpGraphForm.Achievedfinaltorque')} <div />
                      <input
                        type='number'
                        // f18
                        id={'FinalTorque'}
                        defaultValue={Graphs.MakeUpResults.FinalTorque}
                        placeholder={t('MakeUpGraphForm.AchievedfinaltorquePH')}
                        onChange={(e) => {
                          Graphs.MakeUpResults.FinalTorque = e.target.value
                        }}
                      ></input>
                    </div>
                    <div className='Field'>
                      {t('MakeUpGraphForm.Achievedshouldertorque')}
                      <div />
                      <input
                        type='number'
                        // f19
                        id={'ShoulderTorque'}
                        defaultValue={Graphs.MakeUpResults.ShoulderTorque}
                        placeholder={t('MakeUpGraphForm.AchievedshouldertorquePH')}
                        onChange={(e) => {
                          Graphs.MakeUpResults.ShoulderTorque = e.target.value
                        }}
                      ></input>
                    </div>
                    <div className='Field'>
                      {t('MakeUpGraphForm.Achieveddeltaturns')} <div />
                      <input
                        type='number'
                        // f20
                        id={'DeltaTurns'}
                        defaultValue={Graphs.MakeUpResults.DeltaTurns}
                        placeholder={t('MakeUpGraphForm.AchieveddeltaturnsPH')}
                        onChange={(e) => {
                          Graphs.MakeUpResults.DeltaTurns = e.target.value
                        }}
                      ></input>
                    </div>
                    <div className='Field'>
                      {t('MakeUpGraphForm.Achievedshoulderslopefactor')} <div />
                      <input
                        type='number'
                        // f21
                        id={'ShoulderSlopeFactor'}
                        defaultValue={Graphs.MakeUpResults.ShoulderSlopeFactor}
                        placeholder={t('MakeUpGraphForm.AchievedshoulderslopefactorPH')}
                        onChange={(e) => {
                          Graphs.MakeUpResults.ShoulderSlopeFactor = e.target.value
                        }}
                      ></input>
                    </div>
                    <div className='Field'>
                      {t('MakeUpGraphForm.CommentsQuestionsaboutthemakeupgraph')}
                      <div />
                      <textarea
                        rows='8'
                        // f22
                        id={'Comments'}
                        defaultValue={Graphs.MakeUpResults.Comments}
                        placeholder={t('MakeUpGraphForm.CommentsQuestionsaboutthemakeupgraphPH')}
                        onChange={(e) => {
                          Graphs.MakeUpResults.Comments = e.target.value
                        }}
                      ></textarea>
                    </div>
                  </GraphForm>
                ) : tab === 4 ? (
                  <GraphForm id={Graphs.ID + '-4'}>
                    <div className='Title'>{t('MakeUpGraphForm.GeneralJobInformation')}</div>
                    <div className='Field'>
                      {t('MakeUpGraphForm.CustomerWorkshopname')} <div />
                      <input
                        type='text'
                        // f23
                        id={'CustomerOrWorkshopName'}
                        defaultValue={Graphs.GeneralInfo.CustomerOrWorkshopName}
                        placeholder={t('MakeUpGraphForm.CustomerWorkshopnamePH')}
                        onChange={(e) => {
                          Graphs.GeneralInfo.CustomerOrWorkshopName = e.target.value
                        }}
                      ></input>
                    </div>
                    <div className='Field'>
                      {t('MakeUpGraphForm.EndserOilcompany')} <div />
                      <input
                        type='text'
                        // f24
                        id={'EndUserOrOilCompany'}
                        defaultValue={Graphs.GeneralInfo.EndUserOrOilCompany}
                        placeholder={t('MakeUpGraphForm.EndserOilcompanyPH')}
                        onChange={(e) => {
                          Graphs.GeneralInfo.EndUserOrOilCompany = e.target.value
                        }}
                      ></input>
                    </div>
                  </GraphForm>
                ) : null}
              </FormsAlign>
            </div>
          </SideBySideNav>
        ))}
      </MainCard>
      <ButtonsDiv style={{ bottom: isSmallScreen ? '15%' : '13%' }}>
        {/* <button onClick={() => handleSaveForm()}>{t('MakeUpGraphForm.SavetoFinishLater')}</button> */}
        <button onClick={() => handleSubmit()}>{t('MakeUpGraphForm.Submit')}</button>
      </ButtonsDiv>
      <Footer />
    </Body>
  )
}

export default MakeUpGraphForm
