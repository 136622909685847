import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import * as ConnectChat from "../components/connect/chatConnect";
import { sendFile, sendFileS3, } from "../components/connect/sendFileToUpload";
import { UserContext } from "../stores/UserStore";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import * as Calendar from "../components/connect/calendar";
import transactions from '../api/transactions';

export const ChatIDContext = React.createContext();

export const ChatIDProvider = ({ children }) => {

  const history = useHistory();
  const [chatID, setChatID] = useState("");
  const [chatFull, setChatFull] = useState(null);
  const { t } = useTranslation();


  const [ChatInfo, setChatInfo] = useState([]);
  const [messages, setMessages] = useState([]);
  const [ChatStatus, setChatStatus] = useState("");
  const [listenerAdded, setListenerAdded] = useState(true);
  var setIntevalForRefresh;
  var setIntevalForRefreshMessages;
  const [gotMessages, setGotMessages] = useState(false);
  const CurrentLink = "ChatRoom";

  // CREATE CHAT

  const [gotCreateSavedData, setGotCreateSavedData] = useState(false);
  const [createsupportType, setCreateSupportType] = useState([]);
  const [createchatInfo, setCreateChatInfo] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState([])
  const { currentUser } = useContext(UserContext);


  // MESSAGES
  const [savedMessagesChats, setSavedMessagesChats] = useState([]);
  const [gotSavedMessagesData, setGotSavedMessagesData] = useState(false);
  const [gotNewMessagesRequest, setGotNewMessagesRequest] = useState([]);
  const [costumerDetail, setCostumerDetail] = useState(null);
  const [agentDetail, setAgentDetail] = useState(null)
  const [schearchMessagesText, setSchearchMessagesText] = useState("");
  // const MessagesLink = "Messages";
  const [statusMessagesFilter, setStatusMessagesFilter] = useState("Pending");
  // var TotalTimer = 1000;
  const AmountPerAgentMessages = 20;

  // ADMIN
  const [savedAdminChats, setSavedAdminChats] = useState([]);
  const [unreadAdminMessages, setUnreadAdminMessages] = useState([]);
  const [gotSavedAdminData, setGotSavedAdminData] = useState(false);
  const [schearchAdminText, setSchearchAdminText] = useState("");
  const [statusAdminFilter, setStatusAdminFilter] = useState("Pending");

  // UPDATE
  const [savedUpdateChats, setSavedUpdateChats] = useState([]);
  const [unreadUpdateMessages, setUnreadUpdateMessages] = useState([]);
  const [gotSavedUpdateData, setGotSavedUpdateData] = useState(false);
  const [gotNewUpdateRequest, setGotNewUpdateRequest] = useState(false);
  const [schearchUpdateText, setSchearchUpdateText] = useState("");
  const [statusUpdateFilter, setStatusUpdateFilter] = useState("Pending");
  // const UpdateLink = "Messages";
  const AmountPerAgentUpdate = 4;

  const [companyBudget, setCompanyBudget] = useState(0)
  console.log(companyBudget)
  // VIDEO

  const configuration = {
    iceServers: [
      {
        urls: "turn:vam-app-coturn.aws.smart.vallourec.com:3478",
        username: "vamapp",
        credential: "ezJVdtd*"
      },
      {
        urls: "stun:stun1.l.google.com:19302",
      },
    ],
  };
  const [audioTrack, setAudioTracks] = useState(null);
  const [videoTrack, setVideoTracks] = useState(null);
  const [showMyCam, setShowMyCam] = useState(false);
  const [muteCall, setMuteCall] = useState(true);
  const [startedCall, setStartedCall] = useState(false);
  const [ChatVideoInfo, setChatVideoInfo] = useState([]);
  const [GeneratedColor, setGeneratedColor] = useState(false);
  const [kickedTheDoor, setKickedTheDoor] = useState(false);
  var [peerConnection, setPC] = useState(null);
  const [savedVideoChats, setSavedVideoChats] = useState([]);
  const [unreadVideoMessages, setUnreadVideoMessages] = useState([]);
  const [gotVideoRequest, setGotVideoRequest] = useState([]);
  const [gotSavedVideoData, setGotSavedVideoData] = useState(false);
  const [gotNewVideoRequest, setGotNewVideoRequest] = useState(false);
  const [schearchVideoText, setSchearchVideoText] = useState("");
  const [statusVideoFilter, setStatusVideoFilter] = useState("Pending");
  const AmountPerAgentVideo = 4;
  /*-------------------------------------------------------------------------------------------------------*/

  // CHAT ROOM
  const listener = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      handleSendMessage();
    }
  };

  const handleSendMessage = async () => {
    const message = document.getElementById("message").value;
    if (message !== "" && message !== "" && chatID !== "") {
      var castingDate = new Date(),
        mnth = ("0" + (castingDate.getMonth() + 1)).slice(-2),
        day = ("0" + castingDate.getDate()).slice(-2);
      const date =
        [castingDate.getFullYear(), mnth, day].join("/") +
        " " +
        castingDate.getHours() +
        ":" +
        castingDate.getMinutes() +
        ":" +
        castingDate.getSeconds();


      const Message = {
        ChatID: chatID,
        MessageType: "Message",
        MessageContent: message,
        CreatedAt: date,
        MessageSender: currentUser.id,
        MessageAttachment: "",
        MessageAction: "",
        SeenByOtherParty: false,
        SenderName: currentUser.firstName + " " + currentUser.lastName,
      };
      document.getElementById("message").value = "";
      await ConnectChat.SendMessageToChat(
        chatID,
        message,
        currentUser.id,
        "",
        "",
        currentUser.firstName
      );
      ConnectChat.GetTranscript(chatID);
      ConnectChat.MarkUnreadMessages(chatID, currentUser.id);
      ConnectChat.AddMessageAfterUpdate(Message);
      var newMessages = ConnectChat.ReturnTranscript();
      //newMessages.push(Message);
      setMessages(newMessages);
    }
  };

  const handleSendFile = async () => {
    document.getElementById("file").click();
  };

  const ConvertTimezone = (oldDate) => {
    var date = new Date(oldDate),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2),
      hour = ("0" + (date.getHours() + 1)).slice(-2),
      minute = ("0" + (date.getMinutes() + 1)).slice(-2);

    var currentDate = new Date();

    if (date.toDateString() === currentDate.toDateString()) {
      return hour + ":" + minute;
    }
    return [day, mnth, date.getFullYear()].join("/");
  };

  const handleGetTranscript = () => {
    if (listenerAdded) {
      document.addEventListener("keydown", listener);
      setListenerAdded(false);
    }

    try {
      // if (!gotMessages) {
      if (chatID !== "") {
        ConnectChat.GetTranscript(chatID);
        ConnectChat.ListChatRoomsByChatID(chatID);
        ConnectChat.MarkUnreadMessages(chatID, currentUser.id);
        setGotMessages(true);
        scanForChanges();
      } else {
        history.push("./Messages");
      }
      // }
    } catch (e) {
      console.log(e);
    }
  };
  function updateScroll() {
    if (chatID !== "") {
      setTimeout(function () {
        if (document.getElementById("transcript") !== null) {
          var element = document.getElementById("transcript");
          element.scrollTop = element.scrollHeight;
          document.getElementById("message").focus();
        }
      }, 10);
    }
  }

  const scanForChanges = () => {
    setTimeout(async () => {
      if (ConnectChat.ReturnTranscript().length !== 0) {
        setMessages(ConnectChat.ReturnTranscript());
        setChatInfo(ConnectChat.ReturnChatsUnOrdered("Chat"));
      }
    }, 2000);

    setTimeout(async () => {
      if (ConnectChat.ReturnTranscript().length !== 0) {
        setMessages(ConnectChat.ReturnTranscript());
        setChatInfo(ConnectChat.ReturnChatsUnOrdered("Chat"));
      }
    }, 4000);

    setTimeout(async () => {
      if (ConnectChat.ReturnTranscript().length !== 0) {
        setMessages(ConnectChat.ReturnTranscript());
        setChatInfo(ConnectChat.ReturnChatsUnOrdered("Chat"));
      }
    }, 6000);

    setIntevalForRefresh = setInterval(async () => {
      if (window.location.href.indexOf(CurrentLink) > -1) {
        setMessages(ConnectChat.ReturnTranscript());
        setChatInfo(ConnectChat.ReturnChatsUnOrdered("Chat"));
      } else {
        document.removeEventListener("keydown", listener, false);
        clearInterval(setIntevalForRefresh);
      }
    }, 50000);

    setIntevalForRefreshMessages = setInterval(async () => {
      if (window.location.href.indexOf(CurrentLink) > -1) {
        ConnectChat.GetTranscript(chatID);
      } else {
        clearInterval(setIntevalForRefreshMessages);
      }
    }, 10000);
  };

  const handleFromMessage = (Sender) => {
    var Messagesender;
    if (Sender === currentUser.id) {
      Messagesender = "SELF";
    } else if (Sender === "SYSTEM") {
      Messagesender = "SYSTEM";
    } else {
      Messagesender = "OTHER";
    }
    return Messagesender;
  };

  const handleChangeChatStatus = async () => {
    ChatInfo.forEach(async (element) => {
      if (element.RequestID === chatID) {
        if (element.SupportStatus === "In Progress" || element.SupportStatus === "InProgress" || element.SupportStatus === "In progress" || element.SupportStatus === "Pending") {
          await ConnectChat.UpdateChatRooms(chatID, element.Title, element.Description, "Achieved", element.SupportSubject);
        } else {
          await ConnectChat.UpdateChatRooms(chatID, element.Title, element.Description, "Pending", element.SupportSubject);
        }
        ConnectChat.ListChatRoomsByChatID(chatID);
        ConnectChat.MarkUnreadMessages(chatID, currentUser.id);
      }
    });
  };

  const handleSetStatus = (status) => {
    if (ChatStatus === "") {
      setChatStatus(status);
    }
  };

  const handleInputFile = async (event) => {
    var File = event.target.files[0];
    var link;
    const user = String(currentUser.id)
    console.log(File.size)
    if (File.size > 5990000 || File.fileSize > 5990000) {
      link = await sendFileS3(File, user);
      console.log(link)
    } else {
      link = await sendFile(File, user);
    }
    console.log(link)
    await ConnectChat.SendMessageToChat(
      chatID,
      File.name,
      currentUser.id,
      link[0].name,
      currentUser.firstName
    );
    ConnectChat.MarkUnreadMessages(chatID, currentUser.id);
  };

  // CREATE CHAT

  const getCompanyCurrentBudget = async () => {
    const result = await transactions.listTransactions()
    const filteredTransactions = result.map((item) => item.Company === currentUser.company)
    let totalSum = 0

    if (filteredTransactions.length > 0) {
      filteredTransactions.forEach((transaction) => {
        totalSum += transaction.newBudget
      })

      setCompanyBudget(totalSum)
    }
  }

  // console.log(companyBudget)
  const handleCreateChatRoom = async () => {
    const IssueName = document.getElementById("IssueName").value;
    const IssueType = document.getElementById("IssueType").value;
    const SupportType = document.getElementById("SupportType").value;
    const Message = document.getElementById("Message").value;
    if (SupportType === "VideoCall") {
      var IssueDate = document.getElementById("IssueDate").value;
      var ScheduledTime = document.getElementById("CallTime").value ? document.getElementById("Duration").value * 60 : 1800;

      const tokenValue = document.getElementById('Duration').value
      await getCompanyCurrentBudget()

      const payload = {
        operation: 'Video Calling',
        oldBudget:  `${tokenValue}`,
        newBudget: "0",
        customer: `${Object.keys(currentUser.roles.COMPANY)[0]}`,
        user: `${currentUser.firstName} ${currentUser.lastName}`
      }

      await transactions.insertTransaction(payload)
    }

    await ConnectChat.CreateChatRoom(
      currentUser.id,
      IssueName,
      IssueType,
      SupportType,
      IssueDate,
      ScheduledTime,
      Message,
      currentUser.firstName
    )
    const teste = ConnectChat.ReturnChatsPending("Chat");
    setSavedMessagesChats(teste);

    setTimeout(() => {
      if (SupportType === "VideoCall") {
        window.location.href = "/VideoChatMessages";
      } else {
        window.location.href = "/Messages";
      }
    }, 4000)
  };

  const StartMinimumDate = () => {
    if (document.getElementById("SupportType") === "VideoCall") {
      setTimeout(() => {
        var dtToday = new Date();

        var month = dtToday.getMonth() + 1;
        var day = dtToday.getDate();
        var year = dtToday.getFullYear();
        var hour = dtToday.getHours();
        var minutes = dtToday.getMinutes();
        if (month < 10) month = "0" + month.toString();
        if (day < 10) day = "0" + day.toString();
        if (hour < 10) hour = "0" + hour.toString();
        if (minutes < 10) minutes = "0" + minutes.toString();

        var maxDate = year + "-" + month + "-" + day + "T" + hour + ":" + minutes + ":00";

        document.getElementById("IssueDate").min = maxDate;
      }, 50);
    }

  };

  const RecievedPreviousChat = async () => {
    if (!gotCreateSavedData && chatFull !== null) {
      setGotCreateSavedData(true);
      setCreateChatInfo(chatFull);
      // console.log(chatFull);
      setChatFull(null);
      setTimeout(() => {
        var IssueType = document.getElementById("IssueType");
        for (let t, j = 0; (t = IssueType.options[j]); j++) {
          if (t.value.replaceAll(" ", "") === chatFull.IssueType) {
            IssueType.selectedIndex = j;
            break;
          }
        }
        var SupportType = document.getElementById("SupportType");
        for (let t, j = 0; (t = SupportType.options[j]); j++) {
          if (t.value === chatFull.SupportType) {
            SupportType.selectedIndex = j;
            break;
          }
        }
      }, 50);
    } else {
      StartMinimumDate();
    }
  };

  const ConvertDate = (dateOld) => {
    var newDate = new Date(dateOld);
    return (
      newDate.getFullYear() +
      "-" +
      (newDate.getMonth() < 10
        ? "0" + newDate.getMonth()
        : newDate.getMonth()) +
      "-" +
      (newDate.getDate() < 10 ? "0" + newDate.getDate() : newDate.getDate()) +
      "T" +
      (newDate.getHours() < 10
        ? "0" + newDate.getHours()
        : newDate.getHours()) +
      ":" +
      (newDate.getMinutes() < 10
        ? "0" + newDate.getMinutes()
        : newDate.getMinutes()) +
      ":" +
      (newDate.getSeconds() < 10
        ? "0" + newDate.getSeconds()
        : newDate.getSeconds())
    );
  };

  // MESSAGES

  const handleGetAllChats = async (BuyerID) => {
    if (!gotSavedAdminData) {
      setChatID("");
      await ConnectChat.ListChatRooms(BuyerID);
    }
    if (!gotSavedMessagesData) {
      setChatID("");
      await ConnectChat.ListChatRooms(BuyerID);
    }
    if (!gotSavedUpdateData) {
      setChatFull("");
      await ConnectChat.ListChatRooms(BuyerID);
    }
    if (!gotSavedVideoData) {
      setChatID("");
      await ConnectChat.ListChatRooms(BuyerID);
    }
  };

  const checkForCurrentUserInfo = () => {
    if (currentUser == null) {
      toast.error("Error Getting User Info");
    }
  };


  const handlePickNewRequest = (id) => {

    var newMessages = savedMessagesChats;
    ConnectChat.PickNewRequests(currentUser.id, currentUser.firstName, id).then((response) => {
      newMessages.push(response)
    })
    toast.warning("Please, refresh de page", { autoClose: 5000 });
    setSavedMessagesChats(newMessages)
  }

  const getChat = async () => {
    await ConnectChat.ListChatRoomsByAgent("undef", "Pending")
    setGotNewMessagesRequest(ConnectChat.ReturnNewChats())
  }

  const handleGetAllChatsByAgent = async (AgentID) => {
    if (!gotSavedMessagesData) {
      setChatID("");
      await ConnectChat.ListChatRoomsByAgent(AgentID);
      // var setIntevalForRefresh = setInterval(async () => {
      // if (window.location.href.includes(MessagesLink)) {
      if (ConnectChat.ReturnChats("Chat").length < 5) {
        setGotNewMessagesRequest(ConnectChat.ReturnNewChats())
        await ConnectChat.ListChatRoomsByAgent(AgentID);
        setSavedMessagesChats(ConnectChat.ReturnChats("Chat"));
      }
      // } else {
      //   clearInterval(setIntevalForRefresh);
      // }
      // }, 100000);
    }
    if (!gotSavedUpdateData) {
      setChatFull("");
      await ConnectChat.ListChatRoomsByAgent(AgentID);
      // var setIntevalForRefresh1 = setInterval(async () => {
      //   if (window.location.href.includes(UpdateLink)) {
      if (ConnectChat.ReturnChats("Chat").length < 5) {
        await ConnectChat.ListChatRoomsByAgent(AgentID);
        setSavedUpdateChats(ConnectChat.ReturnChats("Chat"));
      }
      //   } else {
      //     clearInterval(setIntevalForRefresh1);
      //   }
      // }, 100000);
    }
    if (!gotSavedVideoData) {
      setChatID("");
      var startDate = new Date();
      var endDate = new Date();
      startDate.setDate(1);
      await ConnectChat.ListChatRoomsByAgent(AgentID);
      // var setIntevalForRefresh2 = setInterval(async () => {
      //   if (window.location.href.includes(VideoLink)) {
      if (Calendar.ReturnChats("VideoCall").length < 5) {
        await Calendar.ListCalendarByAgentID(AgentID, startDate, endDate);
        setSavedVideoChats(Calendar.ReturnChats("VideoCall"));
      }
      //   } else {
      //     clearInterval(setIntevalForRefresh2);
      //   }
      // }, 100000);
    }
  };

  const handleListChats = async () => {
    if (!gotSavedMessagesData) {
      // setTimeout(async () => {
      if (statusMessagesFilter === "Pending" || statusMessagesFilter === "In Progress" || statusMessagesFilter === "InProgress" || statusMessagesFilter === "pending") {
        setSavedMessagesChats(ConnectChat.ReturnChatsPending("Chat"));
      } else if (statusMessagesFilter === "Achieved" || statusMessagesFilter === "Completed") {
        setSavedMessagesChats(ConnectChat.ReturnChatsProcessed("Chat"));
      }
      setGotSavedMessagesData(true);
      // }, 1000);
    }
    if (!gotSavedAdminData) {
      // setTimeout(() => {
      setSavedAdminChats(ConnectChat.ReturnChats("Chat"));
      setGotSavedAdminData(true);
      setGotSavedMessagesData(true);
      // }, TotalTimer);
    }
    if (!gotSavedUpdateData) {
      // setTimeout(() => {
      if (statusUpdateFilter === "Pending") {
        setSavedUpdateChats(ConnectChat.ReturnChatsPending("Chat"));
      } else if (statusUpdateFilter === "Achieved") {
        setSavedUpdateChats(ConnectChat.ReturnChatsProcessed("Chat"));
      }
      setGotSavedUpdateData(true);
      setGotSavedMessagesData(true);
      // }, TotalTimer);
    }
    if (!gotSavedVideoData) {
      // setTimeout(() => {
      var startDate = new Date();
      var endDate = new Date();
      startDate.setDate(1);
      if (statusVideoFilter === "Pending") {
        setSavedVideoChats(await Calendar.ListCalendarByAgentID(currentUser.id, startDate, endDate));
        console.log(await Calendar.ListCalendarByAgentID(currentUser.id, startDate, endDate))
      } else if (statusVideoFilter === "Achieved") {
        setSavedVideoChats(Calendar.ReturnChats("VideoCall"));
      }
      setGotSavedVideoData(true);
      setGotSavedMessagesData(true);
      // }, TotalTimer);
    }
  };

  const handleListChatsAgent = async () => {
    if (!gotSavedMessagesData) {
      // setTimeout(() => {
      setSavedMessagesChats(ConnectChat.ReturnChatsPending("Chat"));
      let AmountOnAgent = 0;
      const chats = ConnectChat.ReturnChats("Chat");
      chats.forEach((element) => {
        if (element.SupportStatus === "Pending" || element.SupportStatus === "InProgress" || element.SupportStatus === "In progress" || element.SupportStatus === "Pending") {
          AmountOnAgent++;
        }
      });

      const RequestedAmount = AmountPerAgentMessages - AmountOnAgent;
      if (RequestedAmount > 0) {
        // setTimeout(() => {
        await ConnectChat.ListChatRoomsByAgent(currentUser.id);
        // }, TotalTimer * 2);

        // setTimeout(() => {
        setSavedMessagesChats(ConnectChat.ReturnChatsPending("Chat"));
        setGotSavedMessagesData(true);
        // }, TotalTimer * 3);
      }
      // }, TotalTimer);
    }
    if (!gotSavedAdminData) {
      // setTimeout(() => {
      setSavedAdminChats(ConnectChat.ReturnChats("Chat"));
      setGotSavedAdminData(true);
      // }, TotalTimer);
    }
    if (!gotSavedUpdateData && !gotNewUpdateRequest) {
      setGotNewUpdateRequest(true);
      // setTimeout(() => {
      setSavedUpdateChats(ConnectChat.ReturnChatsPending("Chat"));

      let AmountOnAgent = 0;
      const chats = ConnectChat.ReturnChats("Chat");

      chats.forEach((element) => {
        if (element.SupportStatus === "In Progress" || element.SupportStatus === "In progress" || element.SupportStatus === "InProgress" || element.SupportStatus === "Pending") {
          AmountOnAgent++;
        }
      });

      const RequestedAmount = AmountPerAgentUpdate - AmountOnAgent;

      if (RequestedAmount > 0) {
        // setTimeout(() => {
        await ConnectChat.ListChatRoomsByAgent(currentUser.id);
        // }, TotalTimer * 2);

        // setTimeout(() => {
        setSavedUpdateChats(ConnectChat.ReturnChatsPending("Chat"));
        setUnreadUpdateMessages(ConnectChat.ReturnUnreadMessages());
        setGotSavedUpdateData(true);
        // }, TotalTimer * 3);
      }
      // }, TotalTimer * 2);
    }
    if (!gotSavedVideoData && !gotNewVideoRequest) {
      setGotNewVideoRequest(true);
      // setTimeout(() => {
      setSavedVideoChats(Calendar.ReturnChats("VideoCall"));
      setGotSavedVideoData(true);

      let AmountOnAgent = 0;
      const chats = Calendar.ReturnChats("VideoCall");

      chats.forEach((element) => {
        if (element.SupportStatus === "Pending" || element.SupportStatus === "In Progress" || element.SupportStatus === "In progress" || element.SupportStatus === "InProgress") {
          AmountOnAgent++;
        }
      });

      const RequestedAmount = AmountPerAgentVideo - AmountOnAgent;

      if (RequestedAmount > 0) {
        // setTimeout(() => {
        await ConnectChat.ListChatRoomsByAgent(currentUser.id);
        // }, TotalTimer * 2);

        // setTimeout(() => {
        setSavedVideoChats(Calendar.ReturnChats("VideoCall"));
        setGotSavedVideoData(true);
        // }, TotalTimer * 3);
      }
      // }, TotalTimer * 2);
    }
  };

  const handleSchearchTerm = () => {
    const SchearchTerm = document.getElementById("scearch").value;
    if (SchearchTerm !== "") {
      setSavedMessagesChats(ConnectChat.ReturnChatsFiltered(SchearchTerm));
      setSchearchMessagesText(SchearchTerm);
      setSavedAdminChats(ConnectChat.ReturnChatsFiltered(SchearchTerm));
      setSchearchAdminText(SchearchTerm);
      setSavedUpdateChats(ConnectChat.ReturnChatsFiltered(SchearchTerm));
      setSchearchUpdateText(SchearchTerm);
      setSavedVideoChats(ConnectChat.ReturnChatsFiltered(SchearchTerm));
      setSchearchVideoText(SchearchTerm);
    } else {
      setSavedMessagesChats(ConnectChat.ReturnChats("Chat"));
      setSchearchMessagesText(SchearchTerm);
      setSavedAdminChats(ConnectChat.ReturnChats("Chat"));
      setSchearchAdminText(SchearchTerm);
      setSavedUpdateChats(ConnectChat.ReturnChats("Chat"));
      setSchearchUpdateText(SchearchTerm);
      setSavedVideoChats(Calendar.ReturnChats("VideoCall"));
      setSchearchVideoText(SchearchTerm);
    }
  };


  const handleGetUnread = (unread) => {
    if (gotSavedMessagesData) {
      if (unread > 0) {
        return (
          <span>
            {unread}{" "}{unread === 1 ? t("Messages.NewMessage") : t("Messages.NewMessages")}
          </span>
        )
      }
    }
    if (gotSavedAdminData) {
      if (
        unreadAdminMessages !== undefined &&
        unreadAdminMessages.PerChatUnreadMessages !== undefined &&
        unreadAdminMessages !== []
      ) {
        for (let i = 0; i < unreadAdminMessages.PerChatUnreadMessages.length; i++) {
          if (unreadAdminMessages.PerChatUnreadMessages[i].RequestID === unread) {
            if (unreadAdminMessages.PerChatUnreadMessages[i].UnreadMessages !== 0) {
              return (
                <span>
                  {unreadAdminMessages.PerChatUnreadMessages[i].UnreadMessages}
                </span>
              );
            }
          }
        }
      }
    }
    if (gotSavedUpdateData) {
      if (
        unreadUpdateMessages !== undefined &&
        unreadUpdateMessages.PerChatUnreadMessages !== undefined &&
        unreadUpdateMessages !== []
      ) {
        for (let i = 0; i < unreadUpdateMessages.PerChatUnreadMessages.length; i++) {
          if (unreadUpdateMessages.PerChatUnreadMessages[i].RequestID === unread) {
            if (unreadUpdateMessages.PerChatUnreadMessages[i].UnreadMessages !== 0) {
              return (
                <span>
                  {unreadUpdateMessages.PerChatUnreadMessages[i].UnreadMessages}
                </span>
              );
            }
          }
        }
      }
    }
    if (gotSavedVideoData) {
      if (
        unreadVideoMessages !== undefined &&
        unreadVideoMessages.PerChatUnreadMessages !== undefined &&
        unreadVideoMessages !== []
      ) {
        for (let i = 0; i < unreadVideoMessages.PerChatUnreadMessages.length; i++) {
          if (unreadVideoMessages.PerChatUnreadMessages[i].RequestID === unread) {
            if (unreadVideoMessages.PerChatUnreadMessages[i].UnreadMessages !== 0) {
              return (
                <span>
                  {unreadVideoMessages.PerChatUnreadMessages[i].UnreadMessages}
                </span>
              );
            }
          }
        }
      }
    }
  };

  const handleGetAllByStatus = async () => {
    if (statusMessagesFilter === "Pending" || statusMessagesFilter === "In Progress" || statusMessagesFilter === "In progress" || statusMessagesFilter === "InProgress") {
      setStatusMessagesFilter("Achieved");
      setSavedMessagesChats(ConnectChat.ReturnChatsProcessed("Chat"));
    } else if (statusMessagesFilter === "Completed" || statusMessagesFilter === "Achieved") {
      setStatusMessagesFilter("Pending");
      setSavedMessagesChats(ConnectChat.ReturnChatsPending("Chat"));
    }

    setChatID("");

    if (statusAdminFilter === "Pending" || statusAdminFilter === "In Progress" || statusAdminFilter === "In progress" || statusAdminFilter === "InProgress") {
      setStatusAdminFilter("Achieved");
      await ConnectChat.ListChatRoomsAgent(currentUser.id, "Achieved");
      setSavedAdminChats(ConnectChat.ReturnChats("Chat"));
    } else if (statusAdminFilter === "Completed" || statusAdminFilter === "Achieved") {
      setStatusAdminFilter("Pending");
      await ConnectChat.ListChatRoomsAgent(currentUser.id, "Pending");
      setSavedAdminChats(ConnectChat.ReturnChats("Chat"));
    }

    if (statusUpdateFilter === "Pending" || statusUpdateFilter === "In Progress" || statusUpdateFilter === "In progress" || statusUpdateFilter === "InProgress") {
      setStatusUpdateFilter("Achieved");
      setSavedUpdateChats(ConnectChat.ReturnChatsProcessed("Chat"));
    } else if (statusUpdateFilter === "Achieved" || statusUpdateFilter === "Completed") {
      setStatusUpdateFilter("Pending");
      setSavedUpdateChats(ConnectChat.ReturnChatsPending("Chat"));
    }

    if (statusVideoFilter === "Pending" || statusVideoFilter === "In Progress" || statusVideoFilter === "In progress" || statusVideoFilter === "InProgress") {
      setStatusVideoFilter("Achieved");
      setSavedVideoChats(Calendar.ReturnChats("VideoCall"));
    } else if (statusVideoFilter === "Achieved" || statusVideoFilter === "Completed") {
      setStatusVideoFilter("Pending");
      setSavedVideoChats(Calendar.ReturnChats("VideoCall"));
    }
  };

  // ADMIN

  const handleGetChatControl = async (SavedChat, AgentID) => {
    if (AgentID !== currentUser.id) {
      await ConnectChat.UpdateChatRoomOwnership(
        SavedChat,
        currentUser.id,
        currentUser.firstName
      );
      handleGetAllByStatus(currentUser.id);
    } else {
      toast.warning("You are already the case owner");
    }
  };

  // VIDEO CHAT

  const GetVideoChat = async () => {
    const date = new Date();
    const initialDateSend = date.getFullYear() - 1 + "-" + date.getMonth() + "-" + date.getDay();
    const endDateSend = date.getFullYear() + 1 + "-" + date.getMonth() + "-" + date.getDay();
    if (currentUser.roles.ADMIN_ACCESS) {
      await Calendar.ListCalendarByAgentID(currentUser.id, initialDateSend, endDateSend)
    } else {
      await Calendar.ListCalendarByBuyerID(currentUser.id, initialDateSend, endDateSend)
    }
    const videoChats = Calendar.ReturnCalendar();
    date.setHours(date.getHours() - 3) //Remove three hours from the GMT
    const isoDate = date.toISOString() //Convert to be the same type as the `ScheduledDate` param
    const filteredChats = videoChats.filter((chat) => Date.parse(new Date(chat.ScheduledDate)) > Date.parse(isoDate))
    setSavedVideoChats(filteredChats);
    setGotSavedVideoData(true);
  }

  const ConvertSecondsToString = (TotalSeconds) => {
    var Hours = 0;
    var Minutes = 0;

    Hours = Math.floor(TotalSeconds / 60);
    Minutes = ("0" + Math.floor(TotalSeconds % 60)).slice(-2);

    return Hours + "h " + Minutes + "min";
  };


  const renderChatID = (
    <ChatIDContext.Provider
      value={{
        // CHAT ROOM
        chatID, setChatID,
        chatFull, setChatFull,
        ChatInfo, setChatInfo,
        messages, setMessages,
        ChatStatus, setChatStatus,
        listenerAdded, setListenerAdded,
        gotMessages, setGotMessages,
        listener,
        handleSendMessage,
        handleSendFile,
        ConvertTimezone,
        handleGetTranscript,
        updateScroll,
        handleFromMessage,
        handleChangeChatStatus,
        handleSetStatus,
        handleInputFile,
        // UserDetail,

        // CREATE CHAT
        gotCreateSavedData, setGotCreateSavedData,
        createsupportType, setCreateSupportType,
        createchatInfo, setCreateChatInfo,
        handleCreateChatRoom,
        RecievedPreviousChat,
        ConvertDate,

        // MESSAGES
        savedMessagesChats, setSavedMessagesChats,
        gotSavedMessagesData, setGotSavedMessagesData,
        gotNewMessagesRequest, setGotNewMessagesRequest,
        costumerDetail,
        schearchMessagesText, setSchearchMessagesText,
        statusMessagesFilter, setStatusMessagesFilter,
        handleGetAllChats,
        checkForCurrentUserInfo,
        handleGetAllChatsByAgent,
        handleListChats,
        handleListChatsAgent,
        handleSchearchTerm,
        handleGetUnread,
        handleGetAllByStatus,
        // AgentDetail,
        agentDetail,

        // ADMIN
        savedAdminChats, setSavedAdminChats,
        unreadAdminMessages, setUnreadAdminMessages,
        gotSavedAdminData, setGotSavedAdminData,
        schearchAdminText, setSchearchAdminText,
        statusAdminFilter, setStatusAdminFilter,
        handleGetChatControl,

        // UPDATE
        savedUpdateChats, setSavedUpdateChats,
        unreadUpdateMessages, setUnreadUpdateMessages,
        gotSavedUpdateData, setGotSavedUpdateData,
        gotNewUpdateRequest, setGotNewUpdateRequest,
        schearchUpdateText, setSchearchUpdateText,
        statusUpdateFilter, setStatusUpdateFilter,

        // VIDEO
        configuration,
        audioTrack, setAudioTracks,
        videoTrack, setVideoTracks,
        showMyCam, setShowMyCam,
        muteCall, setMuteCall,
        startedCall, setStartedCall,
        ChatVideoInfo, setChatVideoInfo,
        GeneratedColor, setGeneratedColor,
        kickedTheDoor, setKickedTheDoor,
        peerConnection, setPC,
        savedVideoChats, setSavedVideoChats,
        unreadVideoMessages, setUnreadVideoMessages,
        gotSavedVideoData, setGotSavedVideoData,
        gotNewVideoRequest, setGotNewVideoRequest,
        schearchVideoText, setSchearchVideoText,
        statusVideoFilter, setStatusVideoFilter,
        GetVideoChat,
        gotVideoRequest, setGotVideoRequest,

        handlePickNewRequest,
        setCostumerDetail,
        setAgentDetail,
        getChat,
        ConvertSecondsToString,
        selectedCompany, setSelectedCompany
      }}
    >
      {children}
    </ChatIDContext.Provider>
  );

  return renderChatID;
};
