import axios from "axios";
import Cookies from "js-cookie";

const EndpointListTransactions = process.env.REACT_APP_API_URL + "/listtransaction";

const EndpointInsertTransactions = process.env.REACT_APP_API_URL + "/inserttransaction";

var LocalListTransaction = [];

const header = {
  'vlr-authorization': 'eyJpZCI6ICJWQU1fQVBQIn0=',
  'authorization': Cookies.get("_ssoToken")
}

export async function ListTransactionByBuyerID(userID, InitialDate, FinalDate) {
  const data = {
    BuyerID: "" + userID,
    InitialDate: "" + InitialDate,
    FinalDate: "" + FinalDate,
  };
  await axios
    .post(EndpointListTransactions, data, { headers: header })
    .then((response) => {
      LocalListTransaction = response.data;
    })
    .catch((error) => {
      console.log(error);
    });
  return true;
}

export async function ListTransactionByAgentID(userID, InitialDate, FinalDate) {
  const data = {
    AgentID: "" + userID,
    InitialDate: "" + InitialDate,
    FinalDate: "" + FinalDate,
  };
  await axios
    .post(EndpointListTransactions, data, { headers: header })
    .then((response) => {
      LocalListTransaction = response.data;
    })
    .catch((error) => {
      console.log(error);
    });
  return true;
}

export async function ListTransactionByAgentKey(InitialDate, FinalDate) {
  const data = {
    AgentKey: "Tony Stark",
    InitialDate: "" + InitialDate,
    FinalDate: "" + FinalDate,
  };
  await axios
    .post(EndpointListTransactions, data, { headers: header })
    .then((response) => {
      LocalListTransaction = response.data;
    })
    .catch((error) => {
      console.log(error);
    });
  return true;
}

export async function InsertTransaction(
  userID,
  Value,
  Currency,
  TypeOfCharge,
  AmountOfSecondsUsed,
  CaseID,
  CaseName,
  BuyerName,
  isCompany,
  TransactionStatus
) {
  const data = {
    BuyerID: "" + userID,
    Value: "" + Value,
    Currency: "" + Currency,
    TypeOfCharge: "" + TypeOfCharge,
    AmountOfMinutesUsed: AmountOfSecondsUsed,
    CaseID: "" + CaseID,
    CaseName: "" + CaseName,
    BuyerName: "" + BuyerName,
    isCompany: isCompany,
    TransactionStatus: "" + TransactionStatus,
  };
  await axios
    .post(EndpointInsertTransactions, data, { headers: header })
    .then((response) => {})
    .catch((error) => {
      console.log(error);
    });
  return true;
}

export function ReturnTransactions() {
  return LocalListTransaction;
}

export function ReturnTransactionsSortedByPending() {
  var SortedList = [];
  if (LocalListTransaction.Transactions !== undefined) {
    LocalListTransaction.Transactions.forEach((element) => {
      if (element.TransactionStatus === "Pending" ||  element.TransactionStatus === "In Progress") {
        SortedList.push(element);
      }
    });

    SortedList.sort((a, b) => (a.CreationDate > b.CreationDate ? -1 : 1));
    return SortedList;
  }
}

export function ReturnTransactionsSortedByPendingFiltered(filtered) {
  var SortedList = [];
  if (LocalListTransaction.Transactions !== undefined) {
    LocalListTransaction.Transactions.forEach((element) => {
      if (
        (element.TransactionStatus === "Pending" || element.TransactionStatus === "In Progress") &&
        (element.CreationDate.includes(filtered) ||
          element.BuyerName.includes(filtered))
      ) {
        SortedList.push(element);
      }
    });

    SortedList.sort((a, b) => (a.CreationDate > b.CreationDate ? -1 : 1));
    return SortedList;
  }
}

export function ReturnLastTransaction(id) {
  var LastTransaction = "none";
  var SortedList = [];
  LocalListTransaction.Transactions.forEach((element) => {
    if (element.TransactionStatus === "Approved") {
      SortedList.push(element);
    }
  });

  SortedList.forEach((element) => {
    if (element.TransactionID === id) {
      LastTransaction = element.Value + " " + element.Currency;
    }
  });

  return LastTransaction;
}

export function ReturnTransactionsFiltered(Filtered) {
  var ListTransaction = [];
  Filtered = Filtered.toLowerCase();
  LocalListTransaction.Transactions.forEach((element) => {
    var Value = "" + element.Value;
    if (
      element.Currency.toLowerCase().includes(Filtered) ||
      element.TypeOfCharge.toLowerCase().includes(Filtered) ||
      Value.includes(Filtered)
    ) {
      ListTransaction.push(element);
    }
  });

  ListTransaction.sort((a, b) => (a.LastAltered > b.LastAltered ? -1 : 1));

  return ListTransaction;
}
