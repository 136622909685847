import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { AiFillCloseCircle } from "react-icons/ai";
import CreateChatIcon from "../../assets/icons/Chat/CreateChat.png";
import MessageIcon from "../../assets/icons/Chat/MessageIcon.png";
import {
  Body,
  HelloTextBold,
  MainCard,
} from "../../components/common/MainCard";
// import Caller from "../../components/layout/caller";
import Footer from "../../components/layout/footer";
import Header from "../../components/layout/header";
import LoadingScreen from "../../components/layout/LoadingScreen";
import NavBar from "../../components/layout/navBar";
import { ChatIDContext } from "../../stores/ChatIDStore";
import { UserContext } from "../../stores/UserStore";
import { ChatSocketContext } from "../../stores/ChatSocketStore"

const Messages = () => {
  document.title = "VAM® Field Service";
  const [abrir, setAbrir] = useState(false);

  const { currentUser } = useContext(UserContext);
  const history = useHistory();
  const {
    setChatID,
    ConvertTimezone,
    savedMessagesChats,
    schearchMessagesText,
    statusMessagesFilter,
    handleGetAllChats,
    handleGetAllChatsByAgent,
    handleListChats,
    handleListChatsAgent,
    handleSchearchTerm,
    handleGetUnread,
    handleGetAllByStatus,
    gotNewMessagesRequest,
    handlePickNewRequest,
    getChat,
    gotSavedMessagesData
  } = useContext(ChatIDContext);

  const { setChatId/* , getChats */ } = useContext(ChatSocketContext)

  const { t } = useTranslation();

  const handleGetChat = (ID) => {
    setChatID(ID)
    setChatId(ID)
    // window.location.href = "ChatRoom"
    history.push("/ChatRoom");
  };

  const handleCreateChat = () => {
    history.push("/CreateChat");
  };

  const WhiteUnitBoxDiv = styled.div`
  display: flex;
  background-color: white;
  justify-content: center;
  padding: 10px 12px 5px 12px;
  margin-left: 10px;
  cursor: pointer;
  
  .active {
    color: #009ee0;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 15px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #7abcf1;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 0;
    bottom: -2.5px;
    background-color: #2196f3;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #7abcf1;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #7abcf1;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;

  const SideBySideOptions = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    input {
      width: 95%;
      height: 20px;
      margin-left: 10px;
      padding: 8px;
      border: 0;
      border-radius: 10px;
      box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);
      :focus {
        outline: none;
      }
    }

    select {
      -webkit-appearance: none;
      padding: 7px 40px 7px 12px;
      width: 200px;
      margin-left: 10px;
      border: 0px;
      border-radius: 5px;
      background: white;
      box-shadow: 0 1px 3px -2px #9098a9;
      cursor: pointer;
      font-family: inherit;
      font-size: 16px;
      transition: all 150ms ease;
      :focus {
        outline: none;
      }
    }

    notify {
      background-color: #cc5200;
      width: 14px;
      height: 14px;
      color: white;
      border-radius: 50%;
      margin: -6px;
      display: inline-block;
      text-align: center;
      font-size: 12px;
      transform: translate(-4px, -13px);
    }

    button {
      padding-top: 5px;
      background-color: #009ee0;
      border: 0;
      color: #fff;
      margin-left: 15px;
      box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);
      transition: all 0.3s ease 0s;
      cursor: pointer;
      :hover {
        transform: translateY(-7px);
      }
      :focus {
        outline: none;
      }
    }
  `;

  const SavedDiv = styled.div`
    width: 98%;
    min-width: 450px;
    display: flex;
    flex-direction: column;
    color: #009ee0;
    background-color: white;
    border-radius: 0 10px 10px 0;
    font-size: calc(12px + 0.5vw);
    vertical-align: center;
    margin-bottom: 10px;
    transition: all 0.3s ease 0s;
    overflow-y: scroll;
    :hover {
      transform: translateX(3px);
    }
    .GrayText {
      color: #666666;
      font-size: calc(8px + 0.3vw);
    }
    .left {
      width: 90%;
      margin-left: 0;
      display: flex;
      align-items: center;
    }
    .information {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 10px;
      text-align: left;
    }
    .statusPending {
      width: 5%;
      font-size: 14px;
      color: white;
      background-color: #009ee0;
      border-radius: 0 10px 10px 0;
      margin-top: 10px;
      padding: 5px;
      padding-left: 2%;
    }
    .statusProcessed {
      width: 5%;
      font-size: 14px;
      color: white;
      background-color: #666666;
      border-radius: 0 10px 10px 0;
      margin-top: 10px;
      padding: 5px;
      padding-left: 2%;
    }
    .right {
      color: #666666;
      font-size: calc(8px + 0.3vw);
      display: flex;
      flex-direction: column;
      width: 6%;
      align-items: flex-end;
      justify-content: center;
    }
    .unread {
      color: white;
      display: flex;
    }
    .newChat {
      background-color: #e6f7ff;
    }
    .PopUp {
      overflow-y: scroll;
    }
    button {
      background-color: Transparent;
      background-repeat: no-repeat;
      border: none;
      cursor: pointer;
      overflow: hidden;
    }
    font {
      font-size: 16px;
      color: black;
    }
    img {
      margin-right: 10px;
    }
    span {
      font-size: 14px;
      display: inline-block;
      background-color: #93117E;
      border-radius: 10px;
      width: 7%;
      margin-top: -26px;
      padding: 5px;
      margin-left: 150px;
    }
  `;

  const GrayOverlay = styled.div`
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: scroll;
    // z-index: 2;
  `;

  const PopUpBeforeProceding = styled.div`
    position: fixed;
    left: 35%;
    top: 20%;
    border-radius: 3%;
    padding: 20px;
    color: black;
    overflow-y: scroll;
    background-color: white;
    width: 500px;
    height: 55vh;
    align-items: center;
    text-align: left;

    .PopUpTitle {
      align-items: center;
      font-size: calc(12px + 0.6vw);
      font-weight: normal;
      margin-bottom: 10px
    }

    .PopUpSubTitle {
      font-size: 16px;
      font-weight: normal;
      margin-bottom: 28px;
    }

    .loading{
      z-index: 3;
    }
  `;


  const SideBySideOptionsPopUp = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: stretch;

    div {
      width: 95%;
    }

    button {
      background-color: Transparent;
      background-repeat: no-repeat;
      border: none;
      cursor: pointer;
      overflow: hidden;
      outline: none;
      position: relative;
    }

  `;

  const Open = () => {
    setAbrir(true)
  }

  const Close = () => {
    setAbrir(false)
  }

  const PickNew = (newMessages) => {
    handlePickNewRequest(newMessages.RequestID);
    setAbrir(false)
  }

  useEffect(() => {
    (async () => {
      if (currentUser && !currentUser.roles.ADMIN_ACCESS) {
        await handleGetAllChats(currentUser.id)
        await handleListChats()
      } else {
        await handleGetAllChatsByAgent(currentUser.id)
        await handleListChatsAgent()
      }
      await getChat()
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Body>
      <Header />
      <NavBar />
      {!gotSavedMessagesData ? (
        <LoadingScreen />
      ) : (
        <MainCard id="MainCard">
          <HelloTextBold> {t("Messages.Messages")} </HelloTextBold>
          <SideBySideOptions>
            {" "}
            <input
              type="text"
              id="scearch"
              placeholder={t("Messages.Scearch")}
              onChange={() => { handleSchearchTerm() }}
              defaultValue={schearchMessagesText}
              autoFocus
            ></input>
            <WhiteUnitBoxDiv>
              <div
                id="Pending"
                className={statusMessagesFilter === "Pending" || statusMessagesFilter === "In Progress" ||
                  statusMessagesFilter === "InProgress" || statusMessagesFilter === "In progress"
                  ? "active" : ""
                }
              >
                {t("Messages.Pending")}
              </div>
              <label className="switch">
                <input
                  type="checkbox"
                  onClick={() => handleGetAllByStatus()}
                  defaultChecked={statusMessagesFilter === "Pending" || statusMessagesFilter === "In Progress" ||
                    statusMessagesFilter === "InProgress" || statusMessagesFilter === "In progress" ? true : false}
                />
                <span className="slider round"></span>
              </label>
              <div
                id="Achieved"
                className={statusMessagesFilter === "Achieved" ||
                  statusMessagesFilter === "Completed"
                  ? "active"
                  : ""
                }
              >
                {t("Messages.Achieved")}
              </div>
            </WhiteUnitBoxDiv>
            {currentUser != null && !currentUser.roles.ADMIN_ACCESS && (
              <button onClick={() => handleCreateChat()} style={{ marginLeft: "5px" }}>
                <img src={CreateChatIcon} alt="Create Chat" />
              </button>
            )}
            {currentUser != null && currentUser.roles.ADMIN_ACCESS && (
              <button onClick={() => Open()} style={{ marginRight: "15px" }}>
                <img src={CreateChatIcon} alt="New Chat" />
              </button>
            )}
            {currentUser != null && currentUser.roles.ADMIN_ACCESS && (
              <notify>{gotNewMessagesRequest.length}</notify>
            )}
          </SideBySideOptions>
          {savedMessagesChats.map((SavedChats, i) => (
            SavedChats.SupportStatus === statusMessagesFilter ?
              <div key={SavedChats.RequestID}>
                <SavedDiv>
                  <button onClick={() => handleGetChat(SavedChats.RequestID)}>
                    <div
                      className={
                        SavedChats.SupportStatus === "In Progress" || SavedChats.SupportStatus === "In progress" ||
                          SavedChats.SupportStatus === "InProgress" || SavedChats.SupportStatus === "Pending"
                          ? "statusPending"
                          : "statusProcessed"
                      }
                    >
                      {t("Messages." + SavedChats.SupportStatus)}
                    </div>
                    <div className="unread">
                      {handleGetUnread(SavedChats.UnreadMessages)}
                    </div>
                    <div className="information">
                      <div className="left">
                        <img src={MessageIcon} alt="Message Icon" />
                        <div>
                          <font> {SavedChats.Title}</font>
                          <div>
                            {SavedChats.SupportSubject} - #{SavedChats.RequestID}
                          </div>
                          {currentUser != null && currentUser.roles.ADMIN_ACCESS ?
                            <div>
                              {SavedChats.BuyerInfo !== undefined ?
                                <>
                                  {t("Messages.RequestFrom")} : {SavedChats.BuyerInfo.FirstName} {SavedChats.BuyerInfo.LastName} - {SavedChats.BuyerInfo.Company}
                                </>
                                : null}
                            </div>
                            :
                            <div>
                              {SavedChats.AgentInfo !== undefined ?
                                <>
                                  {t("Messages.Agent")}: {SavedChats.AgentInfo.FirstName} {SavedChats.AgentInfo.LastName} - {SavedChats.AgentInfo.Company}
                                </>
                                : null}
                            </div>
                          }
                        </div>
                      </div>
                      <div className="right">
                        {" "}
                        {ConvertTimezone(SavedChats.CreatedAt)}
                      </div>
                    </div>
                  </button>
                </SavedDiv>
              </div>
              : null
          ))}
          {!abrir ? null :
            <GrayOverlay>
              <PopUpBeforeProceding>
                <SideBySideOptionsPopUp>
                  <div className="PopUpTitle">
                    {t("Messages.NewChats")}
                  </div>
                  <button onClick={() => Close()}>
                    <AiFillCloseCircle
                      size={30}
                      style={{ color: "#009ee0" }}
                    />{" "}
                  </button>
                </SideBySideOptionsPopUp>{" "}
                {t("Messages.ClickToPickNewRequest")}
                {gotNewMessagesRequest === null ? "Sem novos chats" :
                  gotNewMessagesRequest.map((newMessages) => (
                    <div key={newMessages.RequestID}>
                      <SavedDiv className="PopUp">
                        <button onClick={() => PickNew(newMessages)}>
                          <div className="information">
                            <div className="left">
                              <img src={MessageIcon} alt="Message Icon" />
                              <div>
                                <font> {newMessages.Title}</font>
                                <div>
                                  {newMessages.SupportSubject}
                                </div>
                                <div>
                                  {newMessages.Description}
                                </div>
                                {newMessages.BuyerInfo ?
                                  <div>
                                    {t("Messages.RequestFrom")}: {newMessages.BuyerInfo.FirstName} {newMessages.BuyerInfo.LastName} - {newMessages.BuyerInfo.Company}
                                  </div>
                                  : <div>
                                    {t("Messages.RequestFrom")}: Undefined
                                  </div>}
                              </div>
                            </div>
                            <div className="right">
                              {" "}
                              {ConvertTimezone(newMessages.CreatedAt)}
                            </div>
                          </div>
                        </button>
                      </SavedDiv>
                    </div>
                  ))
                }
              </PopUpBeforeProceding>
            </GrayOverlay>
          }
        </MainCard>)}
      <Footer />
    </Body>
  );
};

export default Messages;
