import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, FlexView, Icon } from '../../components/common'
import { Body, HelloText } from '../../components/common/MainCard'
import Header from '../../components/layout/header'
import { ChatSocketContext } from '../../stores/ChatSocketStore'

const VideoChat = () => {
  const { isMicOn, muteUnmuteCall, isCameraOn, hideShowMyVideo, localName, remoteName, processNewCall, endCall } =
    useContext(ChatSocketContext)
  const { t } = useTranslation()

  useEffect(() => {
    (async () => {
      await processNewCall()
    })()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Body>
    <Header />
      <FlexView width='100%' height='90%' margin='10px' flexDirection='row' alignItems='center'>
        <FlexView width='50%' margin='0 5px 0 0' alignItems='center'>
        <HelloText>{t('VideoChat.local')}</HelloText>
          <FlexView width='100%' height='90%' alignItems='center'>
            <FlexView
              width='80%'
              height='100%'
              alignSelf='center'
              justifyContent='center'
              alignItems='center'
              borderRadius='20px'
            >
              <video id='localVideo' autoPlay={true} />
            </FlexView>
            <HelloText>{localName}</HelloText>
            <FlexView padding='30px 0 0 0'>
              <Card width='24%' height='40%' flexDirection='row' alignSelf='center'>
                <FlexView flex='1' width='33%' onClick={async () => await muteUnmuteCall()}>
                  <Icon name={`${isMicOn ? 'microphone' : 'microphone-off'}`} margin='0 50px 0 0' tooltip={t('VideoChat.microphone')} />
                </FlexView>
                <FlexView flex='1' width='33%' onClick={async () => hideShowMyVideo()}>
                  <Icon name={`${isCameraOn ? 'video' : 'video-off'}`} margin='0 50px 0 0' tooltip={t('VideoChat.camera')} />
                </FlexView>
                <FlexView
                  flex='1'
                  borderRadius='30px'
                  backgroundColor='red'
                  alignItems='center'
                  onClick={async () => await endCall()}
                >
                  <Icon name='hangup' color='white' tooltip={t('VideoChat.endCall')} />
                </FlexView>
              </Card>
            </FlexView>
          </FlexView>
        </FlexView>
        <FlexView width='50%' height='100%' margin='0 0 0 5px' alignItems='center'>
        <HelloText>{t('VideoChat.remote')}</HelloText>
          <FlexView width='100%' height='77%' alignItems='center'>
            <FlexView
              width='80%'
              height='100%'
              backgroundColor='black'
              alignSelf='center'
              justifyContent='center'
              alignItems='center'
              borderRadius='20px'
            >
              <video id='remoteVideo' autoPlay={true} />
            </FlexView>
            <HelloText>{remoteName}</HelloText>
          </FlexView>
        </FlexView>
      </FlexView>
    </Body>
  )
}

export default VideoChat
