import axios from "axios";
import Cookies from "js-cookie";

const EndpointInsert = process.env.REACT_APP_API_URL + "/uploadfile";

const EndpointInsertS3 = process.env.REACT_APP_API_URL + "/getsignedurl";

const EndpointGetFile = process.env.REACT_APP_API_URL + "/getfile";

var verbose = false;

const header = {
  'vlr-authorization': 'eyJpZCI6ICJWQU1fQVBQIn0=',
  'authorization': Cookies.get("_ssoToken")
}

export async function sendFile(fileList, folder) {
  var dataList = [];
  if (Array.isArray(fileList)) {
    for (let i = 0; i < fileList.length; i++) {
      await fileToBase64(fileList[i]).then((FileBase64) => {
        FileBase64 = FileBase64.split(",").pop();
        if (FileBase64 !== "Error") {
          const data = {
            file: FileBase64,
            name: Date.now() + fileList[i].name,
            folder: folder,
          };
          dataList.push(data);
        }
      });
    }
  } else {
    await fileToBase64(fileList).then((FileBase64) => {
      console.log(fileList)
      FileBase64 = FileBase64.split(",").pop();
      if (FileBase64 !== "Error") {
        const data = {
          file: FileBase64,
          name: Date.now() + fileList.name,
          folder: folder,
        };
        dataList.push(data);
      }
    });
  }

  return axios
    .post(EndpointInsert, dataList, { headers: header })
    .then((response) => {
      if (verbose) {
        console.log(response);
      }
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function sendFileS3(fileList, folder) {
  var dataList = [];
  var dataContent = [];
  if (Array.isArray(fileList)) {
    for (let i = 0; i < fileList.length; i++) {
      await fileToBase64(fileList[i]).then((FileBase64) => {
        FileBase64 = FileBase64.split(",").pop();
        if (FileBase64 !== "Error") {
          const data = {
            name: Date.now() + fileList[i].name,
            folder: folder,
          };
          dataContent.push(FileBase64)
          dataList.push(data);
        }
      });
    }
  } else {
    await fileToBase64(fileList).then((FileBase64) => {
      console.log(fileList)
      FileBase64 = FileBase64.split(",").pop();
      if (FileBase64 !== "Error") {
        const data = {
          folder: folder,
          name: Date.now() + fileList.name,
        };
        dataContent.push(FileBase64)
        dataList.push(data);
      }
    });
  }

  return axios
    .post(EndpointInsertS3, dataList, { headers: header })
    .then((response) => {
      if (verbose) {
        console.log(response);
      }
      console.log(dataList)
      console.log(response.data)
      for (var i = 0; i < dataContent.length; i++) {
        putLink(response.data[i].link, fileList)
      }
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

const putLink = async (link, file) => {
  return await axios
    .put(link, file, {
      header: {
        'Content-Type': 'image/jpeg'
      }
    })
    .then((response) => {
      console.log(response)
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function getFile(name, folder) {
  const data = [{ name: name, folder: folder }]
  return axios
    .post(EndpointGetFile, data, { headers: header })
    .then((response) => {
      const link = document.createElement('a')
      link.href = response.data[0].link
      link.target = '_blank'
      link.click()
      return false
    })
    .catch((error) => {
      console.log(error);
    });

}

const fileToBase64 = (file) => {
  return new Promise((resolve) => {
    var reader = new FileReader();

    reader.onload = function (event) {
      resolve(event.target.result);
    };
    reader.onerror = function (error) { };

    // Convert data to base64
    reader.readAsDataURL(file);
  });
};
