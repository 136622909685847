import React, { useContext, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import CheckedRadio from "../assets/icons/check_circle.svg";
import EngineerIcon from "../assets/icons/engineering_black_24dp.svg";
import EngineerWhiteIcon from "../assets/icons/engineering_white_24dp.svg";
import UncheckedRadio from "../assets/icons/uncheck_circle.svg";
import * as calendar from "../components/connect/calendar";
import * as chatConnect from "../components/connect/chatConnect";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { UserContext } from "./UserStore";
import { toast } from "react-toastify";

export const AssignTechnictianContext = React.createContext();

const PopUpBeforeProceding = styled.div`
  position: absolute;
  left: 35%;
  top: 20%;
  border: 0px;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 50px;
  margin-left: 20px;
  color: black;
  background-color: white;
  width: 400px;
  box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);

  text-align: left;
  z-index: 1;

  .PleaseAssign {
    padding: 10px;
  }

  .BlueApplyAgent {
    padding: 10px;
    border: 0px;
    width: 100%;
    border-radius: 0 0 10px 10px;
    background-color: #009ee0;
    color: white;
    margin-top: 20px;
    cursor: pointer;
  }

  .SideBySideOptionsRadio {
    display: flex;
    flex-direction: row;
    padding-top: 5px;
    justify-content: start;
    align-items: center;
  }

  .SideBySideOptions {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: stretch;

    .title {
        padding: 20px;
        padding-bottom: 0px;
        font-weight: bold;
    }

    .companyName {
      padding: 20px;
      padding-bottom: 0px;
      font-style: italic;
    }

    .timeStamp {
      padding: 20px;
      padding-bottom: 0px;
      font-weight: bold;
    }

    button {
        background-color: Transparent;
        background-repeat: no-repeat;
        border: none;
        cursor: pointer;
        overflow: hidden;
        outline: none;
        position
    }
  }

  .AgentRadioList {
    padding: 20px;
    padding-bottom: 0px;

    img {
      cursor: pointer;
      + input {
        position: absolute;
        opacity: 0;
        width: 0;
        height: 0;
      }
    }
  }

  .SideBySideOptionsToghether {
    display: flex;
    justify-content: start;
    flex-direction: row;
    align-items: stretch;
    padding: 20px;
    padding-bottom: 0px;
  }

  .SideBySideOptionsPopUp {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: stretch;
    padding-top: 10px;
    // padding-right: 10px;
    // div {
    //   width: 95%;
    // }
    width: 95%;
    .Title {
      padding-right: 10px;
      padding-top: 2px;
      font-weight: bold;
      outline: none;
      position: relative;
    }

    button {
      background-color: Transparent;
      background-repeat: no-repeat;
      border: none;
      cursor: pointer;
      overflow: hidden;
      outline: none;
      position: relative;
    }
  }
`;

export const AssignTechnictianProvider = ({ children }) => {

    const { currentUser } = useContext(UserContext);

    const { t } = useTranslation();

    const [gotSavedData, setGotSavedData] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [agentList, setAgentList] = useState([]);
    const [currentCalendar, setCurrentCalendar] = useState([]);
    const [selectedAgent, setSelectedAgent] = useState("all");
    const [setChangedWeek] = useState(false);
    const [changedMonth, setChangedMonth] = useState(false);
    const [highlighted, setHighlighted] = useState("none");
    const [filter, setFilter] = useState("week");
    const [currentCase, setCurrentCase] = useState();
    const [update, setUpdate] = useState(0);
    const [showPopUp, setShowPopUp] = useState(false);

    const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const daysNames = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

    const handleGetInitialData = () => {
        setTimeout(async () => {
            if (gotSavedData === false) {
                setGotSavedData(true);

                let lastDaysInMonth = new Date(
                    selectedDate.getFullYear(),
                    selectedDate.getMonth(),
                    0
                ).getDate();

                var startDate = new Date(selectedDate);
                var endDate = new Date(selectedDate);
                startDate.setDate(1);
                endDate.setDate(lastDaysInMonth);
                var CurrentCalendar;
                if (selectedAgent === "all") {
                    await calendar.ListCalendarByAgentID("undef", startDate, endDate);
                    await calendar.ListCalendarByAgentID(currentUser.id, startDate, endDate);
                    console.log(calendar.PutCalendar())
                    CurrentCalendar = calendar.PutCalendar();
                } else {
                    calendar.ListCalendarByAgentID("undef", startDate, endDate);
                    CurrentCalendar = calendar.ReturnNewChats();

                }
                setCurrentCalendar(CurrentCalendar);
                var Agent = await chatConnect.ListAgents(currentUser.id);
                setAgentList(Agent);
            }
        }, 10);
    };

    console.log(currentCalendar)
    function getWeekNumber(d) {
        d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
        d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
        var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
        var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
        return weekNo;
    }

    const calculateDateTable = () => {
        var result = [];
        result.push(
            <tr key={Math.random()} className="WeekDay">
                <th></th>
                <th> {t("AssignTechnictianToLiveSupport.M")}</th>
                <th> {t("AssignTechnictianToLiveSupport.T")}</th>
                <th> {t("AssignTechnictianToLiveSupport.W")}</th>
                <th> {t("AssignTechnictianToLiveSupport.T2")}</th>
                <th> {t("AssignTechnictianToLiveSupport.F")}</th>
                <th> {t("AssignTechnictianToLiveSupport.S")}</th>
                <th> {t("AssignTechnictianToLiveSupport.S2")}</th>
            </tr>
        );
        var currentDate = new Date();
        var dateLastMonday = new Date(currentDate.getTime());
        dateLastMonday.setDate(1);
        let weekNo = getWeekNumber(dateLastMonday);

        let lastDaysInMonth = new Date(
            selectedDate.getFullYear(),
            selectedDate.getMonth() - 2,
            0
        ).getDate();
        let counter = 0;
        while (dateLastMonday.getDay() !== 1 && counter < 10) {
            if (dateLastMonday.getDate() === 1) {
                dateLastMonday.setMonth(dateLastMonday.getMonth() - 1);
                dateLastMonday.setDate(lastDaysInMonth);
            } else {
                dateLastMonday.setDate(dateLastMonday.getDate() - 1);
            }
            counter++;
        }

        let day = dateLastMonday.getDate();
        let current = currentDate.getDate();

        for (let i = 0; i < 6; i++) {
            result.push(
                <tr key={Math.random()}>
                    <td className="WeekNo" key={weekNo + i}>
                        {weekNo + i}
                    </td>
                    <td className={getCurrentDay(day, i * 7, current)[1]}>
                        {getCurrentDay(day, i * 7, current)[0]}
                    </td>
                    <td className={getCurrentDay(day, 1 + i * 7, current)[1]}>
                        {getCurrentDay(day, 1 + i * 7, current)[0]}
                    </td>
                    <td className={getCurrentDay(day, 2 + i * 7, current)[1]}>
                        {getCurrentDay(day, 2 + i * 7, current)[0]}
                    </td>
                    <td className={getCurrentDay(day, 3 + i * 7, current)[1]}>
                        {getCurrentDay(day, 3 + i * 7, current)[0]}
                    </td>
                    <td className={getCurrentDay(day, 4 + i * 7, current)[1]}>
                        {getCurrentDay(day, 4 + i * 7, current)[0]}
                    </td>
                    <td className={getCurrentDay(day, 5 + i * 7, current)[1]}>
                        {getCurrentDay(day, 5 + i * 7, current)[0]}
                    </td>
                    <td className={getCurrentDay(day, 6 + i * 7, current)[1]}>
                        {getCurrentDay(day, 6 + i * 7, current)[0]}
                    </td>
                </tr>
            );
        }

        return result;
    };

    const calculateScheduleTable = () => {
        var result = [];
        var counter = 0;

        var currentDate = new Date();
        var dateLastMonday = new Date(currentDate.getTime());

        if (filter === "week") {
            let lastDaysInMonth = new Date(
                selectedDate.getFullYear(),
                selectedDate.getMonth() - 2,
                0
            ).getDate();

            while (dateLastMonday.getDay() !== 1 && counter < 10) {
                if (dateLastMonday.getDate() === 1) {
                    dateLastMonday.setMonth(dateLastMonday.getMonth() - 1);
                    dateLastMonday.setDate(lastDaysInMonth);
                } else {
                    dateLastMonday.setDate(dateLastMonday.getDate() - 1);
                }
                counter++;
            }

            var day = [];

            for (let i = 0; i < 7; i++) {
                if (i === 0) {
                    dateLastMonday.setDate(dateLastMonday.getDate());
                } else {
                    dateLastMonday.setDate(dateLastMonday.getDate() + 1);
                }
                var date = dateLastMonday.getDate();
                day.push(date);
            }

            result.push(
                <tr key={Math.random()}>
                    <th className="Time"></th>
                    <th className={day[0] === new Date().getDate() ? "today" : ""}>
                        <div> {t("AssignTechnictianToLiveSupport.Mon")}</div>
                        <center>
                            <div
                                className={day[0] === new Date().getDate() ? "todayBall" : ""}
                            >
                                {" "}
                                {day[0]}
                            </div>
                        </center>
                    </th>
                    <th className={day[1] === new Date().getDate() ? "today" : ""}>
                        <div>{t("AssignTechnictianToLiveSupport.Tue")}</div>{" "}
                        <center>
                            <div
                                className={day[1] === new Date().getDate() ? "todayBall" : ""}
                            >
                                {" "}
                                {day[1]}
                            </div>
                        </center>
                    </th>
                    <th className={day[2] === new Date().getDate() ? "today" : ""}>
                        <div>{t("AssignTechnictianToLiveSupport.Wed")}</div>{" "}
                        <center>
                            <div
                                className={day[2] === new Date().getDate() ? "todayBall" : ""}
                            >
                                {" "}
                                {day[2]}
                            </div>
                        </center>
                    </th>
                    <th className={day[3] === new Date().getDate() ? "today" : ""}>
                        <div>{t("AssignTechnictianToLiveSupport.Thu")}</div>{" "}
                        <center>
                            <div
                                className={day[3] === new Date().getDate() ? "todayBall" : ""}
                            >
                                {" "}
                                {day[3]}
                            </div>
                        </center>
                    </th>
                    <th className={day[4] === new Date().getDate() ? "today" : ""}>
                        <div>{t("AssignTechnictianToLiveSupport.Fri")}</div>
                        <center>
                            {" "}
                            <div
                                className={day[4] === new Date().getDate() ? "todayBall" : ""}
                            >
                                {" "}
                                {day[4]}
                            </div>
                        </center>
                    </th>
                    <th className={day[5] === new Date().getDate() ? "today" : ""}>
                        <div>{t("AssignTechnictianToLiveSupport.Sat")}</div>{" "}
                        <center>
                            <div
                                className={day[5] === new Date().getDate() ? "todayBall" : ""}
                            >
                                {" "}
                                {day[5]}
                            </div>
                        </center>
                    </th>
                    <th className={day[6] === new Date().getDate() ? "today" : "lastDay"}>
                        <div>{t("AssignTechnictianToLiveSupport.Sun")}</div>
                        <center>
                            <div
                                className={day[6] === new Date().getDate() ? "todayBall" : ""}
                            >
                                {" "}
                                {day[6]}
                            </div>
                        </center>
                    </th>
                </tr>
            );
            // let time = 8;
            let month = selectedDate.getMonth();

            for (let time = 0; time <= 25; time++) {
                result.push(
                    <tr key={Math.random()}>
                        <td key={Math.random()} className="Time">
                            {time < 10 ? "0" + time + ":00" : time + ":00"}
                        </td>
                        <td className="scheduleWidth">
                            {getAppointments(day[0], time, month)}
                        </td>
                        <td className="scheduleWidth">
                            {getAppointments(day[1], time, month)}
                        </td>
                        <td className="scheduleWidth">
                            {getAppointments(day[2], time, month)}
                        </td>
                        <td className="scheduleWidth">
                            {getAppointments(day[3], time, month)}
                        </td>
                        <td className="scheduleWidth">
                            {getAppointments(day[4], time, month)}
                        </td>
                        <td className="scheduleWidth">
                            {getAppointments(day[5], time, month)}
                        </td>
                        <td className="scheduleWidthLast">
                            {getAppointments(day[6], time, month)}
                        </td>
                    </tr>
                );
                // time++;
            }
        } else if (filter === "day") {
            result.push(
                <tr key={Math.random()}>
                    <th className="Time"></th>
                    <th className="lastDayFiltered">
                        <center>
                            <div>
                                <div>{daysNames[currentDate.getDay() - 1]}</div>{" "}
                                {currentDate.getDate()}
                            </div>
                        </center>
                    </th>
                </tr>
            );
            let time = 8;
            let month = selectedDate.getMonth();

            for (let i = 0; i < 15; i++) {
                result.push(
                    <tr key={Math.random()}>
                        <td key={Math.random()} className="Time">
                            {time < 10 ? "0" + time + ":00" : time + ":00"}
                        </td>
                        <td className="scheduleWidthLast">
                            {getAppointments(currentDate.getDate(), time, month)}
                        </td>
                    </tr>
                );
                time++;
            }
        } else if (filter === "month") {
            result.push(
                <tr key={Math.random()} className="WeekDay">
                    <th></th>
                    <th> {t("AssignTechnictianToLiveSupport.M")}</th>
                    <th> {t("AssignTechnictianToLiveSupport.T")}</th>
                    <th> {t("AssignTechnictianToLiveSupport.W")}</th>
                    <th> {t("AssignTechnictianToLiveSupport.T2")}</th>
                    <th> {t("AssignTechnictianToLiveSupport.F")}</th>
                    <th> {t("AssignTechnictianToLiveSupport.S")}</th>
                    <th> {t("AssignTechnictianToLiveSupport.S2")}</th>
                </tr>
            );
            let currentDate = new Date();
            let dateLastMonday = new Date(currentDate.getTime());
            dateLastMonday.setDate(1);
            let weekNo = getWeekNumber(dateLastMonday);

            let lastDaysInMonth = new Date(
                selectedDate.getFullYear(),
                selectedDate.getMonth() - 2,
                0
            ).getDate();
            let counter = 0;
            while (dateLastMonday.getDay() !== 1 && counter < 10) {
                if (dateLastMonday.getDate() === 1) {
                    dateLastMonday.setMonth(dateLastMonday.getMonth() - 1);
                    dateLastMonday.setDate(lastDaysInMonth);
                } else {
                    dateLastMonday.setDate(dateLastMonday.getDate() - 1);
                }
                counter++;
            }

            let day = dateLastMonday.getDate();
            let current = currentDate.getDate();

            for (let i = 0; i < 6; i++) {
                result.push(
                    <tr key={Math.random()}>
                        <td className="Time" key={weekNo + i}>
                            {weekNo + i}
                        </td>
                        <td className={getCurrentDay(day, i * 7, current)[1]}>
                            {getCurrentDay(day, i * 7, current)[0]}
                            {getCurrentDay(day, i * 7, current)[1] === "thisMonth" &&
                                getAppointmentsPerMonth(getCurrentDay(day, i * 7, current)[0])}
                        </td>
                        <td className={getCurrentDay(day, 1 + i * 7, current)[1]}>
                            {getCurrentDay(day, 1 + i * 7, current)[0]}
                            {getCurrentDay(day, i * 7, current)[1] === "thisMonth" &&
                                getAppointmentsPerMonth(
                                    getCurrentDay(day, i * 7 + 1, current)[0]
                                )}
                        </td>
                        <td className={getCurrentDay(day, 2 + i * 7, current)[1]}>
                            {getCurrentDay(day, 2 + i * 7, current)[0]}
                            {getCurrentDay(day, i * 7, current)[1] === "thisMonth" &&
                                getAppointmentsPerMonth(
                                    getCurrentDay(day, i * 7 + 2, current)[0]
                                )}
                        </td>
                        <td className={getCurrentDay(day, 3 + i * 7, current)[1]}>
                            {getCurrentDay(day, 3 + i * 7, current)[0]}
                            {getCurrentDay(day, i * 7, current)[1] === "thisMonth" &&
                                getAppointmentsPerMonth(
                                    getCurrentDay(day, i * 7 + 3, current)[0]
                                )}
                        </td>
                        <td className={getCurrentDay(day, 4 + i * 7, current)[1]}>
                            {getCurrentDay(day, 4 + i * 7, current)[0]}
                            {getCurrentDay(day, i * 7, current)[1] === "thisMonth" &&
                                getAppointmentsPerMonth(
                                    getCurrentDay(day, i * 7 + 4, current)[0]
                                )}
                        </td>
                        <td className={getCurrentDay(day, 5 + i * 7, current)[1]}>
                            {getCurrentDay(day, 5 + i * 7, current)[0]}
                            {getCurrentDay(day, i * 7, current)[1] === "thisMonth" &&
                                getAppointmentsPerMonth(
                                    getCurrentDay(day, i * 7 + 5, current)[0]
                                )}
                        </td>
                        <td className={getCurrentDay(day, 6 + i * 7, current)[1]}>
                            {getCurrentDay(day, 6 + i * 7, current)[0]}
                            {getCurrentDay(day, i * 7, current)[1] === "thisMonth" &&
                                getAppointmentsPerMonth(
                                    getCurrentDay(day, i * 7 + 6, current)[0]
                                )}
                        </td>
                    </tr>
                );
            }
        }
        return result;
    };

    const PendingOrScheduled = (ID) => {
        if (ID === "") {
            if (highlighted === "none" || highlighted === "pending") {
                return "pending";
            } else if (highlighted === "assigned") {
                return "pendingDimlight";
            }
        } else {
            if (highlighted === "none" || highlighted === "assigned") {
                return "scheduled";
            } else if (highlighted === "pending") {
                return "scheduledDimlight";
            }
        }
    };

    function getAppointments(day, time, month) {
        var results = [];

        if (currentCalendar !== []) {
            currentCalendar.forEach((element) => {
                var date = new Date(element.ScheduledDate);
                if (
                    date.getDate() === day &&
                    date.getHours() === time &&
                    date.getMonth() === month
                ) {
                    results.push(
                        <button
                            key={element.AgentID}
                            className={PendingOrScheduled(element.AgentID)}
                            onClick={() => {
                                OpenSchedulePopUp(element);
                            }}
                            style={{ alignItems: "center" }}
                        >
                            <img
                                alt=""
                                src={element.AgentID !== "" ? EngineerWhiteIcon : EngineerIcon}
                            />{" "}
                            {element.Title}
                        </button>
                    );
                }
            });
            return results;
        }
    }

    function getAppointmentsPerMonth(day) {
        var results = [];
        currentCalendar.forEach((element) => {
            var date = new Date(element.ScheduledDate);
            if (date.getDate() === day) {
                console.log(element)
                results.push(
                    <button
                        key={element.AgentID}
                        className={PendingOrScheduled(element.AgentID)}
                        onClick={() => {
                            OpenSchedulePopUp(element);
                        }}
                    >
                        <img
                            alt=""
                            src={element.AgentID !== "" ? EngineerWhiteIcon : EngineerIcon}
                        />{" "}
                        {element.BuyerName}
                    </button>
                );
            }
        });
        return results;
    }

    function OpenSchedulePopUp(Case) {
        setShowPopUp(true);
        setCurrentCase(Case);
    }

    function CloseSchedulePopUp() {
        setShowPopUp(false);
        setCurrentCase(null);
    }

    function getCurrentDay(day, modifier, current) {
        var result = day + modifier;
        var newMonth = "otherMonth";
        var daysInMonth = new Date(
            selectedDate.getFullYear(),
            selectedDate.getMonth() + 1,
            0
        ).getDate();
        var currentDay = new Date().getDate();
        var lastDaysInMonth = new Date(
            selectedDate.getFullYear(),
            selectedDate.getMonth(),
            0
        ).getDate();
        if (lastDaysInMonth + daysInMonth < result) {
            result = result - lastDaysInMonth - daysInMonth;
        } else if (lastDaysInMonth < result) {
            if (lastDaysInMonth + current === result) {
                result = result - lastDaysInMonth;

                if (result === currentDay) {
                    if (!changedMonth) {
                        newMonth = "currentDay";
                    }
                } else {
                    newMonth = "newSelected";
                }
            } else {
                result = result - lastDaysInMonth;
                newMonth = "thisMonth";
                if (result === currentDay) {
                    if (!changedMonth) {
                        newMonth = "currentDay";
                    }
                }
            }
        }

        return [result, newMonth];
    }

    function ChangeToToday() {
        setSelectedDate(new Date());
        setChangedMonth(false);
    }

    function AddMonth() {
        var newDate = new Date(selectedDate);
        newDate.setMonth(newDate.getMonth() + 1);
        setSelectedDate(newDate);
        ChangedWeekOrMonth(newDate);
    }

    function RemoveMonth() {
        var newDate = new Date(selectedDate);
        newDate.setMonth(newDate.getMonth() - 1);
        setSelectedDate(newDate);
        ChangedWeekOrMonth(newDate);
    }

    // function AddWeek() {
    //   if (filter === "day") {
    //     var newDate = new Date(selectedDate);
    //     newDate.setDate(newDate.getDate() + 1);
    //     setSelectedDate(newDate);
    //     ChangedWeekOrMonth(newDate);
    //   } else if (filter === "week") {
    //     var newDate = new Date(selectedDate);
    //     newDate.setDate(newDate.getDate() + 7);
    //     setSelectedDate(newDate);
    //     ChangedWeekOrMonth(newDate);
    //   } else if (filter === "month") {
    //     AddMonth();
    //   }
    // }

    function ChangedWeekOrMonth(newDate) {
        console.log(new Date());
        console.log(newDate);
        if (new Date().getMonth() === newDate.getMonth()) {
            setChangedMonth(false);
        } else {
            setChangedMonth(true);
        }
        if (getWeekNumber(new Date()) === getWeekNumber(newDate)) {
            setChangedWeek(false);
        } else {
            setChangedWeek(true);
        }
    }

    // function RemoveWeek() {
    //   if (filter === "day") {
    //     var newDate = new Date(selectedDate);
    //     newDate.setDate(newDate.getDate() - 1);
    //     setSelectedDate(newDate);
    //     ChangedWeekOrMonth(newDate);
    //   } else if (filter === "week") {
    //     var newDate = new Date(selectedDate);
    //     newDate.setDate(newDate.getDate() - 7);
    //     setSelectedDate(newDate);
    //     ChangedWeekOrMonth(newDate);
    //   } else if (filter === "month") {
    //     RemoveMonth();
    //   }
    // }

    const ConvertSecondsToString = (TotalSeconds) => {
        var Hours = 0;
        var Minutes = 0;

        Hours = Math.floor(TotalSeconds / 60);
        Minutes = ("0" + Math.floor(TotalSeconds % 60)).slice(-2);

        return Hours + "h " + Minutes + "min";
    };

    function GetAgents() {
        var results = [];
        results.push(
            <option key="all" value="all">
                {t("AssignTechnictianToLiveSupport.All")}
            </option>
        );
        results.push(
            <option key="pending" value="pending">
                {t("AssignTechnictianToLiveSupport.Pending")}
            </option>
        );
        if (agentList) {
            agentList.forEach((element) => {
                if (element.AgentID !== "") {
                    results.push(
                        <option key={element.AgentID} value={element.AgentID}>
                            {element.AgentName}
                        </option>
                    );
                }
            });
        }
        return (
            <select
                className="AgentSelect"
                onChange={(event) => {
                    ChangeAgentFocused(event.target.value);
                }}
            >
                {results}
            </select>
        );
    }

    const ChangeAgentFocused = async (ID) => {
        setGotSavedData(false);
        setSelectedAgent(ID);
    };

    const ChangeAgent = (ID, Name) => {
        var changedCase = currentCase;
        changedCase.AgentID = ID;
        changedCase.AgentName = Name;
        setCurrentCase(changedCase);
        setUpdate(update + 1);
    };

    const HighlightPending = () => {
        if (highlighted === "pending") {
            setHighlighted("none");
        } else {
            setHighlighted("pending");
        }
    };

    const HighlightAssigned = () => {
        if (highlighted === "assigned") {
            setHighlighted("none");
        } else {
            setHighlighted("assigned");
        }
    };

    const ReturnRadioSelectAgentList = () => {
        var results = [];

        if (agentList) {
            agentList.forEach((element) => {
                if (element.AgentID !== "") {
                    results.push(
                        <div className="SideBySideOptionsRadio" key={Math.random()}>
                            <img
                                alt=""
                                src={
                                    element.AgentID === currentCase.AgentID
                                        ? CheckedRadio
                                        : UncheckedRadio
                                }
                                onClick={() => {
                                    ChangeAgent(element.AgentID, element.AgentName);
                                }}
                            />
                            <input
                                key={element.AgentID}
                                type="radio"
                                value={element.AgentID}
                                name="agent"
                                defaultChecked={element.AgentID === currentCase.AgentID}
                            />
                            {element.AgentName}
                        </div>
                    );
                }
            });
        }

        return results;
    };

    function UpdateAgent() {
        chatConnect.PickNewRequests(
            currentUser.id,
            currentUser.firstName + " " + currentUser.lastName,
            currentCase.RequestID,
        );
        CloseSchedulePopUp();
        toast.warning("Please, refresh the page", { autoClose: 5000 })
    }


    function PopUpTecnichian() {
        return (
            <PopUpBeforeProceding className={update}>
                {" "}
                <div className="SideBySideOptions">
                    <div className="title">
                        {console.log(currentCase)}
                        {currentCase && currentCase.Title}
                    </div>
                    <button onClick={CloseSchedulePopUp}>
                        <AiFillCloseCircle
                            size="20px"
                            style={{ color: "#009ee0", paddingTop: "20px" }}
                        />{" "}
                    </button>
                </div>{" "}
                <div className="SideBySideOptions">
                    <div className="companyName">
                        {t("AssignTechnictianToLiveSupport.CreatedBy")}: {currentCase && currentCase.BuyerInfo.FirstName}
                    </div>
                    <div className="timeStamp">
                        {currentCase &&
                            ConvertSecondsToString(currentCase.ScheduledTime / 60)}
                    </div>
                </div>
                {/* <button
                    className="BlueApplyAgent"
                    onClick={() => {
                        UpdateAgent();
                    }}
                >
                    {t("AssignTechnictianToLiveSupport.Apply")}

                </button> */}
                {currentCase && currentCase.AgentID === "" ?
                    <button
                        className="BlueApplyAgent"
                        onClick={() => {
                            UpdateAgent();
                        }}
                    >
                        {t("AssignTechnictianToLiveSupport.Apply")}

                    </button> :
                    <button
                        className="BlueApplyAgent"
                    >
                        {t("AssignTechnictianToLiveSupport.GoToVideoChatMessagesToStart")}
                    </button>
                }
            </PopUpBeforeProceding>
        );
    }

    const renderAssignTechnictian = (
        <AssignTechnictianContext.Provider
            value={{
                gotSavedData, setGotSavedData,
                selectedDate, setSelectedDate,
                agentList, setAgentList,
                currentCalendar, setCurrentCalendar,
                selectedAgent, setSelectedAgent,
                setChangedWeek,
                changedMonth, setChangedMonth,
                highlighted, setHighlighted,
                filter, setFilter,
                currentCase, setCurrentCase,
                update, setUpdate,
                showPopUp, setShowPopUp,
                monthNames,
                daysNames,
                handleGetInitialData,
                getWeekNumber,
                calculateDateTable,
                calculateScheduleTable,
                PendingOrScheduled,
                getAppointments,
                getAppointmentsPerMonth,
                OpenSchedulePopUp,
                CloseSchedulePopUp,
                getCurrentDay,
                ChangeToToday,
                AddMonth,
                RemoveMonth,
                ChangedWeekOrMonth,
                ConvertSecondsToString,
                GetAgents,
                ChangeAgentFocused,
                ChangeAgent,
                HighlightPending,
                HighlightAssigned,
                ReturnRadioSelectAgentList,
                UpdateAgent,
                PopUpTecnichian
            }}
        >
            {children}
        </AssignTechnictianContext.Provider>
    );

    return renderAssignTechnictian;
};
