import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { Manager, Reference, Popper } from 'react-popper'
import PropTypes from 'prop-types'
import { withMargin, withWidth, withHeight } from '../../utils/styled-decorators'
import Video from '../../assets/icons/video-camera.svg'
import VideoOff from '../../assets/icons/camera-off.svg'
import Microphone from '../../assets/icons/microphone.svg'
import MicrophoneOff from '../../assets/icons/microphone-unmute.svg'
import Hangup from '../../assets/icons/hangup.svg'

export const icons = {
  hangup: Hangup,
  microphone: Microphone,
  'microphone-off': MicrophoneOff,
  video: Video,
  'video-off': VideoOff
}

const StyledIcon = styled.div`
  background-color: ${({ theme, color }) => theme.colors[color] || theme.colors.gray};
  mask-image: url(${({ name }) => icons[name] || null});
  background-repeat: no-repeat;
  background-size: cover;
  mask-size: cover;
  min-width: ${({ width }) => width || '24px'};
  min-height: ${({ height }) => height || '24px'};
  ${({ onClick }) => onClick && 'cursor: pointer;'}
  ${withMargin()}
  ${withWidth('24px')}
  ${withHeight('24px')}
  ${({ disabled }) => disabled && 'opacity: 0.4; cursor: not-allowed;'}
  ${({ visible }) => !visible && 'display: none;'}
`

const Tooltip = styled.div`
  position: relative;
  font-family: 'Roboto';
  display: flex;
  visibility: ${({ open }) => open ? 'visible' : 'hidden'};
  opacity: ${({ open }) => open ? '1' : '0'};
  flex-direction: column;
  padding: 8px;
  margin: 8px;
  border-radius: 8px;
  z-index: 999;
  transition: all 0.2s ease;
  ${({ theme }) => css`
    background-color: ${theme.colors.darkGray};
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.small};
    box-shadow: ${theme.boxShadows.high};
  `}

  /* Arrow Style */
  div {
    content: '';
    transform: rotate(45deg);
    background: ${({ theme }) => theme.colors.darkGray};
    width: 10px;
    height: 10px;
    position: absolute;
    z-index: -1;
  }

  &[data-placement='bottom'] div {
    top: -4px;
  }
  &[data-placement='right'] div {
    left: -4px;
  }
  &[data-placement='left'] div {
    right: -4px;
  }
  &[data-placement='top'] div {
    bottom: -4px;
  }
`

const TooltipPopper = React.forwardRef(({ style, tooltipPosition, placement, visibility, tooltip, arrowProps }, ref) => {
  useEffect(() => {
    // scheduleUpdate()
  }, [visibility])

  const tooltipStyle = {
    ...style,
    transform: `${style.transform} ${visibility ? 'translateX(0)' : 'scale(0.9)'}`
  }

  return <Tooltip open={visibility} tooltipPosition={tooltipPosition} ref={ref} style={tooltipStyle} data-placement={placement}>
    {tooltip}
    <div ref={arrowProps.ref} style={arrowProps.style} />
  </Tooltip>
})

const Icon = ({ tooltip, tooltipPosition, ...rest }) => {
  const [visibility, setVisibility] = useState(false)

  const showTooltip = () => setVisibility(true)
  const hideTooltip = () => setVisibility(false)

  const renderIcon = tooltip ? <Manager>
    <Reference>
      {({ ref }) => (
        <StyledIcon {...rest} ref={ref} onMouseEnter={showTooltip} onMouseLeave={hideTooltip} />
      )}
    </Reference>
    <Popper
      placement={tooltipPosition || 'top'}
      modifiers={{
        preventOverflow: {
          enabled: true,
          boundariesElement: 'viewport'
        }
      }}
    >
      {({ ref, style, placement, scheduleUpdate, arrowProps }) => <TooltipPopper {...{ style, scheduleUpdate, tooltipPosition, placement, visibility, tooltip, arrowProps, ref }} />}
    </Popper>
  </Manager> : <StyledIcon {...rest} />

  return renderIcon
}

Icon.propTypes = {
  /**
   * Define the icon that should be rendered
   */
  name: PropTypes.string,
  /**
   * Tooltip text that will appear when you hover the icon
   */
  tooltip: PropTypes.string,
  /**
   * Tooltip position (top | right | bottom | left)
   */
  tooltipPosition: PropTypes.string,
  /**
   * A color key defined in the theme
   */
  color: PropTypes.string,
  /**
   * Override CSS width property. Must be a valid CSS width value as a string
   */
  width: PropTypes.string,
  /**
   * Override CSS height property. Must be a valid CSS height value as a string
   */
  height: PropTypes.string,
  /**
   * Override CSS margin property. Must be a valid CSS margin value as a string
   */
  margin: PropTypes.string,
  /**
   * Override CSS to disable the icon if true
   */
  disabled: PropTypes.bool,
  /**
   * Override CSS to hide the icon if false
   */
  visible: PropTypes.bool
}

Icon.defaultProps = {
  disabled: false,
  visible: true
}

export default Icon
