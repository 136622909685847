import React, { useState, useContext } from "react";
import * as ConnectTransactions from "../components/connect/transaction";
import { UserContext } from "../stores/UserStore";
import { toast } from "react-toastify";

export const TransactionContext = React.createContext();

export const TransactionProvider = ({ children }) => {

    const { currentUser } = useContext(UserContext);
    var [transactions, setTransactions] = React.useState([]);
    const [gotCustomerInformation, setGotCustomerInformation] = useState(false);
    const [showHiddenInfo, setShowHiddenInfo] = useState(false);
    const [reportID, setReportID] = useState("");
    const [schearchText, setSchearchText] = useState("");
    const [initialDate, setInitialDate] = useState("1990/01/01");
    const [finishDate, setFinishDate] = useState("2990/01/01");

    const GetInitialTransactionData = () => {
        if (!gotCustomerInformation) {
            setTimeout(async () => {
                var InitialDate = new Date();
                var FinalDate = new Date();

                InitialDate.setDate(1);

                setInitialDate(InitialDate.toISOString().substr(0, 10));
                setFinishDate(FinalDate.toISOString().substr(0, 10));

                if (currentUser != null && currentUser.roles.ADMIN_ACCESS) {
                    await ConnectTransactions.ListTransactionByAgentID(
                        currentUser.id,
                        InitialDate,
                        FinalDate
                    );
                } else {
                    await ConnectTransactions.ListTransactionByBuyerID(
                        currentUser.id,
                        InitialDate,
                        FinalDate
                    );
                }

                var transaction = ConnectTransactions.ReturnTransactions();
                transaction = transaction.Transactions;

                transaction.sort((a, b) => (a.CreationDate > b.CreationDate ? -1 : 1));
                setTransactions(transaction);

                setGotCustomerInformation(true);
            }, 50);
        }
    };

    const handleSchearchTerm = () => {
        const SchearchTerm = document.getElementById("scearch").value;
        if (SchearchTerm !== "") {
            setTransactions(
                ConnectTransactions.ReturnTransactionsFiltered(SchearchTerm)
            );
            setSchearchText(SchearchTerm);
        } else {
            setTransactions(ConnectTransactions.ReturnTransactions().Transactions);
            setSchearchText(SchearchTerm);
        }
    };
    const handleRefreshData = async () => {
        var InitialDate = new Date(document.getElementById("InitialDate").value);
        var FinalDate = new Date(document.getElementById("FinishDate").value);

        setInitialDate(InitialDate.toISOString().substr(0, 10));
        setFinishDate(FinalDate.toISOString().substr(0, 10));

        if (currentUser != null && currentUser.roles.ADMIN_ACCESS) {
            await ConnectTransactions.ListTransactionByAgentID(
                currentUser.id,
                InitialDate,
                FinalDate
            );
        } else {
            await ConnectTransactions.ListTransactionByBuyerID(
                currentUser.id,
                InitialDate,
                FinalDate
            );
        }

        var transaction = ConnectTransactions.ReturnTransactions();
        transaction = transaction.Transactions;

        transaction.sort((a, b) => (a.CreationDate > b.CreationDate ? -1 : 1));
        setTransactions(transaction);

        setGotCustomerInformation(true);
    };

    const ConvertTimezone = (oldDate) => {
        var date = new Date(oldDate),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2),
            hour = ("0" + (date.getHours() + 1)).slice(-2),
            minute = ("0" + (date.getMinutes() + 1)).slice(-2);

        var currentDate = new Date();

        if (date.toDateString() === currentDate.toDateString()) {
            return hour + ":" + minute;
        }
        return [day, mnth, date.getFullYear()].join("/");
    };

    const ConvertValue = (oldValue) => {
        return "$" + oldValue.toFixed(2);
    };

    const ConvertMinutesToString = (TotalMinutes) => {
        var Hours = 0;
        var Minutes = 0;

        Hours = Math.floor(TotalMinutes / 60);
        Minutes = ("0" + Math.floor(TotalMinutes % 60)).slice(-2);

        return Hours + "h " + Minutes + "min";
    };

    const ShowReportAnIssue = (ID) => {
        setShowHiddenInfo(true);
        setReportID(ID);
    };

    const CloseReportAnIssue = () => {
        setShowHiddenInfo(false);
        setReportID("");
    };

    const ReportAnIssue = () => {
        toast.success("Sended Report about the Transaction " + reportID, { autoClose: 3000 });
    };

    const renderTransaction = (
        <TransactionContext.Provider
            value={{
                transactions, setTransactions,
                showHiddenInfo, setShowHiddenInfo,
                reportID, setReportID,
                schearchText, setSchearchText,
                initialDate, setInitialDate,
                finishDate, setFinishDate,
                GetInitialTransactionData,
                handleSchearchTerm,
                handleRefreshData,
                ConvertTimezone,
                ConvertValue,
                ConvertMinutesToString,
                ShowReportAnIssue,
                CloseReportAnIssue,
                ReportAnIssue
            }}
        >
            {children}
        </TransactionContext.Provider>
    );

    return renderTransaction;
};
