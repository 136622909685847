import Cookies from "js-cookie";
import _ from "lodash";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  Body,
  HelloTextBold,
  MainCard,
} from "../../components/common/MainCard";
import { CreateOnSiteSupport, UpdateOnSiteSupport } from "../../components/connect/OnSiteSupport";
import Caller from "../../components/layout/caller";
import Footer from "../../components/layout/footer";
import Header from "../../components/layout/header";
import NavBar from "../../components/layout/navBar";
import CountryCodeSelect from "../../containers/common/CountryCodeSelect";
import CountrySelect from "../../containers/common/CountrySelect";
import { UserContext } from "../../stores/UserStore";
import { OnSiteSupportContext } from "../../stores/OnSiteSupportStore";
import { useTranslation } from "react-i18next";

const OnSiteSupport = () => {
  document.title = "VAM® Field Service";
  const history = useHistory();
  const { currentUser } = useContext(UserContext);
  const { onSiteSupportObject, setOnSiteSupportObject } =
    useContext(OnSiteSupportContext);
  const [isOnSiteUpdate, setIsOnSiteUpdate] = useState(false);
  const [onSiteUpdate, setOnSiteUpdate] = useState(null);
  const { t } = useTranslation();

  const SideBySideOptions = styled.div`
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: stretch;
    width: 100%;
    .verticalLine {
      border-left: 2px dotted black;
      max-height: 250px;
      max-width: 20px;
      margin-left: 15px;
    }
    div {
      flex: 1;
      padding-right: 10px;
      + div {
        padding-right: 0;
      }
    }
  `;

  const SideBySideOptionsPhoneDiv = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 100%;

    .Left {
      flex: 1;
      margin-right: 20px;
    }
    .Right {
      flex: 1;
    }
  `;

  const CenterDiv = styled.div`
    width: 100%;
    display: flex;
    padding: 10px;
    justify-content: flex-start;
  `;

  const ProfileForm = styled.div`
    margin-top: 0;
    margin-left: 10px;
    font-size: calc(8px + 0.5vw);
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    width: 95%;
    color: black;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);

    input[type="text"],
    select {
      width: 100%;
      padding: 12px 20px;
      margin: 8px 0;
      display: inline-block;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
    }

    input[type="date"],
    select {
      width: 100%;
      padding: 12px 20px;
      margin: 8px 0;
      display: inline-block;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
    }

    textarea {
      width: 100%;
      resize: none;
    }
    .Tip {
      background-color: #c5efff;
      border-radius: 10px;
      padding: 10px;
      margin: 10px;
      font-size: calc(12px + 0.2vw);
    }
  `;

  const SubmitDiv = styled.div`
    text-align: center;
    margin-left: 10px;
    width: 100%;
    button[type="submit"] {
      margin-top: 20px;
      margin-left: 20px;
      min-height: 50px;
      min-width: 100px;
      font-size: calc(8px + 0.4vw);
      width: 10vw;
      background-color: #009ee0;
      color: white;
      border: 2px solid #e7e7e7;
      transition: all 0.3s ease 0s;
      :hover {
        background-color: #fff;
        box-shadow: 0px 15px 20px rgba(167, 164, 206, 0.4);
        color: #009ee0;
        transform: translateX(7px);
      }
    }
    button[type="cancel"] {
      text-align: center;
      font-size: calc(8px + 0.4vw);
      margin-top: 20px;
      min-height: 50px;
      min-width: 100px;
      width: 10vw;
      background-color: white;
      color: #039fe0;
      border: 2px solid #e7e7e7;
      transition: all 0.3s ease 0s;
      :hover {
        background-color: #009ee0;
        box-shadow: 0px 15px 20px rgba(167, 164, 206, 0.4);
        color: #fff;
        transform: translateX(7px);
      }
    }
  `;

  function handleRetriveOnSiteSupport() {
    if (
      onSiteSupportObject !== undefined &&
      onSiteSupportObject !== null &&
      !isOnSiteUpdate
    ) {
      setIsOnSiteUpdate(true);
      setTimeout(() => {
        if (onSiteSupportObject !== undefined && onSiteSupportObject !== null) {
          setOnSiteUpdate(onSiteSupportObject);
          setOnSiteSupportObject(null);
        }
      }, 50);
    }
  }

  function ConvertDate(date) {
    var convertedDate = new Date(date)
    if (convertedDate.getMonth() < 10) {
      return convertedDate.getFullYear() + "-0" + convertedDate.getMonth() + "-" + convertedDate.getDate();
    }
    else {
      return convertedDate.getFullYear() + "-" + convertedDate.getMonth() + "-" + convertedDate.getDate();
    }

  }

  async function handleSubmit() {
    const BuyerID = currentUser.id;
    const FirstName = document.getElementById("FirstName").value;
    const LastName = document.getElementById("LastName").value;
    const Company = document.getElementById("Company").value;
    const Position = document.getElementById("Position").value;
    const Email = document.getElementById("Email").value;
    const CountryCode = _.find({ m_code: Cookies.get("CountryCode") });
    const Phone = document.getElementById("Phone").value;
    const Country = _.find({ m_code: Cookies.get("Country") });
    const TypeOfService = document.getElementById("TypeOfService").value;
    const DateOfService = document.getElementById("DateOfService").value;

    if (isOnSiteUpdate) {
      UpdateOnSiteSupport(
        onSiteUpdate.OnSiteSupportID,
        onSiteUpdate.BuyerID,
        FirstName,
        LastName,
        Company,
        Position,
        Email,
        CountryCode,
        Phone,
        Country,
        TypeOfService,
        DateOfService,
        currentUser.id
      );
    }
    else {
      CreateOnSiteSupport(
        BuyerID,
        FirstName,
        LastName,
        Company,
        Position,
        CountryCode,
        Phone,
        Email,
        Country,
        TypeOfService,
        DateOfService
      );
    }
    history.push("/LandingPage");
  }

  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = today.getFullYear();

  const minDate = yyyy + "-" + mm + "-" + dd;
  return (
    <Body>
      <Header />
      <Caller />
      <NavBar />
      <MainCard id="MainCard">
        <HelloTextBold>{t("OnSiteSupport.RequestOnSiteSupport")}</HelloTextBold>
        <CenterDiv>
          <ProfileForm>
            <SideBySideOptions>
              <div>
                {t("OnSiteSupport.FirstName")} <div />
                <input
                  type="text"
                  id="FirstName"
                  placeholder={t("OnSiteSupport.FirstNameDot")}
                  defaultValue={onSiteUpdate != null ? onSiteUpdate.FirstName : ""}
                ></input>
              </div>
              <div>
                {t("OnSiteSupport.LastName")} <div />
                <input
                  type="text"
                  id="LastName"
                  placeholder={t("OnSiteSupport.LastNameDot")}
                  defaultValue={onSiteUpdate != null ? onSiteUpdate.LastName : ""}
                ></input>
              </div>
            </SideBySideOptions>
            <SideBySideOptions>
              <div>
                {t("OnSiteSupport.Company")} <div />
                <input
                  type="text"
                  id="Company"
                  placeholder={t("OnSiteSupport.CompanyDot")}
                  defaultValue={onSiteUpdate != null ? onSiteUpdate.Company : ""}
                ></input>
              </div>
              <div>
                {t("OnSiteSupport.Position")} <div />
                <input
                  type="text"
                  id="Position"
                  placeholder={t("OnSiteSupport.PositionDot")}
                  defaultValue={onSiteUpdate != null ? onSiteUpdate.BuyerPosition : ""}
                ></input>
              </div>
            </SideBySideOptions>
            <div>
              {t("OnSiteSupport.Email")} <div />
              <input type="text" id="Email" placeholder={t("OnSiteSupport.EmailDot")}
                defaultValue={onSiteUpdate != null ? onSiteUpdate.Email : ""}></input>
            </div>
            <SideBySideOptionsPhoneDiv>
              <div className="Left">
                {t("OnSiteSupport.CountryCode")} <div />
                <CountryCodeSelect />
              </div>
              <div className="Right">
                {t("OnSiteSupport.Phone")} <div />
                <input type="text" id="Phone" placeholder={t("OnSiteSupport.PhoneDot")}
                  defaultValue={onSiteUpdate != null ? onSiteUpdate.Phone : ""}></input>
              </div>
            </SideBySideOptionsPhoneDiv>
            <SideBySideOptionsPhoneDiv>
              <div className="Left">
                {t("OnSiteSupport.CountrytheServiceisRequired")} <div />
                <CountrySelect id="Country" />
              </div>
              <div className="Right">
                {t("OnSiteSupport.TypeofService")} <div />
                <select name="TypeOfService" id="TypeOfService"
                  defaultValue={onSiteUpdate != null ? onSiteUpdate.ServiceType : ""}>
                  <option disabled hidden value="">
                    {t("OnSiteSupport.SelectTypeofService")}
                  </option>
                  <option value="rig">{t("OnSiteSupport.Rig")}</option>
                  <option value="workshop">{t("OnSiteSupport.WorkShop")}</option>
                  <option value="yard">{t("OnSiteSupport.Yard")}</option>
                </select>
              </div>
            </SideBySideOptionsPhoneDiv>
            <div>
              {t("OnSiteSupport.Datetheserviceisrequired")} <div />
              <input
                type="date"
                id="DateOfService"
                defaultValue={onSiteUpdate != null ? ConvertDate(onSiteUpdate.ServiceDate) : ""}
                min={minDate}></input>
            </div>
            <SubmitDiv>
              <button type="submit" onClick={() => handleSubmit()}>
                {" "}
                {t("OnSiteSupport.Send")}{" "}
              </button>
            </SubmitDiv>
          </ProfileForm>
        </CenterDiv>
        {handleRetriveOnSiteSupport()}
      </MainCard>
      <Footer />
    </Body>
  );
};

export default OnSiteSupport;
