import React, { useContext } from "react";
import Cookies from "js-cookie";
import { UserContext } from "../stores/UserStore";
import Compress from "compress.js"
import { UpdateUser } from "../components/connect/profileConncet";
import styled from "styled-components";
import defaultCompanyPic from "../assets/icons/defaultCompany.png";
import defaultProfilePic from "../assets/icons/defaultProfile.png";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export const ProfileContext = React.createContext();

export const ProfileProvider = ({ children }) => {

    const ImageDiv = styled.div`
  color: black;
  display: flex;
  align-items: center;
  margin-left: 30px;
  font {
    color: #009ee0;
  }
  div {
    font-size: calc(12px + 0.5vw);
    color: black;
    padding-left: 16px;
  }
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  button {
    background-color: Transparent;
    background-repeat: no-repeat;
    height: 50px;
    border: none;
    cursor: pointer;
    overflow: hidden;
  }
`;

    const { currentUser } = useContext(UserContext);
    var [profilePicture, setProfilePicture] = React.useState("");
    const [companyPicture] = React.useState("");
    var [showRigSite, setShowRigSite] = React.useState(true);
    var [showAssembly, setShowAssembly] = React.useState(true);
    var [showYard, setShowYard] = React.useState(true);
    var [showMakeUpGraph, setShowMakeUpGraph] = React.useState(true);
    var [gotSettings, setGotSettings] = React.useState(false);
    var [mesuringUnit] = React.useState(
        currentUser !== null && currentUser.measuringUnit !== null
            ? currentUser.measuringUnit
            : "Metric"
    );
    const { t } = useTranslation();

    const GetSettingsFromUser = () => {
        if (!gotSettings) {
            if (Cookies.get("showRigSite")) {
                const settingsShowRigSite = Cookies.get("showRigSite") === "true";
                const settingsShowAssembly = Cookies.get("showAssembly") === "true";
                const settingsShowYard = Cookies.get("showYard") === "true";
                const settingsMakeUpGraph = Cookies.get("showMakeUpGraph") === "true";

                if (
                    settingsShowRigSite !== undefined &&
                    settingsShowAssembly !== undefined &&
                    settingsShowYard !== undefined &&
                    settingsMakeUpGraph !== undefined
                ) {
                    setShowRigSite(settingsShowRigSite);
                    setShowAssembly(settingsShowAssembly);
                    setShowYard(settingsShowYard);
                    setShowMakeUpGraph(settingsMakeUpGraph);
                }
            }
            setGotSettings(true);
        }
    };

    const handleChangeToProfile = () => {
        document.getElementById("ProfileButton").className = "active";
        document.getElementById("CompanyButton").className = "optionsButton";
        document.getElementById("SettingsButton").className = "optionsButton";

        document.getElementById("Profile").style.display = "flex";
        document.getElementById("Company").style.display = "none";
        document.getElementById("Settings").style.display = "none";
    };

    const handleChangeToCompany = () => {
        document.getElementById("ProfileButton").className = "optionsButton";
        document.getElementById("CompanyButton").className = "active";
        document.getElementById("SettingsButton").className = "optionsButton";

        document.getElementById("Profile").style.display = "none";
        document.getElementById("Company").style.display = "flex";
        document.getElementById("Settings").style.display = "none";
    };

    const handleChangeToSettings = () => {
        document.getElementById("ProfileButton").className = "optionsButton";
        document.getElementById("CompanyButton").className = "optionsButton";
        document.getElementById("SettingsButton").className = "active";

        document.getElementById("Profile").style.display = "none";
        document.getElementById("Company").style.display = "none";
        document.getElementById("Settings").style.display = "flex";
    };
    console.log(currentUser)

    const handleUpdateProfile = async () => {
        const FirstName = document.getElementById("FirstName").value;
        const LastName = document.getElementById("LastName").value;
        const Position = document.getElementById("Position").value;
        const Language = Cookies.get("language");
        const Phone = document.getElementById("Phone").value;
        const CountryCode = Cookies.get("CountryCode");
        const CompanyName = document.getElementById("CompanyName").value;
        const CompanyAddress = document.getElementById("CompanyAddress").value;
        const ZipCode = document.getElementById("ZipCode").value;
        const City = document.getElementById("City").value;
        const Country = document.getElementById("Country").value;


        const data = {
            "update": {
                "id": currentUser.id,
                "measuringUnit": mesuringUnit,
                "firstName": FirstName,
                "lastName": LastName,
                "company": CompanyName,
                "location": currentUser.location,
                "address": CompanyAddress,
                "zipCode": ZipCode,
                "city": City,
                "position": Position,
                "country": Country,
                "phoneNumber": Phone,
                "countryCode": CountryCode,
                "type": currentUser.type,
                "language": Language,
            }
        }
        console.log(data)
        await UpdateUser(data);
    };

    const handleChangeMetricUnit = () => {
        if (mesuringUnit === "Metric") {
            document.getElementById("Metric").className = "";
            document.getElementById("Imperial").className = "active";
            mesuringUnit = "Imperial";
        } else {
            document.getElementById("Metric").className = "active";
            document.getElementById("Imperial").className = "";
            mesuringUnit = "Metric";
        }
    };

    const handleImage = () => {
        document.getElementById("picture").click();
    };

    const handleChangeSlider = (option) => {
        if (option === 1) {
            showRigSite = !showRigSite;
        } else if (option === 2) {
            showAssembly = !showAssembly;
        } else if (option === 3) {
            showYard = !showYard;
        } else if (option === 4) {
            showMakeUpGraph = !showMakeUpGraph;
        }
    };

    const compress = new Compress()
    const handleSetupPicture = (event) => {
        const inputPicture = event.target.files[0];
        if (inputPicture.size > 200000 || inputPicture.fileSize > 200000) {
            compress.compress([inputPicture], {
                size: 4,
                quality: .75
            }).then((results) => {
                const img1 = results[0];
                const base64str = img1.data;
                const imgExt = img1.ext;
                const file = Compress.convertBase64ToFile(base64str, imgExt);
                getBase64(file).then((base64) => {
                    localStorage.setItem(
                        "ProfilePictureVamApp" +
                        currentUser.id.split("")[0] +
                        currentUser.id.split("")[1] +
                        currentUser.id.split("")[2],
                        base64
                    );
                    setProfilePicture(file);
                });
            })
        } else {
            console.log(inputPicture)
            getBase64(inputPicture).then((base64) => {
                localStorage.setItem(
                    "ProfilePictureVamApp" +
                    currentUser.id.split("")[0] +
                    currentUser.id.split("")[1] +
                    currentUser.id.split("")[2],
                    base64
                );
                setProfilePicture(inputPicture);
            });
        }
    };
    console.log(profilePicture)

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(file);
        });
    };

    const handleApplyToDashboard = () => {
        Cookies.set("showRigSite", showRigSite, {
            expires: 365,
            domain: process.env.REACT_APP_DOMAIN,
        });
        Cookies.set("showAssembly", showAssembly, {
            expires: 365,
            domain: process.env.REACT_APP_DOMAIN,
        });
        Cookies.set("showYard", showYard, {
            expires: 365,
            domain: process.env.REACT_APP_DOMAIN,
        });
        Cookies.set("showMakeUpGraph", showMakeUpGraph, {
            expires: 365,
            domain: process.env.REACT_APP_DOMAIN,
        });

        toast.success("Saved Settings");
    };


    function getProfileImage() {
        if (profilePicture !== null) {
            const image = profilePicture;
            return (
                <ImageDiv>
                    <img src={image} alt="Profile" />
                    <div>
                        {currentUser != null && currentUser.firstName + " " + currentUser.lastName}
                    </div>
                </ImageDiv>
            );
        } else {
            return (
                <ImageDiv>
                    <img src={defaultProfilePic} alt="Profile" />
                    <div>
                        {currentUser != null && currentUser.firstName + " " + currentUser.lastName}
                    </div>
                </ImageDiv>
            );
        }
    }

    function getCompanyImage() {
        if (companyPicture !== "") {
            const image = URL.createObjectURL(companyPicture);
            return (
                <ImageDiv>
                    <img src={image} alt="Profile" />
                </ImageDiv>
            );
        } else {
            return (
                <ImageDiv>
                    <img src={defaultCompanyPic} alt="Company Logo" />
                </ImageDiv>
            );
        }
    }

    const getProfilePic = () => {
        profilePicture = localStorage.getItem(
            "ProfilePictureVamApp" +
            currentUser.id.split("")[0] +
            currentUser.id.split("")[1] +
            currentUser.id.split("")[2]
        );
    };


    function getProfileButton() {
        if (profilePicture !== null) {
            const image = profilePicture;
            return (
                <ImageDiv>
                    <font>{t("Profile.Update")} </font>
                    <button type="button" onClick={handleImage}>
                        <img
                            src={image}
                            alt="Profile"
                            style={{ width: "40px", height: "40px" }}
                        />
                    </button>
                </ImageDiv>
            );
        } else {
            return (
                <ImageDiv>
                    <font>{t("Profile.Update")} </font>
                    <button type="button" onClick={handleImage}>
                        <img
                            src={defaultProfilePic}
                            alt="Profile"
                            style={{ width: "40px", height: "40px" }}
                        />
                    </button>
                </ImageDiv>
            );
        }
    }

    const renderProfile = (
        <ProfileContext.Provider
            value={{
                profilePicture, setProfilePicture,
                showRigSite, setShowRigSite,
                showAssembly, setShowAssembly,
                showYard, setShowYard,
                showMakeUpGraph, setShowMakeUpGraph,
                gotSettings, setGotSettings,
                GetSettingsFromUser,
                handleChangeToProfile,
                handleChangeToCompany,
                handleChangeToSettings,
                handleUpdateProfile,
                handleChangeMetricUnit,
                handleChangeSlider,
                handleSetupPicture,
                handleApplyToDashboard,
                getProfileImage,
                getCompanyImage,
                getProfilePic,
                getProfileButton,
            }}
        >
            {children}
        </ProfileContext.Provider>
    );

    return renderProfile;
};
