import * as React from 'react'
import { Redirect, Route } from 'react-router'

export class ProtectedRoute extends Route {
  render() {
    let redirectPath = ''
    if (!this.props.isAuthenticated) {
      redirectPath = this.props.authenticationPath
    }

    let RouteComponent
    if (redirectPath) {
      const renderComponent = () => (<Redirect to={`${redirectPath}${this.props.location.search}`} />)
      RouteComponent = <Route {...this.props} component={renderComponent} render={undefined}/>
    }
    else {
      RouteComponent = <Route {...this.props}/>
    }
    return RouteComponent
  }
}