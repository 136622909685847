import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  Body,
  HelloTextBold,
  MainCard,
} from "../../components/common/MainCard";
import Caller from "../../components/layout/caller";
import Footer from "../../components/layout/footer";
import Header from "../../components/layout/header";
import NavBar from "../../components/layout/navBar";
import { ChatIDContext } from "../../stores/ChatIDStore";

const Subtitle = styled.div` 
  margin-left: 30px;
  font-size: calc(10px + 0.8vw);

`;
const WhiteCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  color: black;
  padding: 10px;
  width: 95%;
  height: 90%;
  margin-top: 10px;

  .Field {
    display: flex;
    align-items: center;
    justify-content: stretch;
  }
  .FieldText {
    width: 7%;
    padding: 5px;
    text-align: left;
  }

  messagefield {
    margin-top: 15px;
  }

  input {
    flex: 1;
    height: 20px;
    margin: 5px;
    padding: 8px;
    border: 0;
    border-radius: 10px;
    box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);
    :focus {
      outline: none;
    }
  }
  select {
    flex: 1;
    height: 40px;
    margin: 5px;
    padding: 8px;
    border: 0;
    border-radius: 10px;
    box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);
    :focus {
      outline: none;
    }
    option {
      padding: 16px 16px;
      border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
      cursor: pointer;
      border: 0px;
      outline: 0px;
    }
  }
  textarea {
    resize: none;
    border: 0;
    margin: 10px;
    border-radius: 10px;
    margin-top: 10px;
    box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);
    :focus {
      outline: none;
    }
  }
`;

const ButtonsDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  button {
    min-height: 50px;
    min-width: 150px;
    margin-right: 20px;
    margin-top: 20px;
    background-color: #009ee0;
    color: #fff;
    border: 0px;
    border-radius: 10px;
    transition: all 0.3s ease 0s;
    outline: none;
    :hover {
      background-color: #fff;
      box-shadow: 0px 15px 20px rgba(167, 164, 206, 0.4);
      color: #009ee0;
    }
  }
`;

const ALLOWED_TIMES = [60, 120, 180]

const CreateChat = () => {
  document.title = "VAM® Field Service";
  const isSmallScreen = window.screen.width <= 1366;
  const {
    createsupportType, setCreateSupportType,
    handleCreateChatRoom,
    RecievedPreviousChat,
    ConvertDate } = useContext(ChatIDContext);
  const [callTimeSeconds, setCallTimeSeconds] = useState(0)
  const [tokensToUse, setTokensToUse] = useState(0)
  const [hoursDifference, setHoursDifference] = useState(0)
  const { t } = useTranslation();

  const calculateCallTime = (selectedAmount, multiplier) => {
    const totalSeconds = selectedAmount * 60
    const totalTokens = totalSeconds / 1800 * multiplier
    setCallTimeSeconds(totalSeconds)
    setTokensToUse(totalTokens)
  }

  const checkDate = (date) => {
    const hourCkeck = 60 * 60 * 1000
    const currTime = new Date()
    const selectedTime = new Date(date)

    const timeDiff = Math.abs(selectedTime.getTime() - currTime.getTime())
    const hoursDiff = timeDiff / hourCkeck

    setHoursDifference(hoursDiff)
  }
  useEffect(() => {
    (async () => {
      await RecievedPreviousChat()
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const selectedMinutes = document.getElementById('CallTime')
    if (selectedMinutes) {
      if (hoursDifference > 0 && hoursDifference < 48) {
        calculateCallTime(selectedMinutes.value, 6)
      } else {
        calculateCallTime(selectedMinutes.value, 1)
      }
    }
  }, [callTimeSeconds, hoursDifference])

  return (
    <Body>
      <Header />
      <Caller />
      <NavBar />
      <MainCard id="MainCard">
        <HelloTextBold>{t("CreateChat.RequestLiveSupport")}</HelloTextBold>
        <Subtitle>{t("CreateChat.FillIssueInformationsBelow")}</Subtitle>
        <center>
          <WhiteCard>
            <div className="Field">
              <div className="FieldText">{t("CreateChat.Subject")} </div>
              <input
                type="text"
                id="IssueName"
                placeholder={t("CreateChat.Name")}
              ></input>
            </div>
            <div className="Field">
              <div className="FieldText">{t("CreateChat.IssueType")} </div>
              <select name="Issue Type" id="IssueType">
                <option value="Connection Inspection">
                  {t("CreateChat.ConnectionInspection")}
                </option>
                <option value="Pipe Connection">
                  {" "}
                  {t("CreateChat.PipeConnection")}{" "}
                </option>
                <option value="Make-up Graph Review">
                  {t("CreateChat.MakeupGraphReview")}
                </option>
                <option value="Assembly Make-up Troubleshoting">
                  {t("CreateChat.AssemblyMakeupTroubleshoting")}
                </option>
                <option value="Rig Troubleshooting Support">
                  {t("CreateChat.RigTroubleshootingSupport")}
                </option>
                <option value="Other Request">
                  {" "}
                  {t("CreateChat.OtherRequests")}
                </option>
              </select>
            </div>
            <div className="Field">
              <div className="FieldText">{t("CreateChat.SupportType")} </div>
              <select name="Support Type" id="SupportType" value={createsupportType} onChange={(e) => { setCreateSupportType(e.target.value) }}>
                <option value="Chat">{t("CreateChat.Chat")}</option>
                <option value="VideoCall"> {t("CreateChat.VideoCall")} </option>
              </select>
            </div>
            {createsupportType === 'VideoCall' ?
              <div className="Field">
                <div className="FieldText">{t("CreateChat.Duration") + " (m)"} </div>
                <select name="CallTime" id="CallTime" onChange={(e) => { calculateCallTime(e.target.value, 1) }}>
                  {ALLOWED_TIMES.map((time) => (
                    <option value={time}>{`${time}`}</option>
                  ))}
                </select>
                <div className="FieldText">{`${t("CreateChat.TokensToUse")}:`} </div>
                <input
                  type="number"
                  id="Duration"
                  value={tokensToUse}
                  disabled
                ></input>
                <div className="FieldText">{t("CreateChat.IssueDueDate")}</div>
                <input
                  type="datetime-local"
                  id="IssueDate"
                  placeholder={t("CreateChat.Type")}
                  onChange={(e) => checkDate(e.target.value)}
                  defaultValue={ConvertDate(document.getElementById("IssueDate"))}
                ></input>
              </div>
              : null}

            <messagefield
              className="Field"
              id={"MessageField"}
            >
              {" "}
              <div className="FieldText">{t("CreateChat.Message")} </div>
            </messagefield>
            <textarea
              rows="18"
              id={"Message"}
              placeholder={t("CreateChat.Typeherecommentsandquestions")}
              style={{ display: "block" }}
            ></textarea>
            <ButtonsDiv style={{ bottom: isSmallScreen ? "15%" : "13%" }}>
              <button onClick={() => handleCreateChatRoom()}>
                {t("CreateChat.Submit")}
              </button>
            </ButtonsDiv>
          </WhiteCard>
        </center>
      </MainCard>
      <Footer />
    </Body>
  );
};

export default CreateChat;
