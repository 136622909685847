import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AiFillCloseCircle } from "react-icons/ai";
import styled from "styled-components";
import CricleCheck from "../../assets/images/CircleCheck.png";
import {
  Body,
  HelloTextBold,
  MainCard,
} from "../../components/common/MainCard";
import * as transaction from "../../components/connect/transaction";
import Caller from "../../components/layout/caller";
import Footer from "../../components/layout/footer";
import Header from "../../components/layout/header";
import LoadingScreen from "../../components/layout/LoadingScreen";
import NavBar from "../../components/layout/navBar";
import { AccountManagementContext } from "../../stores/AccountManagementStore";

const SideBySideOptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;

  button {
    background-color: #009ee0;
    border: 5px;
    border-radius: 10px;
    color: #fff;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: bold;
    box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    :hover {
      transform: translateY(-7px);
    }
    :focus {
      outline: none;
    }
  }
`;

const SchearchBar = styled.div`
  input {
    width: 50%;
    height: 20px;
    margin-left: 2%;
    padding: 8px;
    border: 0;
    border-radius: 10px;
    box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);
    :focus {
      outline: none;
    }
  }
`;

const TableDiv = styled.div`
  width: 98%;
  margin: 20px;
  border-radius: 10px;
  box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);
  background-color: #fff;

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  .bordered {
    font-size: 14px;
    text-align: left;
    padding: 20px;
    border-left: 1px solid #dadada;
  }

  td {
    padding: 15px;
  }

  tr.border_bottom {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #dadada;
    border-top-style: none;
    border-left-style: none;
    border-right-style: none;
  }

  .BlackLetter {
    color: black;
  }

  .BlueLetter {
    color: #009ee0;
  }

  .SortRight {
    display: flex;
    float: right;
  }

  .ContactButton {
    background-color: #fff;
    border: 5px;
    border-radius: 10px;
    color: #009ee0;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: bold;
    margin: 10px;
    box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    :hover {
      transform: translateY(-7px);
    }
    :focus {
      outline: none;
    }
  }

  .RechargeButton {
    background-color: #009ee0;
    border: 5px;
    border-radius: 10px;
    color: #fff;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: bold;
    margin: 10px;
    box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    :hover {
      transform: translateY(-7px);
    }
    :focus {
      outline: none;
    }
  }
`;

const GrayOverlay = styled.div`
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
`;

const PopUpBeforeProceding = styled.div`
  position: absolute;
  left: 22.5%;
  top: 20%;
  border-style: outset;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 50px;
  margin-left: 20px;
  color: black;
  background-color: #f8f9fa;
  border-radius: 10px;
  text-align: left;
  width: 52vw;
  z-index: 1;

  .Title {
    display: flex;
    font-size: 24px;
    margin-bottom: 20px;
  }

  .TitleCenter {
    display: flex;
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 40px;
    justify-content: center;
  }

  img {
    margin-bottom: 50px;
  }

  .boldText {
    margin-left: 10px;
    font-weight: bold;
  }

  .inverseBoldText {
    margin-right: 10px;
    font-weight: bold;
  }

  .SubTitle {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .closeButton {
    position: absolute;
    color: #009ee0;
    right: 5px;
    top: 0px;
    cursor: pointer;
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
  }

  .cancelButton {
    color: #009ee0;
    border: 0px;
    padding: 15px;
    padding-left: 35px;
    padding-right: 35px;
    border-radius: 10px;
    margin-right: 5px;
    margin-top: 20px;
    background-color: white;
    cursor: pointer;
    font-weight: bold;
    box-shadow: 0px 5px 10px rgba(167, 164, 206, 0.4);
    :hover {
      background-color: #fff;
      box-shadow: 0px 15px 20px rgba(167, 164, 206, 0.4);
      color: #009ee0;
    }
  }

  .rechargeButton {
    color: white;
    border: 0px;
    padding: 15px;
    padding-left: 35px;
    padding-right: 35px;
    border-radius: 10px;
    margin-left: 5px;
    margin-top: 20px;
    background-color: #009ee0;
    cursor: pointer;
    font-weight: bold;
    box-shadow: 0px 5px 10px rgba(167, 164, 206, 0.4);
    :hover {
      background-color: #fff;
      box-shadow: 0px 15px 20px rgba(167, 164, 206, 0.4);
      color: #009ee0;
    }
  }

  .WhiteBox {
    background-color: white;
    width: 50vw;
    display: flex;
    justify-content: center;
    padding: 10px;

    .marginTop {
      margin-top: 10px;
    }

    select {
      margin-left: 10px;
    }

    .NumberOfRechargeButtonStyle {
      background-color: white;
      color: black;
      border: 0px;
      min-width: 50px;
      min-height: 50px;
      font-size: calc(8px + 0.8vw);
      cursor: pointer;
      outline: none;
      vertical-align: center;
      transition: all 0.3s ease 0s;
      border-radius: 10px;
      box-shadow: 0px 5px 10px rgba(167, 164, 206, 0.4);
      :hover {
        background-color: #fff;
        box-shadow: 0px 15px 20px rgba(167, 164, 206, 0.4);
        color: #009ee0;
      }
    }

    .List {
      display: flex;
      flex-direction: row;
    }

    .blueBox {
      color: #009ee0;
      border: 1px solid #009ee0;
      padding: 10px;
      padding-left: 15px;
      padding-right: 15px;
      border-radius: 10px;
      margin: 10px;
      background-color: white;
    }

    .blueBoxButton {
      color: #009ee0;
      border: 1px solid #009ee0;
      padding: 10px;
      padding-left: 15px;
      padding-right: 15px;
      border-radius: 10px;
      margin: 10px;
      margin-left: 0px;
      background-color: white;
      cursor: pointer;
      :hover {
        background-color: #fff;
        box-shadow: 0px 15px 20px rgba(167, 164, 206, 0.4);
        color: #009ee0;
      }
    }

    input + .NumberOfRechargeButtonStyle {
      border-radius: 10px;
    }

    input {
      min-width: 50px;
      min-height: 50px;
      background-color: white;
      margin: 10px;
      font-size: 24px;
      border: 2px inset lightgray;
      text-align: center;
      vertical-align: center;
      outline: none;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

const SideBySideDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
`;

const SideBySideOnTheLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;

  .firstDiv {
    width: 40%;
    padding: 20px;
  }

  .secondDiv {
    width: 60%;
    padding: 20px;
    text-align: start;
    color: #666666;
  }
`;

const AccountRechargeManagement = () => {
  document.title = "VAM® Field Service";

  const { t } = useTranslation();
  const {
    gotSavedData,
    showRecharge,
    showFinishedRecharge,
    schearchText,
    rechargeCompanyName,
    rechargeCompanyCurrency, setRechargeCompanyCurrency,
    rechargeCompanyValue,
    rechargeRequest,
    recharge,
    handleGetInitialData,
    ConvertTimezone,
    handleSchearchTerm,
    handleRecharge,
    handleCancel,
    handleCancelNotify,
    handleNotify,
    handleStartRecharge,
    handleIncreaseRecharge,
    SaveNumberRecharge,
    handleDecreaseRecharge,
    PutRechargeValue
  } = useContext(AccountManagementContext)



  return (
    <Body>
      {showRecharge && (
        <GrayOverlay id="GrayOverlay">
          {!showFinishedRecharge ? (
            <PopUpBeforeProceding>
              <button className="closeButton" onClick={handleCancel}>
                <AiFillCloseCircle size="30px" />
              </button>
              <div className="Title">
                {t("AccountRechargeManagement.AccountRechargeManagement")}{" "}
                {t("AccountRechargeManagement.RechargingProcessfor")}{" "}
                <div className="boldText">{rechargeCompanyName}</div>
              </div>
              <div className="SubTitle">
                {t(
                  "AccountRechargeManagement.Pleasechoosetheamounttotoberecharged"
                )}{" "}
              </div>

              <div className="WhiteBox">
                <center>
                  <div className="boldText">{rechargeCompanyCurrency}</div>
                  <div className="marginTop">
                    <label for="Currency">
                      {" "}
                      {t("AccountRechargeManagement.ChooseaCurrency")}{" "}
                    </label>

                    <select
                      onChange={(event) => {
                        setRechargeCompanyCurrency(event.target.value);
                      }}
                    >
                      <option value="none">
                        {" "}
                        {t("AccountRechargeManagement.none")}{" "}
                      </option>
                      <option value="USD">USD</option>
                      <option value="BRL">BRL</option>
                      <option value="FF">FF</option>
                    </select>
                  </div>
                  <SideBySideDiv>
                    <button
                      className="NumberOfRechargeButtonStyle"
                      onClick={handleDecreaseRecharge}
                    >
                      -
                    </button>
                    <input
                      type="number"
                      defaultValue={recharge}
                      onChange={SaveNumberRecharge}
                      id="GraphRecharge"
                    />
                    <button
                      className="NumberOfRechargeButtonStyle"
                      onClick={handleIncreaseRecharge}
                    >
                      +
                    </button>
                  </SideBySideDiv>
                  <SideBySideOnTheLeft>
                    <div className="firstDiv">
                      <div className="boldText">
                        {" "}
                        {t(
                          "AccountRechargeManagement.Lastamountrecharged"
                        )}{" "}
                      </div>
                      <div className="List">
                        {rechargeCompanyValue !== undefined ? (
                          <div className="blueBox"> {rechargeCompanyValue}</div>
                        ) : (
                          <div className="blueBox"> 0 </div>
                        )}
                      </div>
                    </div>
                    <div className="secondDiv">
                      <div>
                        {t("AccountRechargeManagement.Suggestedamounts")}
                      </div>
                      <div className="List">
                        <button
                          className="blueBoxButton"
                          onClick={() => PutRechargeValue(500)}
                        >
                          {" "}
                          500
                        </button>
                        <button
                          className="blueBoxButton"
                          onClick={() => PutRechargeValue(1500)}
                        >
                          {" "}
                          1500
                        </button>
                        <button
                          className="blueBoxButton"
                          onClick={() => PutRechargeValue(2000)}
                        >
                          {" "}
                          2000
                        </button>
                        <button
                          className="blueBoxButton"
                          onClick={() => PutRechargeValue(5000)}
                        >
                          {" "}
                          5000
                        </button>
                      </div>
                    </div>
                  </SideBySideOnTheLeft>
                </center>
              </div>
              <SideBySideDiv>
                <button className="cancelButton" onClick={handleCancel}>
                  {t("AccountRechargeManagement.Cancel")}
                </button>
                <button
                  className="rechargeButton"
                  onClick={handleStartRecharge}
                >
                  {t("AccountRechargeManagement.Recharge")}
                </button>
              </SideBySideDiv>
            </PopUpBeforeProceding>
          ) : (
            <PopUpBeforeProceding>
              <button className="closeButton" onClick={handleCancelNotify}>
                <AiFillCloseCircle size="30px" />
              </button>{" "}
              <center>
                <div className="TitleCenter">
                  <div className="inverseBoldText">{rechargeCompanyName}</div>
                  {t("AccountRechargeManagement.accounthasbeenrecharged")}
                </div>{" "}
                <img src={CricleCheck} alt="Created Make-up Graph" /> <div />
                <button className="rechargeButton" onClick={handleNotify}>
                  {t("AccountRechargeManagement.Notify")}
                </button>
              </center>
            </PopUpBeforeProceding>
          )}
        </GrayOverlay>
      )}
      {handleGetInitialData()}
      <Header />
      <Caller />
      <span style={{ display: !gotSavedData ? "block" : "none" }}>
        <LoadingScreen />
      </span>
      <NavBar />
      <MainCard
        id="MainCard"
        style={{ display: gotSavedData ? "block" : "none" }}
      >
        <SideBySideOptions>
          <HelloTextBold>
            {t("AccountRechargeManagement.AccountRechargeManagement")}{" "}
          </HelloTextBold>{" "}
          <button>{t("AccountRechargeManagement.AddNewCompany")}</button>
        </SideBySideOptions>
        <SchearchBar>
          <input
            type="text"
            id="scearch"
            placeholder={t("Messages.Scearch")}
            onChange={handleSchearchTerm}
            defaultValue={schearchText}
            autoFocus
          ></input>
        </SchearchBar>
        <TableDiv>
          <table>
            <thead>
              <tr>
                <th className="bordered">
                  {" "}
                  {t("AccountRechargeManagement.RequestDate")}
                </th>
                <th className="bordered">
                  {" "}
                  {t("AccountRechargeManagement.Company")}
                </th>
                <th className="bordered">
                  {" "}
                  {t("AccountRechargeManagement.ActiveAccounts")}
                </th>
                <th className="bordered">
                  {" "}
                  {t("AccountRechargeManagement.LastRechargeAmount")}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {rechargeRequest.map((recharge) => (
                <tr key={recharge.TransactionID} className="border_bottom">
                  <td className="BlackLetter">
                    {ConvertTimezone(recharge.CreationDate)}
                  </td>
                  <td className="BlueLetter">{recharge.BuyerName}</td>
                  <td className="BlackLetter">{recharge.ActiveAccount}</td>
                  <td className="BlackLetter">
                    {transaction.ReturnLastTransaction(recharge.TransactionID) !== "none" ? transaction.ReturnLastTransaction(recharge.TransactionID) : t("AccountRechargeManagement.none")}
                  </td>
                  <td className="SortRight">
                    <button className="ContactButton">
                      {" "}
                      {t("AccountRechargeManagement.Contact")}{" "}
                    </button>{" "}
                    <button
                      className="RechargeButton"
                      onClick={() =>
                        handleRecharge(
                          recharge.TransactionID,
                          recharge.BuyerName,
                          transaction
                            .ReturnLastTransaction(recharge.TransactionID)
                            .split(" ")[0],
                          transaction
                            .ReturnLastTransaction(recharge.TransactionID)
                            .split(" ")[1]
                        )
                      }
                    >
                      {" "}
                      {t("AccountRechargeManagement.Recharge")}{" "}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableDiv>
      </MainCard>
      <Footer />
    </Body>
  );
};

export default AccountRechargeManagement;
