import React from "react";
import { FaHardHat } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Button } from "../../components/common";
import {
  Body,
  HelloTextBold,
  MainCard
} from "../../components/common/MainCard";
import Footer from "../../components/layout/footer";
import Header from "../../components/layout/header";
import NavBar from "../../components/layout/navBar";
import { useTranslation } from "react-i18next";
import Caller from "../../components/layout/caller";


const UnderConstruction = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const ButtonDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    padding-top:20px;
    button {
      min-height: 50px;
      min-width: 200px;
      background-color: white;
      color: #039fe0;
      border: 2px solid #e7e7e7;
      transition: all 0.3s ease 0s;
      :hover {
        background-color: #009ee0;
        box-shadow: 0px 15px 20px rgba(167, 164, 206, 0.4);
        color: #fff;
        transform: translateX(7px);
      }
    }
  `;

  const handleReturn = () => {
    history.goBack();
  };

  return (
    <Body>
      <Header />
      <Caller />
      <NavBar />
      <MainCard>
        <HelloTextBold>{t("UnderConstruction.UnderConstruction")}</HelloTextBold>
        <center>
          {" "}
          <FaHardHat size="60px" style={{color:"#009ee0"}}/> <div />
          {t("UnderConstruction.UnderConstructionMessage")}
        </center>
        <ButtonDiv>
          <Button onClick={handleReturn}>{t("UnderConstruction.Return")}</Button>{" "}
        </ButtonDiv>
      </MainCard>
      <Footer />
    </Body>
  );
};

export default UnderConstruction;
