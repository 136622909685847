import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import CreateChatIcon from "../../assets/icons/Chat/CreateChat.png";
import MessageIcon from "../../assets/icons/Chat/MessageIcon.png";
import {
  Body,
  HelloTextBold,
  MainCard,
} from "../../components/common/MainCard";
import Footer from "../../components/layout/footer";
import Header from "../../components/layout/header";
import LoadingScreen from "../../components/layout/LoadingScreen";
import NavBar from "../../components/layout/navBar";
import { UserContext } from "../../stores/UserStore";
import { ArticlesContext } from "../../stores/ArticlesStore";
import { ChatIDContext } from "../../stores/ChatIDStore";

const Articles = () => {
  document.title = "VAM® Field Service";

  const history = useHistory();
  const { currentUser } = useContext(UserContext);

  const { setChatID } = useContext(ChatIDContext);
  const {
    savedArticles,
    gotSavedData,
    schearchText,
    handleGetAllArticles,
    handleListChats,
    handleSchearchTerm,
    ConvertTimezone
  } = useContext(ArticlesContext)

  const handleGetArticle = (ID) => {
    setChatID(ID);
    history.push("/ArticleViewer");
  };

  const handleCreateArticles = () => {
    history.push("/CreateArticles");
  };


  const SideBySideOptions = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    input {
      width: 95%;
      height: 20px;
      margin-left: 10px;
      padding: 8px;
      border: 0;
      border-radius: 10px;
      box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);
      :focus {
        outline: none;
      }
    }
    button {
      padding-top: 5px;
      background-color: #009ee0;
      border: 0;
      color: #fff;
      margin-left: 10px;
      box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);
      transition: all 0.3s ease 0s;
      cursor: pointer;
      :hover {
        transform: translateY(-7px);
      }
      :focus {
        outline: none;
      }
    }
  `;

  const SavedDiv = styled.div`
    width: 98%;
    min-width: 450px;
    display: flex;
    flex-direction: column;
    color: #009ee0;
    background-color: white;
    border-radius: 0 10px 10px 0;
    font-size: calc(12px + 0.5vw);
    vertical-align: center;
    margin-bottom: 10px;
    transition: all 0.3s ease 0s;
    :hover {
      transform: translateX(3px);
    }
    .GrayText {
      color: #666666;
      font-size: calc(8px + 0.3vw);
    }
    .left {
      width: 90%;
      margin-left: 0;
      display: flex;
      align-items: center;
    }
    .information {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 10px;
      text-align: left;
    }
    .statusPending {
      width: 5%;
      font-size: 14px;
      color: white;
      background-color: #009ee0;
      border-radius: 0 10px 10px 0;
      margin-top: 10px;
      padding: 5px;
      padding-left: 2%;
    }
    .statusProcessed {
      width: 5%;
      font-size: 14px;
      color: white;
      background-color: #666666;
      border-radius: 0 10px 10px 0;
      margin-top: 10px;
      padding: 5px;
      padding-left: 2%;
    }
    .right {
      color: #666666;
      font-size: calc(8px + 0.3vw);
      display: flex;
      flex-direction: column;
      width: 6%;
      align-items: flex-end;
      justify-content: center;
    }
    .unread {
      color: white;
      display: flex;
      width: 4%;
      justify-content: center;
      align-items: center;
    }
    button {
      background-color: Transparent;
      background-repeat: no-repeat;
      border: none;
      cursor: pointer;
      overflow: hidden;
    }
    font {
      font-size: 16px;
      color: black;
    }
    img {
      margin-right: 10px;
    }
    span {
      background-color: #8d1677;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      display: inline-block;
      text-align: center;
      font-size: 16px;
    }
  `;

  useEffect(() => {
    (async () => {
      await handleGetAllArticles(currentUser.id)
      await handleListChats()
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Body>
      {/* {handleGetAllArticles(currentUser.id)}
      {handleListChats()} */}
      <Header />
      <NavBar />
      {!gotSavedData ? (
        <LoadingScreen />
      ) : (
        <MainCard id="MainCard">
          <HelloTextBold>Articles</HelloTextBold>
          <SideBySideOptions>
            {" "}
            <input
              type="text"
              id="scearch"
              placeholder="Scearch ..."
              onChange={handleSchearchTerm}
              defaultValue={schearchText}
              autoFocus
            ></input>
            {currentUser.roles.ADMIN_ACCESS && (
              <button onClick={() => handleCreateArticles()}>
                <img src={CreateChatIcon} alt="Create Article" />
              </button>
            )}
          </SideBySideOptions>

          {savedArticles.map((SavedArticles) => (
            <div key={SavedArticles.ArticleID}>
              <SavedDiv>
                <button
                  onClick={() => handleGetArticle(SavedArticles.ArticleID)}
                >
                  <div
                    className={
                      SavedArticles.Status === "Active"
                        ? "statusPending"
                        : "statusProcessed"
                    }
                  >
                    {SavedArticles.Status}
                  </div>
                  <div className="information">
                    <div className="left">
                      <img src={MessageIcon} alt="Message Icon" />
                      <div>
                        <font> {SavedArticles.ArticleTopic}</font>
                        <div className="GrayText">
                          {" "}
                          By: {SavedArticles.AgentName}
                        </div>{" "}
                        <div className="GrayText">
                          {" "}
                          Score: {SavedArticles.Score}
                        </div>{" "}
                      </div>
                    </div>
                    <div className="right">
                      {" "}
                      {ConvertTimezone(SavedArticles.LastAltered)}
                    </div>
                  </div>
                </button>
              </SavedDiv>
            </div>
          ))}
        </MainCard>
      )}
      <Footer />
    </Body>
  );
};

export default Articles;
