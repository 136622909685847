import axios from "axios";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import _ from "lodash";

const EndpointListChatRooms = process.env.REACT_APP_API_URL + "/listchatroom";

const EndpointGetChatTranscript = process.env.REACT_APP_API_URL + "/gettranscriptfromchatroom";

const EndpointListChatRoomsUnread = process.env.REACT_APP_API_URL + "/listunreadmessages";

const EndpointCreateChat = process.env.REACT_APP_API_URL + "/createchatroom";

const EndpointSendMessage = process.env.REACT_APP_API_URL + "/sendmessagetochatroom";

const EndpointMarkAsRead = process.env.REACT_APP_API_URL + "/markchatasread";

const EndpointUpdateChatRoom = process.env.REACT_APP_API_URL + "/updatechatroom";

const EndpointPickNewRequest = process.env.REACT_APP_API_URL + "/picknewrequest";

const EndpointGetCustomerDetail = process.env.REACT_APP_AUTH_URL + '/application/users';

var LocalListChats = [];
var LocalListUnread = [];
var LocalTranscript = [];
var NewChats = [];
var verbose = false;

const header = {
  'vlr-authorization': 'eyJpZCI6ICJWQU1fQVBQIn0=',
  'authorization': Cookies.get("_ssoToken")
}

export async function ListChatRooms(userID) {
  const data = {
    BuyerID: "" + userID,
  };
  axios
    .post(EndpointListChatRooms, data, { headers: header })
    .then((response) => {
      LocalListChats = response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function ListChatRoomsByAgent(userID) {
  const data = {
    AgentID: "" + userID,
  };

  if (userID === "undef") {
    await axios
      .post(EndpointListChatRooms, data, { headers: header })
      .then((response) => {
        NewChats = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  } else {
    await axios
      .post(EndpointListChatRooms, data, { headers: header })
      .then((response) => {
        LocalListChats = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return true;
}

export async function ListChatRoomsByBuyerID(userID) {
  const data = {
    BuyerID: "" + userID,
  };
  await axios
    .post(EndpointListChatRooms, data, { headers: header })
    .then((response) => {
      LocalListChats = response.data;
    })
    .catch((error) => {
      console.log(error);
    });
  return true;
}

export async function ListChatRoomsAgent(AgentKey, ChatStatus) {
  const data = {
    AgentID: "" + AgentKey,
    SupportStatus: "" + ChatStatus,
  };
  await axios
    .post(EndpointListChatRooms, data, { headers: header })
    .then((response) => {
      LocalListChats = response.data;
    })
    .catch((error) => {
      console.log(error);
    });
  return true;
}

export async function ListChatRoomsAgentByDate(
  AgentKey,
  InitialDate,
  FinishDate
) {
  const data = {
    AgentID: "" + AgentKey,
    InitialDate: "" + InitialDate,
    FinishDate: "" + FinishDate,
  };
  await axios
    .post(EndpointListChatRooms, data, { headers: header })
    .then((response) => {
      LocalListChats = response.data;
    })
    .catch((error) => {
      console.log(error);
    });
  return true;
}

export async function ListChatRoomsByChatID(ChatID) {
  const data = {
    RequestID: "" + ChatID,
  };
  await axios
    .post(EndpointListChatRooms, data, { headers: header })
    .then((response) => {
      LocalListChats = response.data;
    })
    .catch((error) => {
      console.log(error);
    });
  return true;
}

export async function ListAgents(id) {
  const data = {
    AgentID: "" + id,
  };
  return axios
    .post(EndpointListChatRooms, data, { headers: header })
    .then((response) => {
      if (verbose) {
        console.log(LocalListChats);
      }
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function MarkUnreadMessages(ChatID, MensageSeer) {
  const data = {
    RequestID: "" + ChatID,
    MessageSeer: "" + MensageSeer,
  };
  axios
    .post(EndpointMarkAsRead, data, { headers: header })
    .then((response) => {
      if (verbose) {
        console.log(response);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function ListUnreadMessages(userID) {
  const data = {
    BuyerID: "" + userID,
  };
  await axios
    .post(EndpointListChatRoomsUnread, data, { headers: header })
    .then((response) => {
      LocalListUnread = response.data;
      if (verbose) {
        console.log(response);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export function ReturnNewChats() {
  return NewChats;
}

export function ReturnChats(supportType) {
  var SavedChats = [];
  LocalListChats.forEach((element) => {
    if (element.SupportType === supportType) {
      SavedChats.push(element);
    }
  });

  SavedChats.sort((a, b) => (a.LastAltered > b.LastAltered ? -1 : 1));
  return SavedChats;
}

export function ReturnChatsPending(supportType) {
  var SavedChats = [];
  LocalListChats.forEach((element) => {
    if (
      (element.SupportStatus === "Pending" || element.SupportStatus === "In progress" || element.SupportStatus === "In Progress" || element.SupportStatus === "InProgress") &&
      element.SupportType === supportType
    ) {
      SavedChats.push(element);
    }
  });
  SavedChats.sort((a, b) => (a.LastAltered > b.LastAltered ? -1 : 1));
  return SavedChats;
}

export function ReturnChatsProcessed(supportType) {
  var SavedChats = [];
  LocalListChats.forEach((element) => {
    if (
      (element.SupportStatus === "Completed" || element.SupportStatus === "Achieved") &&
      element.SupportType === supportType
    ) {
      SavedChats.push(element);
    }
  });

  SavedChats.sort((a, b) => (a.LastAltered > b.LastAltered ? -1 : 1));

  return SavedChats;
}

export function ReturnChatsPerSchedule() {
  var SavedChats = [];
  LocalListChats.forEach((element) => {
    SavedChats.push(element);
  });

  SavedChats.sort((a, b) => (a.ScheduledTo > b.ScheduledTo ? 1 : -1));

  return SavedChats;
}

export function ReturnChatsOrderByCreationDate(supportType) {
  var SavedChats = [];
  LocalListChats.forEach((element) => {
    if (element.SupportType === supportType) {
      SavedChats.push(element);
    }
  });

  SavedChats.sort((a, b) => (a.CreationDate > b.CreationDate ? 1 : -1));

  return SavedChats;
}

export function ReturnChatsUnOrdered(supportType) {
  var SavedChats = [];
  LocalListChats.forEach((element) => {
    if (element.SupportType === supportType || supportType === "both") {
      SavedChats.push(element);
    }
  });

  return SavedChats;
}

export function EmptyChat() {
  LocalListChats = [];
}


export function teste() {
  return LocalListChats;
}

export function EmptyTranscript() {
  LocalTranscript = [];
}

export function ReturnChatsFiltered(Filtered, supportType) {
  var SavedChats = [];
  Filtered = Filtered.toLowerCase();
  LocalListChats.forEach((element) => {
    if (
      (element.Title.toLowerCase().includes(Filtered) ||
        element.SupportSubject.toLowerCase().includes(Filtered)) &&
      element.supportType === supportType
    ) {
      SavedChats.push(element);
    }
  });

  SavedChats.sort((a, b) => (a.LastAltered > b.LastAltered ? -1 : 1));

  return SavedChats;
}

export function ReturnUnreadMessages() {
  return LocalListUnread;
}

export function ReturnUnredMessagesFromChatRoom() {
  return _.map(LocalListChats, i => { return i.UnreadMessages });
}

export async function CreateChatRoom(
  BuyerID,
  IssueName,
  IssueType,
  SupportType,
  ScheduledTo,
  ScheduledTime,
  Message,
  Name
) {
  const chatData = {
    BuyerID: "" + BuyerID,
    Title: "" + IssueName,
    SupportSubject: "" + IssueType,
    SupportType: "" + SupportType,
    Description: "" + Message,
    BuyerInfo: { FirstName: Name }
  }

  const videoData = {
    BuyerID: "" + BuyerID,
    Title: "" + IssueName,
    SupportSubject: "" + IssueType,
    Description: "" + Message,
    ScheduledDate: "" + ScheduledTo,
    ScheduledTime: "" + ScheduledTime,
    BuyerInfo: { FirstName: Name },
    SupportType: "" + SupportType,
  }

  if (SupportType === "VideoCall") {
    axios
      .post(EndpointCreateChat, videoData, { headers: header })
      .then((response) => {
        toast.success("Your ticket has been created successfully. A VAM® technician will contact to you", { duration: 3000 } );
        if (verbose) {
          console.log(response);
        }
      })
      .catch((error) => {
        toast.error("Error Submiting", { duration: 3000 });
        console.log(error);
      });
  } else {
    axios
      .post(EndpointCreateChat, chatData, { headers: header })
      .then((response) => {
        toast.success("Your ticket has been created successfully. A VAM® technician will contact to you", { duration: 3000 });
        if (verbose) {
          console.log(response);
        }
      })
      .catch((error) => {
        toast.error("Error Submiting", { duration: 3000 });
        console.log(error);
      });
  }
}

export async function GetTranscript(ID) {
  const data = {
    RequestID: ID
  };
  await axios
    .post(EndpointGetChatTranscript, data, { headers: header })
    .then((response) => {
      LocalTranscript = response.data;
      if (verbose) {
        console.log(response);
      }
    })
    .catch((error) => {
      toast.error("Error Getting Transcript");
      console.log(error);
    });
}

export function AddMessageAfterUpdate(Message) {
  LocalTranscript.push(Message);
}

export function ReturnTranscript() {
  var Transcript = LocalTranscript.sort(function (a, b) {
    var dateA = new Date(a.CreatedAt),
      dateB = new Date(b.CreatedAt);
    return dateA - dateB;
  });
  return Transcript;
}

export async function SendMessageToChat(
  ChatID,
  MessageContent,
  MessageSender,
  attachment,
  action
) {
  const data = {
    RequestID: ChatID,
    MessageType: "Message",
    MessageContent: MessageContent,
    MessageSender: MessageSender,
    MessageAttachment: attachment,
    MessageAction: action,
  };
  await axios
    .post(EndpointSendMessage, data, { headers: header })
    .then((response) => {
      if (verbose) {
        console.log(response);
      }
    })
    .catch((error) => {
      toast.error("Error Sending Message");
      console.log(error);
    });
  return true;
}

export async function UpdateChatRooms(ChatID, Title, Description, ChatStatus, support) {
  const data = {
    RequestID: "" + ChatID,
    Title: "" + Title,
    Description: "" + Description,
    SupportStatus: "" + ChatStatus,
    SupportSubject: "" + support,
  };
  await axios
    .post(EndpointUpdateChatRoom, data, { headers: header })
    .then((response) => {
      toast.success("Updated Chat Status", { autoClose: 3000 });
      if (verbose) {
        console.log(response);
      }
    })
    .catch((error) => {
      toast.error("Error Updating Status Transcript");
      console.log(error);
    });
  return true;
}

export async function UpdateFullChatRooms(
  ChatID,
  IssueName,
  IssueType,
  SupportType,
  ScheduledTo,
  Name
) {
  const data = {
    RequestID: "" + ChatID,
    Title: "" + IssueName,
    SupportSubject: "" + IssueType,
    SupportType: "" + SupportType,
    ScheduledTo: "" + ScheduledTo,
    BuyerInfo: { FirstName: Name }
  };
  await axios
    .post(EndpointUpdateChatRoom, data, { headers: header })
    .then((response) => {
      toast.success("Updated Chat Status", { autoClose: 3000 });
      if (verbose) {
        console.log(response);
      }
    })
    .catch((error) => {
      toast.error("Error Updating Status Transcript");
      console.log(error);
    });
  return true;
}

export async function UpdateChatRoomOwnership(ChatID, AgentID, AgentName) {
  const data = {
    RequestID: "" + ChatID,
    AgentID: "" + AgentID,
    AgentName: "" + AgentName,
    ChatLog: "Agent " + AgentName + " took the case ownership",
  };
  await axios
    .post(EndpointUpdateChatRoom, data, { headers: header })
    .then((response) => {
      toast.success("Updated Chat Status", { autoClose: 3000 });
      if (verbose) {
        console.log(response);
      }
    })
    .catch((error) => {
      toast.error("Error Updating Status Transcript");
      console.log(error);
    });
  return true;
}

export async function PickNewRequests(AgentID, AgentName, RequestID) {
  const data = {
    AgentID: "" + AgentID,
    AgentName: "" + AgentName,
    RequestID: "" + RequestID,
  };
  return axios
    .post(EndpointPickNewRequest, data, { headers: header })
    .then((response) => {
      if (verbose) {
        console.log(response);
      }

      return response.data
    })
    .catch((error) => {
      toast.error("Error Recieving New Chats");
      console.log(error);
    });
}


export async function GetCustomerDetail(data) {
  return await axios
    .post(EndpointGetCustomerDetail, data, { headers: { 'vlr-authorization': 'eyJpZCI6IldBQU1fQVBQIn0=', 'X-Api-Key': process.env.REACT_APP_API_KEY } })
    .then((response) => {
      var resposta = response.data.body;
      return resposta;
    })
    .catch((error) => {
      console.log(error);
    });
}
