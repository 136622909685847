import Cookies from "js-cookie";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Manager, Popper, Reference } from "react-popper";
import styled from "styled-components";
import adFlag from "../../assets/icons/Flags/ad.png";
import aeFlag from "../../assets/icons/Flags/ae.png";
import afFlag from "../../assets/icons/Flags/af.png";
import agFlag from "../../assets/icons/Flags/ag.png";
import aiFlag from "../../assets/icons/Flags/ai.png";
import alFlag from "../../assets/icons/Flags/al.png";
import amFlag from "../../assets/icons/Flags/am.png";
import aoFlag from "../../assets/icons/Flags/ao.png";
import aqFlag from "../../assets/icons/Flags/aq.png";
import arFlag from "../../assets/icons/Flags/ar.png";
import asFlag from "../../assets/icons/Flags/as.png";
import atFlag from "../../assets/icons/Flags/at.png";
import auFlag from "../../assets/icons/Flags/au.png";
import awFlag from "../../assets/icons/Flags/aw.png";
import azFlag from "../../assets/icons/Flags/az.png";
import baFlag from "../../assets/icons/Flags/ba.png";
import bbFlag from "../../assets/icons/Flags/bb.png";
import bdFlag from "../../assets/icons/Flags/bd.png";
import beFlag from "../../assets/icons/Flags/be.png";
import bfFlag from "../../assets/icons/Flags/bf.png";
import bgFlag from "../../assets/icons/Flags/bg.png";
import bhFlag from "../../assets/icons/Flags/bh.png";
import biFlag from "../../assets/icons/Flags/bi.png";
import bjFlag from "../../assets/icons/Flags/bj.png";
import blFlag from "../../assets/icons/Flags/bl.png";
import bmFlag from "../../assets/icons/Flags/bm.png";
import bnFlag from "../../assets/icons/Flags/bn.png";
import boFlag from "../../assets/icons/Flags/bo.png";
import brFlag from "../../assets/icons/Flags/br.png";
import bsFlag from "../../assets/icons/Flags/bs.png";
import btFlag from "../../assets/icons/Flags/bt.png";
import bwFlag from "../../assets/icons/Flags/bw.png";
import byFlag from "../../assets/icons/Flags/by.png";
import bzFlag from "../../assets/icons/Flags/bz.png";
import caFlag from "../../assets/icons/Flags/ca.png";
import ccFlag from "../../assets/icons/Flags/cc.png";
import cdFlag from "../../assets/icons/Flags/cd.png";
import cfFlag from "../../assets/icons/Flags/cf.png";
import cgFlag from "../../assets/icons/Flags/cg.png";
import chFlag from "../../assets/icons/Flags/ch.png";
import ciFlag from "../../assets/icons/Flags/ci.png";
import ckFlag from "../../assets/icons/Flags/ck.png";
import clFlag from "../../assets/icons/Flags/cl.png";
import cmFlag from "../../assets/icons/Flags/cm.png";
import cnFlag from "../../assets/icons/Flags/cn.png";
import coFlag from "../../assets/icons/Flags/co.png";
import crFlag from "../../assets/icons/Flags/cr.png";
import cuFlag from "../../assets/icons/Flags/cu.png";
import cvFlag from "../../assets/icons/Flags/cv.png";
import cwFlag from "../../assets/icons/Flags/cw.png";
import cxFlag from "../../assets/icons/Flags/cx.png";
import cyFlag from "../../assets/icons/Flags/cy.png";
import czFlag from "../../assets/icons/Flags/cz.png";
import deFlag from "../../assets/icons/Flags/de.png";
import djFlag from "../../assets/icons/Flags/dj.png";
import dkFlag from "../../assets/icons/Flags/dk.png";
import dmFlag from "../../assets/icons/Flags/dm.png";
import doFlag from "../../assets/icons/Flags/do.png";
import dzFlag from "../../assets/icons/Flags/dz.png";
import ecFlag from "../../assets/icons/Flags/ec.png";
import eeFlag from "../../assets/icons/Flags/ee.png";
import egFlag from "../../assets/icons/Flags/eg.png";
import ehFlag from "../../assets/icons/Flags/eh.png";
import erFlag from "../../assets/icons/Flags/er.png";
import esFlag from "../../assets/icons/Flags/es.png";
import etFlag from "../../assets/icons/Flags/et.png";
import fiFlag from "../../assets/icons/Flags/fi.png";
import fjFlag from "../../assets/icons/Flags/fj.png";
import fkFlag from "../../assets/icons/Flags/fk.png";
import fmFlag from "../../assets/icons/Flags/fm.png";
import foFlag from "../../assets/icons/Flags/fo.png";
import frFlag from "../../assets/icons/Flags/fr.png";
import gaFlag from "../../assets/icons/Flags/ga.png";
import gbFlag from "../../assets/icons/Flags/gb.png";
import gdFlag from "../../assets/icons/Flags/gd.png";
import geFlag from "../../assets/icons/Flags/ge.png";
import ggFlag from "../../assets/icons/Flags/gg.png";
import ghFlag from "../../assets/icons/Flags/gh.png";
import giFlag from "../../assets/icons/Flags/gi.png";
import glFlag from "../../assets/icons/Flags/gl.png";
import gmFlag from "../../assets/icons/Flags/gm.png";
import gnFlag from "../../assets/icons/Flags/gn.png";
import gqFlag from "../../assets/icons/Flags/gq.png";
import grFlag from "../../assets/icons/Flags/gr.png";
import gtFlag from "../../assets/icons/Flags/gt.png";
import guFlag from "../../assets/icons/Flags/gu.png";
import gwFlag from "../../assets/icons/Flags/gw.png";
import gyFlag from "../../assets/icons/Flags/gy.png";
import hkFlag from "../../assets/icons/Flags/hk.png";
import hnFlag from "../../assets/icons/Flags/hn.png";
import hrFlag from "../../assets/icons/Flags/hr.png";
import htFlag from "../../assets/icons/Flags/ht.png";
import huFlag from "../../assets/icons/Flags/hu.png";
import idFlag from "../../assets/icons/Flags/id.png";
import ieFlag from "../../assets/icons/Flags/ie.png";
import ilFlag from "../../assets/icons/Flags/il.png";
import imFlag from "../../assets/icons/Flags/im.png";
import inFlag from "../../assets/icons/Flags/in.png";
import ioFlag from "../../assets/icons/Flags/io.png";
import iqFlag from "../../assets/icons/Flags/iq.png";
import irFlag from "../../assets/icons/Flags/ir.png";
import isFlag from "../../assets/icons/Flags/is.png";
import itFlag from "../../assets/icons/Flags/it.png";
import jeFlag from "../../assets/icons/Flags/je.png";
import jmFlag from "../../assets/icons/Flags/jm.png";
import joFlag from "../../assets/icons/Flags/jo.png";
import jpFlag from "../../assets/icons/Flags/jp.png";
import keFlag from "../../assets/icons/Flags/ke.png";
import kgFlag from "../../assets/icons/Flags/kg.png";
import khFlag from "../../assets/icons/Flags/kh.png";
import kiFlag from "../../assets/icons/Flags/ki.png";
import kmFlag from "../../assets/icons/Flags/km.png";
import knFlag from "../../assets/icons/Flags/kn.png";
import kpFlag from "../../assets/icons/Flags/kp.png";
import krFlag from "../../assets/icons/Flags/kr.png";
import kwFlag from "../../assets/icons/Flags/kw.png";
import kyFlag from "../../assets/icons/Flags/ky.png";
import kzFlag from "../../assets/icons/Flags/kz.png";
import laFlag from "../../assets/icons/Flags/la.png";
import lbFlag from "../../assets/icons/Flags/lb.png";
import lcFlag from "../../assets/icons/Flags/lc.png";
import liFlag from "../../assets/icons/Flags/li.png";
import lkFlag from "../../assets/icons/Flags/lk.png";
import lrFlag from "../../assets/icons/Flags/lr.png";
import lsFlag from "../../assets/icons/Flags/ls.png";
import ltFlag from "../../assets/icons/Flags/lt.png";
import luFlag from "../../assets/icons/Flags/lu.png";
import lvFlag from "../../assets/icons/Flags/lv.png";
import lyFlag from "../../assets/icons/Flags/ly.png";
import maFlag from "../../assets/icons/Flags/ma.png";
import mcFlag from "../../assets/icons/Flags/mc.png";
import mdFlag from "../../assets/icons/Flags/md.png";
import meFlag from "../../assets/icons/Flags/me.png";
import mfFlag from "../../assets/icons/Flags/mf.png";
import mgFlag from "../../assets/icons/Flags/mg.png";
import mhFlag from "../../assets/icons/Flags/mh.png";
import mkFlag from "../../assets/icons/Flags/mk.png";
import mlFlag from "../../assets/icons/Flags/ml.png";
import mmFlag from "../../assets/icons/Flags/mm.png";
import mnFlag from "../../assets/icons/Flags/mn.png";
import moFlag from "../../assets/icons/Flags/mo.png";
import mpFlag from "../../assets/icons/Flags/mp.png";
import mrFlag from "../../assets/icons/Flags/mr.png";
import msFlag from "../../assets/icons/Flags/ms.png";
import mtFlag from "../../assets/icons/Flags/mt.png";
import muFlag from "../../assets/icons/Flags/mu.png";
import mvFlag from "../../assets/icons/Flags/mv.png";
import mwFlag from "../../assets/icons/Flags/mw.png";
import mxFlag from "../../assets/icons/Flags/mx.png";
import myFlag from "../../assets/icons/Flags/my.png";
import mzFlag from "../../assets/icons/Flags/mz.png";
import naFlag from "../../assets/icons/Flags/na.png";
import ncFlag from "../../assets/icons/Flags/nc.png";
import neFlag from "../../assets/icons/Flags/ne.png";
import ngFlag from "../../assets/icons/Flags/ng.png";
import niFlag from "../../assets/icons/Flags/ni.png";
import nlFlag from "../../assets/icons/Flags/nl.png";
import nlaFlag from "../../assets/icons/Flags/nla.png";
import noFlag from "../../assets/icons/Flags/no.png";
import npFlag from "../../assets/icons/Flags/np.png";
import nrFlag from "../../assets/icons/Flags/nr.png";
import nuFlag from "../../assets/icons/Flags/nu.png";
import nzFlag from "../../assets/icons/Flags/nz.png";
import omFlag from "../../assets/icons/Flags/om.png";
import paFlag from "../../assets/icons/Flags/pa.png";
import peFlag from "../../assets/icons/Flags/pe.png";
import pfFlag from "../../assets/icons/Flags/pf.png";
import pgFlag from "../../assets/icons/Flags/pg.png";
import phFlag from "../../assets/icons/Flags/ph.png";
import pkFlag from "../../assets/icons/Flags/pk.png";
import plFlag from "../../assets/icons/Flags/pl.png";
import pmFlag from "../../assets/icons/Flags/pm.png";
import pnFlag from "../../assets/icons/Flags/pn.png";
import prFlag from "../../assets/icons/Flags/pr.png";
import psFlag from "../../assets/icons/Flags/ps.png";
import ptFlag from "../../assets/icons/Flags/pt.png";
import pwFlag from "../../assets/icons/Flags/pw.png";
import pyFlag from "../../assets/icons/Flags/py.png";
import qaFlag from "../../assets/icons/Flags/qa.png";
import reFlag from "../../assets/icons/Flags/re.png";
import roFlag from "../../assets/icons/Flags/ro.png";
import rsFlag from "../../assets/icons/Flags/rs.png";
import ruFlag from "../../assets/icons/Flags/ru.png";
import rwFlag from "../../assets/icons/Flags/rw.png";
import saFlag from "../../assets/icons/Flags/sa.png";
import sbFlag from "../../assets/icons/Flags/sb.png";
import scFlag from "../../assets/icons/Flags/sc.png";
import sdFlag from "../../assets/icons/Flags/sd.png";
import seFlag from "../../assets/icons/Flags/se.png";
import sgFlag from "../../assets/icons/Flags/sg.png";
import shFlag from "../../assets/icons/Flags/sh.png";
import siFlag from "../../assets/icons/Flags/si.png";
import sjFlag from "../../assets/icons/Flags/sj.png";
import skFlag from "../../assets/icons/Flags/sk.png";
import slFlag from "../../assets/icons/Flags/sl.png";
import smFlag from "../../assets/icons/Flags/sm.png";
import snFlag from "../../assets/icons/Flags/sn.png";
import soFlag from "../../assets/icons/Flags/so.png";
import srFlag from "../../assets/icons/Flags/sr.png";
import ssFlag from "../../assets/icons/Flags/ss.png";
import stFlag from "../../assets/icons/Flags/st.png";
import svFlag from "../../assets/icons/Flags/sv.png";
import sxFlag from "../../assets/icons/Flags/sx.png";
import syFlag from "../../assets/icons/Flags/sy.png";
import szFlag from "../../assets/icons/Flags/sz.png";
import tcFlag from "../../assets/icons/Flags/tc.png";
import tdFlag from "../../assets/icons/Flags/td.png";
import tgFlag from "../../assets/icons/Flags/tg.png";
import thFlag from "../../assets/icons/Flags/th.png";
import tjFlag from "../../assets/icons/Flags/tj.png";
import tkFlag from "../../assets/icons/Flags/tk.png";
import tlFlag from "../../assets/icons/Flags/tl.png";
import tmFlag from "../../assets/icons/Flags/tm.png";
import tnFlag from "../../assets/icons/Flags/tn.png";
import toFlag from "../../assets/icons/Flags/to.png";
import trFlag from "../../assets/icons/Flags/tr.png";
import ttFlag from "../../assets/icons/Flags/tt.png";
import tvFlag from "../../assets/icons/Flags/tv.png";
import twFlag from "../../assets/icons/Flags/tw.png";
import tzFlag from "../../assets/icons/Flags/tz.png";
import uaFlag from "../../assets/icons/Flags/ua.png";
import ugFlag from "../../assets/icons/Flags/ug.png";
import usFlag from "../../assets/icons/Flags/us.png";
import uyFlag from "../../assets/icons/Flags/uy.png";
import uzFlag from "../../assets/icons/Flags/uz.png";
import vaFlag from "../../assets/icons/Flags/va.png";
import vcFlag from "../../assets/icons/Flags/vc.png";
import veFlag from "../../assets/icons/Flags/ve.png";
import vgFlag from "../../assets/icons/Flags/vg.png";
import viFlag from "../../assets/icons/Flags/vi.png";
import vnFlag from "../../assets/icons/Flags/vn.png";
import vuFlag from "../../assets/icons/Flags/vu.png";
import wfFlag from "../../assets/icons/Flags/wf.png";
import wsFlag from "../../assets/icons/Flags/ws.png";
import xkFlag from "../../assets/icons/Flags/xk.png";
import yeFlag from "../../assets/icons/Flags/ye.png";
import ytFlag from "../../assets/icons/Flags/yt.png";
import zaFlag from "../../assets/icons/Flags/za.png";
import zmFlag from "../../assets/icons/Flags/zm.png";
import zwFlag from "../../assets/icons/Flags/zw.png";
import { FlexView, OutlineButton } from "../../components/common";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
`;

const Dropdown = styled.div`
  display: flex;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease;
  transform: ${({ open }) => (open ? "rotate(180deg)" : "rotate(0deg)")};

  svg {
    height: 10px;
    fill: ${({ theme }) => theme.colors.gray};
  }
`;

const Options = styled.div`
  display: flex;
  visibility: ${({ open }) => (open ? "visible" : "hidden")};
  opacity: ${({ open }) => (open ? "1" : "0")};
  transform: ${({ open }) => (open ? "translateX(0)" : "scale(0.9)")};
  flex-direction: column;
  margin: 5px 0px;
  background: #ffffff;
  box-shadow: 0px 0px 4px #ccc;
  max-height: 250px;
  overflow-y: auto;
  border-radius: 8px;
  z-index: 999;
  transition: all 0.2s linear;
`;

const Option = styled.div`
  display: flex;
  padding: 10px 20px;
  align-items: center;
  justify-content: flex-start;
  font-family: Roboto;
  user-select: none;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.darkGray};
  transition: background-color 0.3s;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    background-color: #eeeeee;
  }

  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:not(:first-child) {
    border-top: 1px solid #ddd;
  }

  span {
  }
`;

const Flag = styled.img`
  height: 1rem;
  width: 30px;
`;

const Label = styled.span`
  margin-left: 10px;
  font-family: Roboto;
  font-weight: normal;
`;

const options = [
  {
    FlagName: "af",
    logo: afFlag,
    m_countryName: "Afghanistan",
    m_code: "93",
  },
  {
    FlagName: "al",
    logo: alFlag,
    m_countryName: "Albania",
    m_code: "355",
  },
  {
    FlagName: "dz",
    logo: dzFlag,
    m_countryName: "Algeria",
    m_code: "213",
  },
  {
    FlagName: "as",
    logo: asFlag,
    m_countryName: "American Samoa",
    m_code: "1-684",
  },
  {
    FlagName: "ad",
    logo: adFlag,
    m_countryName: "Andorra",
    m_code: "376",
  },
  {
    FlagName: "ao",
    logo: aoFlag,
    m_countryName: "Angola",
    m_code: "244",
  },
  {
    FlagName: "ai",
    logo: aiFlag,
    m_countryName: "Anguilla",
    m_code: "1-264",
  },
  {
    FlagName: "aq",
    logo: aqFlag,
    m_countryName: "Antarctica",
    m_code: "672",
  },
  {
    FlagName: "ag",
    logo: agFlag,
    m_countryName: "Antigua and Barbuda",
    m_code: "1-268",
  },
  {
    FlagName: "ar",
    logo: arFlag,
    m_countryName: "Argentina",
    m_code: "54",
  },
  {
    FlagName: "am",
    logo: amFlag,
    m_countryName: "Armenia",
    m_code: "374",
  },
  {
    FlagName: "aw",
    logo: awFlag,
    m_countryName: "Aruba",
    m_code: "297",
  },
  {
    FlagName: "au",
    logo: auFlag,
    m_countryName: "Australia",
    m_code: "61",
  },
  {
    FlagName: "at",
    logo: atFlag,
    m_countryName: "Austria",
    m_code: "43",
  },
  {
    FlagName: "az",
    logo: azFlag,
    m_countryName: "Azerbaijan",
    m_code: "994",
  },
  {
    FlagName: "bs",
    logo: bsFlag,
    m_countryName: "Bahamas",
    m_code: "1-242",
  },
  {
    FlagName: "bh",
    logo: bhFlag,
    m_countryName: "Bahrain",
    m_code: "973",
  },
  {
    FlagName: "bd",
    logo: bdFlag,
    m_countryName: "Bangladesh",
    m_code: "880",
  },
  {
    FlagName: "bb",
    logo: bbFlag,
    m_countryName: "Barbados",
    m_code: "1-246",
  },
  {
    FlagName: "by",
    logo: byFlag,
    m_countryName: "Belarus",
    m_code: "375",
  },
  {
    FlagName: "be",
    logo: beFlag,
    m_countryName: "Belgium",
    m_code: "32",
  },
  {
    FlagName: "bz",
    logo: bzFlag,
    m_countryName: "Belize",
    m_code: "501",
  },
  {
    FlagName: "bj",
    logo: bjFlag,
    m_countryName: "Benin",
    m_code: "229",
  },
  {
    FlagName: "bm",
    logo: bmFlag,
    m_countryName: "Bermuda",
    m_code: "1-441",
  },
  {
    FlagName: "bt",
    logo: btFlag,
    m_countryName: "Bhutan",
    m_code: "975",
  },
  {
    FlagName: "bo",
    logo: boFlag,
    m_countryName: "Bolivia",
    m_code: "591",
  },
  {
    FlagName: "ba",
    logo: baFlag,
    m_countryName: "Bosnia and Herzegovina",
    m_code: "387",
  },
  {
    FlagName: "bw",
    logo: bwFlag,
    m_countryName: "Botswana",
    m_code: "267",
  },
  {
    FlagName: "br",
    logo: brFlag,
    m_countryName: "Brazil",
    m_code: "55",
  },
  {
    FlagName: "io",
    logo: ioFlag,
    m_countryName: "British Indian Ocean Territory",
    m_code: "246",
  },
  {
    FlagName: "vg",
    logo: vgFlag,
    m_countryName: "British Virgin Islands",
    m_code: "1-284",
  },
  {
    FlagName: "bn",
    logo: bnFlag,
    m_countryName: "Brunei",
    m_code: "673",
  },
  {
    FlagName: "bg",
    logo: bgFlag,
    m_countryName: "Bulgaria",
    m_code: "359",
  },
  {
    FlagName: "bf",
    logo: bfFlag,
    m_countryName: "Burkina Faso",
    m_code: "226",
  },
  {
    FlagName: "bi",
    logo: biFlag,
    m_countryName: "Burundi",
    m_code: "257",
  },
  {
    FlagName: "kh",
    logo: khFlag,
    m_countryName: "Cambodia",
    m_code: "855",
  },
  {
    FlagName: "cm",
    logo: cmFlag,
    m_countryName: "Cameroon",
    m_code: "237",
  },
  {
    FlagName: "ca",
    logo: caFlag,
    m_countryName: "Canada",
    m_code: "1",
  },
  {
    FlagName: "cv",
    logo: cvFlag,
    m_countryName: "Cape Verde",
    m_code: "238",
  },
  {
    FlagName: "ky",
    logo: kyFlag,
    m_countryName: "Cayman Islands",
    m_code: "1-345",
  },
  {
    FlagName: "cf",
    logo: cfFlag,
    m_countryName: "Central African Republic",
    m_code: "236",
  },
  {
    FlagName: "td",
    logo: tdFlag,
    m_countryName: "Chad",
    m_code: "235",
  },
  {
    FlagName: "cl",
    logo: clFlag,
    m_countryName: "Chile",
    m_code: "56",
  },
  {
    FlagName: "cn",
    logo: cnFlag,
    m_countryName: "China",
    m_code: "86",
  },
  {
    FlagName: "cx",
    logo: cxFlag,
    m_countryName: "Christmas Island",
    m_code: "61",
  },
  {
    FlagName: "cc",
    logo: ccFlag,
    m_countryName: "Cocos Islands",
    m_code: "61",
  },
  {
    FlagName: "co",
    logo: coFlag,
    m_countryName: "Colombia",
    m_code: "57",
  },
  {
    FlagName: "km",
    logo: kmFlag,
    m_countryName: "Comoros",
    m_code: "269",
  },
  {
    FlagName: "ck",
    logo: ckFlag,
    m_countryName: "Cook Islands",
    m_code: "682",
  },
  {
    FlagName: "cr",
    logo: crFlag,
    m_countryName: "Costa Rica",
    m_code: "506",
  },
  {
    FlagName: "hr",
    logo: hrFlag,
    m_countryName: "Croatia",
    m_code: "385",
  },
  {
    FlagName: "cu",
    logo: cuFlag,
    m_countryName: "Cuba",
    m_code: "53",
  },
  {
    FlagName: "cw",
    logo: cwFlag,
    m_countryName: "Curacao",
    m_code: "5999",
  },
  {
    FlagName: "cy",
    logo: cyFlag,
    m_countryName: "Cyprus",
    m_code: "357",
  },
  {
    FlagName: "cz",
    logo: czFlag,
    m_countryName: "Czech Republic",
    m_code: "420",
  },
  {
    FlagName: "cd",
    logo: cdFlag,
    m_countryName: "Democratic Republic of the Congo",
    m_code: "243",
  },
  {
    FlagName: "dk",
    logo: dkFlag,
    m_countryName: "Denmark",
    m_code: "45",
  },
  {
    FlagName: "dj",
    logo: djFlag,
    m_countryName: "Djibouti",
    m_code: "253",
  },
  {
    FlagName: "dm",
    logo: dmFlag,
    m_countryName: "Dominica",
    m_code: "1-767",
  },
  {
    FlagName: "do",
    logo: doFlag,
    m_countryName: "Dominican Republic",
    m_code: "1-809",
  },
  {
    FlagName: "do",
    logo: doFlag,
    m_countryName: "Dominican Republic ",
    m_code: "1-829",
  },
  {
    FlagName: "do",
    logo: doFlag,
    m_countryName: "Dominican Republic  ",
    m_code: "1-849",
  },
  {
    FlagName: "tl",
    logo: tlFlag,
    m_countryName: "East Timor",
    m_code: "670",
  },
  {
    FlagName: "ec",
    logo: ecFlag,
    m_countryName: "Ecuador",
    m_code: "593",
  },
  {
    FlagName: "eg",
    logo: egFlag,
    m_countryName: "Egypt",
    m_code: "20",
  },
  {
    FlagName: "sv",
    logo: svFlag,
    m_countryName: "El Salvador",
    m_code: "503",
  },
  {
    FlagName: "gq",
    logo: gqFlag,
    m_countryName: "Equatorial Guinea",
    m_code: "240",
  },
  {
    FlagName: "er",
    logo: erFlag,
    m_countryName: "Eritrea",
    m_code: "291",
  },
  {
    FlagName: "ee",
    logo: eeFlag,
    m_countryName: "Estonia",
    m_code: "372",
  },
  {
    FlagName: "et",
    logo: etFlag,
    m_countryName: "Ethiopia",
    m_code: "251",
  },
  {
    FlagName: "fk",
    logo: fkFlag,
    m_countryName: "Falkland Islands",
    m_code: "500",
  },
  {
    FlagName: "fo",
    logo: foFlag,
    m_countryName: "Faroe Islands",
    m_code: "298",
  },
  {
    FlagName: "fj",
    logo: fjFlag,
    m_countryName: "Fiji",
    m_code: "679",
  },
  {
    FlagName: "fi",
    logo: fiFlag,
    m_countryName: "Finland",
    m_code: "358",
  },
  {
    FlagName: "fr",
    logo: frFlag,
    m_countryName: "France",
    m_code: "33",
  },
  {
    FlagName: "pf",
    logo: pfFlag,
    m_countryName: "French Polynesia",
    m_code: "689",
  },
  {
    FlagName: "ga",
    logo: gaFlag,
    m_countryName: "Gabon",
    m_code: "241",
  },
  {
    FlagName: "gm",
    logo: gmFlag,
    m_countryName: "Gambia",
    m_code: "220",
  },
  {
    FlagName: "ge",
    logo: geFlag,
    m_countryName: "Georgia",
    m_code: "995",
  },
  {
    FlagName: "de",
    logo: deFlag,
    m_countryName: "Germany",
    m_code: "49",
  },
  {
    FlagName: "gh",
    logo: ghFlag,
    m_countryName: "Ghana",
    m_code: "233",
  },
  {
    FlagName: "gi",
    logo: giFlag,
    m_countryName: "Gibraltar",
    m_code: "350",
  },
  {
    FlagName: "gr",
    logo: grFlag,
    m_countryName: "Greece",
    m_code: "30",
  },
  {
    FlagName: "gl",
    logo: glFlag,
    m_countryName: "Greenland",
    m_code: "299",
  },
  {
    FlagName: "gd",
    logo: gdFlag,
    m_countryName: "Grenada",
    m_code: "1-473",
  },
  {
    FlagName: "gu",
    logo: guFlag,
    m_countryName: "Guam",
    m_code: "1-671",
  },
  {
    FlagName: "gt",
    logo: gtFlag,
    m_countryName: "Guatemala",
    m_code: "502",
  },
  {
    FlagName: "gg",
    logo: ggFlag,
    m_countryName: "Guernsey",
    m_code: "44",
  },
  {
    FlagName: "gn",
    logo: gnFlag,
    m_countryName: "Guinea",
    m_code: "224",
  },
  {
    FlagName: "gw",
    logo: gwFlag,
    m_countryName: "Guinea-Bissau",
    m_code: "245",
  },
  {
    FlagName: "gy",
    logo: gyFlag,
    m_countryName: "Guyana",
    m_code: "592",
  },
  {
    FlagName: "ht",
    logo: htFlag,
    m_countryName: "Haiti",
    m_code: "509",
  },
  {
    FlagName: "hn",
    logo: hnFlag,
    m_countryName: "Honduras",
    m_code: "504",
  },
  {
    FlagName: "hk",
    logo: hkFlag,
    m_countryName: "Hong Kong",
    m_code: "852",
  },
  {
    FlagName: "hu",
    logo: huFlag,
    m_countryName: "Hungary",
    m_code: "36",
  },
  {
    FlagName: "is",
    logo: isFlag,
    m_countryName: "Iceland",
    m_code: "354",
  },
  {
    FlagName: "in",
    logo: inFlag,
    m_countryName: "India",
    m_code: "91",
  },
  {
    FlagName: "id",
    logo: idFlag,
    m_countryName: "Indonesia",
    m_code: "62",
  },
  {
    FlagName: "ir",
    logo: irFlag,
    m_countryName: "Iran",
    m_code: "98",
  },
  {
    FlagName: "iq",
    logo: iqFlag,
    m_countryName: "Iraq",
    m_code: "964",
  },
  {
    FlagName: "ie",
    logo: ieFlag,
    m_countryName: "Ireland",
    m_code: "353",
  },
  {
    FlagName: "im",
    logo: imFlag,
    m_countryName: "Isle of Man",
    m_code: "44",
  },
  {
    FlagName: "il",
    logo: ilFlag,
    m_countryName: "Israel",
    m_code: "972",
  },
  {
    FlagName: "it",
    logo: itFlag,
    m_countryName: "Italy",
    m_code: "39",
  },
  {
    FlagName: "ci",
    logo: ciFlag,
    m_countryName: "Ivory Coast",
    m_code: "225",
  },
  {
    FlagName: "jm",
    logo: jmFlag,
    m_countryName: "Jamaica",
    m_code: "1-876",
  },
  {
    FlagName: "jp",
    logo: jpFlag,
    m_countryName: "Japan",
    m_code: "81",
  },
  {
    FlagName: "je",
    logo: jeFlag,
    m_countryName: "Jersey",
    m_code: "44",
  },
  {
    FlagName: "jo",
    logo: joFlag,
    m_countryName: "Jordan",
    m_code: "962",
  },
  {
    FlagName: "kz",
    logo: kzFlag,
    m_countryName: "Kazakhstan",
    m_code: "7",
  },
  {
    FlagName: "ke",
    logo: keFlag,
    m_countryName: "Kenya",
    m_code: "254",
  },
  {
    FlagName: "ki",
    logo: kiFlag,
    m_countryName: "Kiribati",
    m_code: "686",
  },
  {
    FlagName: "xk",
    logo: xkFlag,
    m_countryName: "Kosovo",
    m_code: "383",
  },
  {
    FlagName: "kw",
    logo: kwFlag,
    m_countryName: "Kuwait",
    m_code: "965",
  },
  {
    FlagName: "kg",
    logo: kgFlag,
    m_countryName: "Kyrgyzstan",
    m_code: "996",
  },
  {
    FlagName: "la",
    logo: laFlag,
    m_countryName: "Laos",
    m_code: "856",
  },
  {
    FlagName: "lv",
    logo: lvFlag,
    m_countryName: "Latvia",
    m_code: "371",
  },
  {
    FlagName: "lb",
    logo: lbFlag,
    m_countryName: "Lebanon",
    m_code: "961",
  },
  {
    FlagName: "ls",
    logo: lsFlag,
    m_countryName: "Lesotho",
    m_code: "266",
  },
  {
    FlagName: "lr",
    logo: lrFlag,
    m_countryName: "Liberia",
    m_code: "231",
  },
  {
    FlagName: "ly",
    logo: lyFlag,
    m_countryName: "Libya",
    m_code: "218",
  },
  {
    FlagName: "li",
    logo: liFlag,
    m_countryName: "Liechtenstein",
    m_code: "423",
  },
  {
    FlagName: "lt",
    logo: ltFlag,
    m_countryName: "Lithuania",
    m_code: "370",
  },
  {
    FlagName: "lu",
    logo: luFlag,
    m_countryName: "Luxembourg",
    m_code: "352",
  },
  {
    FlagName: "mo",
    logo: moFlag,
    m_countryName: "Macau",
    m_code: "853",
  },
  {
    FlagName: "mk",
    logo: mkFlag,
    m_countryName: "Macedonia",
    m_code: "389",
  },
  {
    FlagName: "mg",
    logo: mgFlag,
    m_countryName: "Madagascar",
    m_code: "261",
  },
  {
    FlagName: "mw",
    logo: mwFlag,
    m_countryName: "Malawi",
    m_code: "265",
  },
  {
    FlagName: "my",
    logo: myFlag,
    m_countryName: "Malaysia",
    m_code: "60",
  },
  {
    FlagName: "mv",
    logo: mvFlag,
    m_countryName: "Maldives",
    m_code: "960",
  },
  {
    FlagName: "ml",
    logo: mlFlag,
    m_countryName: "Mali",
    m_code: "223",
  },
  {
    FlagName: "mt",
    logo: mtFlag,
    m_countryName: "Malta",
    m_code: "356",
  },
  {
    FlagName: "mh",
    logo: mhFlag,
    m_countryName: "Marshall Islands",
    m_code: "692",
  },
  {
    FlagName: "mr",
    logo: mrFlag,
    m_countryName: "Mauritania",
    m_code: "222",
  },
  {
    FlagName: "mu",
    logo: muFlag,
    m_countryName: "Mauritius",
    m_code: "230",
  },
  {
    FlagName: "yt",
    logo: ytFlag,
    m_countryName: "Mayotte",
    m_code: "262",
  },
  {
    FlagName: "mx",
    logo: mxFlag,
    m_countryName: "Mexico",
    m_code: "52",
  },
  {
    FlagName: "fm",
    logo: fmFlag,
    m_countryName: "Micronesia",
    m_code: "691",
  },
  {
    FlagName: "md",
    logo: mdFlag,
    m_countryName: "Moldova",
    m_code: "373",
  },
  {
    FlagName: "mc",
    logo: mcFlag,
    m_countryName: "Monaco",
    m_code: "377",
  },
  {
    FlagName: "mn",
    logo: mnFlag,
    m_countryName: "Mongolia",
    m_code: "976",
  },
  {
    FlagName: "me",
    logo: meFlag,
    m_countryName: "Montenegro",
    m_code: "382",
  },
  {
    FlagName: "ms",
    logo: msFlag,
    m_countryName: "Montserrat",
    m_code: "1-664",
  },
  {
    FlagName: "ma",
    logo: maFlag,
    m_countryName: "Morocco",
    m_code: "212",
  },
  {
    FlagName: "mz",
    logo: mzFlag,
    m_countryName: "Mozambique",
    m_code: "258",
  },
  {
    FlagName: "mm",
    logo: mmFlag,
    m_countryName: "Myanmar",
    m_code: "95",
  },
  {
    FlagName: "na",
    logo: naFlag,
    m_countryName: "Namibia",
    m_code: "264",
  },
  {
    FlagName: "nr",
    logo: nrFlag,
    m_countryName: "Nauru",
    m_code: "674",
  },
  {
    FlagName: "np",
    logo: npFlag,
    m_countryName: "Nepal",
    m_code: "977",
  },
  {
    FlagName: "nl",
    logo: nlFlag,
    m_countryName: "Netherlands",
    m_code: "31",
  },
  {
    FlagName: "nla",
    logo: nlaFlag,
    m_countryName: "Netherlands Antilles",
    m_code: "599",
  },
  {
    FlagName: "nc",
    logo: ncFlag,
    m_countryName: "New Caledonia",
    m_code: "687",
  },
  {
    FlagName: "nz",
    logo: nzFlag,
    m_countryName: "New Zealand",
    m_code: "64",
  },
  {
    FlagName: "ni",
    logo: niFlag,
    m_countryName: "Nicaragua",
    m_code: "505",
  },
  {
    FlagName: "ne",
    logo: neFlag,
    m_countryName: "Niger",
    m_code: "227",
  },
  {
    FlagName: "ng",
    logo: ngFlag,
    m_countryName: "Nigeria",
    m_code: "234",
  },
  {
    FlagName: "nu",
    logo: nuFlag,
    m_countryName: "Niue",
    m_code: "683",
  },
  {
    FlagName: "kp",
    logo: kpFlag,
    m_countryName: "North Korea",
    m_code: "850",
  },
  {
    FlagName: "mp",
    logo: mpFlag,
    m_countryName: "Northern Mariana Islands",
    m_code: "1-670",
  },
  {
    FlagName: "no",
    logo: noFlag,
    m_countryName: "Norway",
    m_code: "47",
  },
  {
    FlagName: "om",
    logo: omFlag,
    m_countryName: "Oman",
    m_code: "968",
  },
  {
    FlagName: "pk",
    logo: pkFlag,
    m_countryName: "Pakistan",
    m_code: "92",
  },
  {
    FlagName: "pw",
    logo: pwFlag,
    m_countryName: "Palau",
    m_code: "680",
  },
  {
    FlagName: "ps",
    logo: psFlag,
    m_countryName: "Palestine",
    m_code: "970",
  },
  {
    FlagName: "pa",
    logo: paFlag,
    m_countryName: "Panama",
    m_code: "507",
  },
  {
    FlagName: "pg",
    logo: pgFlag,
    m_countryName: "Papua New Guinea",
    m_code: "675",
  },
  {
    FlagName: "py",
    logo: pyFlag,
    m_countryName: "Paraguay",
    m_code: "595",
  },
  {
    FlagName: "pe",
    logo: peFlag,
    m_countryName: "Peru",
    m_code: "51",
  },
  {
    FlagName: "ph",
    logo: phFlag,
    m_countryName: "Philippines",
    m_code: "63",
  },
  {
    FlagName: "pn",
    logo: pnFlag,
    m_countryName: "Pitcairn",
    m_code: "64",
  },
  {
    FlagName: "pl",
    logo: plFlag,
    m_countryName: "Poland",
    m_code: "48",
  },
  {
    FlagName: "pt",
    logo: ptFlag,
    m_countryName: "Portugal",
    m_code: "351",
  },
  {
    FlagName: "pr",
    logo: prFlag,
    m_countryName: "Puerto Rico",
    m_code: "1-787",
  },
  {
    FlagName: "pr",
    logo: prFlag,
    m_countryName: "Puerto Rico ",
    m_code: "1-939",
  },
  {
    FlagName: "qa",
    logo: qaFlag,
    m_countryName: "Qatar",
    m_code: "974",
  },
  {
    FlagName: "cg",
    logo: cgFlag,
    m_countryName: "Republic of the Congo",
    m_code: "242",
  },
  {
    FlagName: "re",
    logo: reFlag,
    m_countryName: "Reunion",
    m_code: "262",
  },
  {
    FlagName: "ro",
    logo: roFlag,
    m_countryName: "Romania",
    m_code: "40",
  },
  {
    FlagName: "ru",
    logo: ruFlag,
    m_countryName: "Russia",
    m_code: "7",
  },
  {
    FlagName: "rw",
    logo: rwFlag,
    m_countryName: "Rwanda",
    m_code: "250",
  },
  {
    FlagName: "bl",
    logo: blFlag,
    m_countryName: "Saint Barthelemy",
    m_code: "590",
  },
  {
    FlagName: "sh",
    logo: shFlag,
    m_countryName: "Saint Helena",
    m_code: "290",
  },
  {
    FlagName: "kn",
    logo: knFlag,
    m_countryName: "Saint Kitts and Nevis",
    m_code: "1-869",
  },
  {
    FlagName: "lc",
    logo: lcFlag,
    m_countryName: "Saint Lucia",
    m_code: "1-758",
  },
  {
    FlagName: "mf",
    logo: mfFlag,
    m_countryName: "Saint Martin",
    m_code: "590",
  },
  {
    FlagName: "pm",
    logo: pmFlag,
    m_countryName: "Saint Pierre and Miquelon",
    m_code: "508",
  },
  {
    FlagName: "vc",
    logo: vcFlag,
    m_countryName: "Saint Vincent and the Grenadines",
    m_code: "1-784",
  },
  {
    FlagName: "ws",
    logo: wsFlag,
    m_countryName: "Samoa",
    m_code: "685",
  },
  {
    FlagName: "sm",
    logo: smFlag,
    m_countryName: "San Marino",
    m_code: "378",
  },
  {
    FlagName: "st",
    logo: stFlag,
    m_countryName: "Sao Tome and Principe",
    m_code: "239",
  },
  {
    FlagName: "sa",
    logo: saFlag,
    m_countryName: "Saudi Arabia",
    m_code: "966",
  },
  {
    FlagName: "sn",
    logo: snFlag,
    m_countryName: "Senegal",
    m_code: "221",
  },
  {
    FlagName: "rs",
    logo: rsFlag,
    m_countryName: "Serbia",
    m_code: "381",
  },
  {
    FlagName: "sc",
    logo: scFlag,
    m_countryName: "Seychelles",
    m_code: "248",
  },
  {
    FlagName: "sl",
    logo: slFlag,
    m_countryName: "Sierra Leone",
    m_code: "232",
  },
  {
    FlagName: "sg",
    logo: sgFlag,
    m_countryName: "Singapore",
    m_code: "65",
  },
  {
    FlagName: "sx",
    logo: sxFlag,
    m_countryName: "Sint Maarten",
    m_code: "1-721",
  },
  {
    FlagName: "sk",
    logo: skFlag,
    m_countryName: "Slovakia",
    m_code: "421",
  },
  {
    FlagName: "si",
    logo: siFlag,
    m_countryName: "Slovenia",
    m_code: "386",
  },
  {
    FlagName: "sb",
    logo: sbFlag,
    m_countryName: "Solomon Islands",
    m_code: "677",
  },
  {
    FlagName: "so",
    logo: soFlag,
    m_countryName: "Somalia",
    m_code: "252",
  },
  {
    FlagName: "za",
    logo: zaFlag,
    m_countryName: "South Africa",
    m_code: "27",
  },
  {
    FlagName: "kr",
    logo: krFlag,
    m_countryName: "South Korea",
    m_code: "82",
  },
  {
    FlagName: "ss",
    logo: ssFlag,
    m_countryName: "South Sudan",
    m_code: "211",
  },
  {
    FlagName: "es",
    logo: esFlag,
    m_countryName: "Spain",
    m_code: "34",
  },
  {
    FlagName: "lk",
    logo: lkFlag,
    m_countryName: "Sri Lanka",
    m_code: "94",
  },
  {
    FlagName: "sd",
    logo: sdFlag,
    m_countryName: "Sudan",
    m_code: "249",
  },
  {
    FlagName: "sr",
    logo: srFlag,
    m_countryName: "Suriname",
    m_code: "597",
  },
  {
    FlagName: "sj",
    logo: sjFlag,
    m_countryName: "Svalbard and Jan Mayen",
    m_code: "47",
  },
  {
    FlagName: "sz",
    logo: szFlag,
    m_countryName: "Swaziland",
    m_code: "268",
  },
  {
    FlagName: "se",
    logo: seFlag,
    m_countryName: "Sweden",
    m_code: "46",
  },
  {
    FlagName: "ch",
    logo: chFlag,
    m_countryName: "Switzerland",
    m_code: "41",
  },
  {
    FlagName: "sy",
    logo: syFlag,
    m_countryName: "Syria",
    m_code: "963",
  },
  {
    FlagName: "tw",
    logo: twFlag,
    m_countryName: "Taiwan",
    m_code: "886",
  },
  {
    FlagName: "tj",
    logo: tjFlag,
    m_countryName: "Tajikistan",
    m_code: "992",
  },
  {
    FlagName: "tz",
    logo: tzFlag,
    m_countryName: "Tanzania",
    m_code: "255",
  },
  {
    FlagName: "th",
    logo: thFlag,
    m_countryName: "Thailand",
    m_code: "66",
  },
  {
    FlagName: "tg",
    logo: tgFlag,
    m_countryName: "Togo",
    m_code: "228",
  },
  {
    FlagName: "tk",
    logo: tkFlag,
    m_countryName: "Tokelau",
    m_code: "690",
  },
  {
    FlagName: "to",
    logo: toFlag,
    m_countryName: "Tonga",
    m_code: "676",
  },
  {
    FlagName: "tt",
    logo: ttFlag,
    m_countryName: "Trinidad and Tobago",
    m_code: "1-868",
  },
  {
    FlagName: "tn",
    logo: tnFlag,
    m_countryName: "Tunisia",
    m_code: "216",
  },
  {
    FlagName: "tr",
    logo: trFlag,
    m_countryName: "Turkey",
    m_code: "90",
  },
  {
    FlagName: "tm",
    logo: tmFlag,
    m_countryName: "Turkmenistan",
    m_code: "993",
  },
  {
    FlagName: "tc",
    logo: tcFlag,
    m_countryName: "Turks and Caicos Islands",
    m_code: "1-649",
  },
  {
    FlagName: "tv",
    logo: tvFlag,
    m_countryName: "Tuvalu",
    m_code: "688",
  },
  {
    FlagName: "vi",
    logo: viFlag,
    m_countryName: "U.S. Virgin Islands",
    m_code: "1-340",
  },
  {
    FlagName: "ug",
    logo: ugFlag,
    m_countryName: "Uganda",
    m_code: "256",
  },
  {
    FlagName: "ua",
    logo: uaFlag,
    m_countryName: "Ukraine",
    m_code: "380",
  },
  {
    FlagName: "ae",
    logo: aeFlag,
    m_countryName: "United Arab Emirates",
    m_code: "971",
  },
  {
    FlagName: "gb",
    logo: gbFlag,
    m_countryName: "United Kingdom",
    m_code: "44",
  },
  {
    FlagName: "us",
    logo: usFlag,
    m_countryName: "United States",
    m_code: "1",
  },
  {
    FlagName: "uy",
    logo: uyFlag,
    m_countryName: "Uruguay",
    m_code: "598",
  },
  {
    FlagName: "uz",
    logo: uzFlag,
    m_countryName: "Uzbekistan",
    m_code: "998",
  },
  {
    FlagName: "vu",
    logo: vuFlag,
    m_countryName: "Vanuatu",
    m_code: "678",
  },
  {
    FlagName: "va",
    logo: vaFlag,
    m_countryName: "Vatican",
    m_code: "379",
  },
  {
    FlagName: "ve",
    logo: veFlag,
    m_countryName: "Venezuela",
    m_code: "58",
  },
  {
    FlagName: "vn",
    logo: vnFlag,
    m_countryName: "Vietnam",
    m_code: "84",
  },
  {
    FlagName: "wf",
    logo: wfFlag,
    m_countryName: "Wallis and Futuna",
    m_code: "681",
  },
  {
    FlagName: "eh",
    logo: ehFlag,
    m_countryName: "Western Sahara",
    m_code: "212",
  },
  {
    FlagName: "ye",
    logo: yeFlag,
    m_countryName: "Yemen",
    m_code: "967",
  },
  {
    FlagName: "zm",
    logo: zmFlag,
    m_countryName: "Zambia",
    m_code: "260",
  },
  {
    FlagName: "zw",
    logo: zwFlag,
    m_countryName: "Zimbabwe",
    m_code: "263",
  },
];

const CountrySelect = () => {
  const node = useRef(null);
  const [isOpen, setDropdown] = useState(false);

  const toggleSelect = () => setDropdown(!isOpen);

  const changeCountryCode = (m_code) => () => {
    Cookies.set("Country", m_code, {
      expires: 365,
      domain: process.env.REACT_APP_DOMAIN,
    });
    toggleSelect();
  };

  const renderOptions = (scheduleUpdate) => {
    return _.map(options, ({ m_code, m_countryName, logo }) => (
      <Option
        key={m_countryName}
        value={m_code}
        onClick={changeCountryCode(m_code)}
      >
        <Flag src={logo} alt={m_countryName} />
        <Label>{m_countryName}</Label>
      </Option>
    ));
  };

  const getPlaceholder = () => {
    const selectedOption = _.find(options, {
      m_code: Cookies.get("Country"),
    });
    return (
      selectedOption && (
        <FlexView
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Flag src={selectedOption.logo} alt={selectedOption.m_countryName} />
          <Label>{selectedOption.m_countryName}</Label>
        </FlexView>
      )
    );
  };

  const handleOutsideClick = (e) => {
    node &&
      node.current &&
      !node.current.contains(e.target) &&
      setDropdown(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <Manager>
      <Wrapper ref={node}>
        <Reference>
          {({ ref }) => (
            <OutlineButton
              onClick={toggleSelect}
              ref={ref}
              style={{ backgroundColor: "#ffffff" }}
            >
              {getPlaceholder()}
              <Dropdown open={isOpen}>
                <svg
                  version="1.1"
                  x="0px"
                  y="0px"
                  viewBox="0 0 1000 1000"
                  enableBackground="new 0 0 1000 1000"
                >
                  <g>
                    <path d="M500,775.4L10,287.2l64.4-62.6L500,650.2l425.6-425.6l64.4,62.6L500,775.4z" />
                  </g>
                </svg>
              </Dropdown>
            </OutlineButton>
          )}
        </Reference>
        <Popper
          placement="bottom"
        >
          {({ ref, style, scheduleUpdate, placement }) => (
            <Options
              ref={ref}
              style={style}
              data-placement={placement}
              open={isOpen}
            >
              {renderOptions(scheduleUpdate)}
            </Options>
          )}
        </Popper>
      </Wrapper>
    </Manager>
  );
};

export default React.memo(CountrySelect);
