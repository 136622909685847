import React, { useContext, useState } from "react";
// import { toast } from "react-toastify";
import styled from "styled-components";
import * as ConnectChat from "../../components/connect/chatConnect";
import { UserContext } from "../../stores/UserStore";
// import * as socketIoClient from "../connect/socketIOClient";
// import { ChatIDContext } from "../../stores/ChatIDStore";

const Caller = (props) => {
  const { currentUser } = useContext(UserContext);
  // const {RegisterSocketChatEvents } = useContext(ChatIDContext);
  //const { chatID, setChatID } = useContext(ChatIDContext);
  //const history = useHistory();
  //const { setChatMessageCaller } = useContext(ChatMessageContext);
  //const [callerMessage, setCallerMessage] = useState(ChatMessageContext);
  // const [setCallerName] = useState("");
  const [listener, setListener] = useState(true);
 // const { t } = useTranslation();

  const Header = styled.div`
    font-family: Roboto;
    top: 0;
    width: 100vw;
    position: absolute;
    display: none;

    .CallingDiv {
      top: 50%;
      width: 200px;
      height: 100px;
      background-color: white;
      border: 1px groove #d1d1d1;
      border-radius: 0 0 10px 10px;
    }

    .Answer {
      border-radius: 100%;
      width: 50px;
      height: 50px;
      background-color: #4bfa4b;
      border: 0;
      margin-top: 10px;
      margin-bottom: 10px;
      cursor: pointer;
    }

    .Reject {
      border-radius: 100%;
      background-color: #ff5050;
      border: 0;
      width: 50px;
      height: 50px;
      margin-left: 25px;
      margin-top: 10px;
      margin-bottom: 10px;
      cursor: pointer;
    }
  `;

  // const handleRejectCall = () => {
  //   if (callerMessage !== null && callerMessage !== {}) {
  //     const Message = {
  //       ChatID: callerMessage.RequestID,
  //       MessageContent: "Rejected Video Chat",
  //       MessageSender: user.attributes.sub,
  //       MessageAttachment: "",
  //       MessageAction: JSON.stringify({
  //         RequestType: "RejectCall",
  //         ChatID: callerMessage.RequestID,
  //         SenderName: user.attributes.name,
  //         SenderID: user.attributes.sub,
  //         ReceiverID: "",
  //         Sdp: "",
  //         IceCandidate: { candidate: null, sdpMLineIndex: 0, sdpMid: null },
  //         IsOn: false,
  //         Hash: null,
  //       }),
  //     };
  //     socketIoClient.sendMessage(Message);
  //     ConnectChat.SendMessageToChat(
  //       chatID,
  //       "Rejected Video Chat",
  //       user.attributes.sub,
  //       "",
  //       JSON.stringify({
  //         RequestType: "RejectCall",
  //         ChatID: callerMessage.RequestID,
  //         SenderName: user.attributes.name,
  //         SenderID: user.attributes.sub,
  //         ReceiverID: "",
  //         Sdp: "",
  //         IceCandidate: { candidate: null, sdpMLineIndex: 0, sdpMid: null },
  //         IsOn: false,
  //         Hash: null,
  //       }),
  //       user.attributes.name
  //     );
  //   }
  // };

  // const handleAnswerCall = () => {
  //   if (callerMessage !== null && callerMessage !== {}) {
  //     setChatID(callerMessage.RequestID);
  //     const Action = JSON.parse(callerMessage.MessageAction);
  //     setChatMessageCaller(Action.Sdp);
  //     setTimeout(async function () {
  //       history.push("/VideoChat");
  //     }, 500);
  //   }
  // };

  const handleListener = () => {
    if (listener) {
      if (currentUser !== null) {
        var isAgent;
        if (currentUser.roles.ADMIN_ACCESS) {
          isAgent = true;
        } else {
          isAgent = false;
        }
        setTimeout(async function () {
          if (isAgent) {
            await ConnectChat.ListChatRoomsByAgent(currentUser.id);
          } else {
            await ConnectChat.ListChatRoomsByBuyerID(currentUser.id);
          }
          setTimeout(function () {
            const chats = ConnectChat.ReturnChatsUnOrdered("both");

            if (chats !== []) {
              chats.forEach((element) => {
                // console.log(element)
                // RegisterSocketChatEvents(element.RequestID)
                // socketIoClient.socket.on(
                //   "update|" + element.RequestID,
                //   function (msg) {
                //     // var message = JSON.parse(msg);
                //     if (
                //       msg.MessageAction === "" &&
                //       msg.SenderName !== "" &&
                //       msg.MessageContent !== "" &&
                //       msg.MessageSender !== currentUser.id
                //     ) {
                //       toast.success(
                //         msg.SenderName + " - " + msg.MessageContent
                //       );
                //     }
                //   }
                // );
              });

              // if (chats !== []) {
              //   chats.forEach((element) => {
              //     socketIoClient.socket.on(
              //       "update|" + element.RequestID,
              //       function (msg) {
              //         var mensagem = msg;
              //         if (
              //           mensagem.MessageAction !== null &&
              //           mensagem.MessageAction !== "" &&
              //           mensagem.SenderName !== currentUser.name
              //         ) {
              //           const convertedActions = JSON.parse(
              //             mensagem.MessageAction
              //           );

              //           if (
              //             convertedActions.RequestType !== null &&
              //             convertedActions.RequestType !== "" &&
              //             convertedActions.RequestType === "Offer"
              //           ) {
              //             setCallerMessage(mensagem);
              //             setCallerName(callerMessage.SenderName);
              //             if (document.getElementById("Caller") !== null) {
              //               document.getElementById("Caller").style.display =
              //                 "block";
              //             }
              //           } else if (
              //             convertedActions.RequestType !== null &&
              //             convertedActions.RequestType !== "" &&
              //             convertedActions.RequestType === "EndCall"
              //           ) {
              //             setCallerMessage({});
              //             if (document.getElementById("Caller") !== null) {
              //               document.getElementById("Caller").style.display =
              //                 "none";
              //             }
              //           } else if (
              //             convertedActions.RequestType !== null &&
              //             convertedActions.RequestType !== "" &&
              //             convertedActions.RequestType === "RejectCall"
              //           ) {
              //             setCallerMessage({});
              //             if (document.getElementById("Caller") !== null) {
              //               document.getElementById("Caller").style.display =
              //                 "none";
              //             }
              //           }
              //         }
              //       }
              //     );
              //   });
              // }
            }
          }, 1500);
        }, 500);
      }
      setListener(false);
    }
  };

  return (
    <Header id="Caller">
      {handleListener()}{" "}
      {/*
      <center>
        <div className="CallingDiv">
          {t("Header.Profile")}
          <div />
          <button className="Answer" onClick={handleAnswerCall}>
            <IoIosCall size="20px" />
          </button>
          <button className="Reject" onClick={handleRejectCall}>
            <MdCallEnd size="20px" />
          </button>
        </div>
      </center> */}
    </Header>
  );
};

export default Caller;
