import React, { useContext, useState } from "react";
import * as ConnectArticle from "../components/connect/articlesConnect";
import { ChatIDContext } from "../stores/ChatIDStore";

export const ArticlesContext = React.createContext();

export const ArticlesProvider = ({ children }) => {

    const [savedArticles, setSavedArticles] = useState([]);
    const [gotSavedData, setGotSavedData] = useState(false);
    const [schearchText, setSchearchText] = useState("");
    var TotalTimer = 3000;
    const { chatID, setChatID } = useContext(ChatIDContext);

    const handleGetAllArticles = () => {
        if (!gotSavedData) {
            setChatID("");
            ConnectArticle.ListArticles();
        }
    };

    const handleListChats = () => {
        if (!gotSavedData) {
            setTimeout(() => {
                setSavedArticles(ConnectArticle.ReturnArticles());
                setGotSavedData(true);
            }, TotalTimer);
        }
    };

    const handleSchearchTerm = () => {
        const SchearchTerm = document.getElementById("scearch").value;
        if (SchearchTerm !== "") {
            setSavedArticles(ConnectArticle.ReturnArticlesFiltered(SchearchTerm));
            setSchearchText(SchearchTerm);
        } else {
            setSavedArticles(ConnectArticle.ReturnArticles());
            setSchearchText(SchearchTerm);
        }
    };


    const ConvertTimezone = (oldDate) => {
        var date = new Date(oldDate),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2),
            hour = ("0" + (date.getHours() + 1)).slice(-2),
            minute = ("0" + (date.getMinutes() + 1)).slice(-2);

        var currentDate = new Date();

        if (date.toDateString() === currentDate.toDateString()) {
            return hour + ":" + minute;
        }
        return [day, mnth, date.getFullYear()].join("/");
    };

    
  const GetArticles = () => {
    ConnectArticle.ListArticles();
  };


  const GetArticleInfo = () => {
    const Article = ConnectArticle.ReturnArticlesID(chatID);
    console.log(Article);
    if (Article.length > 0) {
      setTimeout(() => {
        document.getElementById("IssueName").value = Article[0].ArticleTopic;
        document.getElementById("Score").value = Article[0].Score;
        document.getElementById("CreatedAt").value = Article[0].CreationDate;
        document.getElementById("LastAltered").value = Article[0].LastAltered;
        document.getElementById("Message").value = Article[0].ArticleText;
      }, 100);
    }
  };

    const renderArticles = (
        <ArticlesContext.Provider
            value={{
                savedArticles, setSavedArticles,
                gotSavedData, setGotSavedData,
                schearchText, setSchearchText,
                handleGetAllArticles,
                handleListChats,
                handleSchearchTerm,
                ConvertTimezone,
                GetArticles,
                GetArticleInfo
            }}
        >
            {children}
        </ArticlesContext.Provider>
    );

    return renderArticles;
};
