import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
// import CreateChatIcon from "../../assets/icons/Chat/CreateChat.png";
import MessageIcon from "../../assets/icons/Chat/MessageIcon.png";
import {
  Body,
  HelloTextBold,
  MainCard,
} from "../../components/common/MainCard";
import Footer from "../../components/layout/footer";
import Header from "../../components/layout/header";
import LoadingScreen from "../../components/layout/LoadingScreen";
import NavBar from "../../components/layout/navBar";
import { ChatIDContext } from "../../stores/ChatIDStore";
import { UserContext } from "../../stores/UserStore";
import { ChatSocketContext } from '../../stores/ChatSocketStore'
// import { AiFillCloseCircle } from "react-icons/ai";

const Messages = () => {
  document.title = "VideoChatMessages";

  const { currentUser } = useContext(UserContext);
  const history = useHistory();
  const {
    // setChatID,
    ConvertTimezone,
    checkForCurrentUserInfo,
    handleSchearchTerm,
    handleGetUnread,
    savedVideoChats,
    gotSavedVideoData,
    schearchVideoText,
    GetVideoChat,
    ConvertSecondsToString } = useContext(ChatIDContext);
  const { setChatId } = useContext(ChatSocketContext)
  const { t } = useTranslation();

  const handleGetChat = (ID) => {
    // setChatID(ID);
    setChatId(ID)
    history.push("/VideoChat");
  };

  //   const WhiteUnitBoxDiv = styled.div`
  //   display: flex;
  //   background-color: white;
  //   justify-content: center;
  //   padding: 10px 12px 5px 12px;
  //   margin-left: 10px;
  //   cursor: pointer;

  //   .active {
  //     color: #009ee0;
  //   }
  //   .switch {
  //     position: relative;
  //     display: inline-block;
  //     width: 35px;
  //     height: 15px;
  //     margin-left: 20px;
  //     margin-right: 20px;
  //     margin-bottom: 10px;
  //   }

  //   .switch input {
  //     opacity: 0;
  //     width: 0;
  //     height: 0;
  //   }

  //   .slider {
  //     position: absolute;
  //     cursor: pointer;
  //     top: 0;
  //     left: 0;
  //     right: 0;
  //     bottom: 0;
  //     background-color: #7abcf1;
  //     -webkit-transition: 0.4s;
  //     transition: 0.4s;
  //   }

  //   .slider:before {
  //     position: absolute;
  //     content: "";
  //     height: 20px;
  //     width: 20px;
  //     left: 0;
  //     bottom: -2.5px;
  //     background-color: #2196f3;
  //     -webkit-transition: 0.4s;
  //     transition: 0.4s;
  //   }

  //   input:checked + .slider {
  //     background-color: #7abcf1;
  //   }

  //   input:focus + .slider {
  //     box-shadow: 0 0 1px #7abcf1;
  //   }

  //   input:checked + .slider:before {
  //     -webkit-transform: translateX(16px);
  //     -ms-transform: translateX(16px);
  //     transform: translateX(16px);
  //   }

  //   /* Rounded sliders */
  //   .slider.round {
  //     border-radius: 34px;
  //   }

  //   .slider.round:before {
  //     border-radius: 50%;
  //   }
  // `;

  const SideBySideOptions = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    input {
      width: 95%;
      height: 20px;
      margin-left: 10px;
      padding: 8px;
      border: 0;
      border-radius: 10px;
      box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);
      :focus {
        outline: none;
      }
    }

    select {
      -webkit-appearance: none;
      padding: 7px 40px 7px 12px;
      width: 200px;
      margin-left: 10px;
      border: 0px;
      border-radius: 5px;
      background: white;
      box-shadow: 0 1px 3px -2px #9098a9;
      cursor: pointer;
      font-family: inherit;
      font-size: 16px;
      transition: all 150ms ease;
      :focus {
        outline: none;
      }
    }

    notify {
      background-color: #cc5200;
      width: 14px;
      height: 14px;
      color: white;
      border-radius: 50%;
      margin: -6px;
      display: inline-block;
      text-align: center;
      font-size: 12px;
      transform: translate(-4px, -13px);
    }

    button {
      padding-top: 5px;
      background-color: #009ee0;
      border: 0;
      color: #fff;
      margin-left: 10px;
      box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);
      transition: all 0.3s ease 0s;
      cursor: pointer;
      :hover {
        transform: translateY(-7px);
      }
      :focus {
        outline: none;
      }
    }
  `;

  const SavedDiv = styled.div`
    width: 98%;
    min-width: 450px;
    display: flex;
    flex-direction: column;
    color: #009ee0;
    background-color: white;
    border-radius: 0 10px 10px 0;
    font-size: calc(12px + 0.5vw);
    vertical-align: center;
    margin-bottom: 10px;
    transition: all 0.3s ease 0s;
    overflow-y: scroll;
    :hover {
      transform: translateX(3px);
    }
    .GrayText {
      color: #666666;
      font-size: calc(8px + 0.3vw);
    }
    .left {
      width: 90%;
      margin-left: 0;
      display: flex;
      align-items: center;
    }
    .information {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 10px;
      text-align: left;
    }
    .statusPending {
      width: 5%;
      font-size: 14px;
      color: white;
      background-color: #009ee0;
      border-radius: 0 10px 10px 0;
      margin-top: 10px;
      padding: 5px;
      padding-left: 2%;
    }
    .statusProcessed {
      width: 5%;
      font-size: 14px;
      color: white;
      background-color: #666666;
      border-radius: 0 10px 10px 0;
      margin-top: 10px;
      padding: 5px;
      padding-left: 2%;
    }
    .right {
      color: #666666;
      font-size: calc(8px + 0.3vw);
      display: flex;
      flex-direction: column;
      width: 6%;
      align-items: flex-end;
      justify-content: center;
    }
    .unread {
      color: white;
      display: flex;
    }
    .newChat {
      background-color: #e6f7ff;
    }
    .PopUp {
      overflow-y: scroll;
    }
    button {
      background-color: Transparent;
      background-repeat: no-repeat;
      border: none;
      cursor: pointer;
      overflow: hidden;
    }
    font {
      font-size: 16px;
      color: black;
      font-weight: bold;
    }
    img {
      margin-right: 10px;
    }
    span {
      font-size: 14px;
      display: inline-block;
      background-color: #93117E;
      border-radius: 10px;
      width: 7%;
      margin-top: -26px;
      padding: 5px;
      margin-left: 150px;
    }
  `;

  useEffect(() => {
    (async () => {
      await GetVideoChat()
      await checkForCurrentUserInfo()
      // window.location.reload()
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Body>
      <Header />
      <NavBar />
      {!gotSavedVideoData ? (
        <LoadingScreen />
      ) : (
        <MainCard id="MainCard">
          <HelloTextBold> {t("Messages.VideoChat")} </HelloTextBold>
          <SideBySideOptions>
            {" "}
            <input
              type="text"
              id="scearch"
              placeholder={t("Messages.Scearch")}
              onChange={handleSchearchTerm}
              defaultValue={schearchVideoText}
              autoFocus
            ></input>
            {/* <WhiteUnitBoxDiv>
              <div
                id="Pending"
                className={statusVideoFilter === "Pending" || statusVideoFilter === "In Progress" ||
                  statusVideoFilter === "InProgress" || statusVideoFilter === "In progress"
                  ? "active" : ""
                }
              >
                {t("Messages.Pending")}
              </div>
              <label className="switch">
                <input
                  type="checkbox"
                  onClick={() => handleGetAllByStatus()}
                  defaultChecked={statusVideoFilter === "Pending" || statusVideoFilter === "In Progress" ||
                    statusVideoFilter === "InProgress" || statusVideoFilter === "In progress" ? true : false}
                />
                <span className="slider round"></span>
              </label>
              <div
                id="Achieved"
                className={statusVideoFilter === "Achieved" ||
                  statusVideoFilter === "Completed"
                  ? "active"
                  : ""
                }
              >
                {t("Messages.Achieved")}
              </div>
            </WhiteUnitBoxDiv> */}
          </SideBySideOptions>
          {savedVideoChats.map((SavedChats, i) => (
            new Date(SavedChats.ScheduledDate) ?
              <div key={SavedChats.RequestID}>
                <SavedDiv>
                  <button onClick={() => handleGetChat(SavedChats.RequestID)}>
                    <div className="unread">
                      {handleGetUnread(SavedChats.UnreadMessages)}
                    </div>
                    <div className="information">
                      <div className="left">
                        <img src={MessageIcon} alt="Message Icon" />
                        <div>
                          <font> {SavedChats.Title}</font>
                          <div>
                            {SavedChats.Description}
                          </div>
                          <div>
                            {t("Messages.Duration")}: {ConvertSecondsToString(SavedChats.ScheduledTime / 60)}
                          </div>
                          {currentUser != null && currentUser.roles.ADMIN_ACCESS ?
                            <div>
                              {SavedChats.BuyerInfo !== undefined ?
                                <>
                                  {t("Messages.RequestFrom")} : {SavedChats.BuyerInfo.FirstName} {SavedChats.BuyerInfo.LastName} - {SavedChats.BuyerInfo.Company}
                                </>
                                : null}
                            </div>
                            :
                            <div>
                              {SavedChats.AgentInfo !== undefined ?
                                <>
                                  {t("Messages.Agent")}: {SavedChats.AgentInfo.FirstName} {SavedChats.AgentInfo.LastName} - {SavedChats.AgentInfo.Company}
                                </>
                                : null}
                            </div>
                          }
                        </div>
                      </div>
                      <div className="right">
                        {" "}
                        {ConvertTimezone(SavedChats.CreatedAt)}
                      </div>
                    </div>
                  </button>
                </SavedDiv>
              </div> : null

          ))}
        </MainCard>
      )
      }
      <Footer />
    </Body >
  );
};

export default Messages;
