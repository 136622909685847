import React, { useContext } from "react";
import { Bar, Line, Pie } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Body, MainCard } from "../../components/common/MainCard";
import Caller from "../../components/layout/caller";
import Footer from "../../components/layout/footer";
import Header from "../../components/layout/header";
import LoadingScreen from "../../components/layout/LoadingScreen";
import NavBar from "../../components/layout/navBar";
import { DashboardContext } from "../../stores/DashboardStore";

const Dashboard = () => {
  document.title = "VAM® Field Service";
  const { t } = useTranslation();
  const {
    gotSavedData,
    gotRefreshData,
    initialDate,
    finishDate,
    listDashboard,
    options,
    optionsPie,
    handleRefreshData,
    handleGetInitialData
  } = useContext(DashboardContext)

  const GraphLine = styled.div`
    display: flex;
    justify-content: space-between;
    width: 95%;
    height: 40%;
    margin-bottom: 10px;
    margin-left: 10px;
  `;

  const DateChooser = styled.div`
    display: flex;
    justify-content: flex-end;
    vertical-align: center;
    width: 95%;

    .Fields {
      margin: 10px;
    }
    button {
      margin: 10px;
      height: 50px;
      width: 100px;
      background-color: #009ee0;
      color: white;
      box-shadow: 0px 15px 20px rgba(167, 164, 206, 0.4);
      transition: all 0.3s ease 0s;
      border: 0;
      border-radius: 25%;
      :hover {
        background-color: #fff;
        color: #009ee0;
        transform: translateX(7px);
      }
    }
  `;

  const GraphBar = styled.div`
    width: 30%;
    background-color: white;
    padding: 10px;
    margin-left: 10px;
  `;

  const GraphBarBig = styled.div`
    width: 50%;
    background-color: white;
    padding: 10px;
    margin-left: 10px;
  `;

  return (
    <Body>
      {handleGetInitialData()}
      <Header />
      <Caller />
      <span style={{ display: !gotSavedData ? "block" : "none" }}>
        <LoadingScreen />
      </span>
      <span style={{ display: gotRefreshData ? "block" : "none" }}>
        <LoadingScreen />
      </span>
      <NavBar />
      <MainCard
        id="MainCard"
        style={{ display: (gotSavedData && !gotRefreshData) ? "block" : "none" }}
      >
        <DateChooser>
          {" "}
          <div className="Fields">
            {t("Dashboard.InitialDate")} <div />
            <input
              type="date"
              id="InitialDate"
              defaultValue={initialDate}
            ></input>
          </div>{" "}
          <div className="Fields">
            {t("Dashboard.FinishDate")}
            <div />
            <input
              type="date"
              id="FinishDate"
              defaultValue={finishDate}
            ></input>
          </div>{" "}
          <button onClick={handleRefreshData}> {t("Dashboard.Refresh")}</button>
        </DateChooser>
        <GraphLine>
          <GraphBar>
            <Bar data={listDashboard[0]} options={options} />
          </GraphBar>
          <GraphBar>
            <Bar data={listDashboard[1]} options={options} />
          </GraphBar>
          <GraphBar>
            <Pie data={listDashboard[4]} options={optionsPie} />
          </GraphBar>
        </GraphLine>
        <GraphLine>
          <GraphBarBig>
            <Line data={listDashboard[2]} options={options} />
          </GraphBarBig>
          <GraphBarBig>
            <Line data={listDashboard[3]} options={options} />
          </GraphBarBig>
        </GraphLine>
      </MainCard>
      <Footer />
    </Body>
  );
};

export default Dashboard;
