import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import BackgroundImage from "../../assets/images/background.jpg";
import Logo from "../../assets/images/logo.png";
import { Button, Card, FlexView } from "../../components/common";

const Background = styled.div`
  background: url(${BackgroundImage}) no-repeat center center fixed;
  background-size: cover;
  background-color: ${({ theme }) => theme.colors.background};
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

// const Copyrights = styled.div`
//   color: ${({ theme }) => theme.colors.gray};
// `

const ContentText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.gray};
  font-family: Roboto;
  text-align: justify;
  width: 100%;
`;

const Anchor = styled.a`
  text-decoration: none;
`;
const LegalNotice = () => {
  const { t } = useTranslation();

  return (
    <Background>
      <Card
        width="90vw"
        height="80vh"
        elevation="veryHigh"
        padding="24px"
        justifyContent="space-between"
        alignItems="stretch"
        style={{ overflow: "auto" }}
      >
        <FlexView
          flexDirection="row"
          alignItems="flex-end"
          justifyContent="space-between"
          width="100%"
        >
          <Anchor href="/">
            <Button width="140px" backgroundColor="primary" color="white">
              {t("Go Back")}
            </Button>
          </Anchor>
          <img src={Logo} alt="Application Logo" width="120px" />
        </FlexView>
        <FlexView
          flex="1 0"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <ContentText>
            <h1> {t("legal_notice.title_uc")} </h1>
            <h2> {t("legal_notice.site_description_title_UC")} </h2>
            <p> {t("legal_notice.site_description")}</p>
            <h2> {t("legal_notice.editor_title_UC")} </h2>
            <p> {t("legal_notice.editor_1")}</p>
            <p> {t("legal_notice.editor_2")}</p>
            <p> {t("legal_notice.editor_3")} </p>
            <p> {t("legal_notice.editor_4")}</p>
            <p> {t("legal_notice.editor_5")} </p>
            <p> {t("legal_notice.editor_6")} </p>
            <p> {t("legal_notice.editor_7")} </p>
            <h2> {t("legal_notice.hosting_title_UC")} </h2>
            <p> {t("legal_notice.hosting_1")}</p>
            <p> {t("legal_notice.hosting_2")}</p>
            <p> {t("legal_notice.hosting_3")}</p>
            <p> {t("legal_notice.hosting_4")}</p>
          </ContentText>
          <Anchor href="/">
            <Button
              width="140px"
              backgroundColor="primary"
              color="white"
              margin="10px 5px"
            >
              {t("Go Back")}
            </Button>
          </Anchor>
        </FlexView>
        {/* <Copyrights></Copyrights> */}
      </Card>
    </Background>
  );
};

export default LegalNotice;
