import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import MessageIcon from "../../assets/icons/Chat/MessageIcon.png";
import {
  Body,
  HelloTextBold,
  MainCard,
} from "../../components/common/MainCard";
import * as OnSiteSupportConnect from "../../components/connect/OnSiteSupport";
import Caller from "../../components/layout/caller";
import Footer from "../../components/layout/footer";
import Header from "../../components/layout/header";
import LoadingScreen from "../../components/layout/LoadingScreen";
import NavBar from "../../components/layout/navBar";
import { OnSiteSupportContext } from "../../stores/OnSiteSupportStore";
import { UserContext } from "../../stores/UserStore";

const ListOnSiteSupport = () => {
  document.title = "VAM® Field Service";
  const [OnSiteSupport, setOnSiteSupport] = useState([]);
  const [gotSavedData, setGotSavedData] = useState(false);
  //const [schearchText, setSchearchText] = useState("");
  const [schearchText] = useState("");
  const { currentUser } = useContext(UserContext);
  const { setOnSiteSupportObject } = useContext(OnSiteSupportContext);
  const history = useHistory();
  const { t } = useTranslation();

  const handleGetOnSiteSupport = (BuyerID) => {
    if (!gotSavedData) {
      setTimeout(async () => {
        var result = await OnSiteSupportConnect.ListOnSiteSuportByBuyerID(
          BuyerID
        );
        setOnSiteSupport(result);
        setGotSavedData(true);
        console.log(result);
      }, 50);
    }
  };

  const checkForCurrentUserInfo = () => {
    if (currentUser == null) {
      toast.error("Error Getting User Info");
    }
  };

  const handleGetOnSiteSupportByAgent = (BuyerID) => {
    if (!gotSavedData) {
      setTimeout(async () => {
        var result = await OnSiteSupportConnect.ListOnSiteSuportByAgentID(
          BuyerID
        );
        setOnSiteSupport(result);
        setGotSavedData(true);
        console.log(result);
      }, 50);
    }
  };

  const handleSchearchTerm = () => {
    // const SchearchTerm = document.getElementById("scearch").value;
    // if (SchearchTerm !== "") {
    //   setOnSiteSupport(ConnectChat.ReturnChatsFiltered(SchearchTerm));
    //   setSchearchText(SchearchTerm);
    // } else {
    //   setOnSiteSupport(ConnectChat.ReturnChats("Chat"));
    //   setSchearchText(SchearchTerm);
    // }
  };

  const handleUpdateOnSiteSupport = (element) => {
    setOnSiteSupportObject(element);
    history.push("/OnSiteSupport");
  };

  const ConvertTimezone = (oldDate) => {
    var date = new Date(oldDate),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2),
      hour = ("0" + (date.getHours() + 1)).slice(-2),
      minute = ("0" + (date.getMinutes() + 1)).slice(-2);

    var currentDate = new Date();

    if (date.toDateString() === currentDate.toDateString()) {
      return hour + ":" + minute;
    }
    return [day, mnth, date.getFullYear()].join("/");
  };

  const SideBySideOptions = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    input {
      width: 95%;
      height: 20px;
      margin-left: 10px;
      padding: 8px;
      border: 0;
      border-radius: 10px;
      box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);
      :focus {
        outline: none;
      }
    }

    select {
      -webkit-appearance: none;
      padding: 7px 40px 7px 12px;
      width: 200px;
      margin-left: 10px;
      border: 0px;
      border-radius: 5px;
      background: white;
      box-shadow: 0 1px 3px -2px #9098a9;
      cursor: pointer;
      font-family: inherit;
      font-size: 16px;
      transition: all 150ms ease;
      :focus {
        outline: none;
      }
    }

    button {
      padding-top: 5px;
      background-color: #009ee0;
      border: 0;
      color: #fff;
      margin-left: 10px;
      box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);
      transition: all 0.3s ease 0s;
      cursor: pointer;
      :hover {
        transform: translateY(-7px);
      }
      :focus {
        outline: none;
      }
    }
  `;

  const SavedDiv = styled.div`
    width: 98%;
    min-width: 450px;
    display: flex;
    flex-direction: column;
    color: #009ee0;
    background-color: white;
    border-radius: 0 10px 10px 0;
    font-size: calc(12px + 0.5vw);
    vertical-align: center;
    margin-bottom: 10px;
    transition: all 0.3s ease 0s;
    :hover {
      transform: translateX(3px);
    }
    .GrayText {
      color: #666666;
      font-size: calc(8px + 0.3vw);
    }
    .left {
      width: 90%;
      margin-left: 0;
      display: flex;
      align-items: center;
    }
    .information {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 10px;
      text-align: left;
    }
    .statusPending {
      width: 5%;
      font-size: 14px;
      color: white;
      background-color: #009ee0;
      border-radius: 0 10px 10px 0;
      margin-top: 10px;
      padding: 5px;
      padding-left: 2%;
    }
    .statusProcessed {
      width: 5%;
      font-size: 14px;
      color: white;
      background-color: #666666;
      border-radius: 0 10px 10px 0;
      margin-top: 10px;
      padding: 5px;
      padding-left: 2%;
    }
    .right {
      color: #666666;
      font-size: calc(8px + 0.3vw);
      display: flex;
      flex-direction: column;
      width: 6%;
      align-items: flex-end;
      justify-content: center;
    }
    .unread {
      color: white;
      display: flex;
      width: 4%;
      justify-content: center;
      align-items: center;
    }
    button {
      background-color: Transparent;
      background-repeat: no-repeat;
      border: none;
      cursor: pointer;
      overflow: hidden;
    }
    font {
      font-size: 16px;
      color: black;
    }
    img {
      margin-right: 10px;
    }
    span {
      background-color: #8d1677;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      display: inline-block;
      text-align: center;
      font-size: 16px;
    }
  `;

  return (
    <Body>
      {currentUser != null && !currentUser.roles.ADMIN_ACCESS
        ? handleGetOnSiteSupport(currentUser.id)
        : handleGetOnSiteSupportByAgent(currentUser.id)}
      {checkForCurrentUserInfo()}
      <Header />
      <Caller />
      <NavBar />
      {!gotSavedData ? (
        <LoadingScreen />
      ) : (
        <MainCard id="MainCard">
          <HelloTextBold>
            {" "}
            {t("ListOnSiteSupport.ListOnSiteSupport")}{" "}
          </HelloTextBold>
          <SideBySideOptions>
            {" "}
            <input
              type="text"
              id="scearch"
              placeholder={t("Messages.Scearch")}
              onChange={handleSchearchTerm}
              defaultValue={schearchText}
              autoFocus
            ></input>
          </SideBySideOptions>

          {OnSiteSupport.map((OnSiteSupportElement) => (
            <div key={OnSiteSupportElement.OnSiteSupportID}>
              <SavedDiv>
                <button
                  onClick={() =>
                    handleUpdateOnSiteSupport(OnSiteSupportElement)
                  }
                >
                  <div
                    className={
                      OnSiteSupportElement.OnSiteSupportStatus === "pending"
                        ? "statusPending"
                        : "statusProcessed"
                    }
                  >
                    {t("Messages." + OnSiteSupportElement.OnSiteSupportStatus)}
                  </div>
                  <div className="information">
                    <div className="left">
                      <img src={MessageIcon} alt="Message Icon" />

                      <div>
                        <font>
                          {" "}
                          Type:{" "}
                          {OnSiteSupportElement.ServiceType.charAt(
                            0
                          ).toUpperCase() +
                            OnSiteSupportElement.ServiceType.slice(1)}
                        </font>
                        {currentUser != null && currentUser.roles.ADMIN_ACCESS && (
                          <div>
                            Customer:{" "}
                            {OnSiteSupportElement.FirstName +
                              " " +
                              OnSiteSupportElement.LastName}{" "}
                          </div>
                        )}
                        {currentUser != null && currentUser.roles.ADMIN_ACCESS && (
                          <div>
                            Scheduled To:{" "}
                            {ConvertTimezone(OnSiteSupportElement.ServiceDate)}{" "}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="right">
                      {" "}
                      {ConvertTimezone(OnSiteSupportElement.LastAltered)}
                    </div>
                  </div>
                </button>
              </SavedDiv>
            </div>
          ))}
        </MainCard>
      )}
      <Footer />
    </Body>
  );
};

export default ListOnSiteSupport;
