import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  Body,
  HelloText,
  HelloTextBold,
  MainCard,
} from "../../components/common/MainCard";
import * as ConnectChat from "../../components/connect/articlesConnect";
import Footer from "../../components/layout/footer";
import Header from "../../components/layout/header";
import NavBar from "../../components/layout/navBar";
import { UserContext } from "../../stores/UserStore";

const WhiteCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  color: black;
  padding: 10px;
  width: 95%;
  height: 100%;
  margin-top: 20px;
  .Field {
    display: flex;
    align-items: center;
    justify-content: stretch;
  }
  .FieldText {
    width: 7%;
    padding: 5px;
    text-align: left;
  }
  input {
    flex: 1;
    height: 20px;
    margin: 5px;
    padding: 8px;
    border: 0;
    border-radius: 10px;
    box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);
    :focus {
      outline: none;
    }
  }
  select {
    flex: 1;
    height: 40px;
    margin: 5px;
    padding: 8px;
    border: 0;
    border-radius: 10px;
    box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);
    :focus {
      outline: none;
    }
    option {
      padding: 16px 16px;
      border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
      cursor: pointer;
      border: 0px;
      outline: 0px;
    }
  }
  textarea {
    resize: none;
    border: 0;
    margin: 10px;
    border-radius: 10px;
    margin-top: 10px;
    box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);
    :focus {
      outline: none;
    }
  }
`;

const ButtonsDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  button {
    min-height: 50px;
    min-width: 150px;
    margin-right: 20px;
    margin-top: 20px;
    background-color: #009ee0;
    color: #fff;
    border: 0px;
    border-radius: 10px;
    transition: all 0.3s ease 0s;
    outline: none;
    :hover {
      background-color: #fff;
      box-shadow: 0px 15px 20px rgba(167, 164, 206, 0.4);
      color: #009ee0;
    }
  }
`;

const CreateArticles = () => {
  document.title = "VAM® Field Service";
  const history = useHistory();
  const isSmallScreen = window.screen.width <= 1366;
  const { user } = useContext(UserContext);

  const handleCreateArticle = () => {
    const IssueName = document.getElementById("IssueName").value;
    const Message = document.getElementById("Message").value;

    ConnectChat.CreateArticle(
      user.attributes.sub,
      user.attributes.name,
      Message,
      IssueName
    );
    history.push("/Articles");
  };

  return (
    <Body>
      <Header />
      <NavBar />
      <MainCard id="MainCard">
        <HelloTextBold>New Article</HelloTextBold>
        <HelloText>Fill the informations below</HelloText>
        <center>
          <WhiteCard>
            <div className="Field">
              <div className="FieldText">Topic: </div>
              <input type="text" id="IssueName" placeholder="Name ..."></input>
            </div>
            <div className="Field">
              {" "}
              <div className="FieldText">Article: </div>
            </div>
            <textarea
              rows="25"
              id={"Message"}
              placeholder="Type here your article...."
            ></textarea>
            <ButtonsDiv style={{ bottom: isSmallScreen ? "15%" : "13%" }}>
              <button onClick={handleCreateArticle}>Submit</button>
            </ButtonsDiv>
          </WhiteCard>
        </center>
      </MainCard>
      <Footer />
    </Body>
  );
};

export default CreateArticles;
