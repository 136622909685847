import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineCloudUpload } from 'react-icons/ai'
import { FaArrowAltCircleRight } from 'react-icons/fa'
import { GiPencilRuler } from 'react-icons/gi'
import { IoCreateOutline } from 'react-icons/io5'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import MakeUpGraphBlue from '../../assets/images/LandingPage/MakeUpGraphBlue.png'
import { Body, HelloText, HelloTextBold, MainCard } from '../../components/common/MainCard'
import Caller from '../../components/layout/caller'
import Footer from '../../components/layout/footer'
import Header from '../../components/layout/header'
import NavBar from '../../components/layout/navBar'
import { GraphContext } from '../../stores/GraphStore'
import { UserContext } from '../../stores/UserStore'

const MakeUpGraphAssistance = () => {
  document.title = 'VAM® Field Service'

  const { graph, graphUnitMesure, setGraphUnitMesure } = useContext(GraphContext)
  const {
    isThereAnRequestID,
    allowFinish,
    errorNumber,
    errorType,
    errorSize,
    uploadedFile,
    overlayUnit,
    overlayDetail,
    returnedFromForm,
    CheckForMakeUpGraphID,
    handleUploadToLambda,
    handleUpload,
    showUpload,
    showCreate,
    showUnit,
    handleListFiles,
    handleIncreaseGraphs,
    handleDecreaseGraphs,
    PopulateListFiles,
    handleChangeMetricUnit,
    SaveNumberGraphs,
  } = useContext(GraphContext)

  const { currentUser } = useContext(UserContext)
  const { t } = useTranslation()

  const history = useHistory()

  const handleDetails = () => {
    setGraphUnitMesure(graphUnitMesure)
    history.push('/MakeUpGraphForm')
  }

  const SideBySideDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 25px;
  `

  const Overlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 15%;
    background-color: black;
  `

  const NextStepButton = styled.div`
    position: absolute;
    bottom: 10px;
    right: 10px;
  `

  const SideBySideOption = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-right: 25px;
    margin-left: 30px;
  `

  const FinishRequest = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      margin-left: auto;
      margin-right: auto;
      min-height: 50px;
      margin: 20px;
      width: 30%;
      padding: 10px;
      background-color: #009ee0;
      color: #fff;
      margin-bottom: 20px;
      font-size: 24px;
      box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);
      transition: all 0.3s ease 0s;
      border-radius: 10px;
      border: 0;
      :focus {
        outline: none;
      }
      :hover {
        background-color: #fff;
        color: #009ee0;
        transform: translateX(7px);
      }
    }
  `

  const ProgressDiv = styled.div`
    justify-content: center;
    display: flex;
    width: 100%;
  `

  const ProgressCard = styled.div`
    padding: 10px;
    background-color: white;
    display: flex;
    text-align: center;
    justify-content: center;
    min-width: 400px;
    width: 40%;
    margin-bottom: 10px;

    .whiteDot {
      position: relative;
      height: 25px;
      width: 25px;
      padding: 2px;
      box-shadow: 0 0 0 3px #f0f0f0;
      border-radius: 50%;
      display: inline-block;
    }
    .blueDot {
      position: absolute;
      left: 2.5px;
      height: 25px;
      width: 25px;
      background-color: #009ee0;
      border-radius: 50%;
      display: inline-block;
    }
    .grayDot {
      position: absolute;
      left: 2.5px;
      height: 25px;
      width: 25px;
      background-color: #e4e3e3;
      border-radius: 50%;
      display: inline-block;
    }
    font {
      font-weight: bold;
    }
  `

  const ProgressLine = styled.span`
    display: block;
    width: 15%;
    margin-top: 12px;
    margin-left: 10px;
    margin-right: 10px;
    border-top: 1px solid #e4e2e2;
  `

  const Card = styled.div`
    color: #009ee0;
    background-color: white;
    width: 24%;
    height: 300px;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding-top: 10px;
    position: relative;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    .Title {
      font-size: calc(12px + 0.5vw);
      color: #0f516d;
    }

    .Upload {
      border-radius: 10px;
      width: 100%;
      max-width: 300px;
      margin-top: 10px;
      margin-bottom: 10px;
      background-color: #009ee0;
      color: white;
      border: 0;
      min-width: 50px;
      min-height: 50px;
      font-size: calc(8px + 0.8vw);
      cursor: pointer;
      outline: none;
      vertical-align: center;
      transition: all 0.3s ease 0s;
      :hover {
        background-color: #fff;
        box-shadow: 0px 15px 20px rgba(167, 164, 206, 0.4);
        color: #009ee0;
      }
    }

    .TransparentButton {
      background-color: Transparent;
      background-repeat: no-repeat;
      border: none;
      cursor: pointer;
      overflow: hidden;
      color: #009ee0;
    }

    img {
      padding-bottom: 24px;
      max-width: 50px;
    }

    .NumberOfGraphButtonStyle {
      background-color: #009ee0;
      color: white;
      border: 0;
      min-width: 50px;
      min-height: 50px;
      font-size: calc(8px + 0.8vw);
      cursor: pointer;
      outline: none;
      vertical-align: center;
      transition: all 0.3s ease 0s;
      border-radius: 10px 0 0 10px;
      :hover {
        background-color: #fff;
        box-shadow: 0px 15px 20px rgba(167, 164, 206, 0.4);
        color: #009ee0;
      }
    }

    input + .NumberOfGraphButtonStyle {
      border-radius: 0 10px 10px 0;
    }

    input {
      min-width: 50px;
      min-height: 50px;
      background-color: #faf6f6;
      border: 0;
      text-align: center;
      vertical-align: center;
      outline: none;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .BottomOfCard {
      position: absolute;
      bottom: 10px;
      justify-content: center;
      .Subtitle {
        font-size: 12px;
        text-align: left;
        color: gray;
        margin-left: 20px;
      }
    }
  `

  const WhiteUnitBoxDiv = styled.div`
    display: flex;
    padding-top: 30px;
    background-color: white;
    justify-content: center;
    color: black;
    .active {
      color: #009ee0;
    }
    .switch {
      position: relative;
      display: inline-block;
      width: 35px;
      height: 15px;
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 10px;
    }

    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #7abcf1;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: '';
      height: 20px;
      width: 20px;
      left: 0;
      bottom: -2.5px;
      background-color: #2196f3;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    input:checked + .slider {
      background-color: #7abcf1;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #7abcf1;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(16px);
      -ms-transform: translateX(16px);
      transform: translateX(16px);
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  `

  const ErrorMessage = styled.div`
    display: none;
    color: #ff0033;
    font-size: calc(8px + 0.3vw);
    padding-bottom: 10px;
  `

  return (
    <Body>
      {CheckForMakeUpGraphID()}
      <Header />
      <Caller />
      <NavBar />
      <MainCard id='MainCard'>
        <HelloTextBold>{t('MakeUpGraphAssistance.MakeupGraphReview')}</HelloTextBold>
        <HelloText> {t('MakeUpGraphAssistance.Assistant')}</HelloText>

        <ProgressDiv>
          <ProgressCard>
            <div>
              <span className='whiteDot'>
                {' '}
                <span className='blueDot'></span>
              </span>
              <div />
              <font> {t('MakeUpGraphAssistance.Number')}</font> <div /> {t('MakeUpGraphAssistance.ofGraphs')}
            </div>
            <ProgressLine />
            <div>
              <span className='whiteDot'>
                {' '}
                <span className='blueDot' style={{ display: !overlayUnit ? 'block' : 'none' }}></span>
                <span className='blueDot' style={{ display: returnedFromForm ? 'block' : 'none' }}></span>
                <span
                  className='grayDot'
                  style={{
                    display: overlayUnit ? (!returnedFromForm ? 'block' : 'none') : 'none',
                  }}
                ></span>
              </span>
              <div />
              <font>{t('MakeUpGraphAssistance.Mesuring')}</font> <div />
              {t('MakeUpGraphAssistance.Unit')}
            </div>
            <ProgressLine />
            <div>
              <span className='whiteDot'>
                {' '}
                <span className='blueDot' style={{ display: !overlayDetail ? 'block' : 'none' }}></span>
                <span className='blueDot' style={{ display: returnedFromForm ? 'block' : 'none' }}></span>
                <span
                  className='grayDot'
                  style={{
                    display: overlayDetail ? (!returnedFromForm ? 'block' : 'none') : 'none',
                  }}
                ></span>
              </span>
              <div />
              <font> {t('MakeUpGraphAssistance.Detail')}</font> <div />
              {t('MakeUpGraphAssistance.Graphs')}
            </div>
            <ProgressLine />
            <div>
              <span className='whiteDot'>
                {' '}
                <span className='blueDot' style={{ display: uploadedFile ? 'block' : 'none' }}></span>
                <span className='grayDot' style={{ display: !uploadedFile ? 'block' : 'none' }}></span>
              </span>
              <div />
              <font> {t('MakeUpGraphAssistance.Upload')}</font> <div /> {t('MakeUpGraphAssistance.MakeupGraphs')}
            </div>
          </ProgressCard>
        </ProgressDiv>

        <SideBySideOption>
          <Card>
            <img alt='Graph Icon' src={MakeUpGraphBlue} />
            <div className='Title'>{t('MakeUpGraphAssistance.Numberofmakeupgraphs')}</div>
            <SideBySideDiv>
              <button className='NumberOfGraphButtonStyle' onClick={handleDecreaseGraphs}>
                -
              </button>
              <input type='number' defaultValue={graph} onChange={SaveNumberGraphs} id='GraphAmount' />
              <button className='NumberOfGraphButtonStyle' onClick={handleIncreaseGraphs}>
                +
              </button>
            </SideBySideDiv>
            <ErrorMessage style={{ display: errorNumber ? 'block' : 'none' }}>
              {t('MakeUpGraphAssistance.Numbermustbeabovezero')}
            </ErrorMessage>
            <NextStepButton>
              <button className='TransparentButton' onClick={showUnit}>
                <FaArrowAltCircleRight size='25px' />
              </button>
            </NextStepButton>
          </Card>
          <Card>
            <Overlay
              style={{
                display: overlayUnit ? (!returnedFromForm ? 'block' : 'none') : 'none',
              }}
            />
            <GiPencilRuler size='40px' style={{ paddingBottom: '20px' }} />
            <div className='Title'>{t('MakeUpGraphAssistance.MesuringUnit')}</div>
            <WhiteUnitBoxDiv>
              <div
                id='Metric'
                className={
                  currentUser !== null && currentUser.measuringUnit !== null && currentUser.measuringUnit === 'Metric'
                    ? 'active'
                    : ''
                }
              >
                {t('MakeUpGraphAssistance.Metric')}
              </div>
              <label className='switch'>
                <input
                  type='checkbox'
                  onClick={handleChangeMetricUnit}
                  defaultChecked={
                    currentUser !== null &&
                    currentUser.measuringUnit !== null &&
                    currentUser.measuringUnit === 'Imperial'
                      ? true
                      : false
                  }
                />
                <span className='slider round'></span>
              </label>
              <div
                id='Imperial'
                className={
                  currentUser !== null && currentUser.measuringUnit !== null && currentUser.measuringUnit === 'Imperial'
                    ? 'active'
                    : ''
                }
              >
                {t('MakeUpGraphAssistance.Imperial')}
              </div>
            </WhiteUnitBoxDiv>
            <NextStepButton>
              <button className='TransparentButton' onClick={showUpload}>
                <FaArrowAltCircleRight size='25px' />
              </button>
            </NextStepButton>
          </Card>
          <Card>
            <Overlay
              style={{
                display: overlayDetail ? (!returnedFromForm ? 'block' : 'none') : 'none',
              }}
            />{' '}
            <IoCreateOutline size='50px' style={{ paddingBottom: '24px' }} />
            <div className='Title'> {t('MakeUpGraphAssistance.DetailyourMakeUpGraphs')}</div>
            <button className='Upload' id='CreateGraph' onClick={handleDetails}>
              {t('MakeUpGraphAssistance.Detail')}
            </button>
            <NextStepButton>
              <button
                className='TransparentButton'
                onClick={showCreate}
                style={{ display: isThereAnRequestID ? 'block' : 'none' }}
              >
                <FaArrowAltCircleRight size='25px' />
              </button>
              <button className='TransparentButton' style={{ display: !isThereAnRequestID ? 'block' : 'none' }}>
                <FaArrowAltCircleRight size='25px' />
              </button>
            </NextStepButton>
          </Card>
          <Card>
            <Overlay
              style={{
                display: overlayDetail ? (!returnedFromForm ? 'block' : 'none') : 'none',
              }}
            />{' '}
            <AiOutlineCloudUpload size='50px' style={{ paddingBottom: '24px' }} />
            <div className='Title'>{t('MakeUpGraphAssistance.Uploadyourmakeupgraphs')}</div>
            <font>{t('MakeUpGraphAssistance.Addupto20MB')}</font>
            <input onChange={handleListFiles} type='file' id='file' multiple style={{ display: 'none' }} />
            <button type='button' className='Upload' onClick={handleUpload}>
              {t('MakeUpGraphAssistance.Upload')}
            </button>
            <ErrorMessage style={{ display: errorSize ? 'block' : 'none' }}>
              {' '}
              {t('MakeUpGraphAssistance.Thefilessizeexceeds20MB')}
            </ErrorMessage>
            <ErrorMessage style={{ display: errorType ? 'block' : 'none' }}>
              {t('MakeUpGraphAssistance.Oneormanyfilestypesincorrect')}
            </ErrorMessage>
            <div className='BottomOfCard'>{PopulateListFiles()}</div>
          </Card>
        </SideBySideOption>
        <FinishRequest>
          <button
            className='TransparentButton'
            onClick={handleUploadToLambda}
            style={{ display: allowFinish ? 'block' : 'none' }}
          >
            {t('MakeUpGraphAssistance.FinishTheRequest')}
          </button>
          <button className='TransparentButton' style={{ display: !allowFinish ? 'block' : 'none' }}>
            {t('MakeUpGraphAssistance.FinishTheRequest')}
          </button>
        </FinishRequest>
      </MainCard>
      <Footer />
    </Body>
  )
}

export default MakeUpGraphAssistance
