import React, { useState, useContext } from "react";
import Cookies from "js-cookie";
import * as ConnectChat from "../components/connect/chatConnect";
import * as ConnectMakeUpGraphReview from "../components/connect/makeUpGraphRequestConnect";
import * as ConnectOnSiteSupport from "../components/connect/OnSiteSupport";
import * as ConnectTransactions from "../components/connect/transaction";
import { UserContext } from "../stores/UserStore";

const initialState = {
    item: "",
};

export const LandingPageContext = React.createContext(initialState);

export const LandingPageProvider = ({ children }) => {

    const [showRigSite, setShowRigSite] = useState(true);
    const [showAssembly, setShowAssembly] = useState(true);
    const [showYard, setShowYard] = useState(true);
    const [showMakeUpGraph, setShowMakeUpGraph] = useState(true);
    const [gotSettings, setGotSettings] = useState(false);
    const [gotCustomerInformation, setGotCustomerInformation] = useState(false);
    const [amountGraphs, setAmountGraphs] = useState([]);
    const [timeElapsed, setTimeElapsed] = useState("");
    const [nextAppointment, setNextAppointment] = useState("");
    const [listOfOnSiteSupports, setListOfOnSiteSupports] = useState([]);
    const [completeListOfOnSiteSupports, setCompleteListOfOnSiteSupports] = useState([]);
    const [showHiddenInfo, setShowHiddenInfo] = useState(false);
    const { currentUser } = useContext(UserContext);


    const GetSettingsFromUser = async () => {
        if (!gotSettings) {
            if (currentUser != null) {
                console.log(currentUser);
            }
            if (Cookies.get("showRigSite")) {
                const settingsShowRigSite = Cookies.get("showRigSite") === "true";
                const settingsShowAssembly = Cookies.get("showAssembly") === "true";
                const settingsShowYard = Cookies.get("showYard") === "true";
                const settingsMakeUpGraph = Cookies.get("showMakeUpGraph") === "true";

                if (
                    settingsShowRigSite !== undefined &&
                    settingsShowAssembly !== undefined &&
                    settingsShowYard !== undefined &&
                    settingsMakeUpGraph !== undefined
                ) {
                    setShowRigSite(settingsShowRigSite);
                    setShowAssembly(settingsShowAssembly);
                    setShowYard(settingsShowYard);
                    setShowMakeUpGraph(settingsMakeUpGraph);
                }
            }
            setGotSettings(true);
        }
    };

    const ConvertTimezone = (oldDate) => {
        var date = new Date(oldDate),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + (date.getDate() + 1)).slice(-2);

        return [day, mnth, date.getFullYear()].join("/");
    };

    const ConvertMinutesToString = (TotalMinutes) => {
        var Hours = 0;
        var Minutes = 0;

        Hours = Math.floor(TotalMinutes / 60);
        Minutes = ("0" + Math.floor(TotalMinutes % 60)).slice(-2);

        return Hours + "h " + Minutes + "min";
    };

    const GetCustomerData = async () => {
        if (!gotCustomerInformation) {
            // setTimeout(async () => {
            await ConnectMakeUpGraphReview.ListMakeUpGraphRequests(currentUser.id);
            var OnSiteSupport = await ConnectOnSiteSupport.ListOnSiteSuportByBuyerID(currentUser.id);
            var InitialDate = new Date();
            var FinalDate = new Date();

            InitialDate.setDate(1);

            if (currentUser != null && currentUser.roles.ADMIN_ACCESS) {
                await ConnectTransactions.ListTransactionByAgentID(
                    currentUser.id,
                    InitialDate,
                    FinalDate
                );
                await ConnectChat.ListChatRoomsByAgent(currentUser.id);

            } else {
                await ConnectTransactions.ListTransactionByBuyerID(
                    currentUser.id,
                    InitialDate,
                    FinalDate
                );
            }
            var amountOfGraphs =
                ConnectMakeUpGraphReview.ReturnMakeUpGraphRequests();

            setAmountGraphs(amountOfGraphs.length);

            var transaction = ConnectTransactions.ReturnTransactions();
            setTimeElapsed(ConvertMinutesToString(transaction.TotalSeconds));

            var NextAppointmentList = ConnectChat.ReturnChatsPerSchedule();
            if (NextAppointmentList.length !== 0) {
                if (new Date(NextAppointmentList[0].ScheduledTo) > new Date()) {
                    setNextAppointment(
                        ConvertTimezone(NextAppointmentList[0].ScheduledTo)
                    );
                } else {
                    setNextAppointment("No Appontments");
                }
            }
            OnSiteSupport.sort((a, b) => (a.ServiceDate > b.ServiceDate ? -1 : 1));
            setCompleteListOfOnSiteSupports(OnSiteSupport);

            if (OnSiteSupport.length >= 2) {
                setListOfOnSiteSupports([OnSiteSupport[0], OnSiteSupport[1]]);
            } else if (OnSiteSupport.length >= 1) {
                setListOfOnSiteSupports([OnSiteSupport[0]]);
            }

            setGotCustomerInformation(true);
            // }, 50);
        }
    };

    const handleShowOnSiteList = () => {
        setShowHiddenInfo(true);
    };

    const handleCloseOnSiteList = () => {
        setShowHiddenInfo(false);
    };


    const renderLandingPage = (
        <LandingPageContext.Provider
            value={{
                showRigSite, setShowRigSite,
                showAssembly, setShowAssembly,
                showYard, setShowYard,
                showMakeUpGraph, setShowMakeUpGraph,
                gotSettings, setGotSettings,
                gotCustomerInformation, setGotCustomerInformation,
                amountGraphs, setAmountGraphs,
                timeElapsed, setTimeElapsed,
                nextAppointment, setNextAppointment,
                listOfOnSiteSupports, setListOfOnSiteSupports,
                completeListOfOnSiteSupports, setCompleteListOfOnSiteSupports,
                showHiddenInfo, setShowHiddenInfo,
                GetSettingsFromUser,
                GetCustomerData,
                handleShowOnSiteList,
                handleCloseOnSiteList,
                ConvertTimezone
            }}
        >
            {children}
        </LandingPageContext.Provider>
    );

    return renderLandingPage;
};
