import axios from "axios";
import { toast } from "react-toastify";
import Cookies from 'js-cookie';

const EndpointUpdate = "https://a1mtqrmcwg.execute-api.eu-west-1.amazonaws.com/int/user/update-profile"


export async function UpdateUser(data) {
    return await axios
        .post(EndpointUpdate, data, { headers: { 'authorization': Cookies.get("_ssoToken") } })
        .then((response) => {
            toast.success(response.data[0].body.status)
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });

}
