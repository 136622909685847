import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import MessageIcon from "../../assets/icons/Chat/MessageIcon.png";
import {
  Body,
  HelloTextBold,
  MainCard,
} from "../../components/common/MainCard";
import Caller from "../../components/layout/caller";
import Footer from "../../components/layout/footer";
import Header from "../../components/layout/header";
import LoadingScreen from "../../components/layout/LoadingScreen";
import NavBar from "../../components/layout/navBar";
import { ChatIDContext } from "../../stores/ChatIDStore";
import { UserContext } from "../../stores/UserStore";

const UpdateMessages = () => {
  document.title = "VAM® Field Service";

  const { currentUser } = useContext(UserContext);
  const history = useHistory();
  const {
    setChatFull,
    ConvertTimezone,
    savedUpdateChats,
    gotSavedUpdateData,
    schearchUpdateText,
    statusUpdateFilter,
    handleGetAllChats,
    handleGetAllChatsByAgent,
    handleListChats,
    handleListChatsAgent,
    checkForCurrentUserInfo,
    handleSchearchTerm,
    handleGetAllByStatus,
    handleGetUnread } = useContext(ChatIDContext);
  // const { chatFull, setChatFull } = useContext(ChatIDContext);

  const { t } = useTranslation();
  const handleGetChat = (element) => {
    setChatFull(element);
    history.push("/CreateChat");
  };

  const SideBySideOptions = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    input {
      width: 95%;
      height: 20px;
      margin-left: 10px;
      padding: 8px;
      border: 0;
      border-radius: 10px;
      box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);
      :focus {
        outline: none;
      }
    }

    select {
      -webkit-appearance: none;
      padding: 7px 40px 7px 12px;
      width: 200px;
      margin-left: 10px;
      border: 0px;
      border-radius: 5px;
      background: white;
      box-shadow: 0 1px 3px -2px #9098a9;
      cursor: pointer;
      font-family: inherit;
      font-size: 16px;
      transition: all 150ms ease;
      :focus {
        outline: none;
      }
    }

    button {
      padding-top: 5px;
      background-color: #009ee0;
      border: 0;
      color: #fff;
      margin-left: 10px;
      box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);
      transition: all 0.3s ease 0s;
      cursor: pointer;
      :hover {
        transform: translateY(-7px);
      }
      :focus {
        outline: none;
      }
    }
  `;

  const SavedDiv = styled.div`
    width: 98%;
    min-width: 450px;
    display: flex;
    flex-direction: column;
    color: #009ee0;
    background-color: white;
    border-radius: 0 10px 10px 0;
    font-size: calc(12px + 0.5vw);
    vertical-align: center;
    margin-bottom: 10px;
    transition: all 0.3s ease 0s;
    :hover {
      transform: translateX(3px);
    }
    .GrayText {
      color: #666666;
      font-size: calc(8px + 0.3vw);
    }
    .left {
      width: 90%;
      margin-left: 0;
      display: flex;
      align-items: center;
    }
    .information {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 10px;
      text-align: left;
    }
    .statusPending {
      width: 5%;
      font-size: 14px;
      color: white;
      background-color: #009ee0;
      border-radius: 0 10px 10px 0;
      margin-top: 10px;
      padding: 5px;
      padding-left: 2%;
    }
    .statusProcessed {
      width: 5%;
      font-size: 14px;
      color: white;
      background-color: #666666;
      border-radius: 0 10px 10px 0;
      margin-top: 10px;
      padding: 5px;
      padding-left: 2%;
    }
    .right {
      color: #666666;
      font-size: calc(8px + 0.3vw);
      display: flex;
      flex-direction: column;
      width: 6%;
      align-items: flex-end;
      justify-content: center;
    }
    .unread {
      color: white;
      display: flex;
      width: 4%;
      justify-content: center;
      align-items: center;
    }
    button {
      background-color: Transparent;
      background-repeat: no-repeat;
      border: none;
      cursor: pointer;
      overflow: hidden;
    }
    font {
      font-size: 16px;
      color: black;
    }
    img {
      margin-right: 10px;
    }
    span {
      background-color: #8d1677;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      display: inline-block;
      text-align: center;
      font-size: 16px;
    }
  `;

  return (
    <Body>
      {currentUser != null && !currentUser.roles.ADMIN_ACCESS
        ? handleGetAllChats(currentUser.id)
        : handleGetAllChatsByAgent(currentUser.id)}
      {currentUser != null && !currentUser.roles.ADMIN_ACCESS
        ? handleListChats()
        : handleListChatsAgent()}
      {checkForCurrentUserInfo()}
      <Header />
      <Caller />
      <NavBar />
      {!gotSavedUpdateData ? (
        <LoadingScreen />
      ) : (
        <MainCard id="MainCard">
          <HelloTextBold> {t("Messages.AlterMessages")} </HelloTextBold>
          <SideBySideOptions>
            {" "}
            <input
              type="text"
              id="scearch"
              placeholder={t("Messages.Scearch")}
              onChange={handleSchearchTerm}
              defaultValue={schearchUpdateText}
              autoFocus
            ></input>
            <select
              onChange={() => handleGetAllByStatus(currentUser.id)}
              id="status"
              defaultValue={statusUpdateFilter}
            >
              <option className="statusOption" value="Pending">
                Pending
              </option>
              <option className="statusOption" value="Achieved">
                Achieved
              </option>
            </select>
          </SideBySideOptions>

          {savedUpdateChats.map((SavedChats) => (
            <div key={SavedChats.RequestID}>
              <SavedDiv>
                <button onClick={() => handleGetChat(SavedChats)}>
                  <div
                    className={
                      SavedChats.SupportStatus === "Pending" || SavedChats.SupportStatus === "In Progress" || SavedChats.SupportStatus === "In progress" || SavedChats.SupportStatus === "InProgress"
                        ? "statusPending"
                        : "statusProcessed"
                    }
                  >
                    {t("Messages." + SavedChats.SupportStatus)}
                  </div>
                  <div className="information">
                    <div className="left">
                      <img src={MessageIcon} alt="Message Icon" />
                      <div>
                        <font> {SavedChats.IssueName}</font>
                        {currentUser != null && currentUser.roles.ADMIN_ACCESS && (
                          <div>
                            {" "}
                            {t("Messages.Customer")} {SavedChats.BuyerName}{" "}
                          </div>
                        )}
                        {currentUser != null && currentUser.roles.ADMIN_ACCESS && (
                          <div>
                            {t("Messages.ScheduledTo")}:{" "}
                            {ConvertTimezone(SavedChats.ScheduledTo)}{" "}
                          </div>
                        )}
                        <div className="GrayText">
                          {" "}
                          {t(
                            "Messages." + SavedChats.SupportSubject.replace(/ /g, "")
                          )}
                        </div>{" "}
                      </div>
                    </div>
                    <div className="right">
                      {" "}
                      {ConvertTimezone(SavedChats.LastAltered)}
                    </div>
                    <div className="unread">
                      {handleGetUnread(SavedChats.RequestID)}
                    </div>
                  </div>
                </button>
              </SavedDiv>
            </div>
          ))}
        </MainCard>
      )}
      <Footer />
    </Body>
  );
};

export default UpdateMessages;
