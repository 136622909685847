import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import CricleCheck from "../../assets/images/CircleCheck.png";
import {
  Body,
  HelloText,
  HelloTextBold,
  MainCard
} from "../../components/common/MainCard";
import Caller from "../../components/layout/caller";
import Footer from "../../components/layout/footer";
import Header from "../../components/layout/header";
import NavBar from "../../components/layout/navBar";
import { GraphContext } from "../../stores/GraphStore";

const MakeUpGraphFinish = () => {
  document.title = "VAM® Field Service";
  const { graphID, setGraphID } = useContext(GraphContext);
  const history = useHistory();
  const { t } = useTranslation();

  const handleNewRequest = () => {
    setGraphID("");
    history.push("/MakeUpGraphAssistance");
  };

  const CenterDiv = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 50px;
    .WhiteBox {
      display: block;
      max-width: 50%;
      width: 100%;
      text-align: center;
      background-color: white;
      color: black;
      padding: 24px;
      font-size: 16px;
    }
    .Title {
      font-size: 24px;
    }
    .SubTitle {
      margin-top: 40px;
      font-size: 20px;
    }
    div {
      margin-bottom: 16px;
    }
    img {
      margin-top: 32px;
    }
    button {
      margin-top: 32px;
      margin-left: auto;
      margin-right: auto;
      min-height: 50px;
      font-size: 14px;
      justify-content: space-between;
      background-color: #fff;
      color: #009ee0;
      margin-bottom: 20px;
      box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);
      transition: all 0.3s ease 0s;
      border: 0;
      :hover {
        background-color: #009ee0;
        color: #fff;
        transform: translateX(7px);
      }
    }
  `;

  return (
    <Body>
      <Header />
      <Caller />
      <NavBar />
      <MainCard id="MainCard">
        <HelloTextBold>{t("MakeUpGraphFinish.MakeupGraphReview")}</HelloTextBold>
        <HelloText> {t("MakeUpGraphFinish.Assistant")}</HelloText>
        <CenterDiv>
          <div className="WhiteBox">
            <div className="Title">{t("Messages.Thankyou")} </div>{" "}
            {t("MakeUpGraphFinish.Wesuccessfullyreceivedyourinquiry")}
            <div /> {t("MakeUpGraphFinish.Pleaseusethisreferencenumber")} <div />
            <b>{graphID}</b> <div />
            <img src={CricleCheck} alt="Created Make-up Graph" /> <div />
            <div className="SubTitle">
              {t("MakeUpGraphFinish.Youwillreceivearesponsefromourteamswithin24hours")}
            </div>
            <button onClick={handleNewRequest}>
              {" "}
              {t("MakeUpGraphFinish.SendNewRequest")}{" "}
            </button>
          </div>
        </CenterDiv>
      </MainCard>
      <Footer />
    </Body>
  );
};

export default MakeUpGraphFinish;
