import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Button } from "../../components/common";
import {
  Body,
  HelloText,
  HelloTextBold,
  HelloTextHidden,
  MainCard
} from "../../components/common/MainCard";
import Caller from "../../components/layout/caller";
import Footer from "../../components/layout/footer";
import Header from "../../components/layout/header";
import NavBar from "../../components/layout/navBar";

const LiveSupport = () => {
  document.title = "VAM® Field Service";
  const history = useHistory();
  const { t } = useTranslation();

  const ButtonDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    transition: all 0.9s ease 0s;
    button {
      margin-left: 20px;
      min-height: 100px;
      min-width: 300px;
      background-color: white;
      color: #039fe0;
      border: 2px solid #e7e7e7;
      transition: all 0.3s ease 0s;
      :hover {
        background-color: #009ee0;
        box-shadow: 0px 15px 20px rgba(167, 164, 206, 0.4);
        color: #fff;
        transform: translateX(7px);
      }
    }
  `;

  const HiddenCard = styled.div`
    color: black;
    margin-left: 10px;
    display: none;
    font-size: calc(8px + 0.7vw);
    width: 35%;
    text-align: center;
    justify-content: center;
    font {
      font-weight: bold;
    }
    button {
      min-height: 50px;
      min-width: 50px;
      width: 40%;
      margin-bottom: 20px;
      background-color: #009ee0;
      color: white;
      border: 2px solid #e7e7e7;
      transition: all 0.3s ease 0s;
      :hover {
        background-color: white;
        box-shadow: 0px 15px 20px rgba(167, 164, 206, 0.4);
        color: #009ee0;
        transform: translateX(7px);
      }
    }
  `;

  const ButtonDivHidden = styled.div`
    display: flex;
    text-align: center;
    justify-content: center;
    width: 100%;
  `;

  const ButtonReturn = styled.div`
    padding-top: 20px;
    display: flex;
    justify-content: center;
    width: 100%;
    button {
      min-height: 50px;
      min-width: 50px;
      width: 40%;
      margin-bottom: 20px;
      background-color: white;
      color: #009ee0;
      border: 2px solid #e7e7e7;
      transition: all 0.3s ease 0s;
      :hover {
        background-color: #009ee0;
        box-shadow: 0px 15px 20px rgba(167, 164, 206, 0.4);
        color: white;
        transform: translateX(7px);
      }
    }
  `;

  const GrayDiv = styled.div`
    padding-top: 10px;
    background-color: #e7e7e7;
    border-radius: 10px;
  `;

  function SelectShownElement(Option) {
    document.getElementById("InitialText").style.display = "none";
    document.getElementById("PipeConnection").style.display = "none";
    document.getElementById("MakeUpGraph").style.display = "none";
    document.getElementById("MakeUpTroubleshoting").style.display = "none";
    document.getElementById("RigTroubleshoting").style.display = "none";
    document.getElementById("OtherRequests").style.display = "none";
    document.getElementById("SelectionDiv").style.display = "none";
    document.getElementById("ConnectionInspection").style.display = "none";
    const showTexts = document.getElementsByClassName("Buttons");

    for (let i = 0; i < showTexts.length; i++) {
      showTexts[i].style.display = "none";
    }

    if (Option === 1) {
      document.getElementById("ConnectionInspection").style.display = "block";
      document.getElementById("SelectionDiv").style.display = "block";
    } else if (Option === 2) {
      document.getElementById("PipeConnection").style.display = "block";
      document.getElementById("SelectionDiv").style.display = "block";
    } else if (Option === 3) {
      document.getElementById("MakeUpGraph").style.display = "block";
      document.getElementById("SelectionDiv").style.display = "block";
    } else if (Option === 4) {
      document.getElementById("MakeUpTroubleshoting").style.display = "block";
      document.getElementById("SelectionDiv").style.display = "block";
    } else if (Option === 5) {
      document.getElementById("RigTroubleshoting").style.display = "block";
      document.getElementById("SelectionDiv").style.display = "block";
    } else if (Option === 6) {
      document.getElementById("OtherRequests").style.display = "block";
      document.getElementById("SelectionDiv").style.display = "block";
    } else if (Option === 9) {
      document.getElementById("InitialText").style.display = "block";

      for (let i = 0; i < showTexts.length; i++) {
        showTexts[i].style.display = "flex";
      }
    }
  }
  
  const handleContinuePipe = () => {
    history.push("/TermsAndConditions");
  };

  const handleSendAnInspectionRequest = () => {
    history.push("/UnderConstruction");
  };

  return (
    <Body>
      <Header />
      <Caller />
      <NavBar />
      <MainCard id="MainCard">
        <HelloTextBold>{t("LiveSupport.RequestConcerns")}</HelloTextBold>
        <HelloText id="InitialText">
          {t("LiveSupport.RequestConcernsInformation")}
        </HelloText>
        <HelloTextHidden id="ConnectionInspection">
          {t("LiveSupport.anConnectionInspection")}
        </HelloTextHidden>
        <HelloTextHidden id="PipeConnection">
          {t("LiveSupport.anPipeConnection")}
        </HelloTextHidden>
        <HelloTextHidden id="MakeUpGraph">
          {t("LiveSupport.anMake-upGraphReview")}
        </HelloTextHidden>
        <HelloTextHidden id="MakeUpTroubleshoting">
          {t("LiveSupport.anAssemblyMake-upTroubleshoting")}
        </HelloTextHidden>
        <HelloTextHidden id="RigTroubleshoting">
          {t("LiveSupport.anRigTroubleshoting")}
        </HelloTextHidden>
        <HelloTextHidden id="OtherRequests">
          {t("LiveSupport.anOtherRequest")}
        </HelloTextHidden>
        <ButtonDiv className="Buttons">
          <Button onClick={() => SelectShownElement(1)}>
            {" "}
            {t("LiveSupport.ConnectionInspection")}
          </Button>
          <Button onClick={() => SelectShownElement(2)}>
            {t("LiveSupport.PipeConnection")}
          </Button>
        </ButtonDiv>
        <ButtonDiv className="Buttons">
          <Button onClick={() => SelectShownElement(3)}>
            {t("LiveSupport.MakeupGraphReview")}
          </Button>
          <Button onClick={() => SelectShownElement(4)}>
            {t("LiveSupport.AssemblyMake-upTroubleshoting")}
          </Button>
        </ButtonDiv>
        <ButtonDiv className="Buttons">
          <Button onClick={() => SelectShownElement(5)}>
            {t("LiveSupport.RigTroubleshootingSupport")}
          </Button>
          <Button onClick={() => SelectShownElement(6)}>
            {" "}
            {t("LiveSupport.OtherRequests")}
          </Button>
        </ButtonDiv>
        <HiddenCard id="SelectionDiv">
          {t("LiveSupport.Forthisservicetheestimatedpriceis")} <div />
          <font> $1.000.000,00 USD </font>
          <ButtonDivHidden>
            <Button onClick={handleContinuePipe}>
              {t("LiveSupport.Continue")}
            </Button>
          </ButtonDivHidden>
          <GrayDiv>
            {t("LiveSupport.NohurryWecanalsoinspectyourconnectionoffline")}
            <ButtonDivHidden>
              <Button onClick={handleSendAnInspectionRequest}>
                {t("LiveSupport.SendanInspectionRequest")}
              </Button>
            </ButtonDivHidden>
          </GrayDiv>
          <ButtonReturn>
            <Button onClick={()=>SelectShownElement(9)}> {t("LiveSupport.Cancel")}</Button>
          </ButtonReturn>
        </HiddenCard>
      </MainCard>
      <Footer />
    </Body>
  );
};

export default LiveSupport;
