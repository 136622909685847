import React, { useState } from "react";
import * as ConnectChat from "../components/connect/chatConnect";
import * as ConnectMakeUpGraphReview from "../components/connect/makeUpGraphRequestConnect";
import { useTranslation } from "react-i18next";

export const DashboardContext = React.createContext();

export const DashboardProvider = ({ children }) => {
    const [gotSavedData, setGotSavedData] = useState(false);
    const [gotRefreshData, setGotRefreshData] = useState(false);
    const [initialDate, setInitialDate] = useState("1990/01/01");
    const [finishDate, setFinishDate] = useState("2990/01/01");
    const { t } = useTranslation();

    var [listDashboard, setListDashboard] = useState([
        {
            labels: [],
            datasets: [
                {
                    label: t("Dashboard.CasesPendingPerAgent"),
                    data: [],
                    backgroundColor: [
                        "rgba(75, 192, 192, 0.2)",
                        "rgba(153, 102, 255, 0.2)",
                        "rgba(255, 159, 64, 0.2)",
                        "rgba(255, 99, 132, 0.2)",
                        "rgba(54, 162, 235, 0.2)",
                        "rgba(255, 159, 64, 0.2)",
                        "rgba(255, 99, 132, 0.2)",
                        "rgba(255, 206, 86, 0.2)",
                        "rgba(75, 192, 192, 0.2)",
                        "rgba(153, 102, 255, 0.2)",
                        "rgba(54, 162, 235, 0.2)",
                        "rgba(255, 206, 86, 0.2)",
                    ],
                    borderColor: [
                        "rgba(75, 192, 192, 1)",
                        "rgba(153, 102, 255, 1)",
                        "rgba(255, 159, 64, 1)",
                        "rgba(255, 99, 132, 1)",
                        "rgba(54, 162, 235, 1)",
                        "rgba(255, 206, 86, 1)",
                        "rgba(75, 192, 192, 1)",
                        "rgba(153, 102, 255, 1)",
                        "rgba(255, 99, 132, 1)",
                        "rgba(54, 162, 235, 1)",
                        "rgba(255, 206, 86, 1)",
                        "rgba(255, 159, 64, 1)",
                        "rgba(255, 99, 132, 1)",
                        "rgba(54, 162, 235, 1)",
                        "rgba(255, 206, 86, 1)",
                    ],
                    borderWidth: 1,
                },
            ],
        },
        {
            labels: [],
            datasets: [
                {
                    label: t("Dashboard.CasesProcessedPerAgent"),
                    data: [],
                    backgroundColor: [
                        "rgba(255, 99, 132, 0.2)",
                        "rgba(54, 162, 235, 0.2)",
                        "rgba(255, 206, 86, 0.2)",
                        "rgba(75, 192, 192, 0.2)",
                        "rgba(153, 102, 255, 0.2)",
                        "rgba(255, 159, 64, 0.2)",
                        "rgba(255, 99, 132, 0.2)",
                        "rgba(54, 162, 235, 0.2)",
                        "rgba(255, 206, 86, 0.2)",
                        "rgba(75, 192, 192, 0.2)",
                        "rgba(153, 102, 255, 0.2)",
                        "rgba(255, 159, 64, 0.2)",
                    ],
                    borderColor: [
                        "rgba(255, 99, 132, 1)",
                        "rgba(54, 162, 235, 1)",
                        "rgba(255, 206, 86, 1)",
                        "rgba(75, 192, 192, 1)",
                        "rgba(153, 102, 255, 1)",
                        "rgba(255, 159, 64, 1)",
                        "rgba(255, 99, 132, 1)",
                        "rgba(54, 162, 235, 1)",
                        "rgba(255, 206, 86, 1)",
                        "rgba(75, 192, 192, 1)",
                        "rgba(153, 102, 255, 1)",
                        "rgba(255, 159, 64, 1)",
                    ],
                    borderWidth: 1,
                },
            ],
        },
        {
            labels: [],
            datasets: [
                {
                    label: t("Dashboard.newChatCases"),
                    data: [],
                    fill: false,
                    backgroundColor: "rgb(255, 99, 132)",
                    borderColor: "rgba(255, 99, 132, 0.2)",
                },
            ],
        },
        {
            labels: [],
            datasets: [
                {
                    label: t("Dashboard.newMakeUpCases"),
                    data: [],
                    fill: false,
                    backgroundColor: "rgb(43, 9, 231)",
                    borderColor: "rgba(59, 60, 100, 0.2)",
                },
            ],
        },
        {
            labels: [],
            datasets: [
                {
                    label: "MakeUpGraph",
                    data: [],
                    backgroundColor: [],
                    borderColor: [],
                    borderWidth: 1,
                },
            ],
        },
    ]);

    const options = {
        redraw: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                ticks: {
                    stepSize: 1,
                },
            },
        },
    };

    const optionsPie = {
        redraw: true,
        maintainAspectRatio: false,
    };

    const handleGetAllMakeUpGraphsByStatus = async (date, previousdate) => {
        await ConnectMakeUpGraphReview.ListMakeUpGraphRequestsAdmin(
            previousdate,
            date
        );
        await listAllMakeUps(ConnectMakeUpGraphReview.ReturnMakeUpGraphRequests());
    };

    const handleRefreshData = async () => {
        setGotRefreshData(true);
        var date = new Date(document.getElementById("FinishDate").value);
        var previousdate = new Date(document.getElementById("InitialDate").value);
        await ConnectMakeUpGraphReview.ListMakeUpGraphRequestsAdmin(
            previousdate,
            date
        );
        setTimeout(async () => {
            var Chats = ConnectChat.ReturnChatsUnOrdered("Chat");
            await listAllAgentsAndCasesPendings(Chats);
            await listAllAgentsAndCasesProcessed(Chats);
            await listNewCases(ConnectChat.ReturnChatsOrderByCreationDate("Chat"));
            await listAllMakeUps(ConnectMakeUpGraphReview.ReturnMakeUpGraphRequests());

            setListDashboard(listDashboard);
            setInitialDate(previousdate.toISOString().substr(0, 10));
            setFinishDate(date.toISOString().substr(0, 10));
            setGotRefreshData(false);
        }, 3000);
    };

    const handleGetInitialData = () => {
        setTimeout(async () => {
            if (!gotSavedData) {
                var date = new Date();
                var previousdate = new Date(
                    new Date().setDate(new Date().getDate() - 30)
                );
                handleGetAllMakeUpGraphsByStatus(date, previousdate);
                if (!gotSavedData) {
                    var Chats = ConnectChat.ReturnChatsUnOrdered("Chat");
                    await listAllAgentsAndCasesPendings(Chats);
                    await listAllAgentsAndCasesProcessed(Chats);
                    await listNewCases(ConnectChat.ReturnChatsOrderByCreationDate("Chat"));

                    setInitialDate(previousdate.toISOString().substr(0, 10));
                    setFinishDate(date.toISOString().substr(0, 10));
                    setListDashboard(listDashboard);
                    setGotSavedData(true);
                }
            }
        }, 150);
    };

    const listAllAgentsAndCasesPendings = async (Chats) => {
        var listAgents = [];
        var listCases = [];
        if (Chats !== undefined) {
            console.log(Chats)
            Chats.forEach((chat) => {
                chat.AgentName = chat.AgentName.replaceAll('  ', ' ')
                if (chat.SupportStatus === "In Progress" || chat.SupportStatus === "InProgress" || chat.SupportStatus === "In progress" || chat.SupportStatus === "Pending") {
                    if (
                        chat.AgentName === "undefined" || chat.AgentName === '' ||
                        !listAgents.includes(
                            chat.AgentName.split(" ")[0] +
                            " " +
                            chat.AgentName.split(" ")[1].split("")[0]
                        )
                    ) {
                        if (chat.AgentName === "undefined" || chat.AgentName === '') {
                            listAgents.push("No Agent");
                        } else {
                            listAgents.push(
                                chat.AgentName.split(" ")[0] +
                                " " +
                                chat.AgentName.split(" ")[1].split("")[0]
                            );
                        }

                        listCases.push(
                            CountCasesPerAgent(Chats, chat.AgentName, "In Progress")
                        );
                    }
                }
            });
        }
        listDashboard[0].labels = listAgents;
        listDashboard[0].datasets[0].data = listCases;
        return true;
    };

    const listNewCases = async (Chats) => {
        var listNewCases = [];
        var listCasesCount = [];

        if (Chats !== undefined) {
            Chats.forEach((chat) => {
                var date = new Date(chat.CreationDate);
                var convertedDate = date.getDate() + "/" + (date.getMonth() + 1);
                if (!listNewCases.includes(convertedDate)) {
                    listNewCases.push(convertedDate);
                    listCasesCount.push(CountNewCases(Chats, date));
                }
            });
        }
        listDashboard[2].labels = listNewCases;
        listDashboard[2].datasets[0].data = listCasesCount;
        return true;
    };

    const listAllAgentsAndCasesProcessed = async (Chats) => {
        var listProcessedAgents = [];
        var listProcessedCases = [];
        if (Chats !== undefined) {
            Chats.forEach((chat) => {
                if (chat.SupportStatus === "Completed" || chat.SupportStatus === "Achieved") {
                    if (
                        chat.AgentName === "undefined" ||
                        !listProcessedAgents.includes(
                            chat.AgentName.split(" ")[0] +
                            " " +
                            chat.AgentName.split(" ")[1].split("")[0]
                        )
                    ) {
                        if (chat.AgentName === "undefined") {
                            listProcessedAgents.push("No Agent");
                        } else {
                            listProcessedAgents.push(
                                chat.AgentName.split(" ")[0] +
                                " " +
                                chat.AgentName.split(" ")[1].split("")[0]
                            );
                        }

                        listProcessedCases.push(
                            CountCasesPerAgent(Chats, chat.AgentName, "Completed")
                        );
                    }
                }
            });
        }
        listDashboard[1].labels = listProcessedAgents;
        listDashboard[1].datasets[0].data = listProcessedCases;
        return true;
    };

    const listAllMakeUps = async (MakeUps) => {
        var listStatus = [];
        var listAmounts = [];
        var listDatesCreation = [];
        var listDatesAmount = [];
        if (MakeUps !== undefined) {
            MakeUps.forEach((MakeUp) => {
                if (!listStatus.includes(t("Dashboard." + MakeUp.RequestStatus))) {
                    listStatus.push(t("Dashboard." + MakeUp.RequestStatus));
                    listAmounts.push(CountMakeUpPerStatus(MakeUps, MakeUp.RequestStatus));
                }

                var date = new Date(MakeUp.CreationDate);
                var convertedDate = date.getDate() + "/" + (date.getMonth() + 1);
                if (!listDatesCreation.includes(convertedDate)) {
                    listDatesCreation.push(convertedDate);
                    listDatesAmount.push(
                        CountNewMakeUpPerDate(MakeUps, MakeUp.CreationDate)
                    );
                }
            });

            var colorList = [];
            var backgroundColorList = [];
            listStatus.forEach((element) => {
                if (element === "Make Up In Progress") {
                    colorList.push("rgba(255, 206, 86, 1)");
                    backgroundColorList.push("rgba(255, 159, 64, 0.2)");
                } else if (element === "Make Up Deleted") {
                    colorList.push("rgba(153, 102, 255, 1)");
                    backgroundColorList.push("rgba(153, 102, 255, 0.2)");
                } else if (element === "Make Up Drafted") {
                    colorList.push("rgba(75, 192, 192, 1)");
                    backgroundColorList.push("rgba(75, 192, 192, 0.2)");
                } else {
                    colorList.push("rgba(255, 99, 132, 1)");
                    backgroundColorList.push("rgba(255, 99, 132, 0.2)");
                }
            });

            listDashboard[4].labels = listStatus;
            listDashboard[4].datasets[0].backgroundColor = backgroundColorList;
            listDashboard[4].datasets[0].borderColor = colorList;
            listDashboard[4].datasets[0].data = listAmounts;

            listDashboard[3].labels = listDatesCreation;
            listDashboard[3].datasets[0].data = listDatesAmount;

            return true;
        }
    };

    const CountCasesPerAgent = (Chats, Agent, Status) => {
        var count = 0;
        Chats.forEach((chat) => {
            if (chat.SupportStatus === Status) {
                if (chat.AgentName === Agent) {
                    count++;
                }
            }
        });
        return count;
    };

    const CountMakeUpPerStatus = (MakeUps, Status) => {
        var count = 0;
        MakeUps.forEach((makeup) => {
            if (makeup.RequestStatus === Status) {
                count++;
            }
        });
        return count;
    };

    const CountNewMakeUpPerDate = (MakeUps, Time) => {
        var count = 0;
        MakeUps.forEach((makeup) => {
            Time = new Date(Time);
            var makeUpCreationDate = new Date(makeup.CreationDate);
            if (
                makeUpCreationDate.getDate() === Time.getDate() &&
                makeUpCreationDate.getMonth() === Time.getMonth() &&
                makeUpCreationDate.getFullYear() === Time.getFullYear()
            ) {
                count++;
            }
        });
        return count;
    };

    const CountNewCases = (Chats, Time) => {
        var count = 0;
        Chats.forEach((chat) => {
            var chatCreationDate = new Date(chat.CreationDate);
            if (
                chatCreationDate.getDate() === Time.getDate() &&
                chatCreationDate.getMonth() === Time.getMonth() &&
                chatCreationDate.getFullYear() === Time.getFullYear()
            ) {
                count++;
            }
        });
        return count;
    };

    const renderDashboard = (
        <DashboardContext.Provider
            value={{
                gotSavedData, setGotSavedData,
                gotRefreshData, setGotRefreshData,
                initialDate, setInitialDate,
                finishDate, setFinishDate,
                listDashboard, setListDashboard,
                options,
                optionsPie,
                handleGetAllMakeUpGraphsByStatus,
                handleRefreshData,
                handleGetInitialData,
                listAllAgentsAndCasesPendings,
                listNewCases,
                listAllAgentsAndCasesProcessed,
                listAllMakeUps,
                CountCasesPerAgent,
                CountMakeUpPerStatus,
                CountNewMakeUpPerDate,
                CountNewCases
            }}
        >
            {children}
        </DashboardContext.Provider>
    );

    return renderDashboard;
};
