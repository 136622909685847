
import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { Body, MainCard } from "../../components/common/MainCard";
import Caller from "../../components/layout/caller";
import Footer from "../../components/layout/footer";
import Header from "../../components/layout/header";
import NavBar from "../../components/layout/navBar";
import CountryCodeSelect from "../../containers/common/CountryCodeSelect";
import LanguageSelect from "../../containers/common/LanguageSelect";
import { ProfileContext } from "../../stores/ProfileStore";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { UserContext } from "../../stores/UserStore";
import { useTranslation } from "react-i18next";

const Profile = () => {
  document.title = "VAM® Field Service";

  const SettingForm = styled.div`
    font-size: calc(8px + 0.5vw);
    display: block;
    padding: 10px;
    div {
      margin-bottom: 10px;
    }
    .switch {
      position: relative;
      display: inline-block;
      width: 35px;
      height: 15px;
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 10px;
    }

    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: 0;
      bottom: -2.5px;
      background-color: #2196f3;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    input:checked + .slider {
      background-color: #7abcf1;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #2196f3;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(16px);
      -ms-transform: translateX(16px);
      transform: translateX(16px);
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  `;


  const SideBySideOptions = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: stretch;
    width: 100%;
    div {
      flex: 1;
    }
  `;
  const SideBySideOptionsPhoneDiv = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 100%;

    .Left {
      flex: 1;
      margin-left: 20px;
      margin-right: 20px;
    }
    .Right {
      margin-right: 30px;
      flex: 1;
    }
  `;

  const ProfileForm = styled.div`
    font-size: calc(8px + 0.5vw);
    display: flex;
    flex-direction: column;
    padding: 2vh;
    margin-top: 10px;
    width: 100%;
    height: 72vh;
    color: #7e7e7e;
    border-radius: 8px;
    background-color: white;
    transform: translateX(-30px);
    justify-items: center;
    align-items: center;
    position: relative;

    .smallerScreen {
      bottom: 25px;
    }

    input[type="text"],
    select {
      width: 95%;
      padding: 1.2vh 20px;
      margin: 2.4vh 24px;
      display: inline-block;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
    }
  `;

  const CompanyForm = styled.div`
    font-size: calc(8px + 0.5vw);
    display: none;
    flex-direction: column;
    padding: 20px;
    margin-top: 10px;
    width: 100%;
    height: 72vh;
    color: #7e7e7e;
    border-radius: 8px;
    background-color: white;
    transform: translateX(-30px);
    justify-items: center;
    align-items: center;
    position: relative;

    input[type="text"],
    select {
      width: 95%;
      padding: 12px 20px;
      margin: 24px 24px;
      display: inline-block;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
    }
  `;

  const SettingsForm = styled.div`
    font-size: calc(8px + 0.5vw);
    display: none;
    flex-direction: column;
    padding: 20px;
    margin-top: 10px;
    width: 100%;
    height: 72vh;
    color: #7e7e7e;
    border-radius: 8px;
    background-color: white;
    transform: translateX(-30px);
    justify-items: center;
    align-items: center;
    position: relative;

    input[type="text"],
    select {
      width: 95%;
      padding: 12px 20px;
      margin: 24px 24px;
      display: inline-block;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
    }
  `;

  const FlexDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
  `;

  const CenterDiv = styled.div`
    color: black;
    display: flex;
    width: 100%;
    max-width: 50vw;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1vh;
  `;
  const MyInfoDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 50vw;
    align-items: center;
    justify-content: center;
    background-color: #f8f9fa;
  `;

  const MySettingsDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 50vw;
    background-color: #f8f9fa;
  `;

  const MyLanguageDiv = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 50vw;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 2vh;
    background-color: #f8f9fa;
    font-size: 14px;
    font-weight: bold;
    font {
      padding-bottom: 1vh;
    }
    .Field {
      display: flex;
      justify-content: start;
      flex-direction: column;
      padding: 2vh;
      flex: 1;
    }
  `;

  const WhiteUnitBoxDiv = styled.div`
    display: flex;
    background-color: white;
    justify-content: center;
    padding-top: 10px;

    .active {
      color: #009ee0;
    }
    .switch {
      position: relative;
      display: inline-block;
      width: 35px;
      height: 15px;
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 10px;
    }

    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #7abcf1;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: 0;
      bottom: -2.5px;
      background-color: #2196f3;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    input:checked + .slider {
      background-color: #7abcf1;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #7abcf1;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(16px);
      -ms-transform: translateX(16px);
      transform: translateX(16px);
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  `;

  const SaveButtonDiv = styled.div`
    display: flex;
    position: absolute;
    width: 100%;
    bottom: 85px;
    justify-content: center;
    margin: 4vh;

    button {
      cursor: pointer;
      min-height: 20px;
      height: 5vh;
      max-height: 50px;
      border-radius: 10px;
      width: 10vw;
      min-width: 100px;
      background-color: #009ee0;
      color: white;
      font-size: calc(8px + 0.5vw);
      border: 0 solid #fff;
      transition: all 0.3s ease 0s;
      :hover {
        background-color: #fff;
        box-shadow: 0px 15px 20px rgba(167, 164, 206, 0.4);
        color: #009ee0;
        transform: translateX(7px);
      }
    }
  `;

  const OptionsDiv = styled.div`
    height: 100px;
    display: block;
    margin-bottom: 10px;

    .optionsButton {
      font-weight: bold;
      margin-top: 24px;
      margin-left: 56px;
      padding-left: 20px;
      padding-right: 20px;
      background-color: Transparent;
      background-repeat: no-repeat;
      height: 46px;
      font-size: calc(16px);
      color: #009ee0;
      border: none;
      cursor: pointer;
      overflow: hidden;
      transition: all 0.3s ease 0s;
      outline: none;
      :hover {
        color: black;
      }
    }

    .active {
      font-weight: bold;
      margin-top: 24px;
      margin-left: 16px;
      padding-left: 20px;
      padding-right: 20px;
      background-color: Transparent;
      background-repeat: no-repeat;
      height: 46px;
      border: 0;
      font-size: calc(16px);
      color: black;
      cursor: pointer;
      overflow: hidden;
      border-bottom: 10px solid black;
      outline: none;
    }
  `;
  const isSmallScreen = window.screen.width <= 1366;

  const {
    GetSettingsFromUser,
    handleChangeToProfile,
    handleChangeToCompany,
    handleChangeToSettings,
    handleUpdateProfile,
    handleChangeMetricUnit,
    handleChangeSlider,
    handleSetupPicture,
    handleApplyToDashboard,
    getProfileImage,
    getCompanyImage,
    getProfilePic,
    getProfileButton,
    showRigSite,
    showAssembly,
    showYard,
    showMakeUpGraph,
  } = useContext(ProfileContext)


  const { t } = useTranslation();
  const { currentUser } = useContext(UserContext);
  const history = useHistory();
  const checkForCurrentUserInfo = async () => {
    if (currentUser == null) {
      toast.error("Error Getting User Info");
      history.push("/landingPage");
    }
  };

  useEffect(() => {
    (async () => {
      await checkForCurrentUserInfo()
      await GetSettingsFromUser()
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Body>
      <Header />
      <Caller />
      <NavBar />
      {/* {checkForCurrentUserInfo()} */}
      {getProfilePic()}
      {/* {GetSettingsFromUser()} */}
      <MainCard id="MainCard">
        {currentUser != null && currentUser != null && (
          <FlexDiv>
            <OptionsDiv>
              <input
                onChange={handleSetupPicture}
                type="file"
                accept=".jpg,.png"
                id="picture"
                style={{ display: "none" }}
              />
              {getProfileImage()}
              <button
                id="ProfileButton"
                onClick={handleChangeToProfile}
                className="active"
              >
                {" "}
                {t("Profile.Profile")}
              </button>
              <button
                id="CompanyButton"
                onClick={handleChangeToCompany}
                className="optionsButton"
              >
                {" "}
                {t("Profile.Company")}
              </button>

              <button
                id="SettingsButton"
                onClick={handleChangeToSettings}
                className="optionsButton"
              >
                {" "}
                {t("Profile.Settings")}
              </button>
            </OptionsDiv>
            <ProfileForm id="Profile">
              <CenterDiv>
                {t("Profile.MyInformation")} <div />
                {getProfileButton()}
              </CenterDiv>
              <MyInfoDiv>
                <SideBySideOptions>
                  <input
                    id="FirstName"
                    type="text"
                    defaultValue={currentUser.firstName}
                    placeholder={t("Profile.FirstName")}
                  ></input>
                  <input
                    id="LastName"
                    type="text"
                    defaultValue={currentUser.lastName}
                    placeholder={t("Profile.LastName")}
                  ></input>
                </SideBySideOptions>
                <input
                  id="Email"
                  type="text"
                  defaultValue={currentUser.email}
                  placeholder={t("Profile.Email")}
                  disabled
                ></input>
                <input
                  id="Position"
                  type="text"
                  defaultValue={
                    currentUser.position != null ? currentUser.position : ""
                  }
                  placeholder={t("Profile.YourPosition")}
                ></input>
                <SideBySideOptionsPhoneDiv>
                  <div className="Left">
                    <CountryCodeSelect />
                  </div>
                  <div className="Right">
                    <input
                      id="Phone"
                      type="text"
                      defaultValue={
                        currentUser.phoneNumber != null
                          ? currentUser.phoneNumber
                          : ""
                      }
                      placeholder={t("Profile.Phone")}
                    ></input>
                  </div>
                </SideBySideOptionsPhoneDiv>
              </MyInfoDiv>
              <MyLanguageDiv>
                <div className="Field">
                  <font>{t("Profile.Language")}</font> <div />
                  <LanguageSelect />
                </div>
                <div className="Field">
                  <font> {t("Profile.Units")}</font> <div />
                  <WhiteUnitBoxDiv>
                    <div
                      id="Metric"
                      className={
                        currentUser !== null &&
                          currentUser.measuringUnit !== null &&
                          currentUser.measuringUnit === "Metric"
                          ? "active"
                          : ""
                      }
                    >
                      {t("Profile.METRIC")}
                    </div>
                    <label className="switch">
                      <input
                        type="checkbox"
                        onClick={handleChangeMetricUnit}
                        defaultChecked={
                          currentUser !== null &&
                            currentUser.measuringUnit !== null &&
                            currentUser.measuringUnit === "Imperial"
                            ? true
                            : false
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                    <div
                      id="Imperial"
                      className={
                        currentUser !== null &&
                          currentUser.measuringUnit !== null &&
                          currentUser.measuringUnit === "Imperial"
                          ? "active"
                          : ""
                      }
                    >
                      {t("Profile.IMPERIAL")}
                    </div>
                  </WhiteUnitBoxDiv>
                </div>
              </MyLanguageDiv>

              <SaveButtonDiv className={isSmallScreen ? "smallerScreen" : ""}>
                <button onClick={handleUpdateProfile}>
                  {" "}
                  {t("Profile.Save")}
                </button>
              </SaveButtonDiv>
            </ProfileForm>
            <CompanyForm id="Company">
              <CenterDiv>
                {t("Profile.CompanyInformation")} <div />
                {getCompanyImage()}
              </CenterDiv>
              <MyInfoDiv>
                <input
                  id="CompanyName"
                  type="text"
                  placeholder={t("Profile.CompanyName")}
                  defaultValue={
                    currentUser.company != null ? currentUser.company : ""
                  }
                ></input>
                <input
                  id="CompanyAddress"
                  type="text"
                  placeholder={t("Profile.CompanyAddress")}
                  defaultValue={
                    currentUser.address != null ? currentUser.address : ""
                  }
                ></input>
                <SideBySideOptions>
                  <input
                    id="ZipCode"
                    type="text"
                    placeholder={t("Profile.ZipCode")}
                    defaultValue={
                      currentUser.zipCode != null ? currentUser.zipCode : ""
                    }
                  ></input>
                  <input
                    defaultValue={
                      currentUser.city != null ? currentUser.city : ""
                    }
                    id="City"
                    type="text"
                    placeholder={t("Profile.City")}
                  ></input>
                  <input
                    id="Country"
                    type="text"
                    placeholder={t("Profile.Country")}
                    defaultValue={
                      currentUser.country != null ? currentUser.country : ""
                    }
                  ></input>
                </SideBySideOptions>
              </MyInfoDiv>
              <SaveButtonDiv>
                <button onClick={handleUpdateProfile}>
                  {t("Profile.Save")}
                </button>
              </SaveButtonDiv>
            </CompanyForm>
            <SettingsForm id="Settings">
              <CenterDiv>
                {t("Profile.SelectDashboardQuickAccesses")} <div />
                <SettingForm>
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider round"></span>
                  </label>
                </SettingForm>
              </CenterDiv>
              <MySettingsDiv>
                <SettingForm>
                  <div>
                    <label className="switch">
                      <input
                        type="checkbox"
                        defaultChecked={!showRigSite}
                        onChange={() => handleChangeSlider(1)}
                      />
                      <span className="slider round"></span>
                    </label>
                    {t("Profile.RigSiteTroubleShooting")} <div />
                  </div>{" "}
                  <div>
                    <label className="switch">
                      <input
                        type="checkbox"
                        id="Assembly"
                        defaultChecked={!showAssembly}
                        onChange={() => handleChangeSlider(2)}
                      />
                      <span className="slider round"></span>
                    </label>
                    {t("Profile.AssemblyMakeupTroubleShooting")} <div />
                  </div>
                  <div>
                    <label className="switch">
                      <input
                        type="checkbox"
                        id="Yard"
                        defaultChecked={!showYard}
                        onChange={() => handleChangeSlider(3)}
                      />
                      <span className="slider round"></span>
                    </label>
                    {t("Profile.YardInspectionTroubleShooting")} <div />
                  </div>
                  <div>
                    <label className="switch">
                      <input
                        type="checkbox"
                        id="MakeUpGraphCheck"
                        defaultChecked={!showMakeUpGraph}
                        onChange={() => handleChangeSlider(4)}
                      />
                      <span className="slider round"></span>
                    </label>
                    {t("Profile.MakeupGraphReview")}
                    <div />
                  </div>
                </SettingForm>
              </MySettingsDiv>
              <SaveButtonDiv>
                <button onClick={handleApplyToDashboard}>
                  {t("Profile.Apply")}{" "}
                </button>
              </SaveButtonDiv>
            </SettingsForm>
          </FlexDiv>
        )}
      </MainCard>
      <Footer />
    </Body>
  );
};

export default Profile;
