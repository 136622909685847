import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
// import { AiFillCar } from "react-icons/ai";
// import { BsGraphUp } from "react-icons/bs";
// import { RiAdminLine, RiDraftFill } from "react-icons/ri";
import { BiTransfer } from "react-icons/bi";
import { FaHardHat } from "react-icons/fa";
import { MdVideoCall } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { AiOutlineHome } from 'react-icons/ai';
import styled from "styled-components";
import Chat from "../../assets/images/NavBar/Chat.svg";
// import LiveSupport from "../../assets/images/NavBar/LiveSupport.svg";
import MakeUpGraph from "../../assets/images/NavBar/MakeUpGraph.svg";
import OpenMenu from "../../assets/images/NavBar/OpenMenu.svg";
// import RigSupport from "../../assets/images/NavBar/RigSupport.svg";
// import AccountRechargeRequest from "../../assets/images/NavBar/AccountRechargeRequest.svg";
import AssignTechnictianToLiveSupport from "../../assets/images/NavBar/AssignTechnictianToLiveSupport.svg";
// import Start from "../../assets/images/NavBar/Start.svg";
import * as ConnectChat from "../../components/connect/chatConnect";
import { UserContext } from "../../stores/UserStore";
import _ from "lodash";

const NavBar = () => {
  const { currentUser } = useContext(UserContext);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const history = useHistory();
  const { t } = useTranslation();

  const NavBarDiv = styled.div`
    font-family: Roboto;
    float: left;
    width: 40px;
    height: 80vh;
    background: rgb(32, 32, 215);
    background: linear-gradient(180deg, #172983 0%, #00093f 100%);
    transition: all 0.3s ease 0s;

    font {
      display: none;
      font-size: 12px;
      margin-left: 10px;
    }
    
    .retractNavBar {
      display: none;
      flex-direction: flex-start;
      img {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
      }
    }

    .active {
      transition: all 0.3s ease 0s;
      background-color: white;
      text-decoration: none;
      img {
        filter: invert(10%) sepia(52%) saturate(3294%) hue-rotate(231deg)
          brightness(0%) contrast(96%);
      }
      color: black;
    }

    span {
      background-color: #cc5200;
      width: 13px;
      height: 13px;
      color: white;
      border-radius: 50%;
      margin: -6px;
      display: inline-block;
      text-align: center;
      font-size: 12px;
      transform: translate(-3px, -10px);
    }

    button {
      width: 100%;
      display: flex;
      flex-direction: flex-start;
      border: 0;
      color: #e7e6e6;
      margin-top: 5px;
      padding-top: 5px;
      background-color: Transparent;
      align-items: center;
      text-align: left;
      cursor: pointer;

      :hover {
        transition: all 0.3s ease 0s;
        background-color: white;
        text-decoration: none;
        color: black;
        img {
          filter: invert(10%) sepia(52%) saturate(3294%) hue-rotate(231deg)
            brightness(0%) contrast(96%);
        }
      }
    }
  `;

  const handleExpandNavBar = () => {
    document.getElementById("NavBar").style.width = "200px";
    document.getElementById("expandNavBar").style.display = "none";
    document.getElementById("retractNavBar").style.display = "flex";
    document.getElementById("MainCard").style.marginLeft = "200px";
    document.getElementById("MainCard").style.width = "90vw";
    const showTexts = document.getElementsByClassName("HiddenText");

    for (let i = 0; i < showTexts.length; i++) {
      showTexts[i].style.display = "block";
    }
  };

  const handleRetractNavBar = () => {
    document.getElementById("NavBar").style.width = "40px";
    document.getElementById("expandNavBar").style.display = "block";
    document.getElementById("retractNavBar").style.display = "none";
    document.getElementById("MainCard").style.marginLeft = "40px";
    document.getElementById("MainCard").style.width = "98vw";
    const showTexts = document.getElementsByClassName("HiddenText");

    for (let i = 0; i < showTexts.length; i++) {
      showTexts[i].style.display = "none";
    }
  };

  const ActivateElement = (elementName) => {
    var element = document.getElementById(elementName);
    if (element != null) {
      element.className = "active";
    }
  };

  const handleGetCurrentLocation = () => {
    setTimeout(() => {
      if (window.location.href.includes("LandingPage")) {
        ActivateElement("Start");
      } else if (window.location.href.includes("MakeUpGraphDraftedGraph")) {
        ActivateElement("MakeUpGraphDraftedGraph");
      } else if (window.location.href.includes("MakeUpGraph")) {
        ActivateElement("MakeUpGraph");
      } else if (window.location.href.includes("UpdateMessages")) {
        ActivateElement("UpdateMessages");
      } else if (
        window.location.href.includes("AssignTechnictianToLiveSupport")
      ) {
        ActivateElement("AssignTechnictianToLiveSupport");
      } else if (window.location.href.includes("LiveSupport")) {
        ActivateElement("LiveSupport");
      } else if (window.location.href.includes("MessagesAdmin")) {
        ActivateElement("MessagesAdmin");
      } else if (window.location.href.includes("VideoMessages")) {
        ActivateElement("VideoMessages");
      } else if (window.location.href.includes("TextMessages")) {
        ActivateElement("TextMessages");
      } else if (window.location.href.includes("Dashboard")) {
        ActivateElement("Dashboard");
      } else if (window.location.href.includes("Transaction")) {
        ActivateElement("Transaction");
      } else if (window.location.href.includes("ListOnSiteSupport")) {
        ActivateElement("ListOnSiteSupport");
      } else if (window.location.href.includes("OnSiteSupport")) {
        ActivateElement("OnSiteSupport");
      } else if (window.location.href.includes("AccountRechargeManagement")) {
        ActivateElement("AccountRechargeManagement");
      }
    }, 1000);
  };

  const ListChatRooms = () => {
    if (currentUser !== null) {
      if (currentUser.roles.ADMIN_ACCESS) {
        ConnectChat.ListChatRoomsByAgent(currentUser.id);
      } else {
        ConnectChat.ListChatRoomsByBuyerID(currentUser.id);
      }
      setTimeout(() => {
        var unread = ConnectChat.ReturnUnredMessagesFromChatRoom();
        var countUnread = 0;
        _.map(unread, i => { return countUnread = i + countUnread })
        setUnreadMessages(countUnread)
      }, 1000);
    }
  }

  const handleLandingPage = () => {
    history.push("./LandingPage");
  };

  const handleMessages = () => {
    history.push("./Messages");
  };

  const handleVideoChat = () => {
    history.push("./VideoChatMessages");
  };

  const handleMakeupGraph = () => {
    history.push("./MakeUpGraph");
  };

  // const handleMakeupGraphDraftedGraph = () => {
  //   history.push("./MakeUpGraphDraftedGraph");
  // };

  // const handleRigSupport = () => {
  //   history.push("/UnderConstruction");
  // };

  // const handleLiveSupport = () => {
  //   history.push("/LiveSupport");
  // };

  // const handleMessageAdmin = () => {
  //   history.push("./MessagesAdmin");
  // };
  // const handleDashboard = () => {
  //   history.push("/Dashboard");
  // };
  const handleTransaction = () => {
    history.push("./Transaction");
  };

  const handleOnSiteSupport = () => {
    history.push("./OnSiteSupport");
  };

  // const handleAccountRechargeManagement = () => {
  //   history.push("./AccountRechargeManagement");
  // };

  const handleAssignTechnictianToLiveSupport = () => {
    history.push("./AssignTechnictianToLiveSupport");
  };

  // const handleListOnSiteSupport = () => {
  //   history.push("./ListOnSiteSupport");
  // };

  // const handleUpdateMessages = () => {
  //   history.push("./UpdateMessages");
  // };

  return (
    <NavBarDiv id="NavBar">
      {/* {ListUnreadMessages()} */}
      {ListChatRooms()}
      <button
        id="expandNavBar"
        className="expandNavBar"
        onClick={handleExpandNavBar}
      >
        <img alt="" src={OpenMenu} />
      </button>
      <button
        id="retractNavBar"
        className="retractNavBar"
        onClick={handleRetractNavBar}
      >
        <img alt="" src={OpenMenu} />
      </button>

      <button
        onClick={handleLandingPage}
        id="Start"
        style={{ paddingLeft: "12px" }}
      >
        {/* <img alt="" src={Start} /> */}
        <AiOutlineHome size={"25px"} style={{ marginLeft: '-6px' }} />
        <font className="HiddenText"> {t("NavBar.Start")} </font>
      </button>
      <button onClick={handleMessages} id="TextMessages">
        <img alt="" src={Chat} />
        {unreadMessages !== 0 && <span> {unreadMessages}</span>}{" "}
        <font className="HiddenText">{t("NavBar.Messages")}</font>
      </button>
      <button onClick={handleVideoChat} id="VideoMessages">
        <MdVideoCall className="IconWhite" size="25px" />
        <font className="HiddenText">{t("NavBar.VideoChatCall")} </font>
      </button>
      <button onClick={handleMakeupGraph} id="MakeUpGraph">
        <img alt="" src={MakeUpGraph} />{" "}
        <font className="HiddenText">{t("NavBar.Make-UpGraphReview")} </font>
      </button>
      {/* <button onClick={handleRigSupport} id="RigSupport">
        <img alt="" src={RigSupport} />{" "}
        <font className="HiddenText">{t("NavBar.RigSupport")} </font>
      </button> */}
      {/* <button onClick={handleLiveSupport} id="LiveSupport">
        <img alt="" src={LiveSupport} />{" "}
        <font className="HiddenText">{t("NavBar.LiveSupport")} </font>
      </button> */}
      <button onClick={handleOnSiteSupport} id="OnSiteSupport">
        <FaHardHat className="IconWhite" size="25px" />
        <font className="HiddenText">{t("NavBar.OnSiteSupport")} </font>
      </button>
      {/* {currentUser != null && currentUser.roles.ADMIN_ACCESS ? (
        <button
          onClick={handleAccountRechargeManagement}
          id="AccountRechargeManagement"
        >
          <img alt="" src={AccountRechargeRequest} />{" "}
          <font className="HiddenText">
            {t("NavBar.AccountRechargeManagement")}{" "}
          </font>
        </button>
      ) : (
        <div />
      )} */}

      {currentUser != null && currentUser.roles.ADMIN_ACCESS ? (
        <button
          onClick={handleAssignTechnictianToLiveSupport}
          id="AssignTechnictianToLiveSupport"
        >
          <img alt="" src={AssignTechnictianToLiveSupport} />{" "}
          <font className="HiddenText">
            {t("NavBar.AssignTechnictianToLiveSupport")}{" "}
          </font>
        </button>
      ) : (
        <div />
      )}


      {currentUser != null && currentUser.roles.ADMIN_ACCESS ? (
        <button onClick={handleTransaction} id="Transaction">
          <BiTransfer className="IconWhite" size="25px" />
          <font className="HiddenText">{t("NavBar.Transaction")} </font>
        </button>
      ) : (
        <div />
      )}
      {/* {currentUser != null &&
        currentUser.roles.ADMIN_ACCESS ? (
        <button onClick={handleMessageAdmin} id="MessagesAdmin">
          <RiAdminLine className="IconWhite" size="25px" />
          <font className="HiddenText">{t("NavBar.MessagesAdmin")} </font>
        </button>
      ) : (
        <div />
      )} */}
      {/* {currentUser != null &&
      currentUser.roles.ADMIN_ACCESS ? (
        <button onClick={handleDashboard} id="Dashboard">
          <BsGraphUp className="IconWhite" size="25px" />
          <font className="HiddenText">{t("NavBar.DashBoard")} </font>
        </button>
      ) : (
        <div />
      )} */}
      {/* {currentUser != null &&
        currentUser.roles.ADMIN_ACCESS ? (
        <button
          onClick={handleMakeupGraphDraftedGraph}
          id="MakeUpGraphDraftedGraph"
        >
          <RiDraftFill className="IconWhite" size="25px" />
          <font className="HiddenText">
            {t("NavBar.MakeUpGraphDraftedGraph")}{" "}
          </font>
        </button>
      ) : (
        <div />
      )} */}
      {/* {currentUser != null &&
        currentUser.roles.ADMIN_ACCESS ? (
        <button onClick={handleListOnSiteSupport} id="ListOnSiteSupport">
          <AiFillCar className="IconWhite" size="25px" />
          <font className="HiddenText">{t("NavBar.ListOnSiteSupport")} </font>
        </button>
      ) : (
        <div />
      )} */}
      {/* {currentUser != null &&
        currentUser.roles.ADMIN_ACCESS ? (
        <button onClick={handleUpdateMessages} id="UpdateMessages">
          <BiMessageEdit className="IconWhite" size="25px" />
          <font className="HiddenText">{t("NavBar.UpdateMessages")} </font>
        </button>
      ) : (
        <div />
      )} */}
      {handleGetCurrentLocation()}
    </NavBarDiv>
  );
};

export default NavBar;
