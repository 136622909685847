import React, { useState } from "react";

export const OnSiteSupportContext = React.createContext();

export const  OnSiteSupportProvider = ({ children }) => {
  const [ onSiteSupportObject, setOnSiteSupportObject] = useState(null);

  const renderChatID = (
    <OnSiteSupportContext.Provider
      value={{
        onSiteSupportObject,
        setOnSiteSupportObject,
      }}
    >
      {children}
    </OnSiteSupportContext.Provider>
  );

  return renderChatID;
};
