import React from "react";
import { useTranslation } from "react-i18next";
import { FaHardHat } from "react-icons/fa";
import styled from "styled-components";
import BackgroundImage from "../../assets/images/background.jpg";
import Logo from "../../assets/images/logo.png";
import { Button, Card, FlexView } from "../../components/common";

const Background = styled.div`
  background: url(${BackgroundImage}) no-repeat center center fixed;
  background-size: cover;
  background-color: ${({ theme }) => theme.colors.background};
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

// const Copyrights = styled.div`
//   color: ${({ theme }) => theme.colors.gray};
// `

// const ContentText = styled.div`
//   font-size: ${({ theme }) => theme.fontSizes.small};
//   color: ${({ theme }) => theme.colors.gray};
//   font-family: Roboto;
//   text-align: justify;
//   width: 100%;
// `;

const Anchor = styled.a`
  text-decoration: none;
`;

const Privacy = () => {
  const { t } = useTranslation();

  return (
    <Background>
      <Card
        width="90vw"
        height="80vh"
        elevation="veryHigh"
        padding="24px"
        justifyContent="space-between"
        alignItems="stretch"
        style={{ overflow: "auto" }}
      >
        <FlexView
          flexDirection="row"
          alignItems="flex-end"
          justifyContent="space-between"
          width="100%"
        >
          <Anchor href="/">
            <Button width="140px" backgroundColor="primary" color="white">
              {t("Go Back")}
            </Button>
          </Anchor>
          <img src={Logo} alt="Application Logo" width="120px" />
        </FlexView>
        <FlexView
          flex="1 0"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <center>
            {" "}
            <FaHardHat size="60px" style={{ color: "#009ee0" }} /> <div />
            Under Construction
          </center>
          {/* <ContentText>


            <h1> { t('privacy.title_page_uc') } </h1>
            <h4> { t('privacy.subtitle_page') } </h4>
            <h2> { t('privacy.preamble_title') } </h2>
            <p> { t('privacy.preamble_1') }</p>
            <p> { t('privacy.preamble_2') }</p>
            <p> { t('privacy.preamble_3') } </p>
            <p> { t('privacy.preamble_4') } </p>
            <p> { t('privacy.preamble_5') } </p>
            <p> {t('privacy.preamble_6')} </p>
            <p>
              {t('privacy.preamble_7')}
              <img src={emailImage} alt="email" style={{ maxWidth: '160px', marginTop: '10px', marginBottom: '-5px' }} /> 
              {t('privacy.preamble_8')}
            </p>
            <h3><b><u> { t('privacy.definitions_title') } </u></b></h3>
            <p> { t('privacy.definitions_1') }  : </p>
            <p><strong> { t('privacy.definitions_2_title') } </strong> – { t('privacy.definitions_2') } </p>
            <p><strong> { t('privacy.definitions_3_title') } </strong> { t('privacy.definitions_3') } </p>
            <p><strong> { t('privacy.definitions_4_title') } </strong>  { t('privacy.definitions_4') } </p>
            <p><strong> { t('privacy.definitions_5_title_1') }  </strong>, <strong> { t('privacy.definitions_5_title_2') } </strong>, <strong> { t('privacy.definitions_5_title_3') } </strong>  { t('privacy.definitions_5') } </p>
            <p><strong> { t('privacy.definitions_6_title') } </strong> { t('privacy.definitions_6') } </p>
            <p><strong> { t('privacy.definitions_7_title') } </strong> { t('privacy.definitions_7') } </p>
            <p><strong> { t('privacy.definitions_8_title') } </strong> { t('privacy.definitions_8') } </p>
            <p><strong> { t('privacy.definitions_9_title') } </strong> { t('privacy.definitions_9') } </p>
            <h2><u>1. { t('privacy.conditions_collecting_personal_data_title') }  </u></h2> 
            <p>  { t('privacy.conditions_collecting_personal_data') } </p>
            <h3>1.1 { t('privacy.cookies_title') } </h3>
            <h4>1.1.1 { t('privacy.cookies_general_title') } </h4>
            <p> { t('privacy.cookies_general_1') } </p>
            <p> { t('privacy.cookies_general_2') } </p>
            <p> { t('privacy.cookies_general_3') } </p>
            <h4>1.1.2 { t('privacy.cookies_generated_website_title') }  </h4>
            <p>  { t('privacy.cookies_generated_website_1') }  : </p>
            <ol>
              <li> { t('privacy.cookies_generated_website_2') }   </li>
              <li> { t('privacy.cookies_generated_website_3') } </li>
            </ol>
            <h4>1.1.3 { t('privacy.cookies_generated_3rd_site_title') }   </h4>
            <p> { t('privacy.cookies_generated_3rd_site_1') }  </p>
            <p> { t('privacy.cookies_generated_3rd_site_2') } </p>
            <p> { t('privacy.cookies_generated_3rd_site_3') } : </p>
            <p> <u>{ t('privacy.cookies_generated_3rd_site_4_tool') }</u>  { t('privacy.cookies_generated_3rd_site_4') } </p>
            <p> <u>{ t('privacy.cookies_generated_3rd_site_5_tool') }</u>  { t('privacy.cookies_generated_3rd_site_5') } </p>
            <p> <u>{ t('privacy.cookies_generated_3rd_site_6_tool') }</u>  { t('privacy.cookies_generated_3rd_site_6') } </p>
            <p> <u>{ t('privacy.cookies_generated_3rd_site_7_tool') }</u>  { t('privacy.cookies_generated_3rd_site_7') } </p>
            <p> <u>{ t('privacy.cookies_generated_3rd_site_8_tool') }</u>  { t('privacy.cookies_generated_3rd_site_8') } </p>
            <h3>1.2  { t('privacy.links_not_controlled_vallourec_title') }  </h3>
            <p>  { t('privacy.links_not_controlled_vallourec_1') }  </p>
            <p> { t('privacy.links_not_controlled_vallourec_2') } </p>
            <p> { t('privacy.links_not_controlled_vallourec_3') } </p>
            <h2><u>2. { t('privacy.purposes_personal_data_title') } </u></h2>
            <h3>2.1 { t('privacy.purposes_personal_data_explicit_legitimate_title') } </h3>
            <p> { t('privacy.purposes_personal_data_explicit_legitimate_1') } </p>
            <p> { t('privacy.purposes_personal_data_explicit_legitimate_2') } </p>
            <ul>
              <li>
                <p> <u> { t('privacy.purposes_personal_data_explicit_legitimate_3_prefix') } </u> { t('privacy.purposes_personal_data_explicit_legitimate_3') }  </p>
                <p> </p>
                <p> <u> { t('privacy.purposes_personal_data_explicit_legitimate_4_prefix') } </u> { t('privacy.purposes_personal_data_explicit_legitimate_4') } </p>
                <p> </p>
                <p> <u> { t('privacy.purposes_personal_data_explicit_legitimate_5_prefix') } </u>{ t('privacy.purposes_personal_data_explicit_legitimate_5') }</p>
                <p>  </p>
                <p> <u> { t('privacy.purposes_personal_data_explicit_legitimate_6_prefix') } </u>  </p>
                <ul>
                  <li> { t('privacy.purposes_personal_data_explicit_legitimate_6_item_1') } </li> 
                  <li> { t('privacy.purposes_personal_data_explicit_legitimate_6_item_2') } </li>
                  <li> { t('privacy.purposes_personal_data_explicit_legitimate_6_item_3') } </li>
                  <li> { t('privacy.purposes_personal_data_explicit_legitimate_6_item_4') } </li>
                </ul>
              </li>
            </ul>
            <h3>2.2  { t('privacy.data_collected_title') } </h3>
            <p> { t('privacy.data_collected_1') } </p>
            <p> { t('privacy.data_collected_2') } </p>
            <p> { t('privacy.data_collected_3') } </p>
            <h2><u>3. { t('privacy.retention_period_personal_data_title') } </u></h2>
            <p> { t('privacy.retention_period_personal_data_1') }   </p>
            <p> { t('privacy.retention_period_personal_data_2') } </p>
            <p> { t('privacy.retention_period_personal_data_3') } </p>
            <ol>
              <li> { t('privacy.retention_period_personal_data_3_item_1') } </li>
              <li> { t('privacy.retention_period_personal_data_3_item_2') } </li>
              <li> { t('privacy.retention_period_personal_data_3_item_3') } </li>
              <li> { t('privacy.retention_period_personal_data_3_item_4') }  </li>
            </ol>
            <h2><u>4.  { t('privacy.recipients_transfer_personal_data_title') }     </u> </h2>
            <p> { t('privacy.recipients_transfer_personal_data_1') } </p>
            <p> { t('privacy.recipients_transfer_personal_data_2') } </p>
            <p> { t('privacy.recipients_transfer_personal_data_3') } </p>
            <p> { t('privacy.recipients_transfer_personal_data_4') } </p>
            <p> { t('privacy.recipients_transfer_personal_data_5') } </p>
            <p> { t('privacy.recipients_transfer_personal_data_6') }</p>
            <h2><u>5. { t('privacy.communication_personal_data_title') } </u> </h2>
            <p>  { t('privacy.communication_personal_dat_1') } </p>
            <p>  { t('privacy.communication_personal_data_2') } </p>
            <ol>
              <li> { t('privacy.communication_personal_data_2_item_1') }  </li>
              <li> { t('privacy.communication_personal_data_2_item_2') }  </li>
              <li> { t('privacy.communication_personal_data_2_item_3') } </li>
            </ol>
            <h2><u>6. { t('privacy.security_measures_title') }  </u> </h2>
            <p> { t('privacy.security_measures_1') } </p>
            <p> { t('privacy.security_measures_2') } </p>
            <p> { t('privacy.security_measures_3') } </p>
            <p> { t('privacy.security_measures_4') } </p>
            <p> { t('privacy.security_measures_5') } </p>
            <p> { t('privacy.security_measures_6') } </p>
            <p>
              {t('privacy.security_measures_7')}
              <img src={emailImage} alt="email" style={{ maxWidth: '160px', marginTop: '10px', marginBottom: '-5px' }} /> 
            </p>
            <p> { t('privacy.security_measures_8') } </p>
            <p> { t('privacy.security_measures_9') } </p>
           
            <h2><u>7. { t('privacy.right_access_correct_personal_data_title') }  </u> </h2>
            <p>  { t('privacy.right_access_correct_personal_data_1') } : </p>
            <blockquote>
              <p>  { t('privacy.right_access_correct_personal_data_2') } </p>
              <p>  { t('privacy.right_access_correct_personal_data_3') } </p>
              <p>
                {t('privacy.right_access_correct_personal_data_4')}
                <img src={emailImage} alt="email" style={{ maxWidth: '160px', marginTop: '10px', marginBottom: '-5px' }} />
              </p>
            </blockquote>
            <p>  { t('privacy.right_access_correct_personal_data_5') } </p>
            <h2><u>8. { t('privacy.applicable_law_title') }  </u> </h2>
            <p> { t('privacy.applicable_law') } </p>
            <h2><u>9. { t('privacy.date_entry_force_update_title') } </u> </h2>
            <p> { t('privacy.date_entry_force_update_1') } </p>
            <p> { t('privacy.date_entry_force_update_2') } </p>
            <p> { t('privacy.date_entry_force_update_3') } </p>
            <h2><u>10. { t('privacy.questions_contact_title') } </u> </h2>
            <p>
              {t('privacy.questions_contact1')}
              <img src={emailImage} alt="email" style={{ maxWidth: '160px', marginTop: '10px', marginBottom: '-5px' }} /> 
              { t('privacy.questions_contact2') }</p>
            <p></p>
            <p></p> 
          </ContentText>
          <Anchor href="/login">
            <Button width="140px" backgroundColor="primary" color="white" margin="10px 5px">{t('Go Back')}</Button>
          </Anchor>*/}
        </FlexView>
        {/* <Copyrights></Copyrights> */}
      </Card>
    </Background>
  );
};

export default Privacy;
