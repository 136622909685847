import React from "react";
import styled from "styled-components";
import { Button } from "../../components/common";
import {
  Body,
  HelloTextBold,
  MainCard
} from "../../components/common/MainCard";
import Footer from "../../components/layout/footer";
import Header from "../../components/layout/header";
import NavBar from "../../components/layout/navBar";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Caller from "../../components/layout/caller";

const TermsAndConditions = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const ButtonDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width:95%;
    padding-top:20px;
    button {
      margin-right: 20px;
      min-height: 50px;
      min-width: 200px;
      background-color: white;
      color: #039fe0;
      border: 0;
      transition: all 0.3s ease 0s;
      :hover {
        background-color: #009ee0;
        box-shadow: 0px 15px 20px rgba(167, 164, 206, 0.4);
        color: #fff;
        transform: translateX(7px);
      }
    }
  `;

  const ScrollableDiv = styled.div`
    display: flex;
    width: 95%;
    height: 60vh;
    border: 1px solid #ccc;
    overflow-x: hidden;
    overflow-y: scroll;
    margin-top: 10px;
    margin-left:40px;
  `;

const handleCancel = () => {
  history.push("/LandingPage");
};

const handleAgree = () => {
  history.push("/UnderConstruction");
};

  return (
    <Body>
      <Header />
      <Caller />
      <NavBar />
      <MainCard id="MainCard">
        <HelloTextBold> {t("TermsAndConditions.TermsAndConditions")}</HelloTextBold>
        <ScrollableDiv>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
          efficitur egestas sem, nec ultrices lectus auctor ut. Nunc nisl ipsum,
          cursus nec rhoncus eget, elementum in nunc. Fusce condimentum a nisi
          quis lacinia. Mauris tincidunt leo risus, nec pretium dolor euismod
          at. Vestibulum semper laoreet commodo. Nullam facilisis feugiat
          venenatis. Nam condimentum condimentum sollicitudin. Suspendisse
          euismod urna vitae augue fermentum tincidunt. Sed ultricies magna ac
          turpis luctus luctus. Nam vitae ligula ipsum. Nullam ut feugiat
          tortor. Aliquam pulvinar justo dui, in venenatis augue tempus quis.
          Mauris eu neque ullamcorper, iaculis nunc a, mattis dolor. Maecenas
          non risus quis elit pretium ultrices ut ut libero. Phasellus ligula
          lectus, iaculis id suscipit eu, egestas vitae eros. Donec lacus
          tellus, accumsan aliquet orci ut, eleifend gravida tortor. Sed id
          porta nibh. Curabitur auctor egestas dolor. Duis faucibus erat elit,
          sed suscipit orci porttitor eu. Maecenas vitae volutpat nisl. Etiam in
          urna consequat, pulvinar arcu ut, feugiat mi. Nunc id dictum quam, eu
          ultrices nunc. Quisque in justo accumsan, laoreet magna in,
          pellentesque est. Etiam eget condimentum nunc, vitae aliquam quam.
          Nunc pulvinar neque at orci luctus, sed tincidunt magna faucibus.
          Etiam ullamcorper mauris turpis, non interdum orci eleifend vel.
          Mauris non sodales lacus. In vel diam sed neque viverra hendrerit.
          Suspendisse hendrerit interdum mollis. Nunc efficitur id nisi volutpat
          tincidunt. Nulla sit amet erat non tellus dapibus tincidunt. Maecenas
          non convallis turpis. Phasellus volutpat vestibulum nibh sit amet
          ultricies. Nullam luctus nibh mattis elit sagittis, id cursus neque
          congue. Suspendisse ultrices sapien eu lectus aliquam, quis dictum
          eros gravida. Aenean volutpat pellentesque lobortis. Praesent at
          aliquam augue. Etiam consequat auctor felis. Nulla dignissim, libero
          nec fringilla laoreet, dui lorem cursus elit, nec posuere nisl velit
          sed odio. Pellentesque tristique mauris ligula, ut vehicula nisl
          pellentesque id. Cras vitae neque a massa mattis tempus. Nulla
          facilisi. Morbi eget tellus eget nisi posuere interdum. Morbi
          efficitur, orci quis mattis rhoncus, est erat eleifend diam, id varius
          elit mauris eu nulla. Aliquam vel aliquam arcu. Ut arcu risus, commodo
          ac odio at, luctus dapibus purus. Fusce euismod at nisl ac luctus.
          Donec accumsan quam tincidunt tortor rutrum fermentum. Nunc in tellus
          erat. Vestibulum vehicula, enim aliquet consectetur imperdiet, mauris
          turpis tempus massa, eget semper lorem sem ac lorem. Ut ac odio sit
          amet ex interdum sodales. Aliquam in imperdiet elit. Ut gravida justo
          non nibh convallis mattis. Praesent id est at purus rutrum porta.
          Maecenas rhoncus semper commodo. Proin nisi nulla, commodo eget luctus
          eget, faucibus et nunc. Pellentesque commodo mi eget semper lacinia.
          Pellentesque habitant morbi tristique senectus et netus et malesuada
          fames ac turpis egestas. Sed sem eros, dignissim sed sem non, ornare
          pellentesque ligula. Duis dui ante, tincidunt non diam at, pharetra
          convallis purus. Aenean tristique, metus vitae semper venenatis, est
          felis blandit odio, eu molestie mauris elit vitae nibh. Fusce quis
          malesuada odio. Ut ut ex fermentum, aliquam sem eget, semper ligula.
          Suspendisse ultrices, mauris ut dictum venenatis, sem erat sodales
          elit, vitae hendrerit sem libero in risus. Cras nec blandit nibh.
          Nulla pulvinar ex id velit tempus, nec posuere ligula tincidunt. Nunc
          est augue, ultrices eu ipsum et, imperdiet tincidunt risus. Sed
          commodo eget risus eu condimentum. Fusce vel venenatis turpis. Nulla
          porta nunc non congue ultrices. Duis scelerisque ipsum massa. Donec
          dapibus nisi sit amet blandit convallis. Praesent ultrices condimentum
          nunc, a dictum nisl. Aliquam erat volutpat. Curabitur sed ultricies
          purus. Orci varius natoque penatibus et magnis dis parturient montes,
          nascetur ridiculus mus. Curabitur tincidunt, nisl ut dictum
          sollicitudin, lectus diam lobortis tellus, et tristique urna dolor sed
          dui. Donec aliquet diam nibh, sit amet porta metus fringilla in.
          Vestibulum sollicitudin, nisl nec pretium mollis, elit sapien aliquam
          tortor, nec facilisis tellus mauris id arcu. Aenean vehicula accumsan
          metus. Morbi viverra fermentum lectus, nec scelerisque elit ultrices
          ut. Aenean in arcu vel nibh elementum feugiat in mattis ante. Aenean
          sodales cursus sem at pharetra. Curabitur ornare commodo lacus nec
          tempor. Suspendisse consequat laoreet accumsan. Aenean vitae
          ullamcorper est, quis consequat velit. Phasellus egestas metus ac
          sapien venenatis sodales. Suspendisse scelerisque, leo eu hendrerit
          ultricies, ante sapien volutpat eros, vel luctus sem erat non justo.
          Nunc consequat dolor sit amet malesuada hendrerit. Aliquam ac rhoncus
          dui, vel faucibus nibh. Cras posuere leo non odio ullamcorper
          hendrerit. Class aptent taciti sociosqu ad litora torquent per conubia
          nostra, per inceptos himenaeos. Fusce id ex vel arcu cursus rutrum.
          Curabitur finibus mi nibh, ac interdum nunc cursus nec. Aliquam
          blandit semper sapien, sed fermentum neque faucibus a. Donec nec lorem
          sit amet turpis porta mattis sed a ipsum. Donec non lorem felis.
          Pellentesque blandit vulputate porta. Quisque venenatis leo sed odio
          pulvinar faucibus. Ut eu mauris bibendum, vulputate est vel, posuere
          augue. Sed suscipit, tortor eu commodo imperdiet, nisi eros ultrices
          quam, ut elementum dolor quam id velit. Sed nunc nisl, tempor sit amet
          est quis, sagittis viverra arcu. Orci varius natoque penatibus et
          magnis dis parturient montes, nascetur ridiculus mus. Cras ac
          tincidunt felis. Fusce consectetur tellus sit amet tincidunt ultrices.
          Suspendisse commodo elit vitae urna sollicitudin lobortis. Curabitur
          nisl quam, dictum a erat vitae, blandit laoreet magna. Suspendisse
          molestie faucibus lectus, at ultrices quam consequat sed. In ipsum ex,
          tincidunt id nulla dictum, posuere lobortis erat. Vestibulum semper
          justo vitae consectetur pellentesque. Sed sed sem eu nisi mollis
          fringilla. Donec bibendum ipsum eros. Phasellus elementum sollicitudin
          ligula at tempor. Donec eleifend, purus in aliquam cursus, nisi quam
          convallis enim, quis porta justo tortor nec lectus. Vivamus vestibulum
          eleifend lectus id ornare. Quisque at felis neque. Fusce varius ligula
          at velit iaculis, sollicitudin varius sem volutpat. Vestibulum laoreet
          varius sodales. Morbi id neque interdum, euismod magna id, gravida mi.
          Sed suscipit sodales sapien, vel euismod eros iaculis quis. Nulla
          facilisi. Vestibulum hendrerit nisi ut massa tempor, sit amet sodales
          quam pulvinar. Etiam nec iaculis ex. In sit amet purus venenatis,
          convallis nibh at, tristique nisl. Sed quis tellus leo. Maecenas
          elementum at neque sit amet tincidunt. Suspendisse dapibus augue eu
          eleifend lacinia. Curabitur consequat est dolor, vel tincidunt tortor
          iaculis at. Suspendisse ut urna suscipit, facilisis mauris et, finibus
          arcu. Proin semper pretium erat, et dignissim arcu aliquet at. Mauris
          tristique scelerisque purus, in fringilla felis volutpat facilisis.
          Morbi eget viverra risus. Maecenas tellus erat, faucibus ut luctus at,
          maximus vel est. Ut dictum erat semper efficitur faucibus. Morbi in
          libero est. Donec nisl ipsum, finibus et ornare eu, efficitur id enim.
          Vivamus sed ultrices metus. Maecenas faucibus, felis quis sollicitudin
          mollis, ex nulla varius ligula, vel faucibus elit dui vitae ipsum.
          Nunc suscipit pulvinar massa eget cursus. Quisque lacinia venenatis
          odio id blandit. Aenean auctor feugiat varius. Cras non leo at velit
          dictum volutpat. Phasellus facilisis tincidunt dolor, at malesuada sem
          vulputate in. Nulla condimentum mauris mi, quis tincidunt mauris
          luctus et. Aliquam magna libero, laoreet at neque in, fringilla
          iaculis sem. Curabitur tempus dignissim leo in convallis. Suspendisse
          aliquam turpis eu justo fermentum, vitae rhoncus turpis pharetra.
          Donec est quam, ultrices sit amet tincidunt at, vehicula id augue. Ut
          vel metus gravida neque ultrices dapibus vitae eu augue. Nunc vel mi
          laoreet, tincidunt dui eu, viverra arcu. Vivamus risus quam, gravida
          sagittis tincidunt et, auctor et enim. Quisque facilisis turpis non
          libero varius, finibus laoreet neque ultrices. Mauris eget magna in
          odio vestibulum rutrum vel id mi. Cras sollicitudin eget massa sit
          amet condimentum. Pellentesque massa massa, ultricies eget ante ut,
          viverra dapibus nisl. Quisque volutpat maximus augue et tempus.
          Curabitur feugiat rutrum justo. In at enim vel lorem scelerisque
          finibus vel id urna. Aliquam luctus neque lorem, non venenatis nibh
          condimentum vitae. Suspendisse vehicula ante nulla, at efficitur
          libero eleifend ac. Vestibulum commodo, purus eget pretium molestie,
          urna augue luctus ipsum, vitae iaculis augue odio ut est. Nullam id
          erat sit amet justo rutrum gravida. Nam nec ultricies arcu. Fusce
          fringilla justo vitae scelerisque laoreet. Phasellus auctor ante in
          tempus hendrerit. Sed et tempor nunc. Etiam sit amet cursus orci, a
          pellentesque turpis. Sed vel vulputate sem, ac sodales sapien. Mauris
          dapibus quam vitae mollis efficitur. Cras ornare, ligula vel suscipit
          placerat, mi velit maximus lectus, in varius ipsum nibh et felis.
          Vestibulum a ligula sodales sem convallis imperdiet sit amet id enim.
          Curabitur semper libero urna, non scelerisque nibh condimentum et.
          Curabitur tempor, nisi sed interdum egestas, lectus quam vulputate
          magna, eget hendrerit velit augue quis erat. Cras et dolor et neque
          aliquet ullamcorper in eget leo. Cras pretium a purus vel lacinia. Sed
          lacinia gravida magna, tincidunt pulvinar metus gravida vel. Mauris id
          maximus sapien, sit amet elementum neque. Quisque accumsan dapibus
          libero interdum sagittis. Phasellus semper dolor erat. In ultricies
          velit nec tincidunt maximus. Interdum et malesuada fames ac ante ipsum
          primis in faucibus. Nulla lobortis, nunc vel rutrum interdum, neque
          velit bibendum lacus, vitae consequat nunc neque vitae nisi. Ut a
          pretium metus. In et eros bibendum quam consectetur sagittis. Aenean
          aliquam sapien quis ultrices blandit. Etiam at aliquam orci, id
          posuere mi. Morbi nisi ex, mattis in leo quis, convallis laoreet est.
          Sed facilisis nulla eget auctor tincidunt. Suspendisse potenti. Morbi
          viverra ut ex sed imperdiet. Vivamus et sodales mauris. Nam mollis sem
          sed faucibus dictum. Aenean eu malesuada nisi. Donec laoreet velit nec
          tortor auctor, non laoreet sem pharetra. Vivamus condimentum justo
          neque, nec molestie ligula ullamcorper quis. Donec ut sollicitudin
          erat. Vestibulum feugiat, elit id ornare interdum, mi tellus molestie
          magna, sodales ultricies metus orci vel magna. Nulla facilisi. In
          luctus euismod urna, consectetur mollis sapien congue et. Etiam vitae
          luctus augue, sit amet consequat ante. Morbi pulvinar urna a ante
          sodales finibus. Sed egestas consequat accumsan. Nulla ut rutrum erat.
          Vestibulum nec condimentum purus, a eleifend risus. Donec fringilla,
          justo a auctor scelerisque, diam massa consequat massa, id egestas
          tortor nisi et tellus. Sed at quam sapien. Aenean turpis ante, dapibus
          at rutrum nec, mollis ac nisi. Suspendisse quis bibendum turpis.
          Pellentesque ornare libero iaculis volutpat lacinia. Nulla id mauris
          ut ipsum viverra faucibus. Proin eros libero, volutpat ut fringilla
          quis, tristique vel neque. Fusce eu luctus dolor. Duis consectetur, mi
          vel placerat aliquam, ipsum nunc semper dolor, vitae commodo nibh
          libero sit amet enim. Sed scelerisque egestas vulputate. Phasellus leo
          sapien, porttitor nec congue a, bibendum sed dolor. Vivamus aliquet
          nisi ac nisl ullamcorper convallis. Donec faucibus condimentum arcu,
          eu rutrum enim vulputate non. Morbi eu semper massa. Aliquam sed odio
          non est feugiat malesuada. Nam dignissim nisl ut enim euismod, in
          hendrerit quam placerat. Aliquam lobortis nisl nec ullamcorper
          vestibulum. Suspendisse condimentum mauris vel dui lobortis, ac
          faucibus ligula cursus. Quisque eu quam posuere orci molestie
          efficitur. Aliquam urna orci, posuere in dui sit amet, gravida
          tristique diam. Nam luctus fringilla viverra. Aenean ultrices dictum
          erat, quis vestibulum odio venenatis nec. Vestibulum sollicitudin
          ultricies nibh at condimentum. Duis pulvinar imperdiet magna non
          auctor. Curabitur id imperdiet augue. Nunc facilisis ex magna, sed
          aliquet tortor molestie eget. Etiam vitae nisi commodo, egestas diam
          sit amet, rhoncus nulla. Morbi nec placerat leo. Aliquam faucibus
          molestie interdum. Integer vel dictum felis, eu rutrum tortor.
          Vestibulum a nisi et felis elementum mollis. Suspendisse turpis nisi,
          auctor nec cursus consectetur, ultrices ut enim. Duis viverra
          venenatis arcu malesuada viverra. Integer in nisi feugiat, feugiat
          lectus molestie, blandit nisi. Integer maximus laoreet nisi, sed
          mattis magna maximus maximus. Duis euismod sit amet turpis vitae
          ultricies. Aenean a diam vitae ex tempus porta. Ut sed leo urna. Sed
          eros eros, lobortis a dui quis, consequat maximus libero. Nunc enim
          purus, faucibus non nibh at, sodales fermentum orci. In sollicitudin
          molestie nunc quis mattis. Proin pretium sed velit sed imperdiet. Nam
          in metus libero. Nunc pulvinar elit a leo ultricies, non tempor nunc
          aliquet. Integer cursus nulla vestibulum tellus imperdiet, sed
          fermentum nunc accumsan. Etiam hendrerit non eros vel fringilla.
          Pellentesque habitant morbi tristique senectus et netus et malesuada
          fames ac turpis egestas. Vivamus et est id quam pharetra iaculis.
          Suspendisse potenti. Sed at velit ut nunc efficitur finibus et sit
          amet ipsum. Vivamus in gravida turpis, ut pellentesque neque. Duis et
          magna id massa pellentesque tempor. Nam arcu justo, blandit sit amet
          metus at, lobortis gravida tellus. Phasellus mattis odio quis accumsan
          sodales. Aenean leo lectus, hendrerit non ultrices et, tristique sed
          nulla. Sed est velit, gravida eget ante vitae, tempor posuere ipsum.
          Duis odio diam, aliquam eu convallis et, sollicitudin nec diam. Nulla
          turpis diam, feugiat sed lorem id, auctor dapibus quam. Vivamus quis
          tempus urna, ut pretium lectus. Nulla volutpat aliquet magna, et
          sodales orci pretium sed. Sed a efficitur erat, ut dignissim eros.
          Interdum et malesuada fames ac ante ipsum primis in faucibus. Nulla
          convallis porttitor mauris in pretium. In hac habitasse platea
          dictumst. Pellentesque suscipit tempus felis, eu porttitor lorem
          consequat non. Ut egestas, nulla ut porta consequat, purus velit
          rutrum dolor, id suscipit diam diam vel dolor. Integer arcu augue,
          iaculis eget nulla sed, tempor facilisis magna. Pellentesque at turpis
          id tortor fringilla semper eget non dui. Morbi purus purus, elementum
          vitae odio a, lobortis posuere purus. Pellentesque habitant morbi
          tristique senectus et netus et malesuada fames ac turpis egestas.
          Aenean nec laoreet velit. Lorem ipsum dolor sit amet, consectetur
          adipiscing elit. Curabitur tincidunt ac lacus quis egestas. Sed
          blandit justo nunc, at fringilla purus egestas non. Praesent pulvinar,
          nulla ut mattis volutpat, ex purus accumsan nibh, at cursus nulla ante
          eget eros. Aenean in metus lobortis, molestie justo commodo, accumsan
          risus. Morbi ultrices nibh non sapien rutrum, eget commodo metus
          iaculis. Aenean pulvinar nisi sed finibus bibendum. Nulla facilisi.
          Cras eget justo leo. Nam bibendum eleifend lectus. Etiam quis orci
          quam. Integer nec dolor id est sagittis tempor. Praesent at quam vitae
          est tempor pellentesque in semper arcu. Curabitur ultrices nisi in
          fermentum pulvinar. Duis porttitor, libero lobortis dapibus fringilla,
          justo tortor lacinia libero, vel porta ipsum ligula eu metus. Aliquam
          nec urna molestie, viverra quam id, molestie leo. Fusce tempor leo
          nisi, ut euismod ex interdum at. Vivamus euismod magna a hendrerit
          facilisis. Donec eros magna, fringilla at turpis sed, vestibulum
          facilisis nisi. Nam laoreet risus eget justo vehicula tincidunt. Sed
          eu quam pharetra, pretium justo nec, mattis orci. Mauris vulputate
          porttitor neque, vel dictum nisi varius sed. Cras placerat odio in
          risus laoreet suscipit. Fusce lobortis sodales libero ac malesuada.
          Sed ex tellus, consectetur et justo a, iaculis pretium mi. Vestibulum
          efficitur orci dui, ut lobortis erat sodales egestas. Vivamus eu
          fringilla arcu, et consectetur nisl. Curabitur cursus sapien vitae
          lorem tempor sollicitudin et id nisl. Aliquam mi tortor, eleifend vel
          pellentesque nec, pellentesque eu tellus. Phasellus commodo nibh
          magna, sed eleifend nulla dictum nec. Donec sodales, mauris id
          dignissim facilisis, erat tellus accumsan nisl, in ornare erat nulla
          at sem. Cras vehicula, sem in porta luctus, neque mauris auctor nisl,
          sit amet mattis tortor purus vel sapien. Suspendisse neque velit,
          tristique sit amet interdum sed, pulvinar sit amet arcu. Vestibulum
          pharetra lobortis elit. Donec porttitor nunc non justo placerat, ut
          semper sapien lacinia. Sed vel urna dolor. Nunc ut vehicula nibh, eget
          euismod libero. Sed vel neque vitae nibh consectetur dictum. Aliquam
          porta, nisl finibus tincidunt vehicula, ipsum orci rhoncus turpis, vel
          euismod ante dolor et neque. Sed consectetur tempus elit et congue.
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ac nunc
          sit amet lacus ultricies auctor. Donec efficitur rutrum lorem et
          varius. Morbi ornare ornare felis, non suscipit ante suscipit sed. In
          enim nibh, fermentum vel porta a, ullamcorper eget turpis. Phasellus
          dapibus euismod enim vitae interdum. Donec commodo mi ipsum, dictum
          ultrices sem fringilla non. Nulla ut nibh leo. Vivamus et vestibulum
          ligula, quis accumsan ante. Nullam dapibus sit amet elit sed dapibus.
          Phasellus vitae odio sed velit bibendum scelerisque. Sed ut neque
          vitae dui mollis ultrices nec eget nisl. Suspendisse mi quam,
          facilisis in interdum in, consequat at odio. Sed ornare non justo at
          imperdiet. Orci varius natoque penatibus et magnis dis parturient
          montes, nascetur ridiculus mus. Integer et consectetur ipsum. Nam
          condimentum a libero et tempor. Fusce vehicula velit risus, a pretium
          ipsum dapibus ut. Suspendisse fermentum, neque a ultricies finibus,
          turpis erat iaculis purus, a eleifend turpis enim ac nibh. In mattis
          eros quis ullamcorper lacinia. Phasellus ac egestas eros. Proin
          tincidunt tempus leo, id laoreet quam fringilla ac. Maecenas fringilla
          vestibulum venenatis. Praesent viverra laoreet rhoncus. Aenean non
          pulvinar augue. Phasellus tincidunt nunc metus, sed viverra eros
          feugiat vitae. Sed euismod nulla non mauris hendrerit pellentesque.
          Duis eget luctus enim, vitae ultricies mi. Phasellus placerat risus in
          augue porta, ac congue ante sollicitudin. Vestibulum molestie aliquam
          ante, id volutpat justo faucibus vitae. Duis iaculis volutpat nisi, eu
          aliquet turpis maximus in. Mauris ac quam non lorem dapibus vehicula
          et id justo. Vivamus ut mauris sagittis, accumsan lectus volutpat,
          tempor quam. Maecenas id nisl non ante cursus pellentesque.
          Suspendisse potenti. Proin vitae bibendum ante. Quisque sagittis magna
          non iaculis scelerisque. Mauris porttitor sagittis est eu ullamcorper.
          Praesent nec lacus a lectus mollis lacinia eget a enim. Orci varius
          natoque penatibus et magnis dis parturient montes, nascetur ridiculus
          mus. Pellentesque purus magna, hendrerit sodales hendrerit id,
          efficitur gravida nisl. Proin a erat lacus. Curabitur sodales, dolor a
          tempus consequat, quam nibh imperdiet dolor, at sodales est dolor vel
          mi. Nullam pharetra, quam sed consequat tincidunt, metus neque
          faucibus felis, nec blandit diam sapien sed nibh. Donec eu nulla
          lorem. Sed tempus imperdiet odio. Pellentesque ornare efficitur purus
          sed rutrum. Quisque eu condimentum lacus. Aliquam ornare eros eget
          metus ultricies vestibulum. Etiam metus nibh, aliquet vitae rhoncus
          nec, pulvinar non tellus. Duis a lobortis nisl. Quisque tincidunt
          ornare justo, tempus venenatis leo lacinia quis. Sed accumsan odio vel
          tincidunt imperdiet. Cras accumsan elit vel arcu mattis sodales. Nulla
          et malesuada nisl, blandit scelerisque nibh. In hac habitasse platea
          dictumst. Quisque interdum condimentum vestibulum. Phasellus
          ultricies, tortor eget lobortis pulvinar, massa metus luctus elit, at
          aliquet turpis massa at orci. Nam vitae enim eu nulla pharetra
          sollicitudin vitae non nibh. Vivamus eu arcu ipsum. Nulla sagittis
          elit eu lorem volutpat facilisis. Vivamus quis hendrerit lectus. Sed
          volutpat dolor quis mauris laoreet, id consectetur felis luctus.
          Phasellus tempor gravida diam non semper. Pellentesque vitae elit in
          libero rhoncus porttitor. Suspendisse a dui at odio malesuada
          imperdiet. Etiam vitae erat sed nulla placerat fringilla. Ut nec augue
          sed ex accumsan placerat. Vestibulum blandit placerat ex in convallis.
          Vestibulum venenatis dapibus congue. Sed felis tellus, vestibulum a
          lectus in, rhoncus pellentesque mi. Donec consequat tortor nec pretium
          placerat. Curabitur pharetra sem quis tincidunt pulvinar. Duis neque
          ex, tempus vitae sollicitudin id, imperdiet vel justo. Sed bibendum
          risus est, nec commodo erat egestas a. Class aptent taciti sociosqu ad
          litora torquent per conubia nostra, per inceptos himenaeos. Integer
          congue, magna non volutpat commodo, felis elit venenatis ex, sed
          aliquet sem diam vel massa. Nullam tristique pretium dictum. Proin
          vestibulum interdum sapien. Aliquam convallis pharetra purus eu
          pulvinar. Donec sit amet laoreet ex. Interdum et malesuada fames ac
          ante ipsum primis in faucibus. Proin condimentum aliquam suscipit.
          Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
          posuere cubilia curae; Donec tincidunt tincidunt tellus, ac facilisis
          elit laoreet vehicula. Pellentesque vel velit commodo, luctus sem
          aliquam, imperdiet metus. Ut nec blandit lectus, eu dictum erat. Nunc
          vulputate nibh sollicitudin hendrerit lacinia. Donec facilisis tellus
          eu purus tincidunt condimentum. Aenean hendrerit elit lectus, id
          eleifend nisi ultricies in. Phasellus eu mi nibh. Suspendisse auctor
          leo ac erat egestas, eu lobortis diam faucibus. Phasellus semper neque
          elementum fermentum volutpat. Aenean vestibulum consectetur fermentum.
          Aliquam dapibus commodo gravida. Nulla semper ligula commodo lorem
          ultricies varius. Suspendisse at tincidunt ipsum, nec scelerisque
          risus. Pellentesque id fermentum urna. Suspendisse tincidunt, neque ac
          convallis consectetur, tellus odio tincidunt nisi, a molestie nunc
          neque in enim. Proin faucibus sodales nisi, in bibendum mi. Nam sem
          eros, egestas vel ultrices vel, tincidunt ac justo. Curabitur
          scelerisque libero id tempus mattis. Pellentesque semper ligula non
          lorem mollis, ullamcorper rhoncus dui convallis. Phasellus ultricies
          dapibus lectus. Curabitur quis odio malesuada, eleifend sem nec,
          laoreet massa. In elementum leo tempor dignissim dictum. Duis eget
          dapibus lacus, in accumsan dui. Integer at turpis eu erat scelerisque
          tempor. Praesent vitae lacus sit amet tortor gravida pulvinar ultrices
          a mi. Donec semper eget velit a tempus. Donec est mauris, scelerisque
          id luctus at, dictum in orci. Donec bibendum lacinia efficitur. Nam
          quis posuere erat. Nulla facilisi. Nullam molestie odio ipsum.
          Phasellus sed quam libero. Nunc cursus dapibus dolor eget mattis.
          Donec gravida pharetra dolor, eu porta neque suscipit et. Nulla
          ultrices malesuada mi id rutrum. Cras ut lectus at arcu imperdiet
          dictum sed nec enim. Integer gravida semper metus. Morbi porttitor,
          ligula et ullamcorper porttitor, ligula turpis mollis magna, ac
          fermentum lorem augue vel massa. Sed gravida cursus pulvinar. Nullam
          auctor ultrices orci, sit amet convallis dui ullamcorper sed. Aliquam
          facilisis neque vel nibh viverra euismod. Nullam vel ultrices massa,
          quis pulvinar neque. Sed venenatis, sapien ut hendrerit tempus, lorem
          ante mattis tortor, non interdum ipsum velit sit amet neque. Nunc
          tincidunt metus felis. Aliquam id arcu maximus, lacinia ex in,
          elementum metus. Cras pretium ornare finibus. Donec consectetur, est
          ut imperdiet volutpat, justo felis vestibulum tortor, vitae dictum mi
          nisi vel nisl. Aenean posuere ante vel vehicula rhoncus. Mauris
          tincidunt suscipit finibus. Suspendisse non sem feugiat, ultrices odio
          pretium, sagittis leo. Duis sit amet fringilla dui. Sed at erat eget
          dui varius dignissim. Duis finibus varius nulla non dapibus. Nulla sit
          amet leo ac dui vulputate posuere eget vel ipsum. Quisque fringilla
          consectetur accumsan. Ut molestie metus et risus malesuada, sit amet
          efficitur dui eleifend. Donec tristique neque tempor erat vulputate,
          nec eleifend eros commodo. Phasellus vehicula felis mi, sed porta
          magna tristique a. Maecenas ut ex eget mauris lacinia faucibus.
          Phasellus quis felis viverra, fermentum urna sed, fringilla dolor.
          Aenean consectetur massa vel purus congue, vel aliquam purus laoreet.
          Suspendisse tristique id tortor et consequat. Morbi pretium nunc nunc,
          sit amet ultrices metus vehicula sit amet. In fermentum nibh eu nisi
          sagittis, sit amet fermentum neque facilisis. Ut et nisl ac leo
          lobortis mattis. Praesent elementum iaculis mauris. In euismod elit
          nec faucibus sodales. Suspendisse quis ex vitae felis molestie
          elementum. Maecenas dictum ipsum libero, in faucibus dolor venenatis
          in. Sed rutrum nibh vitae nisl auctor, aliquet tempus orci dapibus.
          Phasellus faucibus risus eu ex condimentum fringilla. Suspendisse
          potenti. Nam cursus nibh sed dapibus sodales. Curabitur sed
          ullamcorper mi. Vivamus condimentum at neque aliquet blandit. Maecenas
          sollicitudin nunc a dolor lacinia, nec pharetra sem aliquet. Nam
          interdum ex ut ex aliquam iaculis. Aliquam eget elit faucibus, sodales
          nulla sit amet, tempus neque. Aliquam accumsan massa a rhoncus
          tristique. Sed finibus ex eget fermentum luctus. Nullam eros purus,
          eleifend viverra tristique id, tincidunt in augue. Vestibulum nec
          neque malesuada, eleifend orci eu, convallis turpis. Morbi felis
          lacus, tempus sit amet metus eget, viverra vestibulum nisi. Etiam
          pretium tempus leo pharetra euismod. Integer tincidunt purus elit, vel
          auctor enim placerat a. Maecenas sodales vulputate orci sed tempus.
          Praesent ut lectus et quam hendrerit efficitur. Nullam risus leo,
          fermentum facilisis justo et, semper mollis nisl. Integer eu gravida
          nisi. Suspendisse potenti. Nullam convallis ullamcorper ornare. Nulla
          rutrum hendrerit purus, vel eleifend ipsum scelerisque et. Integer
          ullamcorper malesuada justo, sit amet lacinia orci sollicitudin id.
          Donec sollicitudin dictum finibus. Morbi nec convallis nibh. Fusce
          tellus nisl, pellentesque in quam et, elementum ornare enim. Mauris
          vitae euismod neque. Fusce egestas cursus metus sed mollis. Etiam in
          euismod ante, non tempus tellus. Aliquam id ultricies quam. Fusce eget
          vestibulum risus, id posuere tortor. Curabitur elementum efficitur
          accumsan. Morbi ac porta felis. Etiam iaculis lacus risus, et congue
          orci pharetra non. Vestibulum luctus est id dui venenatis, vitae
          rutrum lectus eleifend. Maecenas vulputate scelerisque ultrices. Donec
          id laoreet neque. Nullam lectus mauris, placerat non vehicula
          ullamcorper, ullamcorper a ipsum. Interdum et malesuada fames ac ante
          ipsum primis in faucibus. Nullam suscipit ut est quis fermentum. Ut
          nisi orci, tempus non rhoncus ut, bibendum et turpis. Pellentesque
          mollis tortor sed purus eleifend porta. Vestibulum dictum convallis
          scelerisque. Donec vel lorem lorem. Donec id sapien metus. Proin ipsum
          lacus, fringilla ac libero in, ullamcorper elementum dui. Aliquam nec
          justo arcu. Fusce commodo finibus erat, id interdum mauris tincidunt
          ac. Cras tempus pretium porttitor. Nullam laoreet tincidunt nulla eu
          feugiat. Curabitur accumsan, libero eu hendrerit vehicula, elit risus
          iaculis libero, quis sodales nibh justo nec enim. Nulla porttitor
          risus id ipsum laoreet pulvinar. Sed in eros ut urna pellentesque
          ullamcorper id congue nunc. Nullam a hendrerit eros. Orci varius
          natoque penatibus et magnis dis parturient montes, nascetur ridiculus
          mus. Etiam faucibus urna vitae erat tincidunt lacinia non nec felis.
          Morbi mattis sem est, eu venenatis elit tincidunt vitae. In fringilla
          efficitur dolor id euismod. Quisque a risus euismod, fermentum sem
          eget, congue ex. Vestibulum sit amet bibendum urna. Maecenas sit amet
          tellus eros. Donec pulvinar scelerisque elit vel fringilla. Maecenas
          in aliquam tortor. Etiam vel odio dignissim, hendrerit nisi in,
          accumsan enim. Nunc rutrum lobortis felis, vitae ullamcorper est
          pretium at. In iaculis, risus vitae pellentesque gravida, diam elit
          sagittis lacus, non scelerisque lorem tellus ut dui. Vestibulum ante
          ipsum primis in faucibus orci luctus et ultrices posuere cubilia
          curae; Mauris accumsan odio vel hendrerit ultrices. Ut pharetra dolor
          sed ex interdum interdum. Vivamus consequat tellus non varius euismod.
          Morbi pulvinar pulvinar elit eu finibus. Quisque venenatis, nisl a
          semper sollicitudin, nulla est bibendum sapien, eu fermentum lorem leo
          at orci. Phasellus vitae sagittis leo. Aenean egestas ligula mauris,
          sed accumsan quam vehicula vitae. Morbi facilisis euismod ex, non
          consequat nisl. Nunc finibus, velit eu commodo porta, elit nisl
          tristique elit, quis malesuada nunc ipsum eu purus. Vivamus tempor
          diam a tortor rhoncus pellentesque. Donec tempus egestas arcu, vitae
          bibendum arcu hendrerit id. In hac habitasse platea dictumst.
          Phasellus fringilla tortor ornare iaculis viverra. Nullam lacus nibh,
          condimentum sagittis faucibus at, maximus mollis nunc. In sed euismod
          elit. Nulla non turpis ullamcorper, facilisis lacus volutpat,
          tincidunt ligula. Proin elementum felis turpis, eget fermentum orci
          gravida a. Fusce interdum est ex, eu tincidunt ex posuere vel. Sed et
          lorem blandit, eleifend justo aliquam, venenatis nunc. Donec mattis at
          elit in sodales. Praesent nec varius lectus. Mauris sed enim quis
          tellus vulputate molestie. Suspendisse neque lectus, consectetur non
          molestie id, viverra at elit. Cras sagittis blandit nunc nec blandit.
          Pellentesque habitant morbi tristique senectus et netus et malesuada
          fames ac turpis egestas. Aenean egestas efficitur eleifend. Nunc dolor
          urna, congue vel tellus ut, pretium hendrerit eros. Ut et ligula urna.
          Sed commodo turpis eu velit pharetra aliquet. Proin blandit dignissim
          odio, in gravida metus convallis a. Donec vitae consequat massa.
          Pellentesque habitant morbi tristique senectus et netus et malesuada
          fames ac turpis egestas. Vestibulum sagittis erat quis pharetra
          commodo. Vivamus nec metus libero. Curabitur tortor urna, aliquam sed
          tristique tempus, rhoncus et nisl. Etiam porttitor sapien nec lacus
          tincidunt dapibus. In lobortis pulvinar lobortis. Donec cursus est
          egestas interdum hendrerit. Quisque in lacus a mi facilisis luctus.
          Pellentesque orci risus, vehicula ut dui ut, aliquam scelerisque
          lectus. Donec non elit a dui rutrum rutrum et in nulla. Nunc eget
          lobortis purus. Pellentesque habitant morbi tristique senectus et
          netus et malesuada fames ac turpis egestas. Duis eget dapibus sapien.
          Curabitur at elementum quam. Aliquam tincidunt ipsum elit, ac
          hendrerit enim tempor vitae. Duis egestas maximus consequat. Orci
          varius natoque penatibus et magnis dis parturient montes, nascetur
          ridiculus mus. Ut vel sapien elit. Praesent dictum dui vel ornare
          aliquet. Phasellus id ornare magna. Nullam ac arcu non nibh molestie
          commodo. Sed sed libero quis est molestie blandit. Proin accumsan
          libero mi, ut hendrerit est tincidunt tempor. Vestibulum tempus
          rhoncus lectus id accumsan. Sed luctus, risus id tempus rutrum, nibh
          lorem laoreet diam, vitae blandit massa enim id ante. Mauris iaculis
          orci nec feugiat pharetra. Fusce sit amet lorem dignissim, eleifend
          velit vitae, lacinia leo. Aenean finibus sit amet diam vel euismod.
          Mauris accumsan dapibus mauris, et laoreet nisi malesuada non. Morbi
          fringilla elit tellus, vel aliquam justo dapibus sed. Suspendisse
          rutrum tincidunt mauris et laoreet. Pellentesque pulvinar nibh nec
          felis laoreet varius. Donec porttitor efficitur augue vel suscipit.
          Morbi semper quis nunc nec varius. In tristique purus sed mattis
          dictum. Quisque iaculis auctor risus. Ut porta mollis nulla, in
          vehicula eros egestas at. Vivamus non pharetra odio. Maecenas porta
          lorem neque. In posuere venenatis lectus, lacinia euismod dui pharetra
          non. Vivamus viverra sem eget ligula ornare, non fermentum est
          egestas. Integer sit amet enim iaculis, cursus massa quis, auctor
          quam. Sed nisi augue, lobortis eu mauris ac, pharetra varius odio.
          Cras lobortis varius nisi a faucibus. Aenean viverra egestas purus.
          Etiam ut euismod metus. Nam euismod, enim vehicula condimentum
          sollicitudin, neque purus pulvinar tellus, non aliquam ante enim et
          dui. Donec auctor mi felis, eget tempor velit laoreet at. Nullam at
          dignissim justo. Class aptent taciti sociosqu ad litora torquent per
          conubia nostra, per inceptos himenaeos. Nunc eget felis interdum,
          porta risus vel, posuere nunc. Duis mollis ultrices tortor nec
          accumsan. Aliquam pretium lacus eget metus consequat efficitur.
          Praesent eget nulla tortor. In non metus quis est aliquam pharetra.
          Etiam a suscipit purus, ut malesuada sem. Aenean lacus orci,
          scelerisque non eros et, dignissim sagittis justo. Aliquam erat
          volutpat. Nam nisi sem, malesuada sed nibh sed, egestas sagittis
          velit. Praesent finibus velit nec enim porta, at porta nibh dictum. In
          mollis tellus nunc, feugiat pharetra ligula aliquam a. Nullam id
          efficitur metus. Curabitur sagittis ac erat at sodales. Pellentesque
          vehicula lectus vitae aliquam facilisis. Phasellus auctor nisl non
          velit pellentesque imperdiet. In euismod dignissim sagittis. Nunc
          vestibulum rutrum finibus. Vestibulum in aliquet sapien. Sed ac arcu
          at elit ornare pretium in ac nisl. Aliquam vitae sem eget elit
          tristique eleifend. Donec eget enim vitae tortor dignissim volutpat.
          Proin eros quam, hendrerit sed libero at, facilisis efficitur justo.
          Morbi quis fermentum risus. Sed gravida consectetur odio sit amet
          ultricies. Curabitur id porttitor dolor. Quisque imperdiet eros odio,
          in faucibus lacus cursus quis. Aenean quis dictum ante, eu tincidunt
          augue. Vivamus eget mauris mauris. Cras venenatis dui in massa dapibus
          hendrerit. Sed sit amet lectus auctor, ornare magna vel, commodo nisi.
          Donec lorem massa, finibus at nulla eget, ultrices pulvinar tellus.
          Aenean congue facilisis ligula, eu condimentum leo eleifend ut. Nunc
          sit amet viverra libero. Donec convallis, diam vitae accumsan porta,
          eros massa fermentum metus, at ultrices nisl tortor a turpis. Quisque
          nisi erat, tincidunt id porttitor vulputate, feugiat ut eros. Integer
          sit amet ornare quam. Nullam a mi sit amet erat blandit tristique at
          vel felis. Ut pulvinar, quam sed dictum imperdiet, erat tellus
          efficitur tellus, vitae vestibulum turpis nisl in erat. Morbi tempor
          odio porta, dictum quam vitae, convallis tortor. Nullam quis erat
          enim. Curabitur lacinia congue maximus. Proin sit amet tortor nec
          ligula sagittis consequat. Aliquam erat volutpat. Nullam volutpat
          tempor nunc sit amet egestas. Curabitur ac scelerisque ex. Sed eu
          placerat ante. Sed dignissim mi hendrerit, tincidunt lectus in, luctus
          risus. Morbi tempus, nisl et pharetra porttitor, est nunc semper eros,
          nec luctus augue est et magna. Sed lobortis erat quam, eget vehicula
          turpis aliquet sit amet. Ut posuere, velit a feugiat gravida, diam
          eros sodales velit, quis posuere ligula tellus eget tortor. Maecenas
          ac pharetra nunc. Donec dictum, leo ac molestie mollis, ex mauris
          semper ipsum, sit amet lobortis ex magna non ligula. Ut vitae tempus
          leo, ut consequat dui. Cras rhoncus quam tellus, quis vestibulum
          mauris scelerisque quis. Proin lacus enim, faucibus vel cursus et,
          dictum et massa. Aenean pharetra erat a pretium malesuada. Fusce
          bibendum aliquam erat egestas dignissim. Donec maximus sagittis
          blandit. Proin pretium leo orci. Nunc nibh neque, pulvinar eu rhoncus
          vel, molestie eget elit. Vestibulum fringilla pulvinar purus id
          auctor. Aliquam ut dolor diam. Praesent quis dapibus eros. Nunc ac
          tempor libero, ac gravida ex. Donec quis bibendum neque. Sed non urna
          vel libero consectetur lacinia. Sed suscipit arcu ultricies ligula
          imperdiet vestibulum. Aenean bibendum ornare massa, id sagittis magna
          maximus sed. In ipsum metus, pretium sed interdum sit amet, euismod
          iaculis purus. Mauris nec purus tincidunt, faucibus lectus ut,
          imperdiet lectus. Duis ac enim pellentesque, viverra odio a, varius
          urna. Duis gravida efficitur ex, vitae suscipit nibh ultrices vel. Sed
          sit amet vehicula mi, at gravida dolor. Aenean sollicitudin ante
          mauris, ac malesuada magna cursus ac. Duis condimentum erat sit amet
          dignissim elementum. Maecenas egestas lacus mollis, placerat purus eu,
          tincidunt enim. Morbi id dolor interdum, sodales nunc non, porttitor
          tellus. Curabitur eget enim urna. Sed tempor vel lacus in facilisis.
          Aenean vel nibh ante. Donec velit nibh, maximus non vehicula sit amet,
          auctor a elit. Proin nec orci blandit, fermentum quam sed, finibus
          sem. Maecenas vel tincidunt dolor. Donec porttitor ipsum tortor, a
          scelerisque libero finibus nec. Phasellus a ultricies nulla. Donec
          congue tristique nulla, vel vestibulum mi mollis in. Curabitur gravida
          convallis varius. Sed ut tincidunt eros, eu tincidunt ligula. Lorem
          ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ut
          fringilla leo, iaculis sagittis diam. Sed dictum porta luctus. Donec
          consectetur, ipsum a eleifend ultrices, enim magna malesuada tortor,
          eget malesuada orci ex non eros. Sed elementum, arcu et bibendum
          scelerisque, ante justo volutpat massa, quis aliquet lacus odio eu
          metus. In imperdiet quis tortor nec malesuada. Duis convallis purus ac
          magna facilisis sodales. Pellentesque tempus gravida sapien, et
          convallis orci fermentum in. Aenean sit amet mauris sed quam dictum
          euismod. Mauris justo augue, ornare sit amet orci eu, suscipit
          condimentum urna. Aenean quis tincidunt dui. Fusce gravida pulvinar
          sodales. Curabitur sollicitudin luctus tortor, at euismod velit
          ullamcorper vel. Nulla efficitur pretium mi vitae volutpat. Sed
          interdum, libero quis vulputate hendrerit, metus mauris rutrum dolor,
          ac molestie nibh ante in sem. Morbi dui dui, pharetra ac nisl
          tristique, dapibus convallis arcu. Mauris faucibus fermentum quam, et
          mollis nisl aliquet a. Nullam dictum magna leo, eu accumsan velit
          bibendum nec. Curabitur non quam eu quam condimentum sagittis eu eget
          ligula. Nulla egestas, elit eget ultricies laoreet, sem purus rhoncus
          mi, nec posuere dui nulla nec orci. Maecenas vulputate id urna
          vestibulum ullamcorper. Pellentesque lacinia enim in consequat
          placerat. Nullam placerat at est eu cursus. Pellentesque vestibulum
          sodales mollis. Maecenas a ex ut turpis tempus ornare. Sed hendrerit
          rutrum molestie. Donec varius sagittis magna, et eleifend purus
          efficitur non. Curabitur dignissim turpis non tempus euismod.
          Suspendisse gravida nibh et dui lobortis vulputate. Aenean vitae
          congue tortor, ut tincidunt arcu. Nam sem eros, aliquet ac pharetra
          et, congue vel mi. In non nunc molestie, fermentum arcu vel, ornare
          nisi. Donec ac cursus metus. Nunc vitae laoreet risus. Nam nibh
          tortor, pretium sed aliquam at, varius aliquet urna. Donec a
          consectetur dolor, quis rutrum justo. Proin sodales nisi at neque
          convallis, sit amet volutpat felis sagittis. Nulla consectetur turpis
          eu tincidunt interdum. Morbi viverra sapien ex, id bibendum urna
          laoreet sit amet. Mauris finibus ante vel elit dignissim efficitur.
          Curabitur nisl lacus, consequat nec pretium at, mattis nec dui. In hac
          habitasse platea dictumst. Etiam lacinia diam quis porttitor
          porttitor. Nunc tincidunt interdum orci, sit amet sagittis nisl tempor
          eu. Proin rhoncus arcu tellus, id egestas nisi bibendum a. Donec
          euismod finibus dolor eget vestibulum. Praesent dignissim cursus
          tellus id suscipit. Integer eget justo nisi. Suspendisse ut est quis
          sapien sollicitudin aliquet. Pellentesque euismod, est a ultrices
          pretium, lectus nunc dapibus odio, sit amet lacinia sem nulla a nulla.
          Nulla sed pharetra diam, eget eleifend massa. In et nisl eu odio
          elementum euismod tempus sed nulla. Praesent blandit varius
          vestibulum. Integer purus quam, volutpat ac dolor ut, placerat
          consectetur metus. Proin ut ex nec arcu mattis dignissim eget et
          dolor. Curabitur a libero posuere, maximus nulla varius, sagittis
          tellus. Donec ligula eros, congue porttitor tempus ultrices, laoreet
          sed sem. Ut vel mauris quis massa consequat hendrerit eu in felis.
          Integer tortor ante, fermentum eget justo at, lobortis sollicitudin
          mi. Nullam sollicitudin a nunc eu sodales. Nam fermentum lectus nec
          porttitor finibus. Nam tellus nulla, consequat ut rutrum vitae,
          efficitur id dui. Nam non pharetra mi. Morbi ut ipsum sed mauris
          suscipit elementum nec nec libero. Pellentesque sed nulla id arcu
          facilisis luctus. Cras vel rhoncus orci, ac hendrerit massa. Nam at
          tincidunt eros, sit amet lacinia tortor. Curabitur semper, elit id
          mollis porttitor, lacus neque malesuada lorem, in ultricies purus
          augue quis justo. In commodo iaculis justo a rutrum. Suspendisse
          maximus tellus et purus laoreet, ac ultrices massa tincidunt. Duis
          elit tortor, sagittis et laoreet vel, dictum nec lorem. Donec nec nisl
          in nibh blandit molestie. Nunc finibus felis non turpis pretium
          aliquam. Aenean semper cursus sodales. Cras vel elit vitae diam
          aliquam consequat. Phasellus nec sapien a magna mollis aliquam quis at
          ante. In hac habitasse platea dictumst. Cras sed justo sem. Etiam
          feugiat sed justo eget interdum. Praesent pellentesque tincidunt
          dapibus. Maecenas nec orci ut massa facilisis ultrices mollis quis
          eros. Aliquam in elit id sem suscipit pellentesque. Etiam a lectus et
          lacus tincidunt dapibus vel ut odio. Vestibulum dictum lacus in risus
          pretium gravida id at odio. Nulla venenatis neque id lectus consequat
          porttitor. In hac habitasse platea dictumst. Ut et tincidunt mi. Sed
          quis mollis velit, vitae varius elit. Pellentesque sagittis non enim
          quis pharetra. Donec faucibus, purus sit amet vulputate elementum,
          purus dui suscipit odio, dignissim dignissim lectus ante in elit.
          Donec velit enim, vehicula eu suscipit non, lacinia nec odio. Aliquam
          malesuada, massa aliquam lacinia lacinia, tortor nisi lacinia est, et
          ultrices velit tellus sed lacus. Sed et ante massa. Curabitur interdum
          hendrerit metus, eu imperdiet justo ullamcorper quis. Nam eu velit eu
          dolor feugiat iaculis non nec erat. Donec porta felis et purus
          facilisis facilisis. Phasellus at justo laoreet, gravida nunc ut,
          vulputate est. Ut faucibus lectus nec ligula ullamcorper finibus. Ut
          eleifend diam quis vulputate mattis. Ut pretium mi ac convallis
          vestibulum. Nullam accumsan laoreet sem, id volutpat tortor imperdiet
          ut. Nullam non tincidunt nunc. Suspendisse feugiat aliquam magna quis
          ullamcorper. Aliquam at neque quis lacus faucibus fermentum. Etiam
          tempor dui non mollis cursus. Maecenas pretium consequat purus, id
          aliquet velit ultrices vitae. Proin consequat sapien vel rhoncus
          pulvinar. Sed eget consectetur ipsum. Sed lobortis tellus vel mattis
          sagittis. Phasellus sodales rhoncus maximus. Nullam suscipit est vitae
          orci ultrices, sit amet sollicitudin erat lobortis. Suspendisse elit
          nisl, condimentum vel justo eget, congue mollis ex. Vivamus quis
          pharetra sem, ac vestibulum lorem. Orci varius natoque penatibus et
          magnis dis parturient montes, nascetur ridiculus mus. Sed sit amet
          ipsum dui. Nam volutpat, felis mollis egestas efficitur, lacus tellus
          feugiat arcu, vitae aliquam nunc magna a risus. Cras mauris enim,
          volutpat ut faucibus non, mollis vel lectus. Nullam id molestie nulla.
          Mauris varius lacus scelerisque purus pulvinar, nec ultrices leo
          malesuada. Morbi accumsan condimentum lacus, vitae pulvinar magna
          finibus tempus. Donec et placerat ex, semper accumsan ante. Donec
          egestas finibus quam. Nullam sollicitudin scelerisque laoreet. Aliquam
          erat volutpat. Pellentesque eget urna eleifend tellus hendrerit luctus
          et sit amet neque. Fusce ac tellus sit amet lorem euismod venenatis
          sit amet id leo. Nulla pellentesque felis nec pellentesque ornare.
          Curabitur consectetur auctor dui. Vestibulum vel nulla ultricies,
          blandit dolor vel, porttitor ex. Sed sagittis at erat vel interdum.
          Phasellus imperdiet orci erat, eget aliquet justo lobortis eu. Sed
          venenatis ornare urna, in elementum enim pellentesque id. Curabitur
          facilisis justo mi, nec sollicitudin eros ultrices tempor. Phasellus
          felis lectus, iaculis vel porttitor at, suscipit quis ante.
          Pellentesque ut bibendum nulla. Sed scelerisque augue tellus, id
          blandit purus mattis eget. Integer semper dolor tellus, vel dapibus
          erat pulvinar ac. Nullam sagittis libero eget lacus vestibulum, ac
          pharetra massa mollis. Nullam imperdiet feugiat justo eu mollis. Donec
          nec mauris orci. Fusce porta tincidunt nisl. Morbi et ante finibus ex
          finibus euismod vel ac nisl. Aenean dignissim eu eros quis vehicula.
          Nunc convallis sollicitudin massa, eu tempus purus lobortis in.
          Praesent at mi in risus fermentum ornare. Aenean in cursus nunc, at
          pellentesque quam. Quisque pharetra lorem eros, vitae aliquet magna
          sollicitudin ultricies. Integer lectus eros, ornare eget interdum vel,
          vehicula vitae leo. Curabitur ut quam a sapien venenatis bibendum.
          Morbi malesuada quis nibh nec pretium. Sed vehicula laoreet nulla, sed
          malesuada justo vulputate at. Morbi ex lacus, cursus a consectetur
          vel, aliquet cursus mi. Phasellus a massa sed quam aliquet ultricies
          non sed justo. Nam et leo mi. Nam luctus augue at quam lobortis
          placerat. Nulla et est tristique, placerat purus nec, cursus odio.
          Donec eget odio tristique, consequat purus eu, vestibulum nisl. Donec
          sed rutrum augue, non egestas risus. Vivamus pulvinar nulla ut mauris
          blandit finibus. Pellentesque fermentum porttitor libero, sit amet
          tempus tellus commodo ut. Cras eget nisi porttitor, condimentum ante
          a, semper velit. Donec venenatis gravida ante vel tincidunt. Aenean a
          odio velit. Phasellus at dictum nibh. Duis non commodo nunc. Etiam
          bibendum blandit neque eget iaculis. Nulla pharetra ultrices velit a
          laoreet. Aenean sit amet libero aliquam, molestie nisl luctus, viverra
          urna. Maecenas gravida vitae ligula nec sagittis. Lorem ipsum dolor
          sit amet, consectetur adipiscing elit. Mauris sagittis mauris ac
          congue condimentum. Duis ac lectus congue, tincidunt est vel, eleifend
          urna. Nulla non nisi non sapien laoreet imperdiet. Nulla viverra vel
          est et tempor. Pellentesque eleifend tortor a rhoncus pellentesque. In
          consequat, est sed finibus ullamcorper, ex ex dignissim arcu, eget
          interdum lorem eros nec nibh. Pellentesque et purus dignissim,
          volutpat massa vel, placerat erat. Aliquam erat volutpat. Donec
          commodo lacinia quam, eget lobortis diam posuere porttitor. Fusce ut
          neque sit amet nibh imperdiet consectetur in eu turpis. Nunc vulputate
          dui eget nunc consectetur aliquet. Quisque porta, velit non dictum
          sodales, leo nulla feugiat quam, eget venenatis arcu erat at risus.
          Vestibulum ultricies leo sed ex egestas, vitae accumsan odio varius.
          Etiam quis nisl faucibus, pulvinar tellus sit amet, congue ligula.
          Maecenas quis odio posuere, molestie est id, ultrices dolor. In tempor
          auctor massa, sed facilisis lacus malesuada vestibulum. Integer augue
          turpis, convallis sed ante ut, bibendum euismod libero. Fusce commodo
          tincidunt orci et blandit. In feugiat enim in ante tristique luctus.
          Cras aliquet tempus tortor eu efficitur. Phasellus vel metus vitae
          odio malesuada maximus. Fusce turpis purus, hendrerit quis mattis ac,
          gravida et ex. Sed tempor nisl sed lorem eleifend, eu sodales orci
          finibus. Etiam vitae purus et nisl porta blandit. Curabitur at mi a
          nulla hendrerit dignissim. Mauris elementum orci ac nibh finibus, id
          ullamcorper massa ultricies. Pellentesque volutpat pharetra porttitor.
          Vivamus malesuada, lorem eget luctus laoreet, nulla nisi condimentum
          dolor, eu faucibus odio libero at mi. Nullam accumsan suscipit
          malesuada. Vestibulum fringilla ligula odio, ac convallis tellus
          convallis fermentum. Etiam malesuada fermentum enim eget egestas.
          Curabitur non placerat mi. Cras gravida ipsum eu sem aliquet
          tincidunt. Aliquam nec ligula id mauris vulputate semper nec id
          libero. Suspendisse laoreet neque et orci faucibus, ut hendrerit enim
          tempor. Pellentesque habitant morbi tristique senectus et netus et
          malesuada fames ac turpis egestas. Nam ut accumsan elit. Phasellus ac
          ante eget felis ullamcorper laoreet nec ac diam. Cras congue, arcu
          vitae egestas interdum, purus ipsum porta nulla, sit amet semper ex
          purus quis nisl. Curabitur aliquam nibh eu ipsum aliquam pulvinar. Sed
          vulputate ante id tempus euismod. Class aptent taciti sociosqu ad
          litora torquent per conubia nostra, per inceptos himenaeos. Vestibulum
          feugiat ex et vestibulum ultrices. Proin vel metus sit amet eros
          aliquet pretium. Vestibulum quis mauris ut turpis pharetra mattis quis
          a ipsum. Vestibulum odio nulla, vulputate sed elit non, lobortis
          viverra diam. Fusce finibus ut dolor quis bibendum. Etiam ac erat
          condimentum, mollis ex sit amet, elementum sapien. Sed risus eros,
          gravida vel nibh ut, blandit fermentum libero. Maecenas auctor
          consectetur sollicitudin. Nullam quis enim nunc. Donec nec quam
          semper, ultricies ex ut, finibus quam. Curabitur et metus congue,
          mollis augue sit amet, mollis est. Praesent elit odio, molestie
          egestas pellentesque a, posuere vel elit. Nullam at massa sagittis
          erat imperdiet placerat. Pellentesque varius auctor velit, id feugiat
          ipsum placerat quis. Vestibulum a tortor ut velit tempus feugiat.
          Morbi semper lorem sem, eget accumsan sem lacinia a. Cras eget
          hendrerit felis. Nulla non libero tempus, volutpat augue eu, dapibus
          mi. Curabitur nulla lacus, pharetra sed vehicula pulvinar, placerat eu
          risus. Maecenas eget tempus lacus, eget feugiat tellus. Integer ac
          imperdiet tellus. Etiam vitae libero efficitur, sodales turpis ut,
          rhoncus urna. Nam viverra euismod sem at maximus. Nullam malesuada
          turpis ut felis hendrerit tristique. Pellentesque sollicitudin libero
          eu enim lobortis, ut suscipit quam convallis. Aliquam consectetur eget
          ex sit amet pulvinar. Sed ut blandit diam, ut rutrum leo. Vestibulum
          bibendum porttitor ultrices. Proin et massa at elit commodo eleifend
          vitae at sapien. Nam tincidunt velit et justo condimentum
          pellentesque. Vestibulum ante ipsum primis in faucibus orci luctus et
          ultrices posuere cubilia curae; Fusce ac risus dignissim, feugiat nibh
          nec, consectetur neque. Etiam ultrices mattis elit, bibendum posuere
          tellus. Cras tortor urna, mollis in rutrum nec, cursus eget urna.
          Pellentesque ante felis, vehicula vitae nibh vel, dignissim lacinia
          dolor. Aliquam pellentesque gravida dui id dictum. Sed ex dolor,
          iaculis a augue ac, aliquet tempus tellus. Curabitur ac nulla quis
          dolor placerat convallis. Aenean tempus felis sed convallis sodales.
          Aliquam vel enim ac nibh congue blandit sed eget arcu. Curabitur
          tincidunt lectus lobortis dui mattis, non egestas lorem facilisis.
          Quisque ultricies vulputate nisl quis aliquam. Etiam sed sapien
          tortor. Vivamus commodo risus dignissim est elementum dignissim.
          Curabitur luctus ligula eu sapien tincidunt, vel dignissim neque
          sollicitudin. Sed lacinia faucibus nisl, eu imperdiet lectus
          sollicitudin nec. Donec sit amet lobortis nulla. Integer pellentesque
          rhoncus quam, sit amet sollicitudin ante. Nunc in eros eu felis rutrum
          commodo. Cras volutpat, odio non cursus cursus, erat orci gravida
          erat, vitae dictum augue nisl vel orci. Nulla non hendrerit urna. Sed
          nisl erat, commodo vel felis non, bibendum semper elit. Pellentesque
          semper accumsan magna, hendrerit lobortis lectus scelerisque mattis.
          Donec ullamcorper, est a facilisis pellentesque, turpis leo pharetra
          velit, eu varius massa sem eu leo. Mauris tempus sapien vel dui
          varius, non imperdiet sapien luctus. Mauris lacinia rhoncus ipsum vel
          finibus. Integer bibendum quam ut rutrum maximus. Ut scelerisque
          blandit ex. Praesent sit amet diam ac metus auctor commodo vitae in
          neque. Donec tempor nec elit id luctus. Quisque justo ligula,
          tristique sit amet tempor quis, viverra a neque. Class aptent taciti
          sociosqu ad litora torquent per conubia nostra, per inceptos
          himenaeos. Pellentesque rutrum metus sit amet eleifend faucibus. Etiam
          semper elit at nulla lobortis, ut vestibulum nisl sagittis.
          Pellentesque maximus lobortis justo quis finibus. Donec consequat, ex
          id molestie pretium, erat ante porta urna, a pulvinar enim lorem ut
          dui. Aenean fringilla nibh quam, a placerat leo finibus vel. Mauris a
          laoreet sem, eu accumsan quam. Quisque congue urna in erat venenatis
          sodales. Proin posuere sit amet massa vitae rutrum. Fusce dapibus ex
          nulla, ut efficitur leo tristique porta. Sed maximus lacus a erat
          tincidunt, ut consectetur augue finibus. Nulla ultrices sapien cursus,
          feugiat lacus a, pretium leo. Phasellus iaculis tempor lorem eu
          dapibus. Sed ac volutpat eros, sit amet porta leo. Praesent sit amet
          tortor sed est imperdiet porttitor. Quisque dictum, leo vehicula
          efficitur lacinia, diam mauris consectetur lorem, eget facilisis urna
          ex ac arcu. Vestibulum lobortis erat congue leo scelerisque sodales.
          Cras commodo nisi dignissim orci porta, nec rutrum nunc placerat.
          Aliquam erat volutpat. Mauris vulputate consectetur arcu ac rhoncus.
          Sed ex ligula, faucibus et facilisis vitae, efficitur et turpis.
          Vivamus tempor fermentum ante, vel lobortis lacus condimentum ut. In
          aliquam nisi ac feugiat facilisis. Fusce ullamcorper pharetra sapien
          lacinia dignissim. Praesent ut augue ut lorem varius gravida in a
          ligula. Vivamus sed tortor lacinia, tincidunt augue ac, porttitor
          diam. Pellentesque sem ipsum, aliquam vitae tincidunt vel, consectetur
          eu mauris. Nullam sagittis orci elementum varius euismod. Morbi
          dapibus odio mi, ut faucibus felis luctus ac. Nunc non leo fermentum,
          commodo urna id, ornare mi. Nam ut enim ac ex lobortis condimentum a
          vel erat. Praesent vulputate mauris libero, ac pulvinar augue feugiat
          feugiat. Donec sit amet rutrum nunc. Lorem ipsum dolor sit amet,
          consectetur adipiscing elit. Nullam nisi nibh, pretium iaculis sem
          sed, pulvinar euismod est. Fusce id vehicula magna. Nulla sodales
          laoreet mi, sit amet efficitur dui elementum nec. Fusce nec purus eget
          nunc commodo elementum. Donec volutpat vestibulum odio ut sagittis.
          Suspendisse potenti. Etiam sagittis risus velit. Lorem ipsum dolor sit
          amet, consectetur adipiscing elit. Cras aliquam ullamcorper maximus.
          Nunc vel quam at lectus consequat blandit. Etiam eget quam et ex
          maximus aliquam sit amet non urna. Morbi mollis volutpat velit, in
          viverra erat fringilla nec. Curabitur eget lacus a nisl hendrerit
          venenatis a ac lacus. Ut leo ex, pulvinar eu consequat eget, porta at
          justo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
          dapibus massa at elit viverra volutpat. Vivamus iaculis, velit quis
          sagittis faucibus, metus mauris placerat tellus, sit amet facilisis
          purus ligula a libero. Vivamus sollicitudin, metus a fermentum
          viverra, est augue interdum justo, sed pellentesque purus erat non
          neque. Nunc et posuere metus, eu accumsan purus. Etiam in elit purus.
          In hac habitasse platea dictumst. Morbi ornare vitae nibh at bibendum.
          Etiam molestie nisi purus, nec rutrum odio pretium sit amet. Ut
          consequat enim in gravida accumsan. Nam ut arcu quis neque lobortis
          venenatis. Proin ultricies, sapien non pellentesque feugiat, elit
          purus ullamcorper nisl, non congue diam sem vitae tortor. Sed maximus
          sapien id elit porta, in lobortis justo tristique. Ut fringilla
          vehicula fringilla. Vestibulum ante ipsum primis in faucibus orci
          luctus et ultrices posuere cubilia curae; Vestibulum ante ipsum primis
          in faucibus orci luctus et ultrices posuere cubilia curae; Sed quis
          magna ut turpis vulputate posuere. Aliquam erat volutpat. Nulla
          aliquet posuere purus nec congue. Pellentesque felis orci, imperdiet
          eu tellus ac, tempor ornare quam. Fusce gravida, lectus auctor commodo
          vulputate, lorem dolor venenatis metus, sed hendrerit ipsum justo sit
          amet purus. Proin sed ullamcorper erat, in ultrices orci. Vivamus eget
          imperdiet eros. Nullam porta non neque sit amet condimentum. Vivamus
          magna arcu, condimentum eu dui tempor, tempor facilisis diam. Ut
          auctor, leo a tincidunt egestas, ipsum massa pellentesque massa, eget
          vulputate ex nunc sit amet augue. Donec feugiat nunc vitae mi
          tincidunt, quis convallis tellus pretium. Aliquam varius est eu purus
          faucibus, vitae consectetur leo convallis. Pellentesque egestas nunc
          sed justo cursus, ac hendrerit est lacinia. Maecenas finibus a massa
          ac feugiat. Phasellus ut condimentum urna. Sed ac ligula porta,
          tincidunt diam non, interdum lacus. Donec tristique sodales varius.
          Nullam ultrices sem ac hendrerit ornare. Duis eu suscipit sapien, sed
          dictum nisl. Proin augue ante, lacinia ac tincidunt viverra, iaculis
          in tellus. Sed lacus tortor, scelerisque vitae turpis eget, hendrerit
          tempus lectus. Proin lobortis dictum ligula, quis porttitor lectus
          auctor at. Nullam sodales ac ante sed hendrerit. Fusce quis enim
          lorem. Donec eget egestas eros. Aliquam sollicitudin facilisis tellus,
          a tempor urna euismod id. Donec quam arcu, semper quis elit sed,
          fringilla tempor felis. Orci varius natoque penatibus et magnis dis
          parturient montes, nascetur ridiculus mus. Aenean sit amet diam lorem.
          Nunc dignissim finibus diam, at auctor erat ultricies porttitor. Nunc
          eget consectetur quam. Nunc auctor viverra scelerisque. Integer
          posuere dignissim mi, vitae volutpat diam euismod in. Vivamus et neque
          pharetra, congue massa quis, auctor ligula. Donec eget sem a tortor
          vestibulum gravida eget in quam. Vivamus ut velit vel augue gravida
          mattis quis vel libero. Mauris volutpat bibendum mi, ac sagittis dui
          egestas sed. Fusce ante ipsum, maximus sit amet dapibus nec, tincidunt
          consectetur orci. Aenean laoreet ligula mi, sed blandit nulla vehicula
          ut. Etiam ut rutrum massa, vitae gravida est. Vestibulum et rutrum
          erat, at accumsan purus. Duis ut sodales felis, eu gravida lorem.
          Proin dapibus est nec orci elementum, et consectetur purus laoreet.
          Etiam congue bibendum sagittis. Etiam cursus quam ac ornare vulputate.
          Morbi faucibus aliquet tellus scelerisque ornare. Cras in tellus
          lorem. Duis at suscipit diam. Aliquam varius tortor quis odio porta
          mattis. Aenean vel vehicula dui. Praesent eu odio dui. Curabitur arcu
          purus, elementum quis malesuada in, cursus vitae massa. Donec ac
          placerat risus. Aliquam vel massa aliquam, facilisis sem at, iaculis
          massa. Ut egestas posuere mi id pellentesque. Vestibulum ante ipsum
          primis in faucibus orci luctus et ultrices posuere cubilia curae;
          Vestibulum varius fringilla justo, a mattis ante sagittis eu.
          Pellentesque sodales aliquet neque vel imperdiet. Mauris vitae ornare
          quam. Cras non nibh diam. In in risus mattis, sagittis enim id,
          euismod ipsum. Integer pretium cursus turpis. Lorem ipsum dolor sit
          amet, consectetur adipiscing elit. Aenean tempus, mauris eget
          condimentum molestie, enim neque malesuada odio, sed consectetur purus
          sapien in risus. Cras a ullamcorper mauris. Nulla id neque et eros
          vestibulum pharetra porttitor et neque. Proin faucibus vitae velit et
          volutpat. Curabitur pharetra nisi ac gravida cursus. Maecenas egestas
          leo sit amet mollis luctus. Duis nulla nisl, ultrices in pellentesque
          maximus, mollis sit amet purus. Cras pulvinar, metus in blandit
          sollicitudin, diam sem condimentum turpis, sed feugiat lectus velit ac
          massa.
        </ScrollableDiv>
        <ButtonDiv>
          <Button onClick={handleCancel}>
          {t("TermsAndConditions.Cancel")}{" "}
          </Button>
          <Button onClick={handleAgree}>
          {t("TermsAndConditions.Agree")}{" "}
          </Button>
        </ButtonDiv>
      </MainCard>
      <Footer/>
    </Body>
  );
};

export default TermsAndConditions;
