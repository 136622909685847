import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  Body,
  HelloTextBold,
  MainCard
} from "../../components/common/MainCard";
import Footer from "../../components/layout/footer";
import Header from "../../components/layout/header";
import NavBar from "../../components/layout/navBar";
import { ArticlesContext } from "../../stores/ArticlesStore";

const WhiteCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  color: black;
  padding: 10px;
  width: 95%;
  height: 100%;
  margin-top: 20px;
  .Field {
    display: flex;
    align-items: center;
    justify-content: stretch;
  }
  .FieldText {
    width: 11%;
    padding: 5px;
    text-align: left;
  }
  input {
    flex: 1;
    height: 20px;
    margin: 5px;
    padding: 8px;
    border: 0;
    border-radius: 10px;
    box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);
    :focus {
      outline: none;
    }
  }
  select {
    flex: 1;
    height: 40px;
    margin: 5px;
    padding: 8px;
    border: 0;
    border-radius: 10px;
    box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);
    :focus {
      outline: none;
    }
    option {
      padding: 16px 16px;
      border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
      cursor: pointer;
      border: 0px;
      outline: 0px;
    }
  }
  textarea {
    resize: none;
    border: 0;
    margin: 10px;
    border-radius: 10px;
    margin-top: 10px;
    box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);
    :focus {
      outline: none;
    }
  }
`;

const SideBySideOptions = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
  .Field {
    width: 49%;
  }
`;

const ButtonsDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  button {
    min-height: 50px;
    min-width: 150px;
    margin-right: 20px;
    margin-top: 20px;
    background-color: #009ee0;
    color: #fff;
    border: 0px;
    border-radius: 10px;
    transition: all 0.3s ease 0s;
    outline: none;
    :hover {
      background-color: #fff;
      box-shadow: 0px 15px 20px rgba(167, 164, 206, 0.4);
      color: #009ee0;
    }
  }
`;

const ArticlesViewer = () => {
  document.title = "VAM® Field Service";
  const history = useHistory();
  const isSmallScreen = window.screen.width <= 1366;

  const {
    GetArticles,
    GetArticleInfo
  } = useContext(ArticlesContext)


  const handleReturn = () => {
    history.goBack();
  };

  useEffect(() => {
    (async () => {
      await GetArticles()
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Body>
      {/* {GetArticles()} */}
      <Header />
      <NavBar />
      <MainCard id="MainCard">
        <HelloTextBold>Article</HelloTextBold>
        <center>
          <WhiteCard>
            <SideBySideOptions>
              <div className="Field">
                <div className="FieldText">Topic: </div>
                <input
                  type="text"
                  id="IssueName"
                  placeholder="Name ..."
                  disabled={true}
                ></input>
              </div>
              <div className="Field">
                <div className="FieldText">Score: </div>
                <input
                  type="text"
                  id="Score"
                  placeholder="Score ..."
                  disabled={true}
                ></input>
              </div>
            </SideBySideOptions>
            <SideBySideOptions>
              <div className="Field">
                <div className="FieldText">Created At: </div>
                <input
                  type="text"
                  id="CreatedAt"
                  placeholder="Created At ..."
                  disabled={true}
                ></input>
              </div>
              <div className="Field">
                <div className="FieldText">Last Altered: </div>
                <input
                  type="text"
                  id="LastAltered"
                  placeholder="Last Altered ..."
                  disabled={true}
                ></input>
              </div>
            </SideBySideOptions>
            <div className="Field">
              {" "}
              <div className="FieldText">Article: </div>
            </div>
            <textarea
              rows="30"
              id={"Message"}
              placeholder="Article...."
              disabled={true}
            ></textarea>
            <ButtonsDiv style={{ bottom: isSmallScreen ? "15%" : "13%" }}>
              <button onClick={handleReturn}>Return</button>
            </ButtonsDiv>
          </WhiteCard>
        </center>
      </MainCard>
      <Footer />
      {GetArticleInfo()}
    </Body>
  );
};

export default ArticlesViewer;
