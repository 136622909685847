import React, { useContext, useRef, useState } from 'react'
import { io } from 'socket.io-client'
import moment from 'moment/moment'
import { ChatIDContext } from './ChatIDStore'
import { UserContext } from './UserStore'
import * as ConnectChat from '../components/connect/chatConnect'
import { useEffect } from 'react'

export const ChatSocketContext = React.createContext()

export const ChatSocketProvider = ({ children }) => {

  //Socket Call
  const [chatId, setChatId] = useState('')
  const [localName, setLocalName] = useState('')
  const [remoteName, setRemoteName] = useState('')
  const [isMicOn, setIsMicOn] = useState(true)
  const [isCameraOn, setIsCameraOn] = useState(true)
  const [isCallStarted, setIsCallStarted] = useState(false)
  const [newHash, setNewHash] = useState('')
  const [newReceiver, setNewReceiver] = useState('')
  // const [videoTrack, setVideoTrack] = useState(null)
  // const [audioTrack, setAudioTrack] = useState(null)
  const [streamTracks, setStreamTracks] = useState(null)
  // const [remoteStream, setRemoteStream] = useState(null)
  const [startedRemoteVideo, setStartedRemotVideo] = useState(false)
  const [connectionState, setConnectionState] = useState('new')

  //Socket chat
  // const [messageStatus, setMessageStatus] = useState('Pending')
  // const [updateStatus, setUpdateStatus] = useState('Pending')
  const [savedMessages, setSavedMessages] = useState([])
  const [savedAdminMessages, setSavedAdminMessages] = useState([])
  const [savedUpdateChats, setSavedUpdateChats] = useState([])
  // const [unreadedUpdateMessages, setUnreadedUpdateMessages] = useState([])
  const [chatMessages, setChatMessages] = useState([])

  //Context
  const { currentUser } = useContext(UserContext)
  const { setShowMyCam } = useContext(ChatIDContext)
  const videoRef = useRef(null)
  let SENDER_NAME
  let SENDER_ID
  // Current session username
  if (currentUser) {
    SENDER_NAME = `${currentUser.firstName} ${currentUser.lastName}`
    SENDER_ID = `${currentUser.id}`
  }

  //Chats Status
  const PENDING_STATUS = ['Pending', 'pending', 'In Progress', 'InProgress']
  // const COMPLETED_STATUS = ['Achieved', 'Completed']

  const MAX_CHATS_ALLOWED = 20
  const MAX_ALLOWED_UPDATE = 4

  const iceCandidates = []

  let receiver = ''
  let hash = ''

  let PEER = null

  // Endpoint Initialization
  const ENDPOINT =
    process.env.NODE_ENV === 'prod'
      ? 'wss://vam-app.aws.smart.vallourec.com/'
      : 'wss://int-vam-app.aws.smartdev.vallourec.com/'

  // ======= Socket Initialization ======= //
  const SOCKET = io(ENDPOINT)

  SOCKET.on('connect_error', (error) => {
    console.log(`An connection error happened because of: ${error.message}`)
  })
  // ======= END OF SCOKET INITIALIZATION ======= //

  // Chat Functions
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setLocalName(SENDER_NAME), [])
  const clearIceCandidates = () => {
    for (let i = 0; i <= iceCandidates.length; i++) {
      iceCandidates.pop()
    }
  }

  const sendMessageToServer = async (message) => {
    // console.log('== Message ==\n', message)
    return await ConnectChat.SendMessageToChat(chatId, '', SENDER_ID, '', message, 'Action', 'Action')
  }

  const addLocalVideo = (stream) => {
    const video = document.getElementById('localVideo')
    video.srcObject = stream
    video.muted = true
    video.onloadedmetadata = () => {
      video.play()
    }
  }

  const initializeNewPeer = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true })
    addLocalVideo(stream)
    setStreamTracks(stream)
    const iceConfiguration = {
      iceServers: [
        {
          urls: 'turn:vam-app-coturn.aws.smart.vallourec.com:3478',
          username: 'vamapp',
          credential: 'ezJVdtd*',
        },
        {
          urls: 'stun:stun.l.google.com:19302',
        },
      ],
    }

    const peer = new RTCPeerConnection(iceConfiguration)

    // Add tracks for the call
    stream.getTracks().forEach((track) => {
      if (track.kind === 'audio') {
        // setAudioTrack(track)
      }

      if (track.kind === 'video') {
        // setVideoTrack(track)
      }

      peer.addTrack(track, stream)
    })

    clearIceCandidates()

    // Adding remote video
    const remoteVideo = document.getElementById('remoteVideo')
    peer.ontrack = (track) => {
      const [remote] = track.streams
      remoteVideo.srcObject = remote
      remoteVideo.onloadedmetadata = () => {
        remoteVideo.play()
      }
      setStartedRemotVideo(true)
    }

    // Send ice candidates
    peer.onicecandidate = async (ice) => {
      if (ice.candidate) {
        const message = JSON.stringify({
          ChatID: chatId,
          RequestType: 'IceCandidate',
          SenderName: SENDER_NAME,
          SenderID: SENDER_ID,
          ReceiverID: receiver,
          Hash: hash,
          IceCandidate: {
            candidate: ice.candidate.candidate,
            sdpMLineIndex: ice.candidate.sdpMLineIndex,
            sdpMid: ice.candidate.sdpMid,
            userNameFragment: ice.candidate.usernameFragment,
          },
        })

        if (peer.remoteDescription) {
          await sendMessageToServer(message)
        } else {
          iceCandidates.push(message)
        }
      }
    }

    peer.onconnectionstatechange = async () => {
      setConnectionState(peer.connectionState)
      if (peer.connectionState === 'connected') {
        setIsCallStarted(true)
      }
    }

    PEER = peer
    return peer
  }

  const handleReceivedOffer = async (sdpOffer, receiver, hash) => {
    const peer = await initializeNewPeer()
    await peer.setRemoteDescription({ type: 'offer', sdp: sdpOffer })
    const answer = await peer.createAnswer()
    await peer.setLocalDescription(answer)

    const message = JSON.stringify({
      Sdp: answer.sdp,
      ChatID: chatId,
      RequestType: 'Answer',
      SenderName: SENDER_NAME,
      SenderID: SENDER_ID,
      ReceiverID: `${receiver}`,
      Hash: hash,
    })

    await sendMessageToServer(message)

    if (iceCandidates.length > 0) {
      for (const ice of iceCandidates) {
        await sendMessageToServer(ice)
        iceCandidates.pop()
      }
    }
  }

  const handleReceivedAnswer = async (sdpAnswer) => {
    await PEER?.setRemoteDescription({ type: 'answer', sdp: sdpAnswer })

    if (iceCandidates.length > 0) {
      for (const ice of iceCandidates) {
        await sendMessageToServer(ice)
        iceCandidates.pop()
      }
    }
  }

  const createOffer = async (peer) => {
    const offer = await peer.createOffer({ offerToReceiveAudio: true, offerToReceiveVideo: true })

    await peer.setLocalDescription(offer)

    const message = JSON.stringify({
      ChatID: chatId,
      RequestType: 'Offer',
      SenderName: SENDER_NAME,
      SenderID: SENDER_ID,
      ReceiverID: '',
      Hash: `${Date.now()}`,
      Sdp: offer.sdp,
    })

    await sendMessageToServer(message)
  }

  /**
   * This function runs every time that some message is sent to the server.
   * It is responsible for checking some important status for the call to
   * connect and run smoothly.
   */
  SOCKET.on(`update|${chatId}`, async (event) => {
    const action = event?.MessageAction

    if (action?.SenderID === SENDER_ID) return

    if (remoteName === '') {
      setRemoteName(action.SenderName)
    }

    if (action?.RequestType === 'Offer') {
      await setNewReceiver(action.SenderID)
      await setNewHash(action.Hash)

      receiver = action?.SenderID
      hash = action.Hash

      await handleReceivedOffer(action.Sdp, action.SenderID, action.Hash)
    }

    if (action?.RequestType === 'Answer') {
      await handleReceivedAnswer(action.Sdp)
    }

    if (action?.RequestType === 'IceCandidate') {
      await PEER.addIceCandidate(action.IceCandidate)
    }

    if (action?.RequestType === 'ChangeVideoStatus') {
      setStartedRemotVideo(action?.IsOn)
    }
  })

  /**
   * Starts the video call process
   */
  const processNewCall = async () => {
    if (chatId !== '') {
      SOCKET.emit(`join`, { ChatID: chatId, SenderName: SENDER_NAME })

      setShowMyCam(true)
      await startNewCall()
    }
  }

  const startNewCall = async () => {
    const peer = await initializeNewPeer()
    await createOffer(peer)
    PEER = peer
  }

  /**
   * Mutes or Unmutes the current call
   */
  const muteUnmuteCall = async () => {
    if (streamTracks) {
      setIsMicOn(!isMicOn)
      const newState = !streamTracks.getAudioTracks()[0].enabled
      streamTracks.getAudioTracks()[0].enabled = newState

      const message = JSON.stringify({
        ChatID: chatId,
        RequestType: 'ChangeAudioStatus',
        SenderName: SENDER_NAME,
        SenderID: SENDER_ID,
        ReceiverId: `${newReceiver}`,
        IsOn: newState,
        Hash: `${newHash}`,
      })
      await sendMessageToServer(message)
    }
  }

  const hideShowMyVideo = async () => {
    if (streamTracks) {
      setIsCameraOn(!isCameraOn)
      streamTracks.getVideoTracks()[0].enabled = !streamTracks.getVideoTracks()[0].enabled

      const message = JSON.stringify({
        ChatID: chatId,
        RequestType: 'ChangeVideoStatus',
        SenderName: SENDER_NAME,
        SenderID: SENDER_ID,
        ReceiverId: `${newReceiver}`,
        IsOn: !isCameraOn,
        Hash: `${newHash}`,
      })
      await sendMessageToServer(message)
    }
  }

  /**
   * Ends the current call
   */
  const endCall = async () => {
    const message = JSON.stringify({
      ChatID: chatId,
      RequestType: 'EndCall',
      SenderName: `${currentUser.firstName} ${currentUser.lastName}`,
      SenderID: SENDER_ID,
      ReceiverID: '',
      Hash: newHash,
    })

    const response = await sendMessageToServer(message)

    if (response) {
      window.location.href = '/VideoChatMessages'
    }
  }

  // =============================================
  // =================== Chats ===================
  // =============================================
  const RegisterSocketChatEvents = () => {
    if (chatId !== '') {
      SOCKET.emit('join', { ChatID: chatId, SenderName: SENDER_NAME })

      SOCKET.on(`update|${chatId}`, (event) => {
        const message = event
        if (message.MessageSender && message.MessageSender !== currentUser.id) {
          const chatHistory = ConnectChat.ReturnTranscript()
          chatHistory.push(message)

          setChatMessages(chatHistory)
        }
      })
    }
  }

  // const getChatHistoryByChat = async () => {
  //   if (chatId !== '') {
  //     await ConnectChat.GetTranscript(chatId)
  //     await ConnectChat.ListChatRoomsByChatID(chatId)
  //     await ConnectChat.MarkUnreadMessages(chatId, currentUser.id)
  //     setChatMessages(ConnectChat.ReturnTranscript()) //Filter messages before send
  //   }
  // }

  const getChats = async (userId) => {
    // await ConnectChat.ListChatRooms(userId)

    // if (PENDING_STATUS.includes(messageStatus)) {
    //   setSavedMessages(ConnectChat.ReturnChatsPending('Chat'))
    // } else if (COMPLETED_STATUS.includes(messageStatus)) {
    //   setSavedMessages(ConnectChat.ReturnChatsProcessed('Chat'))
    // }

    // setSavedAdminMessages(ConnectChat.ReturnChats('Chats'))

    // if (PENDING_STATUS.includes(updateStatus)) {
    //   setSavedUpdateChats(ConnectChat.ReturnChatsPending('Chat'))
    // } else if (COMPLETED_STATUS.includes(updateStatus)) {
    //   setSavedUpdateChats(ConnectChat.ReturnChatsProcessed('Chat'))
    // }

    // if (!gotSavedVideoData) {
    //   var startDate = new Date()
    //   var endDate = new Date()
    //   startDate.setDate(1)
    //   if (statusVideoFilter === "Pending") {
    //     setSavedVideoChats(await Calendar.ListCalendarByAgentID(currentUser.id, startDate, endDate))
    //     console.log(await Calendar.ListCalendarByAgentID(currentUser.id, startDate, endDate))
    //   } else if (statusVideoFilter === "Achieved") {
    //     setSavedVideoChats(Calendar.ReturnChats("VideoCall"))
    //   }
    //   setGotSavedVideoData(true)
    //   setGotSavedMessagesData(true)
    // }
  }

  const getAgentChats = async (agentId) => {
    const chats = ConnectChat.ReturnChats('Chat')
    const amountPending = chats.filter((chat) => PENDING_STATUS.includes(chat.SupportStatus)).length
    const resquestedAmount = MAX_CHATS_ALLOWED - amountPending
    const amountToUpdate = MAX_ALLOWED_UPDATE - amountPending
    setSavedMessages(ConnectChat.ReturnChatsPending('Chat'))

    if (resquestedAmount > 0) {
      await ConnectChat.ListChatRoomsAgent(agentId)
      setSavedMessages(ConnectChat.ReturnChatsPending('Chat'))
    }

    setSavedAdminMessages(ConnectChat.ReturnChats('Chat'))

    if (amountToUpdate > 0) {
      await ConnectChat.ListChatRoomsAgent(agentId)
      setSavedUpdateChats(ConnectChat.ReturnChatsPending('Chat'))
      // setUnreadedUpdateMessages(ConnectChat.ReturnUnreadMessages())
    }

    //!TODO Add video chat messages
  }

  const sendMessage = async () => {
    const messageToSend = document.getElementById('message').value

    if (messageToSend !== '' && chatId !== '') {
      const formattedDate = moment(new Date()).format('YYYY/MM/DD HH:mm:ss')

      const message = {
        ChatID: chatId,
        MessageType: 'Message',
        MessageContent: messageToSend,
        CreatedAt: formattedDate,
        MessageSender: SENDER_ID,
        MessageAttachment: '',
        MessageAction: '',
        SeenByOtherParty: false,
        SenderName: SENDER_NAME,
      }

      document.getElementById('message').value = ''

      await ConnectChat.SendMessageToChat(chatId, messageToSend, SENDER_ID, '', '')

      await ConnectChat.GetTranscript(chatId)
      await ConnectChat.MarkUnreadMessages(chatId, SENDER_ID)
      ConnectChat.AddMessageAfterUpdate(message)

      const newMessages = ConnectChat.ReturnTranscript()
      setChatMessages(newMessages)
    }
  }

  return (
    <ChatSocketContext.Provider
      value={{
        chatId,
        setChatId,
        processNewCall,
        videoRef,

        //MESSAGES
        RegisterSocketChatEvents,

        //Call Functions
        muteUnmuteCall,
        hideShowMyVideo,
        endCall,

        //Modifiers
        isMicOn,
        isCameraOn,
        isCallStarted,
        startedRemoteVideo,
        connectionState,

        //Name
        localName,
        remoteName,

        // Functions - Messages
        getChats,
        getAgentChats,
        sendMessage,

        // Chats - Messages
        savedMessages,
        savedAdminMessages,
        savedUpdateChats,
        chatMessages,
      }}
    >
      {' '}
      {children}{' '}
    </ChatSocketContext.Provider>
  )
}
