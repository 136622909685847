import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AiFillCloseCircle, AiOutlineRight } from "react-icons/ai";
import { ImBin2 } from "react-icons/im";
import styled from "styled-components";
import { Button } from "../../components/common";
import {
  Body,
  HelloText,
  HelloTextBold,
  MainCard
} from "../../components/common/MainCard";
import Caller from "../../components/layout/caller";
import Footer from "../../components/layout/footer";
import Header from "../../components/layout/header";
import LoadingScreen from "../../components/layout/LoadingScreen";
import NavBar from "../../components/layout/navBar";
import { GraphContext } from "../../stores/GraphStore";
import { UserContext } from "../../stores/UserStore";

const MakeUpGraph = () => {
  document.title = "VAM® Field Service";
  
  const { 
    savedMakeUpGraphs,
    reviewMakeUpGraphs,
    showHiddenInfo,
    gotSavedData,
    handleGetList,
    ConvertTimezone,
    handleDeleteSaved,
    handleContinue,
    handleChangeCheckbox,
    HandleShowPopUp,
    handleCancel,
    handleConfirmAssembly 
  } = useContext(GraphContext);
  const { currentUser } = useContext(UserContext);
  const { t } = useTranslation();

  const SideBySideOptions = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: stretch;
    margin-bottom: 4vh;
    button {
      margin-left: auto;
      margin-right: auto;
      min-height: 50px;
      justify-content: space-between;
      margin: 20px;
      width: 100%;
      padding: 30px;
      background-color: #009ee0;
      color: #fff;
      margin-bottom: 20px;
      font-size: 24px;
      box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);
      transition: all 0.3s ease 0s;
      :hover {
        background-color: #fff;
        color: #009ee0;
        transform: translateX(7px);
      }
      :focus {
        outline: none;
      }
    }
  `;

  const SideBySideOptionsPopUp = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: stretch;

    div {
      width: 95%;
    }

    button {
      background-color: Transparent;
      background-repeat: no-repeat;
      border: none;
      cursor: pointer;
      overflow: hidden;
      outline: none;
      position: relative;
    }
  `;

  const SideBySideOptionsReview = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: stretch;
    button {
      margin-left: auto;
      margin-right: auto;
      min-height: 50px;
      justify-content: space-between;
      margin: 20px;
      width: 100%;
      padding: 30px;
      background-color: #009ee0;
      color: #fff;
      margin-bottom: 20px;
      font-size: 24px;
      box-shadow: 0px 5px 5px rgba(167, 164, 206, 0.4);
      transition: all 0.3s ease 0s;
      :hover {
        background-color: #fff;
        color: #009ee0;
        transform: translateX(7px);
      }
    }
  `;

  const GrayOverlay = styled.div`
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
  `;

  const PopUpBeforeProceding = styled.div`
    position: absolute;
    left: 35%;
    top: 20%;
    border-style: outset;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 50px;
    margin-left: 20px;
    color: black;
    background-color: white;
    width: 400px;

    text-align: left;
    z-index: 1;

    .PopUpTitle {
      font-size: calc(18px + 0.6vw);
      font-weight: normal;
      margin-bottom: 23px;
    }
    .PopUpSubTitle {
      font-size: 16px;
      font-weight: normal;
      margin-bottom: 28px;
    }

    ul {
      text-align: left;
      list-style-type: none;
      margin: 0;
      padding: 0;
      padding-top: 10px;
      font-weight: normal;
      font-size: 14px;
    }
    li {
      padding-bottom: 24px;
      font-weight: bold;
    }
  `;

  const ButtonPopUp = styled.div`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 24px;
    button {
      width: 150px;
    }
  `;

  const BlueButtonHidden = styled.button`
    cursor: pointer;
    min-height: 50px;
    border-radius: 10px;
    min-width: 100px;
    background-color: #009ee0;
    color: white;
    font-size: calc(8px + 0.5vw);
    border: 0 solid #fff;
    transition: all 0.3s ease 0s;
    :hover {
      background-color: #fff;
      box-shadow: 0px 15px 20px rgba(167, 164, 206, 0.4);
      color: #009ee0;
      transform: translateX(7px);
    }
  `;

  const WhiteButtonHidden = styled.button`
    cursor: pointer;
    min-height: 50px;
    border-radius: 10px;
    min-width: 100px;
    background-color: white;
    color: #009ee0;
    font-size: calc(8px + 0.5vw);
    border: 2px solid #e7e7e7;
    margin-right: 20px;
    transition: all 0.3s ease 0s;
    :hover {
      background-color: #009ee0;
      box-shadow: 0px 15px 20px rgba(167, 164, 206, 0.4);
      color: #fff;
      transform: translateX(7px);
    }
  `;

  const ErrorMessage = styled.div`
    display: none;
    color: #ff0033;
    font-size: calc(8px + 0.3vw);
    padding-bottom: 10px;
  `;

  const SavedDiv = styled.div`
    width: 96%;
    min-width: 450px;
    display: flex;
    color: #009ee0;
    background-color: white;
    padding: 15px;
    border-radius: 10px;
    font-size: calc(8px + 0.5vw);
    margin-left: 20px;
    vertical-align: center;
    margin-bottom: 10px;
    transition: all 0.3s ease 0s;
    :hover {
      transform: translateX(7px);
    }
    .GrayText {
      color: #666666;
      font-size: 12px;
    }
    .left {
      width: 92%;
      margin-left: 20px;
      display: flex;
      align-items: center;
      div {
        padding-right: 48px;
      }
    }
    .right {
      display: flex;
      width: 8%;
      padding-right: 10px;
      align-items: center;
    }
    button {
      margin-top: 5px;
      height: 50px;
      width: 100px;
      background-color: #f8f8f8;
      color: #039fe0;
      border: 2px solid #e7e7e7;
      border-radius: 10px;
      transition: all 0.3s ease 0s;
      :hover {
        background-color: #009ee0;
        box-shadow: 0px 15px 20px rgba(167, 164, 206, 0.4);
        color: #fff;
      }
    }
    font {
      font-size: 16px;
      color: black;
    }
  `;

  const ReviewDiv = styled.div`
    background-color: white;
    width: 96%;
    padding: 15px;
    border-radius: 10px;
    margin-left: 20px;
    margin-bottom: 150px;
    .Title {
      color: Black;
      margin-left: 20px;
    }
  `;

  const ReviewTable = styled.table`
    width: 100%;
    padding-left: 28px;
    th {
      font-weight: 200;
      width: 7%;
      text-align: left;
      + th {
        width: 7%;
        + th {
          width: 86%;
          + th {
            text-align: right;
          }
        }
      }
    }
  `;

  const ReviewOptionsDiv = styled.tr`
    color: #009ee0;
    text-align: right;
  `;

  console.log(reviewMakeUpGraphs)

  return (
    <Body>
      {handleGetList(currentUser.id)}
      {showHiddenInfo && (
        <GrayOverlay id="GrayOverlay">
          <PopUpBeforeProceding>
            <SideBySideOptionsPopUp>
              <div></div>
              <button onClick={handleCancel}>
                <AiFillCloseCircle
                  size="20px"
                  style={{ color: "#009ee0", paddingBottom: "10px" }}
                />{" "}
              </button>
            </SideBySideOptionsPopUp>{" "}
            <div className="PopUpTitle">
              {t("MakeUpGraph.BeforeProceeding")}
            </div>
            <div className="PopUpSubTitle">
              {t("MakeUpGraph.Pleasehavethefollowingdocumentsand")} <div />
              {t("MakeUpGraph.informationonhand")}
            </div>
            <ul>
              <li>
                <input
                  type="checkbox"
                  onChange={() => handleChangeCheckbox(1)}
                />{" "}
                {t("MakeUpGraph.MakeUpequipmentdetails")}
              </li>
              <li>
                <input
                  type="checkbox"
                  onChange={() => handleChangeCheckbox(2)}
                />{" "}
                {t("MakeUpGraph.CalibrationcertificateofMakeupEquipement")}
              </li>
              <li>
                <input
                  type="checkbox"
                  onChange={() => handleChangeCheckbox(3)}
                />{" "}
                {t("MakeUpGraph.Threadcompoundinformation")}
              </li>
              <li>
                <input
                  type="checkbox"
                  onChange={() => handleChangeCheckbox(4)}
                />{" "}
                {t("MakeUpGraph.ConnectionDetails")}
              </li>
              <li>
                <input
                  type="checkbox"
                  onChange={() => handleChangeCheckbox(5)}
                />{" "}
                {t("MakeUpGraph.MakeupGraphsPDFs")}
              </li>
            </ul>
            <ErrorMessage id="errorMessage">
              {t("MakeUpGraph.Pleasechecktheitemsbeforeproceeding")}
            </ErrorMessage>
            <ButtonPopUp>
              <WhiteButtonHidden onClick={handleCancel}>
                {t("MakeUpGraph.Cancel")}
              </WhiteButtonHidden>
              <BlueButtonHidden onClick={handleConfirmAssembly}>
                {t("MakeUpGraph.Confirm")}
              </BlueButtonHidden>
            </ButtonPopUp>
          </PopUpBeforeProceding>
        </GrayOverlay>
      )}
      <Header />
      <Caller />
      <NavBar />
      {!gotSavedData ? (
        <LoadingScreen />
      ) : (
        <MainCard id="MainCard">
          <HelloTextBold> {t("MakeUpGraph.MakeupGraphReview")}</HelloTextBold>
          <HelloText> {t("MakeUpGraph.Yourrequestconcerns")}</HelloText>
          <SideBySideOptions>
            <Button onClick={HandleShowPopUp}>
              {" "}
              <div>
                {t("MakeUpGraph.Assembly")}{" "}
                <font style={{ fontWeight: "normal" }}>
                  {" "}
                  {t("MakeUpGraph.MakeUpGraphs")}
                </font>
              </div>
              <AiOutlineRight size="1em" />
            </Button>
            <Button onClick={HandleShowPopUp}>
              {" "}
              <div>
                {t("MakeUpGraph.RigSite")}{" "}
                <font style={{ fontWeight: "normal" }}>
                  {" "}
                  {t("MakeUpGraph.MakeUpGraphs")}
                </font>
              </div>
              <AiOutlineRight size="1em" />
            </Button>
          </SideBySideOptions>

          {savedMakeUpGraphs.map((SavedGraphs) => (
            <div key={SavedGraphs.RequestID}>
              <SavedDiv>
                <div className="left">
                  <div>
                    <font> {t("MakeUpGraph.Saved")}</font>
                  </div>
                  <div>
                    <div className="GrayText">
                      {" "}
                      {t("MakeUpGraph.NofGraphs")}
                    </div>{" "}
                    {SavedGraphs.NumberOfGraphs}
                  </div>
                  <div>
                    <div className="GrayText">
                      {" "}
                      {t("MakeUpGraph.LastModificationdate")}
                    </div>{" "}
                    {ConvertTimezone(SavedGraphs.LastAltered)}
                  </div>
                </div>
                <div className="right">
                  <button
                    onClick={() =>
                      handleContinue(
                        SavedGraphs.RequestID,
                        SavedGraphs.NumberOfGraphs
                      )
                    }
                  >
                    {t("MakeUpGraph.Continue")}
                  </button>
                  <ImBin2
                    onClick={() => handleDeleteSaved(SavedGraphs.RequestID)}
                    size="24px"
                    style={{ cursor: "pointer", marginLeft: "30px" }}
                  />
                </div>
              </SavedDiv>
            </div>
          ))}
          <ReviewDiv id="ReviewDiv">
            <SideBySideOptionsReview>
              <div className="Title"> {t("MakeUpGraph.Reviewed")}</div>
              {t("MakeUpGraph.ViewAll")}
            </SideBySideOptionsReview>
            <ReviewTable>
              <tbody>
                <tr>
                  <th>
                    {" "}
                    <font> {t("MakeUpGraph.RequestN")}</font>
                  </th>
                  <th>
                    <font> {t("MakeUpGraph.NofGraphs")}</font>
                  </th>
                  <th>
                    {" "}
                    <font> {t("MakeUpGraph.Date")} </font>
                  </th>
                  <th></th>
                </tr>
                {reviewMakeUpGraphs.map((ReviewGraphs) => (
                  <ReviewOptionsDiv key={ReviewGraphs.RequestId}>
                    <th>{ReviewGraphs.RequestID}</th>
                    <th style={{ paddingRight: "30px", textAlign: "right" }}>
                      {ReviewGraphs.NumberOfGraphs}
                    </th>
                    <th>{ConvertTimezone(ReviewGraphs.LastAltered)}</th>
                    <th> {t("MakeUpGraph.View")}</th>
                  </ReviewOptionsDiv>
                ))}
              </tbody>
            </ReviewTable>
          </ReviewDiv>
        </MainCard>
      )}
      <Footer />
    </Body>
  );
};

export default MakeUpGraph;
