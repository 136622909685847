import React, { useState } from "react";
import * as transaction from "../components/connect/transaction";
import { toast } from "react-toastify";

const initialState = {
    item: {},
};

export const AccountManagementContext = React.createContext(initialState);

export const AccountManagementProvider = ({ children }) => {
    document.title = "Account Recharge Management";
    const [gotSavedData, setGotSavedData] = useState(false);
    const [showRecharge, setShowRecharge] = useState(false);
    const [showFinishedRecharge, setShowFinishedRecharge] = useState(false);
    const [schearchText, setSchearchText] = useState("");
    const [rechargeCompanyName, setRechargeCompanyName] = useState("");
    const [rechargeCompanyID, setRechargeCompanyID] = useState("");
    const [rechargeCompanyCurrency, setRechargeCompanyCurrency] = useState("");
    const [rechargeCompanyValue, setRechargeCompanyValue] = useState("");
    const [rechargeRequest, setRechargeRequest] = useState([]);
    const [recharge, setRecharge] = useState(0);

    const handleGetInitialData = () => {
        setTimeout(async () => {
            if (gotSavedData === false) {
                var oldDate = new Date();
                var newDate = new Date();

                oldDate.setFullYear("1990");
                newDate.setFullYear("2500");
                setGotSavedData(true);
                await transaction.ListTransactionByAgentKey(oldDate, newDate);
                var listTransaction = transaction.ReturnTransactionsSortedByPending();
                console.log(listTransaction);
                setRechargeRequest(listTransaction);
            }
        }, 10);
    };

    const ConvertTimezone = (oldDate) => {
        var date = new Date(oldDate),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2),
            hour = ("0" + (date.getHours() + 1)).slice(-2),
            minute = ("0" + (date.getMinutes() + 1)).slice(-2);

        var currentDate = new Date();

        if (date.toDateString() === currentDate.toDateString()) {
            return hour + ":" + minute;
        }
        return (
            [day, mnth, date.getFullYear()].join("/") + " " + hour + ":" + minute
        );
    };

    const handleSchearchTerm = () => {
        const SchearchTerm = document.getElementById("scearch").value;
        if (SchearchTerm !== "") {
            setRechargeRequest(
                transaction.ReturnTransactionsSortedByPendingFiltered(SchearchTerm)
            );
            setSchearchText(SchearchTerm);
        } else {
            setRechargeRequest(transaction.ReturnTransactionsSortedByPending());
            setSchearchText(SchearchTerm);
        }
    };

    const handleRecharge = (Id, name, currency, value) => {
        setRechargeCompanyName(name);
        setRechargeCompanyID(Id);
        setRechargeCompanyCurrency(currency);
        setRechargeCompanyValue(value);
        setRecharge(0);
        setShowRecharge(true);
    };

    const handleCancel = () => {
        setShowRecharge(false);
    };

    const handleCancelNotify = () => {
        setShowRecharge(false);
        setShowFinishedRecharge(false);
    };

    const handleNotify = () => {
        setShowRecharge(false);
        setShowFinishedRecharge(false);
    };

    const handleStartRecharge = () => {
        var value = document.getElementById("GraphRecharge").value;

        if (value !== "" && value !== "0") {
            transaction.InsertTransaction(
                rechargeCompanyID,
                value,
                rechargeCompanyCurrency,
                "Recharge",
                0,
                0,
                0,
                rechargeCompanyName,
                true,
                "approved"
            );
            toast.success("Recharged");
            setShowFinishedRecharge(true);
        } else {
            toast.error("Invalid Recharge Value");
        }
    };

    const handleIncreaseRecharge = () => {
        var value = document.getElementById("GraphRecharge").value;
        if (value !== "") {
            document.getElementById("GraphRecharge").value =
                parseInt(document.getElementById("GraphRecharge").value) + 1;
        } else {
            document.getElementById("GraphRecharge").value = 1;
        }
    };

    const SaveNumberRecharge = () => {
        var value = document.getElementById("GraphRecharge").value;
        if (value !== "") {
            setRecharge(value);
        }
    };

    const handleDecreaseRecharge = () => {
        var value = document.getElementById("GraphRecharge").value;
        if (value !== "") {
            if (value > 0) {
                document.getElementById("GraphRecharge").value =
                    parseInt(document.getElementById("GraphRecharge").value) - 1;
            }
        } else {
            document.getElementById("GraphRecharge").value = 0;
        }
    };

    const PutRechargeValue = (value) => {
        setRecharge(value);
        document.getElementById("GraphRecharge").value = value;
    };

    const renderAccountManagement = (
        <AccountManagementContext.Provider
            value={{
                gotSavedData, setGotSavedData,
                showRecharge, setShowRecharge,
                showFinishedRecharge, setShowFinishedRecharge,
                schearchText, setSchearchText,
                rechargeCompanyName, setRechargeCompanyName,
                rechargeCompanyID, setRechargeCompanyID,
                rechargeCompanyCurrency, setRechargeCompanyCurrency,
                rechargeCompanyValue, setRechargeCompanyValue,
                rechargeRequest, setRechargeRequest,
                recharge, setRecharge,
                handleGetInitialData,
                ConvertTimezone,
                handleSchearchTerm,
                handleRecharge,
                handleCancel,
                handleCancelNotify,
                handleNotify,
                handleStartRecharge,
                handleIncreaseRecharge,
                SaveNumberRecharge,
                handleDecreaseRecharge,
                PutRechargeValue
            }}
        >
            {children}
        </AccountManagementContext.Provider>
    );

    return renderAccountManagement;
};
