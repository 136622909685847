import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CgProfile } from "react-icons/cg";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import logo from "../../assets/images/BlueLogo.png";
import { UserContext } from "../../stores/UserStore";

const Header = (props) => {
  const { currentUser, signOut } = useContext(UserContext);
  const history = useHistory();
  var ProfilePicture = "";

  const redirectIfNotAuthenticated = async () => {
    if (
      currentUser == null &&
      (currentUser.roles.ADMIN_ACCESS || currentUser.roles.DEFAULT)
    ) {
      history.push("/access-denied");
    }
  };

  // const createSocket = () => {
  //   if (currentUser != null) {
  //     var isAdmin;
  //     if (currentUser.roles.ADMIN_ACCESS) {
  //       isAdmin = true;
  //     } else {
  //       isAdmin = false;
  //     }

  //     // socketIoClient.socketIo(currentUser.id, isAdmin);
  //   }
  // };

  const handleProfile = () => {
    history.push("/Profile");
  };

  const handleSignOut = () => {
    signOut();
    history.push("/");
  };

  const Header = styled.div`
    font-family: Roboto;
    float: top;
    width: 100vw;
    height: 90px;
    position: relative;
    background-color: white;
    box-shadow: 0px 5px 10px rgba(167, 164, 206, 0.4);
  `;

  const Logo = styled.img`
    height: 32px;
    width: 127px;
    margin-left: 3vh;
    padding-top: 30px;
    display: block;
  `;

  const ProfileOptions = styled.div`
    display: none;
    position: absolute;
    right: 1em;
    background-color: #f1f1f1;
    min-width: 100px;
    text-align: center;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      width: 120px;
    }
    ul li {
      position: relative;
      background-color: #f1f1f1;
    }
    li ul {
      position: absolute;
      right: 155px;
      top: -10px;
      background-color: #f1f1f1;
      display: none;
    }

    ul li button {
      color: black;
      padding: 12px;
      text-decoration: none;
      display: block;
      cursor: pointer;
      width: 100%;
      border: 0;
      :hover {
        background-color: #ddd;
        text-decoration: none;
      }
    }
    li:hover ul {
      display: block;
    }
  `;

  const Profile = styled.div`
    position: absolute;
    right: 2vh;
    top: 30px;
    text-align: center;

    &:hover ${ProfileOptions} {
      display: block;
    }

    .Position
    {
      margin-top: -10px;
      margin-left: 25px;
      color:black;
      font-style: italic;
    }
  `;

  const ProfileButton = styled.button`
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    outline: none;
  `;

  const getProfilePic = () => {
    if (currentUser != null) {
      if (
        localStorage.getItem(
          "ProfilePictureVamApp" +
          currentUser.id.split("")[0] +
          currentUser.id.split("")[1] +
          currentUser.id.split("")[2]
        ) !== undefined
      ) {
        ProfilePicture = localStorage.getItem(
          "ProfilePictureVamApp" +
          currentUser.id.split("")[0] +
          currentUser.id.split("")[1] +
          currentUser.id.split("")[2]
        );
      }
    }
  };
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      await redirectIfNotAuthenticated()
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Header>
      {/* {redirectIfNotAuthenticated()} */}
      {getProfilePic()}
      <Logo src={logo} />
      <Profile>
        <ProfileButton>
          {ProfilePicture === null ? (
            <CgProfile size="25px" style={{ marginRight: "5px" }} />
          ) : (
            <img
              src={ProfilePicture}
              alt="Profile"
              style={{ width: "35px", height: "35px", marginRight: "5px", borderRadius: "50%" }}
            />
          )}
          <font style={{ fontWeight: "bold" }}>
            {currentUser != null &&
              currentUser.firstName + " " + currentUser.lastName.split("")[0]}
            .
          </font>

        </ProfileButton>
        <ProfileOptions>
          <ul id="nav">
            <li>
              <button onClick={handleProfile}>{t("Header.Profile")}</button>
            </li>
            <li>
              <button onClick={handleSignOut}>{t("Header.SignOut")}</button>
            </li>
          </ul>
        </ProfileOptions>
        <div className="Position">
          {currentUser.roles.ADMIN_ACCESS ? t("Header.Agent") : t("Header.Customer")}
        </div>
      </Profile>

    </Header>
  );
};

export default Header;
