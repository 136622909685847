import React from "react";
import styled from "styled-components";
import backgroundOracle from "../../assets/images/backgroundOracle.png";
import makeUpIcon from "../../assets/images/Oracle/MakeUp.png";
import pipeIcon from "../../assets/images/Oracle/Pipe.png";
import conectionIcon from "../../assets/images/Oracle/Conection.png";
import giantThree from "../../assets/images/Oracle/3.png";
import threeBoxes from "../../assets/images/Oracle/SignUpFree.png";
import freeLogo from "../../assets/images/Oracle/FreeLogo.png";
import clockLogo from "../../assets/images/Oracle/clock.png";
import BlueLogo from "../../assets/images/Oracle/BlueLogo.png";
import FacebookIcon from "../../assets/images/Oracle/Facebook.png";
import TwitterIcon from "../../assets/images/Oracle/Twitter.png";
import YoutubeIcon from "../../assets/images/Oracle/Youtube.png";
import LinkedinIcon from "../../assets/images/Oracle/Linkedin.png";
import logo from "../../assets/images/VAM_App_Logo.png";
import { Anchor } from "../../components/common";
import { toast } from "react-toastify";
import LanguageSelect from "../../containers/common/LanguageSelect";
import { useTranslation } from "react-i18next";

const BackgroundDiv = styled.div`
  position: fixed;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
`;

const BackgroundImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  min-width: 50%;
  min-height: 50%;
  filter: brightness(50%);
`;

const Icons = styled.img`
  height: 48px;
`;
const ClockIcon = styled.img`
  height: 64px;
  margin-bottom: 24px;
`;
const GiantThree = styled.img`
  position: absolute;
  height: 75%;
  margin-top: 3vh;
  margin-left: 10%;
`;
const ThreeBoxes = styled.img`
  position: absolute;
  right: 0;
  height: 75%;
  margin-top: 3vh;
  margin-right: 10%;
`;
const FreeLogo = styled.img`
  position: absolute;
  height: 26vh;
  right: 0;
  margin-right: 8%;
`;

const IconList = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding-top: 52px;
  font-size: calc(24px);
`;

const Logo = styled.img`
  position: absolute;
  top: 2vh;
  left: 25px;
  width: 10vw;
`;

const StratingScreenTextBold = styled.div`
  color: white;
  font-size: calc(12px + 3vw);
  letter-spacing: 1;
  padding-bottom: 0.2em;
  font-weight: bold;
  sup {
    font-size: calc(12px + 1.5vw);
  }
`;

const StartingScreenTextSmall = styled.div`
  font-size: calc(12px + 1vw);
  color: white;
  letter-spacing: 4;
`;

const StartingScreenTextSmallBold = styled.div`
  color: white;
  padding-bottom: 0.5em;
  font-size: calc(12px + 1.3vw);
  font-weight: bold;
`;

const TextOffer = styled.div`
  color: black;
  font-size: calc(18px + 2vw);
`;

const ListOfServices = styled.div`
  color: black;
  margin-top: 24px;
  font-size: calc(18px + 0.4vw);
`;

const TextSignUp = styled.div`
  font-size: calc(24px + 1.25vw);
  padding-bottom: 15px;
`;

const TextsSingUpDivs = styled.div`
  padding-top: 11vh;
  padding-left: 16.5%;
`;

const ButtonSingUpDivs = styled.div`
  width: 100%;
  text-align: center;
`;

const TextSignUpBold = styled.div`
  font-size: calc(24px + 1.25vw);
  font-weight: bold;
`;

const HiddenDiv = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const ScrollDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: scroll;
`;

const TextSign = styled.div`
  position: absolute;
  top: 3vh;
  right: 4vw;
  color: #009ee0;
  font-weight: bold;
  font-size: calc(16px);
`;

const LanguageSign = styled.div`
  position: absolute;
  top: 2vh;
  right: 9vw;
  width: 150px;
  color: #009ee0;
  font-weight: bold;
  font-size: calc(16px);
`;

const Body = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  align-items: center;
  text-align: center;
  top: 20%;
  font-family: Roboto;
`;

const Footer = styled.div`
  margin-top: 14vh;
  position: relative;
  justify-items: center;
  align-items: center;
  display: flex;
  background-color: white;
  padding: 25px 31px;
  width: 100%;
  img {
    height: 31px;
  }
`;

const FooterLinks = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 96px;
  font-size: 14px;
  border-left: 1px solid black;
  a {
    color: black;
    margin-left: 10px;
    + a {
      margin-top: 12px;
    }
  }
`;

const FooterIcon = styled.div`
  position: absolute;
  right: 100px;

  img {
    margin-left: 26px;
    height: 16px;
  }
`;

const Cards = styled.div`
  display: block;
  flex-direction: row;
  align-items: center;
  background-color: #f8f9fa;
  width: 100%;
  position: absolute;
  top: 55%;
`;

const CardOffer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10vh;
  padding-bottom: 3em;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  width: 100%;
  margin-bottom: 96px;
  box-shadow: 1px 80px 100px -50px rgba(0, 0, 0, 0.36);
`;

const PlansOfferDiv = styled.div`
  display: flex;
  padding-top: 4.8vh;
  align-items: center;
  justify-content: space-around;
  width: 100%;
`;

const WeRecomendPlan = styled.div`
  position: absolute;
  font-size: calc(12px + 0.4vw);
  width: 100%;
  border-radius: 5px 5px 0 0;
  padding: 15px 0;
  background-color: #d9dde8;
`;

const PlansOfferCard = styled.div`
  position: relative;
  box-shadow: 0px 15px 20px rgba(167, 164, 206, 0.4);
  background-color: white;
  width: 30%;
  height: 30vh;
  min-height: 200px;
  max-height: 276px;
  border-radius: 5px;
  font-weight: 300;
  button {
    position: absolute;
    width: 24vw;
    height: 46px;
    left: 3vw;
    bottom: 4vh;
    background-color: #039fe0;
    color: white;
    border: 0;
    border-radius: 5px;
    transition: all 0.3s ease 0s;
    font-size: 14px;
    :hover {
      background-color: #fff;
      box-shadow: 0px 15px 20px rgba(167, 164, 206, 0.4);
      border: 2px solid #e7e7e7;
      color: #039fe0;
    }
  }
`;
const CostPlansOffer = styled.div`
  display: flex;
  justify-content: space-around;
  font-size: calc(24px + 0.4vw);
  color: black;
  width: 100%;
  padding-top: 64px;
`;

const CostPlansOfferPrice = styled.div`
  text-align: right;
  padding-top: 10px;
  font-size: calc(16px + 0.4vw);
  color: gray;
  font-weight: bold;
`;

const CardSignUp = styled.div`
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
  height: 44vh;
  min-height: 250px;
  text-align: left;
  background-color: black;
  color: white;
  width: 100%;
`;

const CardPlans = styled.div`
  padding-top: 3em;
  padding-bottom: 3em;
  background-color: #f8f9fa;
  width: 100%;
  box-shadow: 1px 80px 100px -50px rgba(0, 0, 0, 0.36);
`;

const PricedPlansOffer = styled.div`
  padding-left: 48px;
  padding-top: 24px;
  text-align: left;
  color: black;
`;

const ButtonStart = styled.button`
  width: 15vw;
  height: 70px;
  border-radius: 5px;
  cursor: pointer;
  background-image: linear-gradient(
    to right,
    #00d2ff 0%,
    #0290d5 15%,
    #172983 100%
  );
  border: 0;
  font-size: calc(12px + 1vw);
  color: white;
`;

const ButtonSignUp = styled.button`
  border: 0;
  cursor: pointer;
  background-image: linear-gradient(
    to right,
    #00d2ff 0%,
    #0290d5 15%,
    #172983 100%
  );
  width: 202px;
  height: 60px;
  border-radius: 5px;
  font-size: 24px;
  color: white;
`;

const handleSubscribe = () => {
  toast.warning("Feature is under construction");
};

const Oracle = () => {
  document.title = "VAM® Field Service";
  const { t } = useTranslation();
  return (
    <HiddenDiv>
      <ScrollDiv>
        <BackgroundDiv>
          <BackgroundImg alt="" src={backgroundOracle} />
        </BackgroundDiv>
        <Logo src={logo} />
        <LanguageSign>
          <LanguageSelect />
        </LanguageSign>{" "}
        <Anchor href= {process.env.REACT_APP_LOGIN_URL + "/login?app=" + process.env.REACT_APP_ID}>
          {" "}
          <TextSign>{t("Oracle.SingIn")}</TextSign>{" "}
        </Anchor>
        <Body>
          <StratingScreenTextBold>
            {t("Oracle.WelcomeToVAM")}
            <sup>®</sup> {t("Oracle.FieldServices")}
          </StratingScreenTextBold>
          <StartingScreenTextSmall>
            {t("Oracle.GetSupportAnytimeAndAnywhere")}
          </StartingScreenTextSmall>
          <StartingScreenTextSmallBold>
            {t("Oracle.SigUpAndSaveYourDay!")}
          </StartingScreenTextSmallBold>

          <Anchor href= {process.env.REACT_APP_LOGIN_URL + "/login?app=" + process.env.REACT_APP_ID}>
            <ButtonStart> {t("Oracle.GetStarted")}</ButtonStart>
          </Anchor>
          <Cards>
            <CardOffer>
              <div>
                <TextOffer>{t("Oracle.WhatWeOffer")}</TextOffer>
                <ListOfServices>
                  {t("Oracle.ServiceAndSupportFromOurExperts")}
                </ListOfServices>
              </div>
              <IconList>
                <div>
                  <Icons src={makeUpIcon} /> <div />
                  {t("Oracle.Make-UpGraphReview")}
                </div>
                <div>
                  <Icons src={pipeIcon} />
                  <div />
                  {t("Oracle.PipeInspection")}
                </div>
                <div>
                  <Icons src={conectionIcon} />
                  <div />
                  {t("Oracle.ConnectionInspection")}
                </div>
              </IconList>
            </CardOffer>
            <CardSignUp>
              <GiantThree src={giantThree} /> <div />
              <ThreeBoxes src={threeBoxes} /> <div />
              <FreeLogo src={freeLogo} /> <div />
              <TextsSingUpDivs>
                <TextSignUp>{t("Oracle.SignUpNowAndGet")}</TextSignUp>
                <TextSignUp>{t("Oracle.3Make-upGraphReviews")}</TextSignUp>
                <TextSignUpBold>{t("Oracle.ForFree")}</TextSignUpBold>
              </TextsSingUpDivs>
              <ButtonSingUpDivs>
                <Anchor href= {process.env.REACT_APP_LOGIN_URL + "/login?app=" + process.env.REACT_APP_ID}>
                  {" "}
                  <ButtonSignUp>{t("Oracle.SignUp")}</ButtonSignUp>{" "}
                </Anchor>
              </ButtonSingUpDivs>
            </CardSignUp>
            <CardPlans>
              <TextOffer>{t("Oracle.OurPlans")}</TextOffer>
              <ListOfServices>
                {t("Oracle.FindTheRightPlanForYouNeeds")}
              </ListOfServices>
              <PlansOfferDiv>
                <PlansOfferCard>
                  <CostPlansOffer>
                    <ClockIcon src={clockLogo} />
                    <div>
                      {t("Oracle.1hour")}
                      <CostPlansOfferPrice>R$ 350</CostPlansOfferPrice>
                    </div>
                  </CostPlansOffer>
                  <button onClick={handleSubscribe}>
                    {" "}
                    {t("Oracle.Subscribe")}
                  </button>
                </PlansOfferCard>
                <PlansOfferCard>
                  <WeRecomendPlan> {t("Oracle.WeRecomend")} </WeRecomendPlan>
                  <CostPlansOffer>
                    <ClockIcon src={clockLogo} />
                    <div>
                      {t("Oracle.4hour")}
                      <CostPlansOfferPrice>R$ 900</CostPlansOfferPrice>
                    </div>
                  </CostPlansOffer>
                  <button onClick={handleSubscribe}>
                    {t("Oracle.Subscribe")}
                  </button>
                </PlansOfferCard>
                <PlansOfferCard>
                  <CostPlansOffer>
                    <ClockIcon src={clockLogo} />
                    <div>
                      {t("Oracle.12hour")}
                      <CostPlansOfferPrice>R$ 1 200</CostPlansOfferPrice>
                    </div>
                  </CostPlansOffer>
                  <button onClick={handleSubscribe}>
                    {t("Oracle.Subscribe")}
                  </button>
                </PlansOfferCard>
              </PlansOfferDiv>
              <PricedPlansOffer>{t("Oracle.PricesAreInUSD")}</PricedPlansOffer>
            </CardPlans>
            <Footer>
              <div
                color="gray"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                  fontSize: "x-small",
                }}
              >
                <img alt="" src={BlueLogo} />
                {process.env.REACT_APP_VERSION}
              </div>
              <FooterLinks>
                <Anchor href="/legal-notice">{t("Oracle.LegalNotice")}</Anchor>
                <Anchor href="/privacy">{t("Oracle.Privacy")}</Anchor>
                <Anchor href="/terms">{t("Oracle.TermsOfUse")}</Anchor>
              </FooterLinks>
              <FooterIcon>
                <Anchor
                  href="https://www.facebook.com/Vallourec/"
                  target="_blank"
                >
                  {" "}
                  <img alt="" src={FacebookIcon} />{" "}
                </Anchor>
                <Anchor href="https://twitter.com/vallourec/" target="_blank">
                  {" "}
                  <img alt="" src={TwitterIcon} />{" "}
                </Anchor>
                <Anchor
                  href="https://www.linkedin.com/company/vallourec/"
                  target="_blank"
                >
                  <img alt="" src={YoutubeIcon} />{" "}
                </Anchor>
                <Anchor
                  href="https://www.linkedin.com/company/vallourec/"
                  target="_blank"
                >
                  {" "}
                  <img alt="" src={LinkedinIcon} />{" "}
                </Anchor>
              </FooterIcon>
            </Footer>
          </Cards>
        </Body>
      </ScrollDiv>
    </HiddenDiv>
  );
};

export default Oracle;
