import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  Body,
  HelloText,
  HelloTextBold,
  MainCard
} from "../../components/common/MainCard";
import Caller from "../../components/layout/caller";
import Footer from "../../components/layout/footer";
import Header from "../../components/layout/header";
import LoadingScreen from "../../components/layout/LoadingScreen";
import NavBar from "../../components/layout/navBar";
import { GraphContext } from "../../stores/GraphStore";
import { UserContext } from "../../stores/UserStore";

const MakeUpGraphDraftedGraph = () => {
  document.title = "VAM® Field Service";
  const { handleGetList, ConvertTimezone, handleContinue, gotSavedData, draftedMakeUpGraphs } = useContext(GraphContext);
  const { currentUser } = useContext(UserContext);
  const { t } = useTranslation();

  const SavedDiv = styled.div`
    width: 96%;
    min-width: 450px;
    display: flex;
    color: #009ee0;
    background-color: white;
    padding: 15px;
    border-radius: 10px;
    font-size: calc(8px + 0.5vw);
    margin-left: 20px;
    vertical-align: center;
    margin-bottom: 10px;
    transition: all 0.3s ease 0s;
    :hover {
      transform: translateX(7px);
    }
    .GrayText {
      color: #666666;
      font-size: 12px;
    }
    .left {
      width: 92%;
      margin-left: 20px;
      display: flex;
      align-items: center;
      div {
        padding-right: 48px;
      }
    }
    .right {
      display: flex;
      padding-right: 10px;
      align-items: center;
    }
    button {
      margin-top: 5px;
      height: 50px;
      width: 100px;
      background-color: #f8f8f8;
      color: #039fe0;
      border: 2px solid #e7e7e7;
      border-radius: 10px;
      transition: all 0.3s ease 0s;
      :hover {
        background-color: #009ee0;
        box-shadow: 0px 15px 20px rgba(167, 164, 206, 0.4);
        color: #fff;
      }
    }
    font {
      font-size: 16px;
      color: black;
    }
  `;

  return (
    <Body>
      {handleGetList(currentUser.id)}
      <Header />
      <Caller />
      <NavBar />
      {!gotSavedData ? (
        <LoadingScreen />
      ) : (
        <MainCard id="MainCard">
          <HelloTextBold> {t("MakeUpGraph.MakeupGraphReview")}</HelloTextBold>
          <HelloText>
            {" "}
            {t("MakeUpGraphDraftedGraph.SentForEvaluation")}
          </HelloText>
          <div style={{ padding: "15px" }}></div>

          {draftedMakeUpGraphs.map((SavedGraphs) => (
            <div key={SavedGraphs.RequestID}>
              <SavedDiv>
                <div className="left">
                  <div>
                    <font>
                      {" "}
                      {t("MakeUpGraphDraftedGraph.Draft") +
                        " - " +
                        SavedGraphs.RequestID}
                    </font>
                  </div>
                  <div>
                    <div className="GrayText">
                      {" "}
                      {t("MakeUpGraph.NofGraphs")}
                    </div>{" "}
                    {SavedGraphs.NumberOfGraphs}
                  </div>
                  <div>
                    <div className="GrayText">
                      {" "}
                      {t("MakeUpGraphDraftedGraph.Status")}
                    </div>{" "}
                    {SavedGraphs.ReviewerID === ""
                      ? t("MakeUpGraphDraftedGraph.AwaitingAgent")
                      : t("MakeUpGraphDraftedGraph.AssignedAgent")}
                  </div>
                  <div>
                    <div className="GrayText">
                      {" "}
                      {t("MakeUpGraph.LastModificationdate")}
                    </div>{" "}
                    {ConvertTimezone(SavedGraphs.LastAltered)}
                  </div>
                </div>
                <div className="right">
                  <button
                    onClick={() =>
                      handleContinue(
                        SavedGraphs.RequestID,
                        SavedGraphs.NumberOfGraphs
                      )
                    }
                  >
                    {t("MakeUpGraphDraftedGraph.Update")}
                  </button>
                </div>
              </SavedDiv>
            </div>
          ))}
        </MainCard>
      )}
      <Footer />
    </Body>
  );
};

export default MakeUpGraphDraftedGraph;
